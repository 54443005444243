import React, { memo, useRef, useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import SearchableDropDownItem from './SearchableDropDownItem';
import { SearchableStyles } from './style/searchableDropdownStyles';
import useOnClickOutside from '../../hooks/useOnClickOutside';

const SearchableNameDropDown = ({
  country,
  setCountry,
  apiList,
  HandleSelect,
  placeholder,
  DetailsIcons,
  style,
}) => {
  const ref = useRef();
  const [open, setOpen] = useState(false);
  const [dataCode, setDataCode] = useState([]);
  const [searchDetails, setSearchDetails] = useState();
  const [query, setQuery] = useState('');
  const [showSelectCondition, setShowSelectCondition] = useState(false);

  const handleChange = (e) => {
    const { value } = e.target;
    setCountry({ ...country, name: value });
    setQuery(value);
    const searchData = apiList.filter((item) =>
      item?.name?.toLowerCase()?.includes(value?.toLowerCase())
    );

    if (open === false) {
      setOpen(true);
    }

    setSearchDetails(searchData);
  };

  const openMenu = () => {
    if (showSelectCondition === false) {
      setOpen(true);
      setShowSelectCondition(true);
    } else if (showSelectCondition === true) {
      setOpen(false);
      setShowSelectCondition(false);
    }

    setQuery('');
    setDataCode(apiList);
  };

  const closeMenu = () => {
    setOpen(false);
  };

  useOnClickOutside(ref, () => setOpen(false));

  return (
    <React.Fragment>
      <SearchableStyles>
        <div
          onClick={openMenu}
          className="search-container"
          style={{ width: '100%', padding: '0px 5px', ...style }}
        >
          <div className="searchMetoInputBody">
            <input
              type="text"
              className="searchMetoInput"
              name="name"
              placeholder={placeholder}
              onChange={handleChange}
              autocomplete="off"
              value={(country?.name && country?.name) || ''}
            />
          </div>

          <div className="searchAbleIconDecorator">
            {open ? (
              <div className="searchIconBody">
                <IoIosArrowUp className="searchIcon" />
              </div>
            ) : (
              <div className="searchIconBody">
                <IoIosArrowDown className="searchIcon" />
              </div>
            )}
          </div>
        </div>

        <div style={{ width: '100%' }}>
          <SearchableDropDownItem
            open={open}
            closeMenu={closeMenu}
            ref={ref}
            HandleSelect={HandleSelect}
            List={dataCode}
            query={query}
            searchDetails={searchDetails}
            DetailsIcons={DetailsIcons}
            country={country}
          />
        </div>
      </SearchableStyles>
    </React.Fragment>
  );
};

export default memo(SearchableNameDropDown);
