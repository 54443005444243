import axios from "axios";
import React, { useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Modal from "react-bootstrap/Modal";
import { Api_Url } from "../../api_calls/url";

const UserSignup = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [creatingAccount, setCreatingAccount] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Error Occured");
  const [value, setValue] = useState();
  const [formValues, setFormValues] = useState({
    email: "",
    name: "",
    password: "",
    phone: "",
    user_type: "customer",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log("user data", formValues);
  };
  const toogleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      setCreatingAccount(true);
      const requiredValues = {
        ...formValues,
        phone_number: value,
      };
      console.log(requiredValues, "required values");
      const register = await axios.post(
        `${Api_Url}/api/v1/client_registration`,
        requiredValues
      );
      console.log(register.status, register.data);
      if (register.status === 201) {
        setCreatingAccount(false);
        setShowModal(true);
        setErrorState(false);
        localStorage.setItem("airdrop_user_email", formValues.email);
      }
    } catch (err) {
      console.log("error", err.response.data);
      setCreatingAccount(false);
      setShowModal(true);
      setErrorState(true);
      setErrorMessage(err.response.data.message);
    }
  };
  return (
    <div className="content">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-6 mx-auto">
            {!showModal && (
              <div className="login-wrap">
                <div className="login-header">
                  <h3>User Signup</h3>
                </div>

                {/* <!-- Login Form --> */}
                <div>
                  <div className="form-group">
                    <label className="col-form-label">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your name"
                      name="name"
                      value={formValues.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="johndoe@example.com"
                      name="email"
                      value={formValues.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">Phone</label>
                    <PhoneInput
                      placeholder="Enter phone number"
                      value={value}
                      class="form-control form-control-lg group_formcontrol"
                      onChange={setValue}
                    />
                    {/* <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your phone number"
                      name="phone"
                      value={formValues.phone}
                      onChange={handleChange}
                    /> */}
                  </div>
                  <div className="form-group">
                    <label className="col-form-label d-block">
                      Password
                      <span className="brief-bio float-end">
                        Must be 8 Characters at Least
                      </span>
                    </label>
                    <div className="pass-group">
                      <input
                        type={showPassword === true ? "text" : "password"}
                        className="form-control pass-input"
                        placeholder="*************"
                        name="password"
                        value={formValues.password}
                        onChange={handleChange}
                      />
                      <span
                        className={
                          showPassword === true
                            ? "toggle-password feather-eye"
                            : "toggle-password feather-eye-off"
                        }
                        onClick={toogleShowPassword}
                      ></span>
                    </div>
                  </div>
                  <button
                    className="btn btn-primary w-100 login-btn"
                    onClick={handleSignup}
                  >
                    {creatingAccount === true ? "creating" : "Signup"}
                  </button>
                  <p className="no-acc">
                    Already have an account ? <a href="/login"> Sign In</a>
                  </p>
                </div>
                {/* <!-- /Login Form --> */}
              </div>
            )}

            {showModal && (
              <div className="login-wrap">
                <div className="modal-content">
                  <div className="modal-body danger-body text-center">
                    {errorState ? (
                      <img src="assets/img/icons/sad.gif" alt="image" />
                    ) : (
                      <span style={{ fontSize: "80px" }}>
                        <i className="fa-regular fa-circle-check text-green"></i>
                      </span>
                    )}

                    <h3 className={errorState ? "text-danger" : ""}>
                      {errorState
                        ? "Error Occured !!!"
                        : "Registration Success"}
                    </h3>
                    <p className="success-modal-body">
                      {errorState
                        ? errorMessage
                        : "A one time password has been sent to your email for verification."}
                    </p>
                  </div>
                  {!errorState && (
                    <div className="popup-btn text-center">
                      <a
                        href="/email-otp"
                        className="btn btn-primary verify-document"
                      >
                        Proceed to Otp Verification
                      </a>
                    </div>
                  )}
                  {errorState && (
                    <div className="popup-btn text-center">
                      <a
                        href="#"
                        onClick={() => setShowModal(false)}
                        className="btn btn-primary verify-document"
                      >
                        Try again
                      </a>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSignup;
