import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ConfirmUserSubscription } from "../../../manage_states/gen-states/subscription/subscriptionServiceSync";

const CustomerSubscriptionSuccess = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  // Extract path segments
  // const pathSegments = location.pathname.split("/").filter(Boolean);
  // const id = pathSegments[pathSegments.length - 3]; // Third-to-last segment
  // const subscriptionType = pathSegments[pathSegments.length - 2]; // Second-to-last segment
  // const duration = pathSegments[pathSegments.length - 1]; // Last segment

  // Parse query parameters
  // const params = new URLSearchParams(location.search);
  // const status = params.get("status");

  // const { confirmingUserSub } = useSelector((state) => state.subcription);

  // useEffect(() => {
  //   if (id && subscriptionType && duration && status === "successful") {
  //     const payload = {
  //       subscription: subscriptionType,
  //       duration, // Use dynamic duration
  //     };

  //     console.log("Dispatching payload:", payload);
  //     dispatch(ConfirmUserSubscription(payload));
  //   }
  // }, [id, subscriptionType, duration, status, dispatch]);

  // Fallback for invalid URL
  // if (!id || !subscriptionType || !duration) {
  //   return <div>Error: Invalid or missing URL parameters</div>;
  // }

  return (
    <div className="content book-content">
      <div className="container">
        {/* {confirmingUserSub ? (
          <div>
            <h1>Loading...</h1>
          </div>
        ) : ( */}
          <div className="row">
            <div className="col-lg-10 mx-auto">
              {/* <ul className="step-register row">
                <li className="active col-md-12">
                  <div className="multi-step-icon">
                    <span>
                      <img
                        src="/assets/img/icons/book-done.svg"
                        alt="Payment Completed"
                      />
                    </span>
                  </div>
                  <div className="multi-step-info">
                    <h6>Subscription Success</h6>
                  </div>
                </li>
              </ul> */}
              <div className="row align-items-center">
                <div className="col-md-12">
                  <div style={{ textAlign: "center" }} className="BookingDone">
                    <h6>Subscription Package Activated</h6>
                    <p>
                      Your subscription package has been successfully activated.
                      Enjoy the benefits of your plan!
                    </p>
                    <div className="book-submit">
                      <a href="/customer" className="btn btn-secondary">
                        Proceed to dashboard
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div style={{ textAlign: "center" }} className="BookingDone">
                    <img
                      src="/assets/img/subscription-success-img.avif"
                      className="img-fluid"
                      alt="Booking Done"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        {/* )} */}
      </div>
    </div>
  );
};

export default CustomerSubscriptionSuccess;
