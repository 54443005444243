import React from "react";
import LoginRegisterNav from "../common/nav/login-register-nav";
import ProviderSignup from "../components/register/provider-sign-up";

const ProviderSignupPage = () => {
  return (
    <div className="main-wrapper">
      <LoginRegisterNav />
      <ProviderSignup />
    </div>
  );
};

export default ProviderSignupPage;
