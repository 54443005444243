import React, { useEffect, useState } from "react";
import APIs from "../../manage_states/Api";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Api_Url } from "../../api_calls/url";
import { toast } from "react-toastify";

const ChangeForgotPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [creating, setCreating] = useState();
  const token = localStorage.getItem("airdrop_change_pass_token");
  const navigate = useNavigate();
  const [errors, setErrors] = useState({
    password: '',
    confirmPassword: '',
  });

  const validatePassword = (password) => {
    // Example validation: Minimum 8 characters, at least 1 letter, 1 number
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    return passwordRegex.test(password);
  };

  useEffect(() => {
    if (!token) {
      navigate("/sign-up");
    }
  }, []);

  const toogleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Validate password and confirmPassword fields
    if (name === 'password') {
      if (!validatePassword(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          password: 'Password must be at least 8 characters long and include at least one letter and one number.',
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, password: '' }));
      }
    }

    if (name === 'confirmPassword') {
      if (value !== formData.password) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          confirmPassword: 'Passwords do not match.',
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: '' }));
      }
    }
  };

  const handleSend = async (e) => {
    e.preventDefault();
    if (!formData.password || !formData.confirmPassword) {
      toast('Please fill out all fields.');
      return;
    }

    if (errors.password || errors.confirmPassword) {
      toast('Please fix the errors before submitting.');
      return;
    }
    try {
      setCreating(true);
      // Retrieve the token (replace 'your_token_key' with the actual key you use to store the token)
      const token = localStorage.getItem("airdrop_change_pass_token");
  
      const register = await axios.patch(
        `${Api_Url}/api/v1/edit_user_password`,
        {newPassword: formData.password},
        {
          headers: {
            Authorization: `Bearer ${token}`, 
          },
        }
      );
  
      console.log(register.status, register.data);
      if (register.status === 200) {
        localStorage.removeItem("airdrop_change_pass_token");
        navigate("/login");
        toast("password changed successful")
      }
    } catch (err) {
      console.log("error", err.response.data);
    } finally {
      setCreating(false); // Reset creating state
    }
  };
  
  return (
    <div className="content">
      <div className="container">
        <div className="row">
          {/* Password Recovery */}
          <div className="col-md-6 col-lg-6 mx-auto">
            <div className="login-wrap">
              <div className="login-header">
                <h3>Create a New Password</h3>
                <p>Please type a password</p>
              </div>
              <>
                <div className="log-form">
                  <div className="form-group">
                    <label className="col-form-label">Type New Password</label>
                    <div class="pass-group">
                      <input
                        type={showPassword === true ? "text" : "password"}
                        class="form-control pass-input"
                        placeholder="*************"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                      />
                      <span
                        class={
                          showPassword === true
                            ? "toggle-password feather-eye"
                            : "toggle-password feather-eye-off"
                        }
                        onClick={toogleShowPassword}
                      ></span>
                    </div>
                    {errors.password && <p style={{ color: 'red' }}>{errors.password}</p>}
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">Confirm Password</label>
                    <div class="pass-group">
                      <input
                        type={showConfirmPassword === true ? "text" : "password"}
                        class="form-control pass-input"
                        placeholder="*************"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                      />
                      <span
                        class={
                          showConfirmPassword === true
                            ? "toggle-password feather-eye"
                            : "toggle-password feather-eye-off"
                        }
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      ></span>
                    </div>
                    {errors.confirmPassword && <p style={{ color: 'red' }}>{errors.confirmPassword}</p>}
                  </div>
                </div>
                <button
                  className="btn btn-primary w-100 login-btn"
                  onClick={handleSend}
                >
                  {creating ? "updating..." : "Submit"}
                </button>
                <p className="no-acc mt-0">
                  Remember Password ? <a href="/login">Login</a>
                </p>
              </>
            </div>
          </div>
          {/* /Password Recovery */}
        </div>
      </div>
    </div>
  );
};

export default ChangeForgotPassword;
