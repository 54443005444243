import React from 'react'
import OwlCarousel from 'react-owl-carousel'

const OurPartners = () => {
  const options = {
    nav: true,
    dots: true,
    margin: 20,
    loop: true,
    autoPlay: true,
    autoplayTimeout: 4000,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      768: {
        items: 2,
      },
      992: {
        items: 5,
      },
      1200: {
        items: 5,
      },
    },
  }
  return (
    <section class='blog-section pt-0'>
      <div class='container'>
        <div class='row'>
          <div class='col-md-12 text-center aos ' data-aos='fade-up'>
            <div class='section-heading'>
              <h2>Our Partners</h2>
              <p>Our Partners can be found in all Industries</p>
            </div>
          </div>
          <OwlCarousel
            class='owl-carousel partners-slider aos '
            data-aos='fade-up'
            autoplay={true}
            loop
            margin={10}
            {...options}
          >
            <div class='partner-img'>
              <img src='assets/img/partner/partner1.svg' alt='img' />
            </div>
            <div class='partner-img'>
              <img src='assets/img/partner/partner2.svg' alt='img' />
            </div>
            <div class='partner-img'>
              <img src='assets/img/partner/partner3.svg' alt='img' />
            </div>
            <div class='partner-img'>
              <img src='assets/img/partner/partner4.svg' alt='img' />
            </div>
            <div class='partner-img'>
              <img src='assets/img/partner/partner5.svg' alt='img' />
            </div>
            <div class='partner-img'>
              <img src='assets/img/partner/partner6.svg' alt='img' />
            </div>
          </OwlCarousel>
        </div>
      </div>
    </section>
  )
}

export default OurPartners
