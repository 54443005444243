import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  VendorSubscribe,
  UserSubscribe,
  ConfirmUserSubscription,
  ConfirmVendorSubscription,
} from "./subscriptionServiceSync";

const initialState = {
  loading: false,
  user: null,
  token: localStorage.getItem("token") ? localStorage.getItem("token") : null,
  error: null,

  userSubscribing: false,
  userSubscribeSuccess: false,
  userSubscribeError: false,
  userSubscribeMessage: "",

  // confirm subscription
  confirmingUserSub: false,
  confirmingUserSubSuccess: false,
  confirmingUserSubError: false,
  confirmingUserSubMessage: "",

  // request refund
  vendorSubing: false,
  vendorSubSuccess: false,
  vendorSubError: false,
  vendorSubMessage: "",

  // vendor confirm sub
  confirmingVendorSub: false,
  confirmVendorSubError: false,
  confirmVendorSubSuccess: false,
};

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
      localStorage.removeItem("token");
      toast.info("Logged out successfully");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(UserSubscribe.pending, (state) => {
        state.userSubscribing = true;
      })
      .addCase(UserSubscribe.fulfilled, (state, { payload }) => {
        state.userSubscribing = false;
        state.userSubscribeSuccess = true;
        state.userSubscribeMessage = "transaction successful";
      })
      .addCase(UserSubscribe.rejected, (state, { payload }) => {
        state.userSubscribing = false;
        state.userSubscribeError = true;
        state.userSubscribeMessage = "";
      })
      .addCase(ConfirmUserSubscription.pending, (state) => {
        state.confirmingUserSub = true;
      })
      .addCase(ConfirmUserSubscription.fulfilled, (state, { payload }) => {
        state.confirmingUserSub = false;
        state.confirmingUserSubSuccess = true;
        state.confirmingUserSubMessage = "transaction successful";
      })
      .addCase(ConfirmUserSubscription.rejected, (state, { payload }) => {
        state.confirmingUserSub = false;
        state.confirmingUserSubError = true;
        state.confirmingUserSubMessage = "";
      })
      .addCase(VendorSubscribe.pending, (state) => {
        state.vendorSubing = true;
      })
      .addCase(VendorSubscribe.fulfilled, (state, { payload }) => {
        state.vendorSubing = false;
        state.vendorSubSuccess = true;
        state.vendorSubMessage = "fund request successful";
      })
      .addCase(VendorSubscribe.rejected, (state, { payload }) => {
        state.vendorSubing = false;
        state.vendorSubError = true;
        state.vendorSubMessage = "Error in fund request";
      })
      .addCase(ConfirmVendorSubscription.pending, (state) => {
        state.confirmingVendorSub = true;
      })
      .addCase(ConfirmVendorSubscription.fulfilled, (state, { payload }) => {
        state.confirmingVendorSub = false;
        state.confirmVendorSubSuccess = true;
      })
      .addCase(ConfirmVendorSubscription.rejected, (state, { payload }) => {
        state.confirmingVendorSub = false;
        state.confirmVendorSubError = true;
      });
  },
});

export const { logout } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
