import React from "react";
import ServiceIndex from "../../../components/dashboard/provider/services/ServiceIndex";

const ServiceList = () => {
  return (
    <>
      <ServiceIndex />
    </>
  );
};

export default ServiceList;
