/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/context'
import { useDispatch, useSelector } from 'react-redux'
import { getServices } from '../../manage_states/gen-states/services/servicesSync'
import { updateServicesSkip } from '../../manage_states/gen-states/services/slice'
import { useNavigate } from 'react-router-dom'

const ServicesList = ({ mainClass = 'col-lg-9 col-sm-12', serviceList }) => {
  const dispatch = useDispatch()

  const { serviceLimit, serviceSkip, services, loadingServices } = useSelector(
    (state) => state.services
  )

  useEffect(() => {
    dispatch(getServices({ page: serviceSkip, limit: serviceLimit }))
  }, [])

  const navigate = useNavigate()

  const handleBookAppointment = (params) => {
    const isAuthenticated = !!localStorage.getItem('airdrops_token') // Check if token exists

    if (isAuthenticated) {
      navigate(`/book-service/${params}`) // If logged in, go to booking
    } else {
      navigate('/login') // If not logged in, go to login
    }
  }

  const nextPage = () => {
    if (!loadingServices) {
      if (services?.hasNextPage) {
        dispatch(updateServicesSkip(serviceSkip + 1))

        dispatch(
          getServices({
            page: serviceSkip + 1,
            limit: serviceLimit,
          })
        )
      }
    }
  }

  const prevPage = () => {
    if (!loadingServices) {
      if (services?.hasPrevPage) {
        dispatch(updateServicesSkip(serviceSkip - 1))

        dispatch(
          getServices({
            page: serviceSkip - 1,
            limit: serviceLimit,
          })
        )
      }
    }
  }

  return (
    <>
      <div className={mainClass}>
        <div className='row sorting-div'>
          <div className='col-lg-4 col-sm-12'>
            <div className='count-search'>
              <h6>Found 6 Services</h6>
            </div>
          </div>
          <div className='col-lg-8 col-sm-12 d-flex justify-content-end'>
            <div className='sortbyset'>
              <div className='sorting-select'>
                <select className='form-control select'>
                  <option>Price Low to High</option>
                  <option>Price High to Low</option>
                </select>
              </div>
            </div>
            <div className='grid-listview'>
              <ul>
                {/* <li>
                  <a href='service-grid.html'>
                    <i className='feather-grid' />
                  </a>
                </li> */}
                {/* <li>
                  <a href='service-list.html' className='active'>
                    <i className='feather-list' />
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            {/* Service List */}
            {serviceList?.docs?.map((service, i) => (
              <div key={i} className='service-list'>
                <div className='service-cont'>
                  <div className='service-cont-img'>
                    <a href={`/service-details/${service?.service_title}`}>
                      <img
                        className='img-fluid serv-img'
                        alt='Service icon'
                        src={`${service?.gallery[0]?.pic_url}`}
                      />
                    </a>
                    <div className='fav-item'>
                      <a href='#' className='fav-icon'>
                        <i className='feather-heart' />
                      </a>
                    </div>
                  </div>
                  <div className='service-cont-info'>
                    <span className='item-cat'>{service?.category}</span>
                    <h3 className='title'>
                      <a
                        style={{ textTransform: 'uppercase' }}
                        href={`/service-details/${service?.service_title}`}
                      >
                        {service?.service_title}
                      </a>
                    </h3>
                    <p>
                      {/* <i className="feather-map-pin" /> */}
                      {service?.description}, {service?.city},{service?.country}
                    </p>
                    {/* <div className="service-pro-img">
                      <img src="assets/img/profiles/avatar-01.jpg" alt="user" />
                      <span>
                        <i className="fas fa-star filled" />
                        4.9
                      </span>
                    </div> */}
                  </div>
                </div>
                <div className='service-action'>
                  {/* <h6>
                    ${service?.price}.00
                  </h6> */}
                  <a
                    href={`/book-service/${service?.service_title}`}
                    className='btn btn-secondary'
                    onClick={(e) => {
                      e.preventDefault() // Prevents the default anchor tag behavior
                      handleBookAppointment(service?.service_title) // Calls your custom function
                    }}
                  >
                    Book Now
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* Pagination */}
        <div className='row'>
          <div className='col-sm-12'>
            <div className='blog-pagination rev-page'>
              <nav>
                <ul className='pagination justify-content-center mt-0'>
                  <li className='page-item disabled'>
                    <a className='page-link page-prev' href='#' tabIndex={-1}>
                      <i className='fa-solid fa-arrow-left me-1' /> PREV
                    </a>
                  </li>
                  <li className='page-item active'>
                    <a className='page-link' href='#'>
                      1
                    </a>
                  </li>
                  <li className='page-item'>
                    <a className='page-link' href='#'>
                      2
                    </a>
                  </li>
                  <li className='page-item'>
                    <a className='page-link' href='#'>
                      3
                    </a>
                  </li>
                  <li className='page-item'>
                    <a className='page-link page-next' href='#'>
                      NEXT <i className='fa-solid fa-arrow-right ms-1' />
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        {/* /Pagination */}
      </div>
    </>
  )
}

export default ServicesList
