import React from "react";
import ServicesComp from "../components/services";

const ServicesPage = () => {
  return (
    <div>
      <ServicesComp />
    </div>
  );
};

export default ServicesPage;
