import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppContext } from '../../../context/context';
import {
  updateCurrentRoom,
  updateMessage,
  updateNormalRoom,
  updateSelectedRoom,
} from '../../../manage_states/gen-states/room/roomSlice';
import { UploadImages } from '../../../manage_states/gen-states/room/roomsServiceSync';
import axios from 'axios';
import moment from 'moment';
import { PhotoView } from 'react-photo-view';
import { InfinitySpin } from 'react-loader-spinner';

const ChatScreen = () => {
  const dispatch = useDispatch();

  const [typedMessage, setTypedMessage] = useState('');

  const [uploadImages, setUploadImages] = useState({ length: 0 });

  const { user } = useSelector((state) => state.auth);

  const { socket } = useContext(AppContext);

  const {
    normalRooms,
    current_room,
    messages,
    selectedRoom,
    roomImageLoader,
    roomImageDetails,
  } = useSelector((state) => state.room);

  useEffect(() => {
    dispatch(updateCurrentRoom(''));

    dispatch(updateSelectedRoom({}));

    dispatch(updateNormalRoom([]));

    dispatch(updateMessage(null));
  }, []);

  useEffect(() => {
    const requestHandler = async () => {
      if (user) {
        const payload = {
          name: user?.name,
          email: user?.email,
          picture: user?.profile_pic,
          roomOwner: user?._id,
          room: `vendor-${user?._id}-admin`,
        };

        await socket.emit('create-vendor-user', payload);

        // socket.emit('my-vendor-new-user', { id: user?._id });
      }
    };

    requestHandler();
  }, [user]);

  socket.off('total-vendor-user').on('total-vendor-user', (payload) => {
    dispatch(updateNormalRoom(payload));
  });

  socket
    .off('normal-new-vendor-user')
    .on('normal-new-vendor-user', (payload) => {
      dispatch(updateNormalRoom(payload));
    });

  socket
    .off('normal-vendor-room-messages')
    .on('normal-vendor-room-messages', (roomMessages) => {
      dispatch(updateMessage(roomMessages));
    });

  const JoinRoom = (room, currentRoom, isPublic = true) => {
    if (!user) {
      return alert('please login');
    }

    socket.emit('vendor-join-room', room.room, currentRoom, room);

    dispatch(updateCurrentRoom(room.room));

    dispatch(updateSelectedRoom(room));
  };

  useEffect(() => {
    if (normalRooms && user) {
      JoinRoom({ ...normalRooms[0] });
    }
  }, [normalRooms, user]);

  const handleChange = (e) => {
    const { value } = e.target;
    setTypedMessage(value);
  };

  const getFormattedDate = () => {
    const date = new Date();

    const year = date.getFullYear();

    let month = (1 + date.getMonth()).toString();

    month = month.length > 1 ? month : '0' + month;

    let day = date.getDate().toString();

    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
  };

  const todayDate = getFormattedDate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!typedMessage) return;

    let today = new Date();

    const minutes =
      today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes();

    const time = today.getHours() + ':' + minutes;

    const roomId = current_room;
    if (uploadImages.length === 0) {
      socket.emit(
        'vendor-message-room',
        roomId,
        typedMessage,
        user,
        time,
        todayDate,
        false,
        []
      );
    } else {
      const ImageDetails = roomImageDetails?.map((item) => {
        return {
          pic_url: item?.image?.secure_url,
          pic_id: item?.image?.public_id,
        };
      });

      socket.emit(
        'vendor-message-room',
        roomId,
        typedMessage,
        user,
        time,
        todayDate,
        true,
        ImageDetails
      );

      setUploadImages({ length: 0 });
    }

    setTypedMessage('');
  };

  const handleUploadImage = async (e) => {
    const image = e.target.files;

    if (image) {
      const formData = new FormData();

      Array.from(image).forEach((file, i) => {
        formData.append(`file`, file);
      });

      await dispatch(UploadImages(formData));

      setUploadImages(image);
    }
  };

  useEffect(() => {
    if (roomImageDetails?.length > 0) {
      roomImageDetails.forEach((file, i) => {
        setTypedMessage((value) => value + ' ' + file?.image?.secure_url);
      });
    }
  }, [roomImageDetails]);

  const downloadImage = async (item) => {
    console.log(
      item?.split('/')[item?.split('/').length - 2],
      item?.split('/')[item?.split('/').length - 1].split('.')[0],
      'my love'
    );

    const response = await axios({
      url: item,
      method: 'GET',
      responseType: 'blob', // Important: Use 'blob' for binary data
    });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(new Blob([response.data]));
    link.setAttribute(
      'download',
      `${item?.split('/')[item?.split('/').length - 2]}_${
        item?.split('/')[item?.split('/').length - 1].split('.')[0]
      }.${item?.split('/')[item?.split('/').length - 1].split('.')[1]}`
    ); // Custom filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  console.log(normalRooms, 'going help');

  console.log(selectedRoom, 'the lord is my God and my lord');

  return (
    <div className="">
      <div className="content">
        <div className="container">
          <div className="customer-chat">
            <div className="row chat-window">
              {/* <div class="login-back mb-3">
								<a href="customer-dashboard.html"> <i class="feather-arrow-left"></i> Back</a>
							</div> */}
              {/* Chat User List */}
              <div className="col-lg-3 chat-cont-left d-flex">
                <div className="card mb-sm-3 mb-md-0 contacts_card flex-fill">
                  <div className="chat-header">
                    <div>
                      <h6>Chats</h6>
                      <p>Start New Conversation</p>
                    </div>
                    {/* <a href="javascript:void(0)" class="chat-compose">
											<i class="feather-plus"></i>
										</a> */}
                  </div>
                  {/* <div className="chat-search chat-search-head">
                    <div className="input-group">
                      <input
                        type="text"
                        placeholder="Search"
                        className="form-control search-chat chat-search"
                      />
                      <div className="search-icon-right">
                        <span className="search_btn">
                          <i className="feather-search" />
                        </span>
                      </div>
                    </div>
                  </div> */}
                  <div className="card-body contacts_body chat-users-list chat-scroll">
                    {normalRooms?.length > 0 &&
                      normalRooms?.map((item, key) => (
                        <a
                          href="javascript:void(0);"
                          onClick={() => JoinRoom(item)}
                          key={key}
                          className="media d-flex read-chat"
                        >
                          <div className="media-img-wrap flex-shrink-0">
                            <div className="avatar avatar-online">
                              <img
                                src={`/assets/img/avatar-profile-photo.png`}
                                alt="User Imag"
                                className="avatar-img rounded-circle"
                              />
                            </div>
                          </div>
                          <div className="media-body flex-grow-1">
                            <div>
                              <div className="user-name">Admin</div>
                              <div className="user-last-chat">
                                {/* <i className="feather-image" /> Image */}
                              </div>
                            </div>
                            <div>
                              <div className="last-chat-time">
                                {' '}
                                {moment(item?.lastTime).format('hh:mm:ss a')}
                              </div>

                              {item?.client_notification > 0 && (
                                <div className="badge badge-primary badge-pill">
                                  {item?.client_notification}
                                </div>
                              )}
                            </div>
                          </div>
                        </a>
                      ))}
                  </div>
                </div>
              </div>
              {/* Chat User List */}
              {/* Chat Content */}
              <div className="col-lg-9 chat-cont-right chat-window-long">
                {/* Chat History */}
                <div className="card chat-window mb-0">
                  {current_room && (
                    <>
                      <div className="card-header msg_head">
                        <div className="d-flex bd-highlight">
                          <a
                            id="back_user_list"
                            href="#"
                            className="back-user-list"
                          >
                            <i className="fas fa-chevron-left" />
                          </a>
                          <div className="img_cont">
                            <img
                              className="rounded-circle user_img"
                              src={
                                selectedRoom?.picture
                                  ? selectedRoom?.picture
                                  : `/assets/img/avatar-profile-photo.png`
                              }
                              alt="User"
                            />
                          </div>
                          <div className="user_info">
                            <span>{`Admin`}</span>
                            <p className="mb-0 active">
                              {selectedRoom?.status === 'online'
                                ? 'Online'
                                : 'Offline'}
                            </p>
                          </div>
                        </div>
                        <div className="chat-options">
                          <ul>
                            <li>
                              <a href="#">
                                <i className="feather-volume-2" />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="feather-search" />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="feather-video" />
                              </a>
                            </li>
                            <li>
                              <a href="#" className="chat-profile-open">
                                <i className="feather-user" id="task_chat" />
                              </a>
                            </li>
                            <li>
                              <a href="#" className="with-bg">
                                <i className="feather-more-horizontal" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-body msg_card_body chat-scroll pt-0">
                        <ul className="list-unstyled">
                          {messages?.map((item, key) => (
                            <Fragment key={key}>
                              <li className="chat-date mt-0">
                                <span>{item?._id}</span>
                              </li>

                              {item?.messagesByDate?.map((data, key) => (
                                <>
                                  {data?.from?._id === user?._id ? (
                                    <>
                                      {data?.image === true ? (
                                        <>
                                          <li className="media received d-flex">
                                            <div className="avatar flex-shrink-0">
                                              <img
                                                src={
                                                  item?.from?.profile_pic
                                                    ? item?.from?.profile_pic
                                                    : `/assets/img/avatar-profile-photo.png`
                                                }
                                                alt="User Imag"
                                                className="avatar-img rounded-circle"
                                              />
                                            </div>
                                            <div className="media-body flex-grow-1">
                                              <div className="msg-box">
                                                <div className="msg-img">
                                                  <div className="chat-msg-attachments">
                                                    {data?.imageDetails?.map(
                                                      (image, ind) => (
                                                        <div
                                                          key={ind}
                                                          className="chat-attachment"
                                                        >
                                                          <PhotoView
                                                            src={
                                                              image?.pic_url ||
                                                              '/assets/img/chat-img-01.jpg'
                                                            }
                                                          >
                                                            <img
                                                              src={
                                                                image?.pic_url ||
                                                                '/assets/img/chat-img-01.jpg'
                                                              }
                                                              alt="Attachment"
                                                              style={{
                                                                maxHeight:
                                                                  '90px',
                                                              }}
                                                            />
                                                          </PhotoView>
                                                          <a
                                                            href="javascript:void(0);"
                                                            className="chat-attach-download"
                                                            onClick={() =>
                                                              downloadImage(
                                                                image?.pic_url ||
                                                                  '/assets/img/chat-img-01.jpg'
                                                              )
                                                            }
                                                          >
                                                            <i className="fas fa-download" />
                                                          </a>
                                                        </div>
                                                      )
                                                    )}
                                                  </div>
                                                </div>
                                                <span className="drop-item message-more-option">
                                                  <a
                                                    href="#"
                                                    className="more-vertical-bar"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                  >
                                                    <i className="feather-more-vertical" />
                                                  </a>
                                                  <small className="dropdown-menu">
                                                    <a
                                                      className="dropdown-item"
                                                      href="#"
                                                    >
                                                      <i className="feather-copy me-2" />
                                                      Copy
                                                    </a>
                                                    <a
                                                      className="dropdown-item"
                                                      href="#"
                                                    >
                                                      <i className="feather-trash-2 me-2" />
                                                      Delete
                                                    </a>
                                                  </small>
                                                </span>
                                                <ul className="chat-msg-info name-date">
                                                  <li>Hendrita</li>
                                                  <li>
                                                    <span className="chat-time">
                                                      09:30 am
                                                      <i className="fa-solid fa-check-double un-read" />
                                                    </span>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </li>
                                        </>
                                      ) : (
                                        <li
                                          className="media received d-flex"
                                          key={key}
                                        >
                                          <div className="avatar flex-shrink-0">
                                            <img
                                              src={
                                                item?.from?.profile_pic
                                                  ? item?.from?.profile_pic
                                                  : `/assets/img/avatar-profile-photo.png`
                                              }
                                              alt="User Imag"
                                              className="avatar-img rounded-circle"
                                            />
                                          </div>
                                          <div className="media-body flex-grow-1">
                                            <div className="msg-box">
                                              <div>
                                                <p>{data?.content}</p>
                                              </div>
                                              <span className="drop-item message-more-option">
                                                <a
                                                  href="#"
                                                  className="more-vertical-bar"
                                                  data-bs-toggle="dropdown"
                                                  aria-expanded="false"
                                                >
                                                  <i className="feather-more-vertical" />
                                                </a>
                                                <small className="dropdown-menu">
                                                  <a
                                                    className="dropdown-item"
                                                    href="#"
                                                  >
                                                    <i className="feather-copy me-2" />
                                                    Copy
                                                  </a>
                                                  <a
                                                    className="dropdown-item"
                                                    href="#"
                                                  >
                                                    <i className="feather-trash-2 me-2" />
                                                    Delete
                                                  </a>
                                                </small>
                                              </span>
                                              <ul
                                                className="chat-msg-info name-date"
                                                style={{
                                                  minWidth: '160px',
                                                }}
                                              >
                                                <li>{data?.from?.name}</li>
                                                <li>
                                                  <span className="chat-time">
                                                    {data?.time}
                                                    <i className="fa-solid fa-check-double read" />
                                                  </span>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </li>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {data?.image === true ? (
                                        <li className="media sent d-flex">
                                          <div className="avatar flex-shrink-0">
                                            <img
                                              src={
                                                item?.from?.profile_pic
                                                  ? item?.from?.profile_pic
                                                  : `/assets/img/avatar-profile-photo.png`
                                              }
                                              alt="User Imag"
                                              className="avatar-img rounded-circle"
                                            />
                                          </div>
                                          <div className="media-body flex-grow-1">
                                            <div className="msg-box">
                                              <div className="msg-img">
                                                <div className="chat-msg-attachments">
                                                  {data?.imageDetails?.map(
                                                    (image, ind) => (
                                                      <div
                                                        key={ind}
                                                        className="chat-attachment"
                                                      >
                                                        <PhotoView
                                                          src={
                                                            image?.pic_url ||
                                                            '/assets/img/chat-img-01.jpg'
                                                          }
                                                        >
                                                          <img
                                                            src={
                                                              image?.pic_url ||
                                                              '/assets/img/chat-img-01.jpg'
                                                            }
                                                            alt="Attachment"
                                                            style={{
                                                              maxHeight: '90px',
                                                            }}
                                                          />
                                                        </PhotoView>
                                                        <a
                                                          href="javascript:void(0);"
                                                          className="chat-attach-download"
                                                          onClick={() =>
                                                            downloadImage(
                                                              image?.pic_url ||
                                                                '/assets/img/chat-img-01.jpg'
                                                            )
                                                          }
                                                        >
                                                          <i className="fas fa-download" />
                                                        </a>
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              </div>
                                              <span className="drop-item message-more-option">
                                                <a
                                                  href="#"
                                                  className="more-vertical-bar"
                                                  data-bs-toggle="dropdown"
                                                  aria-expanded="false"
                                                >
                                                  <i className="feather-more-vertical" />
                                                </a>
                                                <small className="dropdown-menu">
                                                  <a
                                                    className="dropdown-item"
                                                    href="#"
                                                  >
                                                    <i className="feather-copy me-2" />
                                                    Copy
                                                  </a>
                                                  <a
                                                    className="dropdown-item"
                                                    href="#"
                                                  >
                                                    <i className="feather-trash-2 me-2" />
                                                    Delete
                                                  </a>
                                                </small>
                                              </span>
                                              <ul className="chat-msg-info name-date">
                                                <li>Hendrita</li>
                                                <li>
                                                  <span className="chat-time">
                                                    09:30 am
                                                    <i className="fa-solid fa-check-double un-read" />
                                                  </span>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </li>
                                      ) : (
                                        <li className="media sent d-flex">
                                          <div className="avatar flex-shrink-0">
                                            <img
                                              src={
                                                item?.from?.profile_pic
                                                  ? item?.from?.profile_pic
                                                  : `/assets/img/avatar-profile-photo.png`
                                              }
                                              alt="User Imag"
                                              className="avatar-img rounded-circle"
                                            />
                                          </div>
                                          <div className="media-body flex-grow-1">
                                            <div className="msg-box">
                                              <div>
                                                <p>{data?.content}</p>
                                              </div>
                                              <span className="drop-item message-more-option">
                                                <a
                                                  href="#"
                                                  className="more-vertical-bar"
                                                  data-bs-toggle="dropdown"
                                                  aria-expanded="false"
                                                >
                                                  <i className="feather-more-vertical" />
                                                </a>
                                                <small className="dropdown-menu">
                                                  <a
                                                    className="dropdown-item"
                                                    href="#"
                                                  >
                                                    <i className="feather-copy me-2" />
                                                    Copy
                                                  </a>
                                                  <a
                                                    className="dropdown-item"
                                                    href="#"
                                                  >
                                                    <i className="feather-trash-2 me-2" />
                                                    Delete
                                                  </a>
                                                </small>
                                              </span>
                                              <ul
                                                className="chat-msg-info name-date"
                                                style={{
                                                  minWidth: '160px',
                                                }}
                                              >
                                                <li>
                                                  <span className="chat-time">
                                                    {data?.time}
                                                    <i className="fa-solid fa-check" />
                                                  </span>
                                                </li>
                                                <li>{'Service Advicer'}</li>
                                              </ul>
                                            </div>
                                          </div>
                                        </li>
                                      )}
                                    </>
                                  )}
                                </>
                              ))}
                            </Fragment>
                          ))}
                        </ul>
                      </div>

                      <div className="card-footer">
                        {roomImageLoader ? (
                          <InfinitySpin width="150" color="#ED1D24" />
                        ) : (
                          <div className="input-group">
                            <div className="btn-file btn">
                              <i className="feather-plus" />
                              <input
                                type="file"
                                multiple
                                disabled={roomImageLoader}
                                onChange={handleUploadImage}
                              />
                            </div>
                            <input
                              className="form-control type_msg mh-auto empty_check"
                              placeholder="Write your message..."
                              value={typedMessage || ''}
                              autocomplete="off"
                              onChange={handleChange}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  handleSubmit(e);
                                }
                              }}
                            />
                            <div className="send-action">
                              <a href="#">
                                <i className="fa-solid fa-face-smile" />
                              </a>
                              <a href="#">
                                <i className="feather-mic" />
                              </a>
                              <button
                                className="btn btn-primary btn_send"
                                onClick={handleSubmit}
                              >
                                <i
                                  className="fa fa-paper-plane"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
                {/* Chat Profile */}
                <div
                  className="chat-cont-profile chat-profile-right"
                  id="task_window"
                >
                  <div className="card mb-sm-3 mb-md-0 flex-fill">
                    <div className="card-header">
                      <div className="profile-wrap">
                        <div className="chat-profile">
                          <a
                            id="back_user_lists"
                            href="javascript:void(0)"
                            className="back-user-list"
                          >
                            <i className="fas fa-chevron-left" />
                          </a>
                          <div className="chat-profile-img">
                            <img
                              src="/assets/img/profiles/chat-profile-01.jpg"
                              alt="User"
                            />
                          </div>
                          <div className="chat-profile-info">
                            <h6>Hendrita</h6>
                            <p>Newyork</p>
                          </div>
                        </div>
                        <ul>
                          <li>
                            <a href="javascript:void(0);">
                              <i className="feather-mail" />
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <i className="feather-phone" />
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <i className="feather-map-pin" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="chat-pro-list">
                        <ul>
                          <li>
                            <span className="role-title">
                              <i className="fa-solid fa-briefcase" />
                              Company
                            </span>
                            <span className="role-info">Company Name</span>
                          </li>
                          <li>
                            <span className="role-title">
                              <i className="fa-solid fa-user" />
                              Role
                            </span>
                            <span className="role-info">Co-founder</span>
                          </li>
                          <li>
                            <span className="role-title">
                              <i className="fa-solid fa-phone" />
                              Phone
                            </span>
                            <span className="role-info">+1 123 456 7890</span>
                          </li>
                          <li>
                            <span className="role-title">
                              <i className="fa-solid fa-envelope" />
                              Email
                            </span>
                            <span className="role-info">
                              <a
                                href="https://html.truelysell.com/cdn-cgi/l/email-protection"
                                className="__cf_email__"
                                data-cfemail="4e272028210e242126203d3a2b382b20602d2123"
                              >
                                [email&nbsp;protected]
                              </a>
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="chat-media">
                        <div className="chat-media-title">
                          <h6>Shared Media</h6>
                          <span>(3475 items)</span>
                        </div>
                        <div className="media-list">
                          <ul className="nav">
                            <li>
                              <a
                                href="javascript:void(0);"
                                data-bs-toggle="tab"
                                data-bs-target="#chat-photo"
                                className="active"
                              >
                                Photos
                              </a>
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                data-bs-toggle="tab"
                                data-bs-target="#chat-file"
                              >
                                File
                              </a>
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                data-bs-toggle="tab"
                                data-bs-target="#chat-vdo"
                              >
                                Video
                              </a>
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                data-bs-toggle="tab"
                                data-bs-target="#chat-link"
                              >
                                Link
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="tab-content pt-0">
                          <div className="tab-pane active" id="chat-photo">
                            <div className="photo-list">
                              <ul>
                                <li>
                                  <img
                                    src="/assets/img/services/service-03.jpg"
                                    alt="img"
                                  />
                                </li>
                                <li>
                                  <img
                                    src="/assets/img/services/service-04.jpg"
                                    alt="img"
                                  />
                                </li>
                                <li>
                                  <img
                                    src="/assets/img/services/service-05.jpg"
                                    alt="img"
                                  />
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="tab-pane fade" id="chat-vdo">
                            <div className="photo-list">
                              <ul>
                                <li>
                                  <img
                                    src="/assets/img/services/service-01.jpg"
                                    alt="img"
                                  />
                                </li>
                                <li>
                                  <img
                                    src="/assets/img/services/service-04.jpg"
                                    alt="img"
                                  />
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="tab-pane fade" id="chat-file">
                            <div className="photo-file">
                              <ul>
                                <li>
                                  <i className="feather-file-text me-2" />{' '}
                                  admin_v1.0.zip
                                </li>
                                <li>
                                  <i className="feather-file-text me-2" />{' '}
                                  test.pdf
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="tab-pane fade" id="chat-link">
                            <div className="photo-link">
                              <ul>
                                <li>
                                  <a href="#">
                                    <i className="feather-link-2 me-2" /> Sed ut
                                    perspiciatis
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="feather-link-2 me-2" />{' '}
                                    https:example.com
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="chat-notification">
                          <ul>
                            <li>
                              <p>
                                <i className="fa-solid fa-bell" /> Notifications
                              </p>
                              <div className="status-toggle blue-tog d-flex justify-content-sm-end align-items-center">
                                <input
                                  type="checkbox"
                                  id="status_1"
                                  className="check"
                                  defaultChecked
                                />
                                <label
                                  htmlFor="status_1"
                                  className="checktoggle"
                                >
                                  checkbox
                                </label>
                              </div>
                            </li>
                            <li>
                              <p>
                                <i className="fa-solid fa-star" /> Add To
                                Favourites
                              </p>
                              <div className="status-toggle blue-tog d-flex justify-content-sm-end align-items-center">
                                <input
                                  type="checkbox"
                                  id="status_2"
                                  className="check"
                                  defaultChecked
                                />
                                <label
                                  htmlFor="status_2"
                                  className="checktoggle"
                                >
                                  checkbox
                                </label>
                              </div>
                            </li>
                            <li>
                              <p>
                                <i className="fa-solid fa-volume-xmark" /> Mute
                              </p>
                              <div className="status-toggle blue-tog d-flex justify-content-sm-end align-items-center">
                                <input
                                  type="checkbox"
                                  id="status_3"
                                  className="check"
                                />
                                <label
                                  htmlFor="status_3"
                                  className="checktoggle"
                                >
                                  checkbox
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="clear-list">
                          <ul>
                            <li>
                              <a href="#">
                                <i className="feather-trash-2" />
                                Clear Chat
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="feather-external-link" />
                                Export Chat
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="feather-alert-circle" />
                                Report Contact
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Chat Content */}
              </div>
              {/* /Chat Content */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatScreen;
