import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  UserSubscribe,
  VendorSubscribe,
} from "../../manage_states/gen-states/subscription/subscriptionServiceSync";
import { useNavigate } from "react-router-dom";
import { plans } from "../../datas/planData";

const PricePlans = ({ planType = "user" }) => {
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState(planType);
  const [currency, setCurrency] = useState("USD"); // Default to NGN
  const [billingCycle, setBillingCycle] = useState("monthly"); // Default to monthly
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const apiKey = "ce8d36c700884044adac223495514f4f";
        const response = await axios.get(
          `https://api.ipapi.com/api/check?access_key=${apiKey}`
        );
        const data = response.data;
        console.log(data, "ipstack")
        const userCountry = data.country_code;
        setCurrency(
          ["US", "CA", "UK", "AU"].includes(userCountry) ? "USD" : "USD"
        );
      } catch (error) {
        console.error("Failed to fetch location", error);
      }
    };
    fetchLocation();
  }, []);

  const handleSubscribe = (value, type) => {
    const isAuthenticated = !!localStorage.getItem("airdrops_token");
    if (isAuthenticated) {
      // subscription: value.subscription.toLowerCase(),
      // amount: Number(value.value),
      // currency,
      // duration: value.duration,
      const requiredValue = {
        product_name: value.subscription.toLowerCase(),
        product_description: `Access to ${value.subscription}`,
        amount: Number(value.value),
        currency: currency,
        interval: value.duration,
      };
      // navigate('/card', { state: requiredValue });
      dispatch(
        type === "user"
          ? UserSubscribe(requiredValue)
          : VendorSubscribe(requiredValue)
      );
    } else {
      navigate("/login");
    }
  };

  return (
    <section className="service-section pricing-sections pt-0">
      <div className="container">
        <div className="section-heading">
          <div className="row">
            <div className="col-md-12 text-center" data-aos="fade-up">
              <h2>Plans</h2>
              <p>Choose the pricing type that best suits your needs</p>
              <br />
              <div className="pricing-toggle">
                <button
                  className={`btn ${
                    selectedType === "user" ? "btn-primary" : "btn-secondary"
                  }`}
                  onClick={() => setSelectedType("user")}
                >
                  User Plans
                </button>
                <button
                  className={`btn ${
                    selectedType === "technician"
                      ? "btn-primary"
                      : "btn-secondary"
                  }`}
                  onClick={() => setSelectedType("technician")}
                >
                  Technician Plans
                </button>
              </div>
              <br />
              <div className="billing-toggle mt-3">
                <button
                  className={`btn ${
                    billingCycle === "monthly" ? "btn-primary" : "btn-secondary"
                  }`}
                  onClick={() => setBillingCycle("monthly")}
                >
                  Monthly
                </button>
                <button
                  className={`btn ${
                    billingCycle === "yearly" ? "btn-primary" : "btn-secondary"
                  }`}
                  onClick={() => setBillingCycle("yearly")}
                >
                  Yearly
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row" data-aos="fade-up">
          {plans[selectedType][currency][billingCycle]?.map((plan, index) => (
            <div className="price-col col-sm-12" key={index}>
              {plan.popular && (
                <div className="best-value">
                  {" "}
                  <h3>Best Value</h3>{" "}
                </div>
              )}
              <div
                className={`pricing-plans ${
                  plan.popular
                    ? "pricing-plans-popular"
                    : plan.subscription === "Enterprise"
                    ? "pricing-plans-enterprise"
                    : ""
                }`}
              >
                <div className="pricing-planshead">
                  <h4>{plan.subscription}</h4>
                  {/* {plan.popular && (
                    <div
                      style={{
                        border: "1px solid #ED1D24",
                        width: "40%",
                        margin: "0.5rem auto",
                        padding: "2px",
                        borderRadius: "20px",
                      }}
                      className="pricing-popular"
                    >
                      <span style={{ color: "#ED1D24" }}>Popular</span>
                    </div>
                  )} */}
                  <h5>{plan.description}</h5>
                  {plan.subscription === "Enterprise" ? (
                    <h6>Custom Pricing</h6>
                  ) : (
                    <h6>
                      {currency} {plan.price}
                      <span>/{plan.duration}</span>
                    </h6>
                  )}
                </div>
                <br />
                <div className="pricing-footer">
                  {plan.subscription === "Enterprise" ? (
                    <button
                      // onClick={() => handleSubscribe(plan, selectedType)}
                      className=""
                    >
                      Contact us
                    </button>
                  ) : (
                    <button
                      onClick={() => handleSubscribe(plan, selectedType)}
                      className={plan.popular ? "popular" : ""}
                    >
                      Subscribe
                    </button>
                  )}
                </div>
                <br />
                <div className="pricing-features">
                  <ul>
                    {plan.features.map((feature, index) => (
                      <li style={{ fontSize: "13px" }} key={index}>
                        <i className="fa fa-check-circle me-2 text-primary"></i>
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                {/* <br /> */}
                {/* <br /> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PricePlans;
