import React, { useContext, useEffect, useState } from 'react'
import ServicesFiltering from './filter'
import ServicesList from './services-list'
import { useDispatch, useSelector } from 'react-redux'
import {
  filterServices,
  GetAllCategory,
  getServices,
} from '../../manage_states/gen-states/services/servicesSync'
import { updateServicesSkip } from '../../manage_states/gen-states/services/slice'

const ServicesComp = () => {
  const dispatch = useDispatch()
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [currentSubCategories, setCurrentSubCategories] = useState([])

  const handleCategoryChange = (category) => {
    setSelectedCategory(category.name)
    setCurrentSubCategories(category.sub_category)
  }

  const {
    serviceLimit,
    serviceSkip,
    services,
    loadingServices,
    completeCategory,
    category_loader,
  } = useSelector((state) => state.services)

  useEffect(() => {
    dispatch(getServices({ page: serviceSkip, limit: serviceLimit }))
    dispatch(GetAllCategory())
  }, [])

  // console.log(completeCategory, 'complete services category')

  const nextPage = () => {
    if (!loadingServices) {
      if (services?.hasNextPage) {
        dispatch(updateServicesSkip(serviceSkip + 1))

        dispatch(
          getServices({
            page: serviceSkip + 1,
            limit: serviceLimit,
          })
        )
      }
    }
  }

  const prevPage = () => {
    if (!loadingServices) {
      if (services?.hasPrevPage) {
        dispatch(updateServicesSkip(serviceSkip - 1))

        dispatch(
          getServices({
            page: serviceSkip - 1,
            limit: serviceLimit,
          })
        )
      }
    }
  }

  const categoryChange = (value) => {
    const returnedValue = JSON.parse(value)
    setSelectedCategory(returnedValue.name)
    setCurrentSubCategories(returnedValue.sub_category)
    console.log(returnedValue, 'category value')
    dispatch(
      filterServices({
        category: returnedValue.name,
        sub_category: returnedValue.sub_category[0].name,
        page: serviceSkip,
        limit: serviceLimit,
      })
    )
  }
  return (
    <div className='content'>
      <div className='container'>
        <div className='row'>
          <ServicesFiltering
            categories={completeCategory}
            selectedCategory={selectedCategory}
            handleCategoryChange={categoryChange}
            sub_categories={currentSubCategories}
          />
          <ServicesList serviceList={services} />
        </div>
      </div>
    </div>
  )
}

export default ServicesComp
