import React from "react";

const CreateServiceHeader = () => {
  return (
    <div className="breadcrumb-bar">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-12">
            <h2 className="breadcrumb-title">Create Service</h2>
            <nav aria-label="breadcrumb" className="page-breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/provider">Dashboard</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Create Service
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateServiceHeader;
