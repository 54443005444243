import React, { useState } from "react";
import { Api_Url } from "../../../api_calls/url";
import axios from "axios";
import { getUserProfile } from "../../../api_calls/profile/service";
import { useQuery } from "@tanstack/react-query";
import Modal from "react-bootstrap/Modal";
const BusinessVerification = () => {
  const [trade_certificate, setTrade_Certificate] = useState();
  const [trade_certificate_id, setTrade_Certificate_id] = useState();
  const [deleting_file, setDeleting_File] = useState(false);
  const [showVerification, setShowVerification] = useState(false);
  const [uploadingDocument, setUploadingDocument] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [userData, setUserData] = useState({});
  const [submitingDocument, setSubmitingDocument] = useState(false);
  const result = useQuery({ queryKey: ["profile"], queryFn: getUserProfile });
  const userToken = localStorage.getItem("airdrops_token");

  console.log(result, result.data, "result returned, user profile");

  // if (result.isLoading) return <div>Fetching profile...</div>;
  // if (result.error) return <div>An error occurred: {result.error.message}</div>;

  const convertToBase64 = (file, callback) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      callback(e.target.result);
    };

    reader.readAsDataURL(file);
  };

  const handleFileInputChange = (event) => {
    event.preventDefault();
    try {
      setUploadingDocument(true);
      const file = event.target.files[0];
      console.log(file, "from file");
      // setUpdatingPics(true);
      if (file) {
        convertToBase64(file, async (base64String) => {
          const pics = { file: base64String };
          // setIsLoading(true);
          console.log(pics, "base 64");
          try {
            const response = await axios.post(
              `${Api_Url}/api/v1/upload_through_cloudinary`,
              pics
            );
            console.log(response, "file document upload complete");
            if (response.status === 200) {
              console.log(response.data.data, "response data");
              setTrade_Certificate_id(response.data.data.public_id);
              setTrade_Certificate(response.data.data.url);
              setUploadingDocument(false);
            }
          } catch (err) {
            console.log(err, "from file upload error");
            setUploadingDocument(false);
          }
        });
      }
    } catch (err) {
      console.log(err, "error occurred");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Use parseInt or parseFloat to convert the value to a number
    // const newValue = name === "NIN" ? parseInt(value, 10) : value;
    setUserData({
      ...userData,
      [name]: value,
    });
    console.log(userData);
  };

  const deleteImage = async (event) => {
    event.preventDefault();

    try {
      setDeleting_File(true);
      const pics = { cloudinary_id: trade_certificate_id };
      console.log(pics);
      const response = await axios.delete(
        `${Api_Url}/api/v1/delete_from_cloudinary`,
        {
          data: pics,
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      console.log(response, "file document upload complete");
      if (response.status === 200) {
        console.log(response.data.data, "response data delete");
        setTrade_Certificate_id("");
        setTrade_Certificate("");
        setDeleting_File(false);
      }
    } catch (err) {
      console.log(err, "from file delete error");
      setDeleting_File(false);
    }
  };

  const businessVerification = async (event) => {
    event.preventDefault();
    try {
      setSubmitingDocument(true);
      const requiredData = {
        ...userData,
        year_of_experience: Number(userData?.year_of_experience),
        name: result?.data?.name,
        email: result?.data?.email,
        user_type: result?.data?.user_type,
        trade_certificate: trade_certificate,
        trade_certificate_id: trade_certificate_id,
      };
      console.log(requiredData, "requiredData");
      const response = await axios.post(
        `${Api_Url}/api/v1/bussiness_verfication`,
        requiredData,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      console.log(response, "file document upload complete");
      if (response.status === 200) {
        console.log(response.data.data, "response data");
        setSubmitingDocument(false);
        setUploadComplete(true);
        setShowVerification(false);
      }
    } catch (err) {
      console.log(err);
      setSubmitingDocument(false);
      setUploadComplete(true);
      setShowVerification(false);
    }
  };

  const handleShowVerification = () => setShowVerification(true);

  console.log(
    trade_certificate,
    trade_certificate_id,
    "trade certificate and trade certificate id"
  );
  return (
    <>
      <div class="linked-item">
        <div class="row">
          <div class="col-md-8">
            <div class="linked-acc">
              <span class="link-icon">
                <img src="/assets/img/icons/document-pdf.svg" alt="Icons" />
              </span>
              <div class="linked-info">
                <h6>
                  <a href="javascript:void(0);">Business Verification</a>
                </h6>
                <p>Not Yet Verified </p>
              </div>
            </div>
          </div>
          <div class="col-md-4 text-md-end">
            <div class="verfification-modal-block">
              <a
                href="javascript:void(0);"
                class="link-close"
                data-bs-toggle="tooltip"
                data-bs-placement="left"
                data-bs-title="Not Verified"
              >
                <i class="fa-solid fa-circle-xmark"></i>
              </a>
              <a
                href="javascript:void(0);"
                class="btn btn-primary btn-connect"
                onClick={handleShowVerification}
                // data-bs-toggle="modal"
                // data-bs-target="#change-business"
              >
                Change
              </a>
              <a href="javascript:void(0);" class="btn-acc">
                Remove
              </a>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showVerification}
        class="modal fade custom-modal verify-modal"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content doctor-profile">
            <div class="modal-header verfication-modal-head">
              <h5 class="modal-title">Verify Your Business</h5>
              <p>Upload document</p>
            </div>
            <div class="modal-body">
              <div>
                <div class="wallet-add">
                  <div class="form-group">
                    <label>Business Name</label>
                    <input
                      class="form-control"
                      name="business_name"
                      type="text"
                      onChange={handleChange}
                      value={userData?.business_name}
                    />
                  </div>
                  <div className="row">
                    <div class="form-group col-6">
                      <label>Certificate Number</label>
                      <input
                        class="form-control"
                        name="certificate_number"
                        type="text"
                        onChange={handleChange}
                        value={userData?.certificate_number}
                      />
                    </div>
                    <div class="form-group col-6">
                      <label>Year of experience</label>
                      <input
                        class="form-control"
                        name="year_of_experience"
                        type="text"
                        onChange={handleChange}
                        value={userData?.year_of_experience}
                      />
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Business address</label>
                    <input
                      class="form-control"
                      name="business_address"
                      type="text"
                      value={userData?.business_address}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="row">
                    {/* <div class="form-group col-6">
                      <label>Trade certificate id</label>
                      <input
                        class="form-control"
                        name="trade_certificate_id"
                        type="text"
                      />
                    </div> */}

                    <div class="form-group">
                      <label>Business type</label>
                      <input
                        class="form-control"
                        name="business_type"
                        type="text"
                        value={userData?.business_type}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div
                    class="file-uploaded-mail"
                    style={{ border: "1px solid gray", display: "flex" }}
                  >
                    {uploadingDocument ? (
                      <div>
                        <p>uploading...</p>
                      </div>
                    ) : (
                      <label
                        for="file"
                        style={{ textAlign: "center", margin: "0 auto" }}
                      >
                        <img
                          src="/assets/img/icons/upload-icon-02.svg"
                          class="img-fluid"
                          alt="Upload"
                        />

                        <h5>
                          Click to Upload<span> Trade Certificate </span>
                        </h5>
                        <p>Supported formats: Png, Jpeg, jpg</p>
                      </label>
                    )}

                    <input
                      style={{ display: "none" }}
                      type="file"
                      name="file"
                      id="file"
                      onChange={handleFileInputChange}
                      class="image-upload"
                    />
                  </div>
                  <div class="document-upload-file row align-items-center">
                    <p
                      className="col-6"
                      style={{ marginTop: "1rem", position: "relative" }}
                    >
                      {" "}
                      <img
                        src={
                          trade_certificate
                            ? trade_certificate
                            : "/assets/img/icons/file-pdf.svg"
                        }
                        width={trade_certificate ? "100%" : 20}
                        class="img-fluid"
                        alt="Pdf"
                      />{" "}
                      {/* trade cert. */}
                      {deleting_file && (
                        <p
                          style={{
                            position: "absolute",
                            top: 0,
                            background: "rgba(128, 128, 128, 0.267)",
                            padding: "20px 10px",
                            // color: "white",
                          }}
                        >
                          deleting file...
                        </p>
                      )}
                    </p>
                    <span className="col-6">
                      <a href="#" onClick={deleteImage}>
                        <img
                          src="/assets/img/icons/trash-alt.svg"
                          class="img-fluid"
                          alt="Trash"
                        />
                      </a>
                    </span>
                  </div>
                  <div class="document-update-success">
                    <p>
                      <i class="fa-regular fa-circle-check"></i> Document
                      uploaded successfully{" "}
                    </p>
                  </div>
                  <div class="modal-submit">
                    <a
                      href="#"
                      class="btn btn-primary w-100"
                      onClick={businessVerification}
                      // data-bs-toggle={uploadComplete && "modal"}
                      // data-bs-target={uploadComplete && "#success-document"}
                    >
                      {submitingDocument ? "submitting..." : "Continue"}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        show={submitingDocument}
        // className="fade success-modal verify-modal"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body success-body text-center">
              <span>
                <i class="fa-regular fa-circle-check"></i>
              </span>
              <h3>Success</h3>
              <p class="success-modal-body">
                Document is sent for approval for the admin once approved you
                will notified.
              </p>
            </div>
            <div class="popup-btn text-center">
              <a href="/provider" class="btn btn-primary verify-document">
                Go to Dashboard
              </a>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BusinessVerification;
