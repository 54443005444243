/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
// import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { serviceSelector } from '../../../manage_states/gen-states/services/slice';
import {
  bookService,
  getSingleService,
  UploadImage,
} from '../../../manage_states/gen-states/services/servicesSync';
import { getbase64 } from '../../../utils/base64Converter';
import { Country, State, City } from 'country-state-city';
import { Oval } from 'react-loader-spinner';
import SearchableDropDown from '../../../common/SearchableDropDown/SearchableDropDown';
import { CountryIcons } from '../../../common/CountryIcons';
import { Skeleton } from '@mui/material';
import {
  GetAllCities,
  GetAllCountry,
  GetAllStates,
} from '../../../manage_states/gen-states/country/countryServices_sync';
import SearchableNameDropDown from '../../../common/SearchNameDropdown/SearchableNameDropDown';

const BookService = () => {
  const [value, onChange] = useState(new Date());
  const { id } = useParams();
  const dispatch = useDispatch();
  const [image, setImage] = useState();

  const [countryStateValue, setCountryStateValue] = useState({});

  const [statesValue, setStatesValue] = useState({});

  const [cityValue, setCityValue] = useState({});

  const [currencyValue, setCurrencyValue] = useState('');

  const [bookData, setBookData] = useState({
    description: '',
    amount_currency: '',
    booked_time: '',
    booker_address: '',
    booker_city: '',
    booking_type: '',
    // booker_state: "",
    // booker_country: "",
    booking_car_shape: [],
  });
  const [countryCode, setCountryCode] = useState('');
  const [countryName, setCountryName] = useState('');
  const [stateCode, setStateCode] = useState('');
  const [stateName, setStateName] = useState('');
  const countries = Country.getAllCountries();
  const states = State?.getStatesOfCountry(countryCode);
  const cities = City?.getCitiesOfState(countryCode, stateCode);
  const navigate = useNavigate();

  const {
    country_loading,
    all_countries,
    all_states,
    state_loading,
    all_cities,
    cityLoading,
  } = useSelector((state) => state.country);

  useEffect(() => {
    dispatch(getSingleService(id));

    // dispatch(GetAllCountry());
  }, []);

  const {
    singleService,
    isBooking,
    booking,
    isBookingComplete,
    uploadingImage,
    uploadingImageSuccess,
    uploadingImageFailure,
    uploadingImageMessage,
    uploadedImage,
  } = useSelector(serviceSelector);

  function formatDate(inputDateStr) {
    // Create a new Date object from the input date string
    const dateObj = new Date(inputDateStr);

    // Extract day, month, and year from the date object
    const day = dateObj.getDate().toString().padStart(2, '0'); // Get day and ensure 2-digit format
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Get month (add 1 because month is zero-indexed) and ensure 2-digit format
    const year = dateObj.getFullYear(); // Get full year

    // Form the date string in "day-month-year" format
    const formattedDate = `${day}-${month}-${year}`;

    // Return the formatted date string
    return formattedDate;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBookData({
      ...bookData,
      [name]: value,
    });
  };

  const removeIdAndGetRest = (obj) => {
    const { _id, ...rest } = obj;
    return rest;
  };

  const handleSelectCountry = (e) => {
    const value = e.target.value;
    const splitString = value?.split(',');

    if (splitString.length === 2) {
      setCountryCode(splitString[0]);
      setCountryName(splitString[1]);
    }
  };

  const handleSelectState = (e) => {
    const value = e.target.value;
    const splitString = value.split(',');

    if (splitString.length === 2) {
      setStateCode(splitString[0]);
      setStateName(splitString[1]);
    }
  };

  const handleImageUpload = (e) => {
    const image = e.target.files[0];

    if (image) {
      let promise = getbase64(image);
      promise
        .then((data) => {
          dispatch(UploadImage({ file: data }));
          return data;
        })
        .then((res) => {
          setImage(image?.name);
        });
    }
  };

  const handleDeleteImage = (imageIndex) => {
    setBookData((prevData) => ({
      ...prevData,
      booking_car_shape: prevData?.booking_car_shape.filter(
        (_, index) => index !== imageIndex
      ),
    }));
  };

  const handleBookService = async () => {
    const datas = removeIdAndGetRest(singleService);
    const requiredData = {
      ...datas,
      ...bookData,
      booker_country: countryStateValue?.name,
      booker_state: statesValue?.name,
      booker_city: cityValue?.name,
      amount_currency: countryStateValue?.currency,
      duration: singleService.duration,
      servicesId: singleService?._id,
      booked_date: formatDate(value),
    };

    dispatch(bookService(requiredData));
  };

  useEffect(() => {
    if (isBookingComplete) {
      navigate('/customer/booking');
    }
  }, [isBookingComplete]);

  useEffect(() => {
    setBookData({
      ...bookData,
      booking_car_shape: [
        ...bookData?.booking_car_shape,
        { pic_url: uploadedImage.url, pic_id: uploadedImage.public_id },
      ],
    });
  }, [uploadedImage]);

  const HandleClickSearch = (item) => {
    setCountryStateValue({ ...item });

    setCountryCode(item?.isoCode);

    // dispatch(GetAllStates({ country: item?.cca2 }));
  };

  const HandleClickSearchState = (item) => {
    setStatesValue({ ...item });

    dispatch(
      GetAllCities({
        country: countryStateValue?.isoCode,
        state: item?.isoCode,
      })
    );
  };

  const HandleClickSearchCities = (item) => {
    setCityValue({ ...item });
  };

  const handleSelectCurrency = (item) => {
    setCurrencyValue(item);
  };

  return (
    <div className="content">
      <div className="container">
        <div className="row">
          {/* Booking */}
          <div className="col-lg-10 mx-auto">
            {/* Booking Step */}
            <ul className="step-register row">
              <li className="active col-md-12">
                <div className="multi-step-icon">
                  <img src="/assets/img/icons/calendar-icon.svg" alt="img" />
                </div>
                <div className="multi-step-insfo">
                  <h6>Appointment</h6>
                  <p>Choose time &amp; date for the service</p>
                </div>
              </li>
            </ul>
            {/* /Booking Step */}
            {/* Appointment */}
            <div className="booking-service">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="service-book">
                    <div className="service-book-img">
                      <img
                        src={
                          singleService?.gallery
                            ? `${singleService?.gallery[0]?.pic_url}`
                            : ''
                        }
                        alt="img"
                      />
                      {/* <img src="/assets/img/booking.jpg" alt="img" /> */}
                    </div>
                    <div className="serv-profile">
                      <span
                        style={{ textTransform: 'uppercase' }}
                        className="badge"
                      >
                        {singleService?.category}
                      </span>
                      <h2 style={{ textTransform: 'uppercase' }}>
                        {singleService?.service_title}
                      </h2>
                      <ul>
                        <li className="serv-pro">
                          {/* <img
                            src={singleService?.data?.gallery[0]?.pic_url}
                            alt="img"
                          /> */}
                          <div className="serv-pro-info">
                            <h6>{singleService?.data?.name}</h6>
                            {/* <p className="serv-review">
                              <i className="fa-solid fa-star" />
                              <span>4.9 </span>(255 reviews)
                            </p> */}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="row align-items-center">
                    <div className="col-md-7 col-sm-6">
                      <div className="provide-box">
                        <span>
                          <i className="feather-phone" />
                        </span>
                        <div className="provide-info">
                          <h6>Speak to an agent</h6>
                          <p>+1 888 888 8888</p>
                        </div>
                      </div>
                      <div className="provide-box">
                        <span>
                          <i className="feather-mail" />
                        </span>
                        <div className="provide-info">
                          <h6>Email Us</h6>
                          <p>
                            <a
                              href="Support@airdropsmobile.com"
                              className="__cf_email__"
                            >
                              Support@airdropsmobile.com
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5 col-sm-6">
                      {/* <div className="provide-box">
                        <span>
                          <i className="feather-map-pin" />
                        </span>
                        <div className="provide-info">
                          <h6>Address</h6>
                          <p>Hanover, Maryland</p>
                        </div>
                      </div> */}
                      {/* <div className="provide-box">
                        <span>
                          <img
                            src="/assets/img/icons/service-icon.svg"
                            alt="img"
                          />
                        </span>
                        <div className="provide-info">
                          <h6>Service Amount</h6>
                          <h5>$150.00</h5>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="book-form">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="col-form-label">Country</label>

                    <div style={{ width: '100%' }}>
                      {/* {country_loading ? (
                        <Skeleton
                          height={40}
                          width={'100%'}
                          style={{ margin: 0 }}
                        />
                      ) : ( */}
                      <SearchableDropDown
                        iconsLead={true}
                        DetailsIcons={CountryIcons}
                        country={countryStateValue && countryStateValue}
                        setCountry={setCountryStateValue}
                        placeholder={'Please Search for a country'}
                        HandleSelect={HandleClickSearch}
                        apiList={countries}
                      />
                      {/* )} */}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="col-form-label">State</label>
                    <div style={{ width: '100%' }}>
                      {/* {state_loading ? (
                        <Skeleton
                          height={40}
                          width={'100%'}
                          style={{ margin: 0 }}
                        />
                      ) : ( */}
                      <SearchableNameDropDown
                        country={statesValue && statesValue}
                        setCountry={setStatesValue}
                        placeholder={'Please Search for a state'}
                        HandleSelect={HandleClickSearchState}
                        apiList={states}
                      />
                      {/* )} */}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="col-form-label">City</label>

                    <div style={{ width: '100%' }}>
                      {cityLoading ? (
                        <Skeleton
                          height={40}
                          width={'100%'}
                          style={{ margin: 0 }}
                        />
                      ) : (
                        <SearchableNameDropDown
                          country={cityValue && cityValue}
                          setCountry={setCityValue}
                          placeholder={'Please Search for a city'}
                          HandleSelect={HandleClickSearchCities}
                          apiList={all_cities}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <div className="form-group">
                    <label className="col-form-label">Currency</label>
                    <select
                      name="booker_state"
                      // onChange={handleInputChange}
                      onChange={handleSelectCurrency}
                      className="form-control select"
                    >
                      <option>Select Currency</option>
                      {Object.keys(countryStateValue?.currency)?.map(
                        (state) => (
                          <option value={state}>{state}</option>
                        )
                      )}
                    </select>
                  </div>
                </div> */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="col-form-label">Address</label>
                    <input
                      type="text"
                      name="booker_address"
                      placeholder="input address"
                      onChange={handleInputChange}
                      className="select form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="col-form-label">Short Note</label>
                    <textarea
                      type="text"
                      name="description"
                      placeholder="write a short note"
                      onChange={handleInputChange}
                      className="select form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="col-form-label">
                      Service Operation Type
                    </label>
                    <select
                      name="booking_type"
                      onChange={handleInputChange}
                      className="select form-control"
                    >
                      <option value="hourly">Select operation type</option>
                      <option value="hourly">Hourly</option>
                      <option value="per_task">Per Task</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {/* /Appointment */}
            {/* adding imgage */}
            <div className="heading-service">
              <h4>Car Photos</h4>
            </div>
            {uploadingImage ? (
              <div className="file-upload">
                <Oval
                  visible={true}
                  height="80"
                  width="80"
                  color="#4fa94d"
                  ariaLabel="oval-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <div className="file-upload">
                <img src="/assets/img/icons/upload-icon.svg" alt="imag" />
                <h6>
                  Upload Car Images <span>Browse</span>
                </h6>
                <p>Supported formates: JPEG, PNG</p>
                <input
                  type="file"
                  onChange={handleImageUpload}
                  accept="video/image"
                />
              </div>
            )}

            <div className="addition-service card-section ">
              <div className="heading-service">
                <h4>Gallery</h4>
              </div>
              <div className="file-preview">
                <label className="form-label">Select Default Image</label>
                <ul className="gallery-selected-img">
                  {bookData?.booking_car_shape?.map((image, index) => (
                    <li>
                      <div className="img-preview">
                        <img src={image?.pic_url} alt="Service Imag" />
                        <button
                          // href="javascript:void(0);"
                          className="remove-gallery"
                          style={{ fontSize: '20px' }}
                          onClick={() => handleDeleteImage(index)}
                        >
                          <i className="fe fe-trash-2" />
                        </button>
                      </div>
                      <label className="custom_check">
                        <input type="radio" name="gallery" defaultChecked />
                        <span className="checkmark" />
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {/* adding image */}
            {/* Appointment Date & Time */}
            {/* <div className="row">
              <div className="col-lg-4">
                <div className="book-title">
                  <h5>Appointment Date</h5>
                </div>
                <Calendar onChange={onChange} value={value} />
              </div>
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-md-12">
                    <div className="book-title">
                      <h5>Appointment Time</h5>
                    </div>
                  </div>
                </div>
                <div className="token-slot mt-2">
                  <div className="form-check-inline visits me-0">
                    <label className="visit-btns">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="booked_time"
                        value="09.00 am"
                        onChange={handleInputChange}
                      />
                      <span className="visit-rsn">09.00 AM</span>
                    </label>
                  </div>
                  <div className="form-check-inline visits me-0">
                    <label className="visit-btns">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="booked_time"
                        value="09.30 am"
                        onChange={handleInputChange}
                      />
                      <span className="visit-rsn">09.30 AM</span>
                    </label>
                  </div>
                  <div className="form-check-inline visits me-0">
                    <label className="visit-btns">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="booked_time"
                        value="10.00 am"
                        onChange={handleInputChange}
                      />
                      <span className="visit-rsn">10.00 AM</span>
                    </label>
                  </div>
                  <div className="form-check-inline visits me-0">
                    <label className="visit-btns">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="booked_time"
                        value="10.30 am"
                        onChange={handleInputChange}
                      />
                      <span className="visit-rsn">10.30 AM</span>
                    </label>
                  </div>
                  <div className="form-check-inline visits me-0">
                    <label className="visit-btns">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="booked_time"
                        value="11.00 am"
                        onChange={handleInputChange}
                      />
                      <span className="visit-rsn">11.00 AM</span>
                    </label>
                  </div>
                  <div className="form-check-inline visits me-0">
                    <label className="visit-btns">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="booked_time"
                        value="11.30 am"
                        onChange={handleInputChange}
                      />
                      <span className="visit-rsn">11.30 AM</span>
                    </label>
                  </div>
                  <div className="form-check-inline visits me-0">
                    <label className="visit-btns">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="booked_time"
                        value="12.00 pm"
                        onChange={handleInputChange}
                      />
                      <span className="visit-rsn">12.00 PM</span>
                    </label>
                  </div>
                  <div className="form-check-inline visits me-0">
                    <label className="visit-btns">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="booked_time"
                        value="12.30 pm"
                        onChange={handleInputChange}
                      />
                      <span className="visit-rsn">12.30 PM</span>
                    </label>
                  </div>
                  <div className="form-check-inline visits me-0">
                    <label className="visit-btns">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="booked_time"
                        value="01.00 pm"
                        onChange={handleInputChange}
                      />
                      <span className="visit-rsn">01.00 PM</span>
                    </label>
                  </div>
                  <div className="form-check-inline visits me-0">
                    <label className="visit-btns">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="booked_time"
                        value="01.30 pm"
                        onChange={handleInputChange}
                      />
                      <span className="visit-rsn">01.30 PM</span>
                    </label>
                  </div>
                  <div className="form-check-inline visits me-0">
                    <label className="visit-btns">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="booked_time"
                        value="02.00 pm"
                        onChange={handleInputChange}
                      />
                      <span className="visit-rsn">02.00 PM</span>
                    </label>
                  </div>
                  <div className="form-check-inline visits me-0">
                    <label className="visit-btns">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="booked_time"
                        value="02.30 pm"
                        onChange={handleInputChange}
                      />
                      <span className="visit-rsn">02.30 PM</span>
                    </label>
                  </div>
                  <div className="form-check-inline visits me-0">
                    <label className="visit-btns">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="booked_time"
                        value="03.00 pm"
                        onChange={handleInputChange}
                      />
                      <span className="visit-rsn">03.00 PM</span>
                    </label>
                  </div>
                  <div className="form-check-inline visits me-0">
                    <label className="visit-btns">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="booked_time"
                        value="03.30 pm"
                        onChange={handleInputChange}
                      />
                      <span className="visit-rsn">03.30 PM</span>
                    </label>
                  </div>
                  <div className="form-check-inline visits me-0">
                    <label className="visit-btns">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="booked_time"
                        value="04.00 pm"
                        onChange={handleInputChange}
                      />
                      <span className="visit-rsn">04.00 PM</span>
                    </label>
                  </div>
                  <div className="form-check-inline visits me-0">
                    <label className="visit-btns">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="booked_time"
                        value="04.30 pm"
                        onChange={handleInputChange}
                      />
                      <span className="visit-rsn">04.30 PM</span>
                    </label>
                  </div>
                  <div className="form-check-inline visits me-0">
                    <label className="visit-btns">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="booked_time"
                        value="05.00 pm"
                        onChange={handleInputChange}
                      />
                      <span className="visit-rsn">05.00 PM</span>
                    </label>
                  </div>
                  <div className="form-check-inline visits me-0">
                    <label className="visit-btns">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="booked_time"
                        value="05.30 pm"
                        onChange={handleInputChange}
                      />
                      <span className="visit-rsn">05.30 PM</span>
                    </label>
                  </div>
                  <div className="form-check-inline visits me-0">
                    <label className="visit-btns">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="booked_time"
                        value="06.00 pm"
                        onChange={handleInputChange}
                      />
                      <span className="visit-rsn">06.00 PM</span>
                    </label>
                  </div>
                  <div className="form-check-inline visits me-0">
                    <label className="visit-btns">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="booked_time"
                        value="06.30 pm"
                        onChange={handleInputChange}
                      />
                      <span className="visit-rsn">06.30 PM</span>
                    </label>
                  </div>
                  <div className="form-check-inline visits me-0">
                    <label className="visit-btns">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="booked_time"
                        value="07.00 pm"
                        onChange={handleInputChange}
                      />
                      <span className="visit-rsn">07.00 PM</span>
                    </label>
                  </div>
                  <div className="form-check-inline visits me-0">
                    <label className="visit-btns">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="booked_time"
                        value="07.30 pm"
                        onChange={handleInputChange}
                      />
                      <span className="visit-rsn">07.30 PM</span>
                    </label>
                  </div>
                  <div className="form-check-inline visits me-0">
                    <label className="visit-btns">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="booked_time"
                        value="08.00 pm"
                        onChange={handleInputChange}
                      />
                      <span className="visit-rsn">08.00 PM</span>
                    </label>
                  </div>
                  <div className="form-check-inline visits me-0">
                    <label className="visit-btns">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="booked_time"
                        value="08.30 pm"
                        onChange={handleInputChange}
                      />
                      <span className="visit-rsn">08.30 PM</span>
                    </label>
                  </div>
                  <div className="form-check-inline visits me-0">
                    <label className="visit-btns">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="booked_time"
                        value="09.00 pm"
                        onChange={handleInputChange}
                      />
                      <span className="visit-rsn">09.00 PM</span>
                    </label>
                  </div>
                  <div className="form-check-inline visits me-0">
                    <label className="visit-btns">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="booked_time"
                        value="09.30 pm"
                        onChange={handleInputChange}
                      />
                      <span className="visit-rsn">09.30 PM</span>
                    </label>
                  </div>
                  <div className="form-check-inline visits me-0">
                    <label className="visit-btns">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="booked_time"
                        value="10.00 pm"
                        onChange={handleInputChange}
                      />
                      <span className="visit-rsn">10.00 PM</span>
                    </label>
                  </div>
                  <div className="form-check-inline visits me-0">
                    <label className="visit-btns">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="booked_time"
                        value="10.30 pm"
                        onChange={handleInputChange}
                      />
                      <span className="visit-rsn">10.30 PM</span>
                    </label>
                  </div>
                </div>
              </div>
            </div> */}
            {/* /Appointment Date & Time */}
            <div className="book-submit text-end">
              <a href="#" className="btn btn-secondary">
                Cancel
              </a>
              <button onClick={handleBookService} className="btn btn-primary">
                {isBooking ? 'sending request...' : 'Book Appointment'}
              </button>
            </div>
          </div>
          {/* /Booking */}
        </div>
      </div>
    </div>
  );
};

export default BookService;
