/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const ServicesFiltering = ({
  categories,
  sub_categories,
  handleCategoryChange,
  selectedCategory,
  handleSubCategoryChange,
}) => {
  return (
    <div className="col-lg-3 col-sm-12 theiaStickySidebar">
      <div className="filter-div">
        <div className="filter-head">
          <h5>Filter by</h5>
          <a href="#" className="reset-link">
            Reset Filters
          </a>
        </div>
        <div className="filter-content">
          <h2>Search Service</h2>
          <input
            type="text"
            className="form-control"
            placeholder="What are you looking for?"
          />
        </div>
        <div className="filter-content">
          <h2>
            Categories
            <span>
              <i className="feather-chevron-down" />
            </span>
          </h2>
          <div className="filter-checkbox filter-checkbox-ul" id="fill-more">
            <ul>
              {categories?.map((item) => (
                <li>
                  <label className="checkboxs">
                    <input
                      onChange={() =>
                        handleCategoryChange(JSON.stringify(item))
                      }
                      checked={selectedCategory === item?.name}
                      type="checkbox"
                    />
                    <span>
                      <i />
                    </span>
                    <b className="check-content">{item?.slug}</b>
                  </label>
                </li>
              ))}
            </ul>
          </div>
          {/* <a href="javascript:void(0);" id="more" className="more-view">
            View More <i className="feather-arrow-down-circle ms-1" />
          </a> */}
        </div>
        <div className="filter-content">
          <h2>Sub Category</h2>
          <select
            onChange={handleSubCategoryChange}
            className="form-control select"
          >
            <option>Select Sub Category</option>
            {sub_categories?.map((sub_category) => (
              <option value={sub_category?.name}>{sub_category?.slug}</option>
            ))}
          </select>
        </div>
        <div className="filter-content">
          <h2>Location</h2>
          <div className="group-img">
            <input
              type="text"
              className="form-control"
              placeholder="Select Location"
            />
            <i className="feather-map-pin" />
          </div>
        </div>
        {/* <button className="btn btn-primary">Search</button> */}
      </div>
    </div>
  );
};

export default ServicesFiltering;
