import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  GetVendorPayoutRequests,
  UserRequestRefund,
  VendorRequestPayout,
} from "../../../manage_states/gen-states/payment/paymentServiceSync";
import { formatAmount, formatDate } from "../../../utils/formatters";

const Payout = () => {
  const [refundDetails, setRefundDetails] = useState({
    reason: "",
    country: "",
    currency: "",
    bankName: "",
    bankAccountNumber: "",
    amount: 0,
  });
  const [currentStatus, setCurrentStatus] = useState("all");
  const dispatch = useDispatch();
  const {
    requestingPayout,
    payoutSuccessful,
    payoutError,
    payoutMessage,
    gettingRequestPayout,
    gettingRequestPayoutSuccessful,
    gettingRequestPayoutError,
    gettingRequestPayoutMessage,
    payouts,
    requestPayoutPage,
    requestPayoutLimit,
  } = useSelector((state) => state.payment);

  const handleRefundChange = (e) => {
    const { name, value } = e.target;

    if (name === "bankAccountNumber") {
      // Only allow numbers and limit to 10 digits
      const numericValue = value.replace(/\D/g, "").slice(0, 10);
      setRefundDetails((prevDetails) => ({
        ...prevDetails,
        bankAccountNumber: numericValue,
      }));
    } else if (name === "amount") {
      // Only allow numbers for the amount
      const numericValue = value.replace(/\D/g, "");
      setRefundDetails((prevDetails) => ({
        ...prevDetails,
        amount: numericValue === "" ? 0 : Number(numericValue),
      }));
    } else {
      setRefundDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }
  };

  const validateForm = () => {
    const { reason, country, currency, bankName, bankAccountNumber, amount } =
      refundDetails;

    // Ensure all fields are filled
    if (!reason || !country || !currency || !bankName || !bankAccountNumber) {
      toast("All fields must be filled.");
      return false;
    }

    // Validate bankAccountNumber as a 10-digit number
    const isAccountNumberValid = /^[0-9]{10}$/.test(bankAccountNumber);
    if (!isAccountNumberValid) {
      toast("Bank account number must be a 10-digit number.");
      return false;
    }

    // Validate amount as a valid number greater than zero
    const isAmountValid =
      typeof amount === "number" && !isNaN(amount) && amount > 0;
    if (!isAmountValid) {
      toast("Amount must be a valid number greater than zero.");
      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("Form is valid:", refundDetails);
    if (validateForm()) {
      // Proceed with form submission or other actions
      console.log("Form is valid:", refundDetails);
      dispatch(VendorRequestPayout(refundDetails));
    }
  };

  useEffect(() => {
    const payload = {
      status: currentStatus,
      page: requestPayoutPage,
      limit: requestPayoutLimit,
    };
    dispatch(GetVendorPayoutRequests(payload));
  }, []);

  console.log("from vendor request", payouts);
  return (
    <>
      <div className="content container-fluid">
        {/* Page Header */}
        <div className="page-header">
          <div className="row">
            <div className="col-md-4">
              <div className="provider-subtitle">
                <h6>Payout</h6>
              </div>
            </div>
            <div className="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap">
              <a
                href="#"
                className="btn btn-primary add-set"
                data-bs-toggle="modal"
                data-bs-target="#add-payout"
              >
                <i className="feather-settings me-2" />
                Request Payment
              </a>
            </div>
          </div>
        </div>
        {/* /Page Header */}
        <div className="row">
          {/* Payout card */}
          <div className="col-xl-4 col-md-6 d-flex">
            <div className="card flex-fill">
              <div className="card-body">
                <div className="balance-crad">
                  <div className="balance-head">
                    <span className="balance-icon">
                      <img src="/assets/img/icons/bal-icon.svg" alt="Icon" />
                    </span>
                    <div className="balance-info">
                      <h6>Available Payout</h6>
                      <h3>
                        $
                        <span className="counters" data-count={180}>
                          0
                        </span>
                      </h3>
                    </div>
                  </div>
                  <div className="balance-amt">
                    <a href="#" className="btn btn-primary view-transaction">
                      View Transactions
                    </a>
                    <a href="#" className="btn btn-secondary  btn-withdraw">
                      Withdraw
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 d-flex">
            <div className="card flex-fill">
              <div className="card-body">
                <div className="balance-crad">
                  <div className="balance-head">
                    <span className="balance-icon">
                      <img src="/assets/img/icons/bal-icon.svg" alt="Icon" />
                    </span>
                    <div className="balance-info">
                      <h6>Last Payout</h6>
                      <h3>
                        $
                        <span className="counters" data-count={200}>
                          0
                        </span>
                      </h3>
                    </div>
                  </div>
                  <div className="balance-amt">
                    <a href="#" className="btn btn-secondary view-transaction">
                      View Transactions
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 d-flex">
            <div className="card flex-fill">
              <div className="card-body">
                <div className="balance-crad">
                  <div className="balance-head">
                    <span className="balance-icon">
                      <img src="/assets/img/icons/bal-icon.svg" alt="Icon" />
                    </span>
                    <div className="balance-info">
                      <h6>Next Payout</h6>
                      <h3>
                        $
                        <span className="counters" data-count={200}>
                          0
                        </span>
                      </h3>
                    </div>
                  </div>
                  <div className="balance-amt">
                    <a href="#" className="btn btn-secondary view-transaction">
                      View Transactions
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Payout card */}
        </div>
        {/* Payout History */}
        <div className="row">
          <div className="col-md-12">
            <h6 className="subhead-title">Payout History</h6>
            <div className="provide-table manage-table">
              <div className="table-responsive">
                <table className="table custom-table datatable" id="data-table">
                  <thead className="thead-light">
                  <tr>
                  <th>#</th>
                  <th>Account Number</th>
                  <th>Bank Name</th>
                  <th>Amount</th>
                  <th>Date</th>
                  <th>Currency</th>
                  <th>Status</th>
                </tr>
                  </thead>
                  <tbody>
                  {payouts?.docs?.map((item, key) => (
                  <tr>
                    <td>{key + 1}</td>
                    <td>{item?.bankAccountNumber}</td>
                    <td>{item?.bankName}</td>
                    <td className="text-light-success">
                      {formatAmount(item?.amount)}
                    </td>
                    <td className="text-body">{formatDate(item?.date)}</td>
                    <td className="text-body">{item?.currency}</td>
                    <td>
                      <span
                        className={
                          item?.status === "pending"
                            ? "badge-warning"
                            : item?.status === "cancelled"
                            ? "badge-danger"
                            : "badge-success"
                        }
                      >
                        {item?.status}
                      </span>
                    </td>
                  </tr>
                ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div id="tablelength" />
              </div>
              <div className="col-md-9">
                <div className="table-ingopage">
                  <div id="tableinfo" />
                  <div id="tablepagination" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Payout History */}
      </div>

      <div className="modal fade custom-modal" id="add-payout">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content doctor-profile">
            <div className="modal-header border-bottom-0 justify-content-between pb-0">
              <h5 className="modal-title">Set Your Payouts</h5>
              <button
                type="button"
                className="close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="feather-x" />
              </button>
            </div>
            <div className="modal-body pt-0">
              <>
                <div className="wallet-add">
                  <div className="form-group">
                    <label className="col-form-label pt-0">Amount</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Amount"
                      name="amount"
                      value={refundDetails?.amount}
                      onChange={handleRefundChange}
                    />
                  </div>
                  <div className="form-group">
                    <label className="col-form-label pt-0">Country</label>
                    <select
                      className="form-control"
                      name="country"
                      value={refundDetails?.country}
                      onChange={handleRefundChange}
                      id=""
                    >
                      <option value="NGN">Nigeria</option>
                      <option value="USD">United State</option>
                      <option value="USD">Canada</option>
                      <option value="EUR">Euros</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label pt-0">Currency</label>
                    <select
                      className="form-control"
                      name="currency"
                      value={refundDetails?.currency}
                      onChange={handleRefundChange}
                    >
                      <option value="NGN">Naira</option>
                      <option value="USD">Dollars</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label pt-0">
                      Account Number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter account number"
                      name="bankAccountNumber"
                      value={refundDetails?.bankAccountNumber}
                      onChange={handleRefundChange}
                      maxLength={10}
                    />
                  </div>
                  <div className="form-group">
                    <label className="col-form-label pt-0">Bank Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your bank name"
                      name="bankName"
                      value={refundDetails?.bankName}
                      onChange={handleRefundChange}
                    />
                  </div>

                  <div className="form-group">
                    <label className="col-form-label pt-0">Description</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter a description"
                      name="reason"
                      value={refundDetails?.reason}
                      onChange={handleRefundChange}
                    />
                  </div>

                  <div className="modal-submit text-end">
                    <a
                      href="#"
                      className="btn btn-secondary me-2"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </a>
                    <button
                      onClick={handleSubmit}
                      type="submit"
                      className="btn btn-primary"
                    >
                      {requestingPayout ? "sending request" : "Submit"}
                    </button>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payout;
