import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import ProviderDashboardHeader from "../../components/dashboard/header/provider-header";
import ProviderSidebar from "../../components/dashboard/sidebars/provider-sidebar";
import "./styles/common-styles.css";
import { useQuery } from "@tanstack/react-query";
import { isAuthenticated } from "../../hooks";
import ProviderPrivateRoute from "../../RouteHelpers/ProviderPrivateRoute";
import { GetSingleUser } from "../../manage_states/gen-states/auth/authServiceSync";
import { useDispatch } from "react-redux";
const ProviderDashboardWrapper = () => {
  return (
    <ProviderPrivateRoute>
      <div className="provider-body">
        <div className="main-wrapper">
          <ProviderDashboardHeader />
          <ProviderSidebar />
          <div class="page-wrapper">
            <div class="content container-fluid">
              <Outlet />
            </div>
          </div>
          {/* <div class=" custom-main-dashboard-wrapper">
          <div class="content container-fluid">
            <Outlet />
          </div>
        </div> */}
        </div>
      </div>
    </ProviderPrivateRoute>
  );
};

export default ProviderDashboardWrapper;
