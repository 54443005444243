import React, { useState } from "react";

const VehicleInspectionForm = () => {
  const [feedback, setFeedback] = useState("");
  
  // State for inspection results
  const [inspectionResults, setInspectionResults] = useState({
    tires: { treadDepth: "", pressure: "" },
    lights: { headlights: "" },
    seatbelts: "",
    horn: "",
  });

  // Calculate feedback based on inspection results
  const calculateFeedback = () => {
    let issues = 0;

    // Tread Depth
    if (inspectionResults.tires.treadDepth === "poor" || inspectionResults.tires.treadDepth === "urgent") issues++;
    // Tire Pressure
    if (inspectionResults.tires.pressure === "poor" || inspectionResults.tires.pressure === "urgent") issues++;
    // Headlights
    if (inspectionResults.lights.headlights === "poor" || inspectionResults.lights.headlights === "urgent") issues++;
    // Seatbelts
    if (inspectionResults.seatbelts === "poor" || inspectionResults.seatbelts === "urgent") issues++;
    // Horn
    if (inspectionResults.horn === "poor" || inspectionResults.horn === "urgent") issues++;

    // Feedback based on issues detected
    if (issues === 0) {
      setFeedback("The vehicle is in good condition. No immediate issues found.");
    } else if (issues <= 2) {
      setFeedback("The vehicle has some minor issues. Consider addressing them soon.");
    } else {
      setFeedback("The vehicle requires urgent attention! Please consult a specialist.");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    calculateFeedback();
  };

  return (
    <div className="container mt-4">
      <h2>Comprehensive Vehicle Inspection Checklist</h2>
      <p>A simple inspection today can save time and cost tomorrow.</p>

      <form onSubmit={handleSubmit}>
        {/* Inspector Information */}
        <h4>Inspector Information</h4>
        <div className="row mb-3">
          <div className="col-md-4">
            <label className="form-label">Name</label>
            <input type="text" className="form-control" placeholder="Inspector Name" />
          </div>
          <div className="col-md-4">
            <label className="form-label">ID Number</label>
            <input type="text" className="form-control" placeholder="Inspector ID" />
          </div>
          <div className="col-md-4">
            <label className="form-label">Date and Time</label>
            <input type="datetime-local" className="form-control" />
          </div>
        </div>

        {/* Vehicle Information */}
        <h4>Vehicle Information</h4>
        <div className="row mb-3">
          <div className="col-md-4">
            <label className="form-label">Make & Model</label>
            <input type="text" className="form-control" placeholder="Make & Model" />
          </div>
          <div className="col-md-4">
            <label className="form-label">Year</label>
            <input type="text" className="form-control" placeholder="Year" />
          </div>
          <div className="col-md-4">
            <label className="form-label">License Plate Number</label>
            <input type="text" className="form-control" placeholder="License Plate" />
          </div>
          <div className="col-md-4">
            <label className="form-label">Odometer Mileage</label>
            <input type="text" className="form-control" placeholder="Odometer" />
          </div>
          <div className="col-md-4">
            <label className="form-label">Vehicle Identification Number (VIN)</label>
            <input type="text" className="form-control" placeholder="VIN" />
          </div>
        </div>

        {/* Exterior Inspection */}
        <h4>Exterior Inspection</h4>
        <h6>Tires</h6>
        <div className="row mb-3">
          <div className="col-md-4">
            <label className="form-label">Tread Depth</label>
            <select className="form-select" onChange={(e) => setInspectionResults({ ...inspectionResults, tires: { ...inspectionResults.tires, treadDepth: e.target.value } })}>
              <option value="">Select Condition</option>
              <option value="good">Good (Deep tread, no visible wear)</option>
              <option value="fair">Fair (Slight wear, safe for driving)</option>
              <option value="poor">Poor (Shallow tread, replacement needed soon)</option>
              <option value="urgent">Urgent (Bald or uneven wear, unsafe)</option>
            </select>
          </div>
          <div className="col-md-4">
            <label className="form-label">Pressure</label>
            <select className="form-select" onChange={(e) => setInspectionResults({ ...inspectionResults, tires: { ...inspectionResults.tires, pressure: e.target.value } })}>
              <option value="">Select Condition</option>
              <option value="good">Good (Properly inflated)</option>
              <option value="fair">Fair (Slightly low or high pressure)</option>
              <option value="poor">Poor (Under/over-inflated, needs adjustment)</option>
              <option value="urgent">Urgent (Dangerously low/high pressure)</option>
            </select>
          </div>
        </div>

        <h6>Lights</h6>
        <div className="row mb-3">
          <div className="col-md-4">
            <label className="form-label">Headlights</label>
            <select className="form-select" onChange={(e) => setInspectionResults({ ...inspectionResults, lights: { ...inspectionResults.lights, headlights: e.target.value } })}>
              <option value="">Select Condition</option>
              <option value="good">Good (Both lights work well)</option>
              <option value="fair">Fair (One light dim or flickering)</option>
              <option value="poor">Poor (One or both lights not working)</option>
              <option value="urgent">Urgent (Both lights out)</option>
            </select>
          </div>
        </div>

        {/* Interior Inspection */}
        <h4>Interior Inspection</h4>
        <div className="row mb-3">
          <div className="col-md-4">
            <label className="form-label">Seatbelts</label>
            <select className="form-select" onChange={(e) => setInspectionResults({ ...inspectionResults, seatbelts: e.target.value })}>
              <option value="">Select Condition</option>
              <option value="good">Good (All seatbelts work)</option>
              <option value="fair">Fair (Slight wear, functional)</option>
              <option value="poor">Poor (Tangled or worn)</option>
              <option value="urgent">Urgent (Non-functional)</option>
            </select>
          </div>
          <div className="col-md-4">
            <label className="form-label">Horn</label>
            <select className="form-select" onChange={(e) => setInspectionResults({ ...inspectionResults, horn: e.target.value })}>
              <option value="">Select Condition</option>
              <option value="good">Good (Works well)</option>
              <option value="fair">Fair (Occasional issues)</option>
              <option value="poor">Poor (Faint or inconsistent)</option>
              <option value="urgent">Urgent (Not working)</option>
            </select>
          </div>
        </div>

        {/* Comments/Notes */}
        <h4>Comments/Notes</h4>
        <div className="form-group mb-3">
          <textarea className="form-control" rows="4" placeholder="Add additional notes or recommendations"></textarea>
        </div>

        <button type="submit" className="btn btn-primary">Submit Inspection</button>
      </form>

      {/* Feedback Section */}
      {feedback && (
        <div className="mt-4">
          <h4>Feedback</h4>
          <p>{feedback}</p>
          <button className="btn btn-warning" onClick={() => alert('Contacting a specialist...')}>Speak to a Specialist</button>
        </div>
      )}
    </div>
  );
};

export default VehicleInspectionForm;
