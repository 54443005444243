import React from 'react'

const OurSafety = () => {
  return (
    <section className='safety-section'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6 col-12'>
            <div className='safety-first-all aos' data-aos='fade-up'>
              <div
                className='section-heading section-heading-nine safety-heading-nine aos'
                data-aos='fade-up'
              >
                <p>
                  About Airdrops' <span className='text-black'>PUNCH</span>{' '}
                </p>
                <h2>Setting The Frontier for Transportation!</h2>
              </div>
              <p className='safety-para'>
                Empower Your Journey Today. Join Airdrops' PUNCH for a New Era
                of Transportation Technology Transformation
              </p>
              <p className='safety-para-two'>
                Airdrops' PUNCH is revolutionizing the future of transportation.
                The platform combines cutting-edge automotive technologies, with
                a network of Skilled Technicians to provide comprehensive
                vehicle services. Whether you're a commercial driver or a
                private vehicle owner, Airdrops' Punch ensures your safety and
                reliable maintenance of your vehicle.
              </p>
              <p>
                We're not just a Service Centre; we're your partner on the road,
                offering reliability and peace of mind. Get your vehicle punched
                today by one of verified technicians and discover a new world of
                auto-assurance. Drive with confidence, drive with Airdrops'
                PUNCH.
              </p>
            </div>
          </div>
          <div className='col-lg-6 col-12'>
            <div className='row'>
              <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                <div className='safety-ryt-main aos' data-aos='fade-up'>
                  <img
                    src='assets/img/blog/blog-13.jpg'
                    alt='imag'
                    className='img-fluid'
                  />
                  <ul>
                    <li>
                      <i className='feather-check' />
                      <span>Highly Professional Staff</span>
                    </li>
                    <li>
                      <i className='feather-check' />
                      <span>100% Satisfaction Guarantee</span>
                    </li>
                    <li>
                      <i className='feather-check' />
                      <span>Quality Control System</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                <div
                  className='safety-ryt-main safety-ryt-two aos'
                  data-aos='fade-up'
                >
                  <img
                    src='assets/img/blog/blog-12.jpg'
                    alt='imag'
                    className='img-fluid'
                  />
                  <ul>
                    <li>
                      <i className='feather-check' />
                      <span>Accurate Testing Process</span>
                    </li>
                    <li>
                      <i className='feather-check' />
                      <span>Unrivalled Workmanship</span>
                    </li>
                    <li>
                      <i className='feather-check' />
                      <span>Timely Delivery</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurSafety
