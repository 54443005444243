import React, { useEffect } from 'react'
import OwlCarousel from 'react-owl-carousel'
import { useDispatch, useSelector } from 'react-redux'
import { GetAllCategory } from '../../manage_states/gen-states/services/servicesSync'

const OurServices = () => {
  const options = {
    // nav: true,
    dots: true,
    margin: 20,
    loop: true,
    autoPlay: true,
    autoplayTimeout: 4000,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      768: {
        items: 2,
      },
      992: {
        items: 4,
      },
      1200: {
        items: 4,
      },
    },
  }
  const dispatch = useDispatch()

  // Get categories from Redux state
  const { completeCategory } = useSelector((state) => state.services)

  useEffect(() => {
    dispatch(GetAllCategory())
  }, [dispatch])

  return (
    <section className='service-nine-section'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12 text-center'>
            <div
              className='section-heading section-heading-nine aos'
              data-aos='fade-up'
            >
              <p>TRUSTED &amp; QUALITY SERVICE</p>
              <h2>Our Service Categories</h2>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <OwlCarousel
              className='owl-carousel service-nine-slider'
              autoplay={true}
              loop
              margin={10}
              {...options}
            >
              {completeCategory?.map((category) => (
                <div
                  key={category.id}
                  className='service-widget service-widget-nine aos'
                  data-aos='fade-up'
                >
                  <div className='service-img'>
                    <a href='/services'>
                      <img
                        className='img-fluid service-img-responsive'
                        alt={category.name}
                        src={category.category_image}
                      />
                    </a>
                  </div>
                  <div className='service-content service-feature-nine'>
                    <div className='shop-content-logo'>
                      <img
                        src='assets/img/icons/car-service-icon-1.svg'
                        alt='imag'
                      />
                    </div>
                    <span>{category.sub_category.length} Sub-Categories</span>
                    <p>{category.name}</p>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
        <div className='btn-sec btn-service-nine aos' data-aos='fade-up'>
          {/* <a href='search.html' className='btn btn-primary btn-view'>
            VIEW ALL 255 SERVICES
          </a> */}
        </div>
      </div>
    </section>
  )
}

export default OurServices
