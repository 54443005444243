import { createAsyncThunk } from '@reduxjs/toolkit';
import AuthServices from './authService';
import { toast } from 'react-toastify';

export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (credentials, thunkAPI) => {
    try {
      const delivered = await AuthServices.Login(credentials);
      return delivered;
    } catch (e) {
      toast(e.message);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const GetSingleUser = createAsyncThunk(
  'auth/getSingleUser',
  async (thunkAPI) => {
    try {
      const delivered = await AuthServices.getSingleUser();
      // console.log(delivered, 'delivered successfully');
      return delivered;
    } catch (e) {
      // toast(e.message);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const EditProfile = createAsyncThunk(
  'auth/editProfile',
  async (data, thunkAPI) => {
    try {
      const delivered = await AuthServices.editProfile(data);
      // console.log(delivered, 'delivered successfully');
      return delivered;
    } catch (e) {
      // toast(e.message);
      return thunkAPI.rejectWithValue(e);
    }
  }
);