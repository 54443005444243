import React from 'react'
// import Hero from '../components/home/hero'
import FeaturedCategories from '../components/home/featured-categories'
import FeaturedServices from '../components/home/services'
import TopProviders from '../components/home/top-providers'
import HowItWorks from '../components/home/works'
import MostPopularServices from '../components/home/mostpopular-services'
import PricePlans from '../components/home/pricing'
import Testimonies from '../components/home/testimonies'
import OurPartners from '../components/home/our-partners'
import DownloadApp from '../components/home/download-app'
import HeroTwo from '../components/home/HeroTwo'
import OurSafety from '../components/home/OurSafety'
import ActivityChoose from '../components/home/ActivityChoose'
import OurMechanics from '../components/home/OurMechanics'
import OurServices from '../components/home/OurServices'

const Hompage = () => {
  return (
    <>
      {/* <Hero /> */}
      <HeroTwo />
      <OurSafety />
      <OurServices />
      {/* <FeaturedCategories /> */}
      <FeaturedServices />
      <ActivityChoose />
      {/* <TopProviders />
      <HowItWorks /> */}
      {/* <OurMechanics /> */}
      <MostPopularServices />
      <PricePlans />
      <Testimonies />
      <OurPartners />
      <DownloadApp />
    </>
  )
}

export default Hompage
