import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBiddings,
  getSingleBidding,
  placeBid,
} from "../../../../manage_states/dashboard/provider/bidding/bidding-services-sync";
import { useEffect } from "react";
import { GetSingleUser } from "../../../../manage_states/gen-states/auth/authServiceSync";
import { formatAmount, formatDate } from "../../../../utils/formatters";
import Timer from "../../Timer";

const ServiceIndex = () => {
  const [bidId, setBidId] = useState("");
  const [bidAmount, setBidAmount] = useState("");
  const dispatch = useDispatch();

  const {
    requests,
    singleBidding,
    placingBid,
    placeBidError,
    placeBidSuccess,
  } = useSelector((state) => state.bidding);

  function convertToKebabCase(str) {
    return str.replace(/\s+/g, "-").toLowerCase();
  }

  const handleSetId = ({ title, id }) => {
    console.log(title, id, "place id");
    const service_title = convertToKebabCase(title);
    const required = `${service_title}-${id}`;
    console.log(required, "required");
    dispatch(getSingleBidding(`${required}`));
    setBidId(id);
  };

  const handlePlaceBid = () => {
    const requiredData = {
      id: bidId,
      data: {
        amount: Number(bidAmount),
      },
    };
    console.log(requiredData);
    dispatch(placeBid(requiredData));
  };

  const isDateExpired = (targetDate) => {
    const currentDate = new Date();
    const target = new Date(targetDate);
    return target < currentDate;
  };

  console.log(requests, "biddings", singleBidding, "single bidding");
  console.log(placeBidError, placeBidSuccess, "bid info");
  useEffect(() => {
    dispatch(GetSingleUser());
    dispatch(getBiddings("0/20"));
  }, []);

  return (
    <>
      <div className="">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-md-4">
                <div className="provider-subtitle">
                  <h6>Available Bids</h6>
                </div>
              </div>
              <div className="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap">
                <div className="review-sort me-2">
                  <p>Sort</p>
                  <select className="select">
                    <option>A -&gt; Z</option>
                    <option>Most Helpful</option>
                  </select>
                </div>
                <div className="grid-listview me-2">
                  <ul>
                    <li>
                      <a href="javascript:void(0);">
                        <i className="feather-filter" />
                      </a>
                    </li>
                    {/* <li>
                    <a href="provider-services.html">
                      <i className="feather-grid" />
                    </a>
                  </li> */}
                    {/* <li>
                    <a href="provider-services-list.html" className="active">
                      <i className="feather-list" />
                    </a>
                  </li> */}
                  </ul>
                </div>
                {/* <a href="/create-service" className="btn btn-primary add-set">
                <i className="feather-plus"> </i>Add Service
              </a> */}
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-md-12">
              {requests?.data?.docs?.map((item, key) => (
                <div key={key} class="booking-list">
                  <div class="booking-widget">
                    <div class="booking-img">
                      <a href="service-details.html">
                        <img src={item?.bid_pic[1]?.pic_url} alt="User Image" />
                      </a>
                      <div class="fav-item">
                        <a href="javascript:void(0)" class="fav-icon">
                          <i class="feather-heart"></i>
                        </a>
                      </div>
                    </div>
                    <div class="booking-det-info">
                      <h3>
                        <a href="service-details.html">{item?.service_title}</a>
                        <span class="badge badge-success">Completed</span>
                      </h3>
                      <ul class="booking-details">
                        <li>
                          <span class="book-item">Expiration time:</span>
                          <span>
                            <Timer
                              spanClass="text btn btn-danger-light"
                              targetDate={item?.bid_end_date_time}
                            />
                          </span>
                        </li>
                        <li>
                          <span class="book-item">Booking Date</span> :{" "}
                          {formatDate(item?.booking_date)}
                        </li>
                        <li>
                          <span class="book-item">Amount Range</span> : from ${" "}
                          {formatAmount(item?.amount_range_Start)} to ${" "}
                          {formatAmount(item?.amount_range_end)}
                          {/* <span class="badge-grey">COD</span> */}
                        </li>
                        <li>
                          <span class="book-item">Location</span> :{" "}
                          {item?.booker_address}, {item?.booker_city},{" "}
                          {item?.booker_state},{item?.booker_country}
                        </li>
                        <li>
                          <span class="book-item">Creator</span> :
                          <div class="user-book">
                            <div class="avatar avatar-xs">
                              <img
                                class="avatar-img rounded-circle"
                                alt="User Image"
                                src={item?.bid_creator_profile_pic}
                              />
                            </div>
                            {item?.bid_creator_name}
                          </div>
                          <p>
                            <a
                              href="https://html.truelysell.com/cdn-cgi/l/email-protection"
                              class="__cf_email__"
                              data-cfemail="98f1f6fef7d8f2f7f0f6ebf5f1ecf0b6fbf7f5"
                            >
                              [email&#160;protected]
                            </a>
                          </p>
                          {/* <p>+1 607-276-5393</p> */}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="booking-action">
                    <button
                      href="#"
                      class="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target={"#add-review"}
                      onClick={() =>
                        handleSetId({
                          title: `${item?.service_title}`,
                          id: `${item._id}`,
                        })
                      }
                      disabled={
                        isDateExpired(item?.bid_end_date_time) ? true : false
                      }
                    >
                      {/* <button onClick={handleSetId(`${item._id}`)}> */}
                      {isDateExpired(item?.bid_end_date_time)
                        ? "Bid Expired"
                        : "Place a Bid"}

                      {/* </button> */}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Pagination */}
          <div className="row">
            <div className="col-sm-6">
              <div className="review-entries">
                <span>Show</span>
                <select>
                  <option>07</option>
                  <option>08</option>
                </select>
                <span>entries</span>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="review-pagination">
                <p>1 - 09 of 09</p>
                <ul className="pagination">
                  <li className="page-item">
                    <a className="page-link" href="javascript:void(0);">
                      1
                    </a>
                  </li>
                  <li className="page-item active">
                    <a className="page-link" href="javascript:void(0);">
                      2 <span className="visually-hidden">(current)</span>
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="javascript:void(0);">
                      3
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Pagination */}
        </div>
      </div>
      <div className="modal fade custom-modal" id="add-review">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content doctor-profile">
            <div className="modal-header border-bottom-0 justify-content-between">
              <h5 className="modal-title">Place a bid</h5>
              <button
                type="button"
                className="close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="feather-x" />
              </button>
            </div>
            <div className="modal-body pt-0">
              <div>
                <div className="write-review">
                  <div className="review-add">
                    <div className="rev-img">
                      <img
                        // src="assets/img/services/service-19.jpg"
                        src={singleBidding?.data?.bid_pic[1]?.pic_url}
                        alt="image"
                      />
                    </div>
                    <div className="rev-info">
                      <h6>{singleBidding?.data?.service_title}</h6>
                      <p>
                        {" "}
                        {singleBidding?.data?.booker_state},
                        {singleBidding?.data?.booker_country}
                      </p>
                    </div>
                  </div>
                  <div className="form-group form-info">
                    <ul class="booking-details">
                      <li>
                        <span class="book-item">Booking Date</span> :{" "}
                        {formatDate(singleBidding?.data?.booking_date)}
                      </li>
                      <li>
                        <span class="book-item">Amount</span> : from ${" "}
                        {formatAmount(singleBidding?.data?.amount_range_Start)}{" "}
                        to ${" "}
                        {formatAmount(singleBidding?.data?.amount_range_end)}
                        <span class="badge-grey">COD</span>
                      </li>
                      <li>
                        <span class="book-item">Location</span> :{" "}
                        {singleBidding?.data?.booker_address},{" "}
                        {singleBidding?.data?.booker_city},{" "}
                        {singleBidding?.data?.booker_state},
                        {singleBidding?.data?.booker_country}
                      </li>
                    </ul>
                  </div>
                  <div className="form-group form-info">
                    <label className="col-form-label">Bid amount</label>
                    <input
                      className="form-control"
                      placeholder="input amount"
                      defaultValue={""}
                      onChange={(e) => setBidAmount(e.target.value)}
                    />
                  </div>
                  <div className="modal-submit text-end">
                    <button
                      onClick={handlePlaceBid}
                      type="button"
                      className="btn btn-primary"
                    >
                      {placingBid ? "bidding.." : "Add Bid"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceIndex;
