import React from 'react'

const ContactUs = () => {
  return (
    <>
      {/* Breadcrumb */}
      <div className='breadcrumb-bar'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 col-12'>
              <h2 className='breadcrumb-title text-white'>Contact Us</h2>
              <nav aria-label='breadcrumb' className='page-breadcrumb'>
                <ol className='breadcrumb'>
                  <li className='breadcrumb-item'>
                    <a href='/'>Home</a>
                  </li>
                  <li
                    className='breadcrumb-item active text-black'
                    aria-current='page'
                  >
                    Contact Us
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* /Breadcrumb */}

      <div className='content'>
        <div className='container'>
          {/* Contact Details */}
          <div className='contact-details'>
            <div className='row justify-content-center'>
              {/* <div className='col-md-6 col-lg-4 d-flex'>
                <div className='contact-info flex-fill'>
                  <span>
                    <i className='feather-phone' />
                  </span>
                  <div className='contact-data'>
                    <h4>Phone Number</h4>
                    <p>(888) 888-8888</p>
                    <p>(123) 456-7890</p>
                  </div>
                </div>
              </div> */}

              <div className='col-md-6 col-lg-4 d-flex'>
                <div className='contact-info flex-fill'>
                  <span>
                    <i className='feather-mail' />
                  </span>
                  <div className='contact-data'>
                    <h4>Email</h4>
                    <a
                      href='https://html.truelysell.com/cdn-cgi/l/email-protection'
                      className='__cf_email__'
                      data-cfemail='384c4a4d5d54414b5d5454785d40595548545d165b5755'
                    >
                      info@airdropspunch.com
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-6 col-lg-4 d-flex'>
                <div className='contact-info flex-fill'>
                  <span>
                    <i className='feather-map-pin' />
                  </span>
                  <div className='contact-data'>
                    <h4>Address</h4>
                    <p>8500 Macleod Trail, Calgary, AB T2H 2N1</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Contact Details */}
          {/* Get In Touch */}
          <div className='row'>
            <div className='col-md-6'>
              <div className='contact-img'>
                <img
                  src='assets/img/service-img.png'
                  className='img-fluid'
                  alt='img'
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='contact-queries'>
                <h2>Get In Touch</h2>
                <form action='#'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label className='col-form-label'>Name</label>
                        <input
                          className='form-control'
                          type='text'
                          placeholder='Enter Name*'
                        />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label className='col-form-label'>Email</label>
                        <input
                          className='form-control'
                          type='email'
                          placeholder='Enter Email Address*'
                        />
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <label className='col-form-label'>Phone Number</label>
                        <input
                          className='form-control'
                          type='text'
                          placeholder='Enter Phone Number'
                        />
                      </div>
                      <div className='form-group'>
                        <label className='col-form-label'>Message</label>
                        <textarea
                          className='form-control'
                          rows={4}
                          placeholder='Type Message'
                          defaultValue={''}
                        />
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <button className='btn btn-primary' type='submit'>
                        Send Message
                        <i className='feather-arrow-right-circle ms-2' />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* /Get In Touch */}
        </div>
      </div>
    </>
  )
}

export default ContactUs
