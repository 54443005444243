/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import SingleBookList from "./single-book-list";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { serviceSelector } from "../../../manage_states/gen-states/services/slice";
import { getUserBookings } from "../../../manage_states/gen-states/services/servicesSync";
import { AppContext } from "../../../context/context";
import { useNavigate } from "react-router-dom";
import {
  updateCurrentRoom,
  updateSelectedRoom,
} from "../../../manage_states/gen-states/room/roomSlice";
import { toast } from "react-toastify";
import { UserMakePayment } from "../../../manage_states/gen-states/payment/paymentServiceSync";

const CustomerBooking = () => {
  const navigate = useNavigate();

  const { socket } = useContext(AppContext);

  const { user } = useSelector((state) => state.auth);

  const { current_room } = useSelector((state) => state.room);

  const [value, onChange] = useState(new Date());

  const dispatch = useDispatch();
  const [clickedService, setClickedService] = useState({});
  const { userBookings, isGettingUserBookings, isGettingUserBookingsFailure } =
    useSelector(serviceSelector);

  useEffect(() => {
    dispatch(getUserBookings("all/0/10"));
  }, []);
  // console.log(userBookings?.data, "bookings of user now");

  const determineAmount = (data) => {
    const result = data?.find((payment) => payment.status === "pending");
    const value = result?.amount;
    return value;
  };

  const handleMakePayment = (e, data) => {
    e.preventDefault();
    console.log(data, "data");
    const pendingPayment = data?.cummulative_amount?.find(
      (payment) => payment.status === "pending"
    );
    // console.log(pendingPayment, "pending payment");
    // console.log("clicked payment", data);
    const paymentValue = {
      amount: pendingPayment?.amount,
      currency: data.currency,
      services: data?.service_title,
      serviceId: data?.servicesId,
      services_pic: data?.gallery[0]?.pic_url,
      booked_serviceId: data?._id,
    };
    // const paymentPayload = {
    //   paymentId: data?.paymentId
    // }
    console.log(paymentValue, "payment value");
    dispatch(
      UserMakePayment({ paymentId: data?._id, data: paymentValue })
    );
  };

  const JoinRoom = (room, isPublic = true) => {
    socket.emit("join-room", room.room, current_room);

    dispatch(updateCurrentRoom(room.room));

    dispatch(updateSelectedRoom({ ...room, status: "online" }));
  };

  const CreateRoomToConnectTo = async (item) => {
    if (!user) {
      // toast.info("Please Login to assess this.");

      return;
    }

    const payload = {
      name: item?.booker_name,
      email: item?.booker_email,
      picture: item?.gallery[0]?.pic_url,
      roomOwner: user?._id,
      service_title: item?.service_title,
      service_id: item?.servicesId,
      room: `${item?.BookedById}-${item?.servicesId}-admin`,
    };

    socket.emit("create-user", payload);

    navigate("/customer/customer-service-chat");

    JoinRoom(payload);
  };

  return (
    <>
      {/* <!-- Sort --> */}
      <div className="row align-items-center">
        <div className="col-md-4">
          <div className="widget-title">
            <h4>Booking List</h4>
          </div>
        </div>

        <div className="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap">
          <div className="review-sort me-2">
            <p>Sort</p>
            <select className="select">
              <option>Recent</option>
              <option>Older</option>
              <option>A to Z</option>
              <option>Z to A</option>
            </select>
          </div>
          <div className="grid-listview">
            <ul>
              <li>
                <a href="customer-booking-calendar.html">
                  <i className="feather-calendar"></i>
                </a>
              </li>
              <li>
                <a href="">
                  <i className="feather-filter"></i>
                </a>
              </li>
              <li>
                <a href="">
                  <i className="feather-grid"></i>
                </a>
              </li>
              <li>
                <a href="customer-booking.html" className="active">
                  <i className="feather-list"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!-- /Sort --> */}
      {userBookings?.data?.docs?.length === 0 && (
        <>
          <div className="table-responsive recent-booking flex-fill">
            {/* <br /> */}
            <br />
            <h3 className="text-center">No Booking Found</h3>
            <p className="text-center">
              click the button to proceed book a service
            </p>
            <div className="row">
              <a
                class="btn btn-primary mx-auto col-3"
                href="/customer/services-list"
              >
                Book Now
              </a>
            </div>

            <br />
            <br />
          </div>
        </>
      )}
      {userBookings?.data?.docs?.map((item, key) => (
        <SingleBookList
          status={item?.booking_status || "in_progress"}
          data={item}
          amount={
            determineAmount(item?.cummulative_amount)
              ? determineAmount(item?.cummulative_amount)
              : "In review"
          }
          booking_date={item.booking_date}
          service_title={item?.service_title}
          location={`${item?.booker_country},
            ${item?.booker_state},
            ${item?.booker_city},
            ${item?.booker_address}`}
          key={key}
          handlePay={(e) => handleMakePayment(e, item)}
          CreateRoom={(sta) => CreateRoomToConnectTo(sta)}
          btnDisabled={item?.service_payment_status === true ? false : true}
        />
      ))}

      {/* <!-- Reschedule --> */}
      <div class="modal fade custom-modal reshchedule-modal" id="reschedule">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content doctor-profile">
            <div className="modal-body">
              <div class="hide-show" id="show-first">
                <div className="row">
                  <div className="col-lg-3 d-flex">
                    <div className="modal-active-dots text-center w-100">
                      <ul>
                        <li
                          className="active-dot active"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Select Date & Time"
                        ></li>
                        <li className="active-dot"></li>
                        <li className="active-dot"></li>
                        <li className="active-dot"></li>
                      </ul>
                      <div className="calender-modal">
                        <img
                          src="/assets/img/icons/modal-calender-icon.svg"
                          alt="calender Icon"
                        />
                        <h6>Date & time</h6>
                        <p>Select date & time to schedule appointment</p>
                      </div>
                      <div className="call-help">
                        <h6>
                          Questions?
                          <span>Call 321 546 8764 help</span>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 d-flex">
                    <div className="main-booking-form w-100">
                      <div className="rechedule-calender">
                        <div className="book-title">
                          <h5>Select Date & Time</h5>
                        </div>
                        {/* <div id='datetimepickershow'></div> */}
                        <Calendar onChange={onChange} value={value} />
                        <div className="pick-slot">
                          <h6>
                            Pick a Slot For <span> July 18</span>
                          </h6>
                          <div className="token-slot">
                            <div className="form-check-inline visits me-0">
                              <label className="visit-btns">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="appintment"
                                />
                                <span className="visit-rsn">08:00 am</span>
                              </label>
                            </div>
                            <div className="form-check-inline visits me-0">
                              <label className="visit-btns">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="appintment"
                                />
                                <span className="visit-rsn">08:30 am</span>
                              </label>
                            </div>
                            <div className="form-check-inline visits me-0">
                              <label className="visit-btns">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="appintment"
                                />
                                <span className="visit-rsn">09:00 am</span>
                              </label>
                            </div>
                            <div className="form-check-inline visits me-0">
                              <label className="visit-btns">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="appintment"
                                />
                                <span className="visit-rsn">09:30 am</span>
                              </label>
                            </div>
                            <div className="form-check-inline visits me-0">
                              <label className="visit-btns">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="appintment"
                                />
                                <span className="visit-rsn">10:00 am</span>
                              </label>
                            </div>
                            <div className="form-check-inline visits me-0">
                              <label className="visit-btns">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="appintment"
                                />
                                <span className="visit-rsn">10:30 am</span>
                              </label>
                            </div>
                            <div className="form-check-inline visits me-0">
                              <label className="visit-btns">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="appintment"
                                />
                                <span className="visit-rsn">11:00 am</span>
                              </label>
                            </div>
                            <div className="form-check-inline visits me-0">
                              <label className="visit-btns">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="appintment"
                                  checked
                                />
                                <span className="visit-rsn">11:30 am</span>
                              </label>
                            </div>
                            <div className="form-check-inline visits me-0">
                              <label className="visit-btns">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="appintment"
                                />
                                <span className="visit-rsn">12:00 pm</span>
                              </label>
                            </div>
                            <div className="form-check-inline visits me-0">
                              <label className="visit-btns">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="appintment"
                                />
                                <span className="visit-rsn">12:30 pm</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="field-bottom-btns select-timing justify-content-between">
                        <div className="field-btns">
                          <button
                            className="btn btn-primary prev_btnn disabled"
                            type="button"
                          >
                            <i className="fa-solid fa-arrow-left"></i>Prev
                          </button>
                        </div>
                        <div className="field-btns">
                          <button
                            className="btn btn-primary next_btnn"
                            type="button"
                          >
                            Next <i className="fa-solid fa-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 d-flex">
                    <div className="card booking-summary-card">
                      <div className="card-body">
                        <div className="sub-title">
                          <h5>Booking Summary</h5>
                        </div>
                        <div className="appointment-details">
                          <ul>
                            <li>
                              <div className="detail-list">
                                <h5>Appointment date & time</h5>
                                <h6 className="date-red">July 18, 11:30 am</h6>
                              </div>
                            </li>
                            <li>
                              <div className="detail-list">
                                <h5>Selected Service</h5>
                                <h6>Computer Services</h6>
                              </div>
                              <span>$40.00</span>
                            </li>
                            <li>
                              <div className="detail-list">
                                <h5>Additional Service</h5>
                                <h6>Changing Switch Boards</h6>
                              </div>
                              <span>$10.00</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-footer">
                        <ul>
                          <li>
                            <h6>Sub Total</h6>
                            <span>$257.00</span>
                          </li>
                          <li>
                            <h6>Tax @ 12.5%</h6>
                            <span>$5.36</span>
                          </li>
                          <li className="total-amount">
                            <h6>Total</h6>
                            <span>$251.36</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hide-show">
                <div className="row">
                  <div className="col-lg-3">
                    <div className="modal-active-dots text-center">
                      <ul>
                        <li
                          className="active-dot activated"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Select Date & Time"
                        ></li>
                        <li
                          className="active-dot active"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Enter Information"
                        ></li>
                        <li className="active-dot"></li>
                        <li className="active-dot"></li>
                      </ul>
                      <div className="calender-modal">
                        <img
                          src="/assets/img/icons/booking-info-icon.svg"
                          alt="calender Icon"
                        />
                        <h6>Enter Information</h6>
                        <p>
                          Ad your inhtmlFormation for the appointment Booking
                        </p>
                      </div>
                      <div className="call-help">
                        <h6>
                          Questions?
                          <span>Call 321 546 8764 help</span>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 d-flex">
                    <div className="main-booking-form d-flex w-100">
                      <div className="rechedule-calender h-100">
                        <div className="book-title">
                          <h5>Enter Information</h5>
                        </div>
                        <div className="card booking-info-tab h-100">
                          <ul
                            className="nav nav-pills"
                            id="pills-tab"
                            role="tablist"
                          >
                            <li className="nav-item" role="presentation">
                              <button
                                className="nav-link active"
                                id="pills-guest-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-guest"
                                type="button"
                                role="tab"
                                aria-controls="pills-guest"
                                aria-selected="true"
                              >
                                Book as Guest
                              </button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button
                                className="nav-link"
                                id="pills-user-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-user"
                                type="button"
                                role="tab"
                                aria-controls="pills-user"
                                aria-selected="false"
                              >
                                Already have an account?
                              </button>
                            </li>
                          </ul>
                          <div class="tab-content" id="pills-tabContent">
                            <div
                              className="tab-pane fade show active"
                              id="pills-guest"
                              role="tabpanel"
                              aria-labelledby="pills-guest-tab"
                            >
                              <form>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeHolder="Name"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <input
                                        type="email"
                                        className="form-control"
                                        placeHolder="Email Address"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeHolder="Phone Number"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeHolder="Address"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="guest-address d-flex">
                                      <div className="guest-country w-100 me-2">
                                        <div className="form-group">
                                          <select className="select">
                                            <option>Country</option>
                                            <option>US</option>
                                            <option>Kuwait</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="guest-city w-100">
                                        <div className="form-group">
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeHolder="City"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="guest-state-pin d-flex">
                                      <div className="guest-state w-100 me-2">
                                        <div className="form-group">
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeHolder="State"
                                          />
                                        </div>
                                      </div>
                                      <div className="guest-pin w-100">
                                        <div className="form-group">
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeHolder="Zipcode"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group mb-0">
                                      <textarea
                                        className="form-control"
                                        placeHolder="Comments"
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                            <div
                              className="tab-pane fade guest-user-tab"
                              id="pills-user"
                              role="tabpanel"
                              aria-labelledby="pills-user-tab"
                            >
                              <form>
                                <div className="sub-title">
                                  <h5>Login</h5>
                                </div>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <input
                                        type="email"
                                        className="form-control"
                                        placeHolder="Email Address"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <input
                                        type="password"
                                        className="form-control"
                                        placeHolder="Password"
                                      />
                                    </div>
                                  </div>
                                  <div className="get-new-password">
                                    <p>
                                      Forgot Password ?{" "}
                                      <a href="#"> Click to Get Link</a>
                                    </p>
                                  </div>
                                  <div className="form-group">
                                    <button className="btn btn-primary w-100">
                                      Login
                                    </button>
                                  </div>
                                </div>
                                <div className="join-user">
                                  <a href="#">
                                    <i className="fa-solid fa-user me-2"></i>
                                    Join as a User
                                  </a>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="field-bottom-btns select-timing justify-content-between">
                        <div className="field-btns">
                          <button
                            className="btn btn-primary prev_btnn"
                            type="button"
                          >
                            <i className="fa-solid fa-arrow-left"></i>Prev
                          </button>
                        </div>
                        <div className="field-btns">
                          <button
                            className="btn btn-primary next_btnn"
                            type="button"
                          >
                            Next <i className="fa-solid fa-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 d-flex">
                    <div className="card booking-summary-card">
                      <div className="card-body">
                        <div className="sub-title">
                          <h5>Booking Summary</h5>
                        </div>
                        <div className="appointment-details">
                          <ul>
                            <li>
                              <div className="detail-list">
                                <h5>Appointment date & time</h5>
                                <h6 className="date-red">July 18, 11:30 am</h6>
                              </div>
                            </li>
                            <li>
                              <div className="detail-list">
                                <h5>Selected Service</h5>
                                <h6>Computer Services</h6>
                              </div>
                              <span>$40.00</span>
                            </li>
                            <li>
                              <div className="detail-list">
                                <h5>Additional Service</h5>
                                <h6>Changing Switch Boards</h6>
                              </div>
                              <span>$10.00</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-footer">
                        <ul>
                          <li>
                            <h6>Sub Total</h6>
                            <span>$257.00</span>
                          </li>
                          <li>
                            <h6>Tax @ 12.5%</h6>
                            <span>$5.36</span>
                          </li>
                          <li className="total-amount">
                            <h6>Total</h6>
                            <span>$251.36</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hide-show">
                <div className="row">
                  <div className="col-lg-3">
                    <div className="modal-active-dots text-center">
                      <ul>
                        <li
                          className="active-dot activated"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Select Date & Time"
                        ></li>
                        <li
                          className="active-dot activated"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Enter Information"
                        ></li>
                        <li
                          className="active-dot active"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Payment Gateway"
                        ></li>
                        <li className="active-dot"></li>
                      </ul>
                      <div className="calender-modal">
                        <img
                          src="/assets/img/icons/payment-gateway-icon.svg"
                          alt="calender Icon"
                        />
                        <h6>Payment Gateway</h6>
                        <p>
                          Select your payment type to pay htmlFor appointment
                        </p>
                      </div>
                      <div className="call-help">
                        <h6>
                          Questions?
                          <span>Call 321 546 8764 htmlFor help</span>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 d-flex">
                    <div className="main-booking-form d-flex w-100">
                      <div className="rechedule-calender h-100">
                        <div className="book-title">
                          <h5>Payment Gateway</h5>
                        </div>
                        <div className="card booking-info-tab h-100">
                          <div className="payment-card">
                            <div className="payment-head">
                              <div className="payment-title">
                                <label className="custom_radio">
                                  <input
                                    type="radio"
                                    name="payment"
                                    className="card-payment"
                                    checked=""
                                  />
                                </label>
                                <h6>Paypal</h6>
                              </div>
                              <div className="card-icon">
                                <img
                                  src="/assets/img/icons/paypal-icon.svg"
                                  alt="paypal icon"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="payment-card payment-bg">
                            <div className="payment-head">
                              <div className="payment-title">
                                <label className="custom_radio">
                                  <input
                                    type="radio"
                                    name="payment"
                                    className="card-payment"
                                  />
                                </label>
                                <h6>Credit / Debit Card</h6>
                              </div>
                              <div className="card-icon">
                                <img
                                  src="/assets/img/card-icon-1.png"
                                  alt=" card icon "
                                />
                              </div>
                            </div>
                          </div>
                          <div className="payment-card">
                            <div className="payment-head">
                              <div className="payment-title">
                                <label className="custom_radio credit-card-option">
                                  <input
                                    type="radio"
                                    name="payment"
                                    className="card-payment"
                                  />
                                </label>
                                <h6>Cash on Delivery</h6>
                              </div>
                              <div className="card-icon">
                                <img
                                  src="/assets/img/card-icon-2.png"
                                  alt="card icon"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="field-bottom-btns select-timing justify-content-between">
                        <div className="field-btns">
                          <button
                            className="btn btn-primary prev_btnn"
                            type="button"
                          >
                            <i className="fa-solid fa-arrow-left"></i>Prev
                          </button>
                        </div>
                        <div className="field-btns">
                          <button
                            className="btn btn-primary next_btnn submit-btn"
                            type="button"
                          >
                            Submit <i className="fa-solid fa-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 d-flex">
                    <div className="card booking-summary-card">
                      <div className="card-body">
                        <div className="sub-title">
                          <h5>Booking Summary</h5>
                        </div>
                        <div className="appointment-details">
                          <ul>
                            <li className="droped-item">
                              <div className="detail-list">
                                <h5>
                                  Customer Details{" "}
                                  <i className="fa-solid fa-circle-info"></i>
                                </h5>
                                <h6 className="date-red">Testuser</h6>
                              </div>
                              <ul className="customer-detail-list">
                                <li>
                                  <h6>Email Address</h6>
                                  <p>
                                    <a
                                      href="https://html.truelysell.com/cdn-cgi/l/email-protection"
                                      className="__cf_email__"
                                      data-cfemail="513b3e393f113429303c213d347f323e3c"
                                    >
                                      [email&#160;protected]
                                    </a>
                                  </p>
                                </li>
                                <li>
                                  <h6>Phone Number</h6>
                                  <p>+1 63993 35556</p>
                                </li>
                                <li>
                                  <h6>Address</h6>
                                  <p>578 Fleming StreetMontgomery, AL 36104</p>
                                </li>
                                <li>
                                  <h6>Comments</h6>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing and typesetting industry.
                                  </p>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <div className="detail-list">
                                <h5>Appointment date & time</h5>
                                <h6 className="date-red">July 18, 11:30 am</h6>
                              </div>
                            </li>
                            <li>
                              <div className="detail-list">
                                <h5>Selected Service</h5>
                                <h6>Computer Services</h6>
                              </div>
                              <span>$40.00</span>
                            </li>
                            <li>
                              <div className="detail-list">
                                <h5>Additional Service</h5>
                                <h6>Changing Switch Boards</h6>
                              </div>
                              <span>$10.00</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-footer">
                        <ul>
                          <li>
                            <h6>Sub Total</h6>
                            <span>$257.00</span>
                          </li>
                          <li>
                            <h6>Tax @ 12.5%</h6>
                            <span>$5.36</span>
                          </li>
                          <li className="total-amount">
                            <h6>Total</h6>
                            <span>$251.36</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hide-show">
                <div className="row">
                  <div className="col-lg-3">
                    <div className="modal-active-dots text-center">
                      <ul>
                        <li
                          className="active-dot activated"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Tooltip on top"
                        ></li>
                        <li
                          className="active-dot activated"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Enter Information"
                        ></li>
                        <li
                          className="active-dot activated"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Payment Gateway"
                        ></li>
                        <li
                          className="active-dot active"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Payment Gateway"
                        ></li>
                      </ul>
                      <div className="calender-modal">
                        <img
                          src="/assets/img/icons/payment-gateway-icon.svg"
                          alt="calender Icon"
                        />
                        <h6>Payment Gateway</h6>
                        <p>
                          Select your payment type to pay htmlFor appointment
                        </p>
                      </div>
                      <div className="call-help">
                        <h6>
                          Questions?
                          <span>Call 321 546 8764 htmlFor help</span>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 d-flex">
                    <div className="main-booking-form d-flex w-100">
                      <div className="rechedule-calender h-100">
                        <div className="book-title">
                          <h5>Payment Gateway</h5>
                        </div>
                        <div className="card booking-info-tab h-100 justify-content-between">
                          <div>
                            <div className="sub-title">
                              <h5>Saved Cards</h5>
                            </div>
                            <div className="card-pay-save">
                              <div className="payment-card save-cards">
                                <div className="payment-head">
                                  <div className="payment-title">
                                    <label className="custom_radio">
                                      <input
                                        type="radio"
                                        name="payment"
                                        className="card-payment"
                                        checked
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                    <img
                                      src="/assets/img/icons/saved-card-icon.svg"
                                      alt="saved card icon"
                                    />
                                    <h6>Mastercard</h6>
                                  </div>
                                  <div className="card-number">
                                    <span> ********* 1234</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="add-more-card-details">
                              <div className="add-more-card">
                                <a href="">
                                  <i className="fa-solid fa-circle-plus"></i>{" "}
                                  Add new card
                                </a>
                              </div>
                              <div className="hide-cards-group">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeHolder="Name On Card"
                                  />
                                </div>
                                <div className="form-group">
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeHolder="Card Number"
                                  />
                                </div>
                                <div className="card-details d-flex">
                                  <div className="expiry-date w-100 me-2">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeHolder="Expiry Date"
                                      />
                                    </div>
                                  </div>
                                  <div className="cvv-num w-100">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeHolder="CVV Number"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="save-later">
                                  <label className="custom_check">
                                    <input
                                      type="checkbox"
                                      name="rememberme"
                                      className="rememberme"
                                    />
                                    <span class="checkmark"></span>Save htmlFor
                                    later
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="secure-transaction">
                              <span>
                                <i className="fa-solid fa-lock"></i>
                              </span>
                              <p>
                                All transactions are secure and encrypted.
                                Credit card information is never stored.
                              </p>
                            </div>
                          </div>
                          <div className="total-price">
                            <h5>
                              Total Booking Price :{" "}
                              <span className="price-value"> $251.36</span>
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="field-bottom-btns select-timing justify-content-between">
                        <div className="field-btns">
                          <button
                            className="btn btn-primary prev_btnn"
                            type="button"
                          >
                            <i className="fa-solid fa-arrow-left"></i>Prev
                          </button>
                        </div>
                        <div className="field-btns">
                          <button
                            className="btn btn-primary next_btnn submit-btn"
                            type="button"
                          >
                            Next<i className="fa-solid fa-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 d-flex">
                    <div className="card booking-summary-card">
                      <div className="card-body">
                        <div className="sub-title">
                          <h5>Booking Summary</h5>
                        </div>
                        <div className="appointment-details">
                          <ul>
                            <li className="droped-item">
                              <div className="detail-list">
                                <h5>
                                  Customer Details{" "}
                                  <i className="fa-solid fa-circle-info"></i>
                                </h5>
                                <h6 className="date-red">Testuser</h6>
                              </div>
                              <ul className="customer-detail-list">
                                <li>
                                  <h6>Email Address</h6>
                                  <p>
                                    <a
                                      href="https://html.truelysell.com/cdn-cgi/l/email-protection"
                                      className="__cf_email__"
                                      data-cfemail="96fcf9fef8d6f3eef7fbe6faf3b8f5f9fb"
                                    >
                                      [email&#160;protected]
                                    </a>
                                  </p>
                                </li>
                                <li>
                                  <h6>Phone Number</h6>
                                  <p>+1 63993 35556</p>
                                </li>
                                <li>
                                  <h6>Address</h6>
                                  <p>578 Fleming StreetMontgomery, AL 36104</p>
                                </li>
                                <li>
                                  <h6>Comments</h6>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing and typesetting industry.
                                  </p>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <div className="detail-list">
                                <h5>Appointment date & time</h5>
                                <h6 className="date-red">July 18, 11:30 am</h6>
                              </div>
                            </li>
                            <li>
                              <div className="detail-list">
                                <h5>Selected Service</h5>
                                <h6>Computer Services</h6>
                              </div>
                              <span>$40.00</span>
                            </li>
                            <li>
                              <div className="detail-list">
                                <h5>Additional Service</h5>
                                <h6>Changing Switch Boards</h6>
                              </div>
                              <span>$10.00</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-footer">
                        <ul>
                          <li>
                            <h6>Sub Total</h6>
                            <span>$257.00</span>
                          </li>
                          <li>
                            <h6>Tax @ 12.5%</h6>
                            <span>$5.36</span>
                          </li>
                          <li className="total-amount">
                            <h6>Total</h6>
                            <span>$251.36</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hide-show booking-confirm-active-dots">
                <div className="row">
                  <div className="col-xl-4 col-lg-3">
                    <div className="modal-active-dots text-center">
                      <div className="calender-modal">
                        <img
                          src="/assets/img/icons/appointment-confirm-icon.svg"
                          alt="calender Icon"
                        />
                        <h6>Confirm Order</h6>
                        <p>
                          Handles different career a accordingly, after a of the
                          htmlFor found customary feedback by happiness
                        </p>
                      </div>
                      <div className="call-help">
                        <h6>
                          Questions?
                          <span>Call 321 546 8764 htmlFor help</span>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-9 d-flex">
                    <div className="main-booking-form d-flex w-100">
                      <div className="rechedule-calender h-100">
                        <div className="book-title">
                          <h5>Confirmation</h5>
                        </div>
                        <div className="card booking-confirmation-info h-100 mb-0">
                          <div className="card-body">
                            <div className="appointment-details">
                              <div className="details-head">
                                <h6>Appointment Details</h6>
                                <span>UBID-52</span>
                              </div>
                              <div className="add-calender">
                                <a href="#">
                                  <span>
                                    <i className="fa-solid fa-calendar-days"></i>
                                  </span>
                                  Add to Calender
                                </a>
                              </div>
                            </div>
                            <div className="confirmation-product-card">
                              <div className="row align-items-center">
                                <div className="col-md-6">
                                  <div className="service-item">
                                    <span>
                                      <img
                                        src="/assets/img/product-confirm-img.jpg"
                                        className="img-fluid"
                                        alt="confirm product"
                                      />
                                    </span>
                                    <div className="product-info">
                                      <h5>Computer Services</h5>
                                      <span className="duration">
                                        Duration : 30 Min
                                      </span>
                                      <span className="date-time">
                                        July 18, 11:30 am - 12:30 pm{" "}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="product-info service-additional">
                                    <h6>Additional Service</h6>
                                    <span>Changing Switch Boards</span>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="product-info service-cost">
                                    <h6>Total Paid</h6>
                                    <span>$400</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="customer-provider">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="name-card">
                                    <h6>Customer</h6>
                                    <div className="profile-detail">
                                      <span className="profile-pic">
                                        <img
                                          src="/assets/img/profiles/avatar-21.jpg"
                                          className="img-fluid"
                                          alt="profile"
                                        />
                                      </span>
                                      <div className="email-name">
                                        <span>Adrian</span>
                                        <p>
                                          <a
                                            href="https://html.truelysell.com/cdn-cgi/l/email-protection"
                                            className="__cf_email__"
                                            data-cfemail="34405147404147514674514c55594458511a575b59"
                                          >
                                            [email&#160;protected]
                                          </a>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="name-card">
                                    <h6>Provider</h6>
                                    <div className="profile-detail">
                                      <span className="profile-pic">
                                        <img
                                          src="/assets/img/profiles/avatar-22.jpg"
                                          className="img-fluid"
                                          alt="profile avatar"
                                        />
                                      </span>
                                      <div className="email-name">
                                        <span>Harrris</span>
                                        <a href="#">Learn More</a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="payed-method">
                              <span>Payment Method : Debit card</span>
                            </div>
                            <div className="field-bottom-btns select-timing justify-content-end">
                              <div className="field-btns">
                                <button
                                  className="btn btn-primary next_btn submit-btn"
                                  type="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#successmodal"
                                >
                                  Confirm Order{" "}
                                  <i className="fa-solid fa-arrow-right"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /Reschedule --> */}

      {/* <!-- Modal Succss --> */}
      <div
        className="modal fade add-service-modal booking-success-modal"
        id="successmodal"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <span>
                <i className="fa-regular fa-circle-check"></i>
              </span>
              <h3>Success</h3>
              <p>Booking has been successfully Confirmed on</p>
              <div className="booking-date-time">
                <i className="fa-regular fa-clock"> </i> 25 July 2023, 19:00 pm
              </div>
              <div className="popup-btn">
                <a href="/customer" className="btn btn-primary">
                  Go to Dashboard <i className="fa-solid fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Modal Succss --> */}
    </>
  );
};

export default CustomerBooking;
