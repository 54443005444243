/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  const authenticated = localStorage.getItem("airdrops_token");
  const user_type = localStorage.getItem("airdrops_user_type");
  console.log("authenticated", user_type, authenticated);
  return (
    <header className="header header-one">
      <div className="container">
        <nav className="navbar navbar-expand-lg header-nav">
          <div className="navbar-header">
            <a id="mobile_btn" href="">
              <span className="bar-icon">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </a>
            <a href="" className="navbar-brand logo">
              <img
                src="/assets/img/cropped-Untitled-1-1 3.png"
                className="img-fluid"
                alt="Logo"
              />
            </a>
            <a href="/" className="navbar-brand logo-small">
              <img
                src="/assets/img/cropped-Untitled-1-1 3.png"
                className="img-fluid"
                alt="Logo"
              />
            </a>
          </div>
          <div className="main-menu-wrapper">
            <div className="menu-header">
              <a href="/" className="menu-logo">
                <img
                  src="/assets/img/cropped-Untitled-1-1 3.png"
                  className="img-fluid"
                  alt="Logo"
                />
              </a>
              <a id="menu_close" className="menu-close" href="#">
                <i className="fas fa-times"></i>
              </a>
            </div>
            <ul className="main-nav">
              <li className="has-submenu megamenu ">
                <NavLink reloadDocument to="/" activeClassName="active">
                  Home
                </NavLink>
              </li>
              <li className="has-submenu">
                <NavLink reloadDocument to="/services" activeClassName="active">
                  Services
                </NavLink>
              </li>
              <li className="has-submenu">
                <NavLink reloadDocument to="/about-us" activeClassName="active">
                  About us
                </NavLink>
              </li>

              {/* <li className="has-submenu">
                <a href="#">Market Place</a>
              </li> */}
              <li className="has-submenu">
                <NavLink reloadDocument to="/pricing/technician" activeClassName="active">Pricing</NavLink>
              </li> 
              <li className="has-submenu">
                <a href="javascript:void(0);">
                  Providers <i className="fas fa-chevron-down" />
                </a>
                <ul className="submenu">
                  <li>
                    <a href="/tow-trucks">Tow Trucks</a>
                  </li>
                  <li>
                    <a href="/technicians">Technicians</a>
                  </li>
                  <li>
                    <a href="/dealers">Dealers</a>
                  </li>
                </ul>
              </li>

              <li className="has-submenu">
                <NavLink
                  reloadDocument
                  to="/contact-us"
                  activeClassName="active"
                >
                  Contact us
                </NavLink>
              </li>

              {authenticated && (
            <>
              <li className="login-link">
                <a
                  className="nav-link header-login"
                  href={user_type === "client" ? "/customer" : "/provider"}
                >
                  <i className="fa-regular fa-circle-user me-2"></i>Dashboard
                </a>
              </li>
            </>
          )}
          {!authenticated && (
            <>
              <li className="login-link">
                <a className="nav-link header-reg" href="/sign-up">
                  Join us
                </a>
              </li>
              <li className="login-link">
                <a className="nav-link header-login" href="/login">
                  <i className="fa-regular fa-circle-user me-2"></i>Login
                </a>
              </li>
            </>
          )}

              {/* <li className="login-link">
                <a href="/sign-up">Join us</a>
              </li>
              <li className="login-link">
                <a href="/login">Login</a>
              </li> */}
            </ul>
          </div>
          {authenticated && (
            <ul className="nav header-navbar-rht">
              <li className="nav-item">
                <a
                  className="nav-link header-login"
                  href={user_type === "client" ? "/customer" : "/provider"}
                >
                  <i className="fa-regular fa-circle-user me-2"></i>Dashboard
                </a>
              </li>
            </ul>
          )}
          {!authenticated && (
            <ul className="nav header-navbar-rht">
              <li className="nav-item">
                <a className=" nav-link header-login" href="/sign-up">
                  Start For Free
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link header-reg" href="/login">
                  <i className="fa-regular fa-circle-user me-2"></i>Login
                </a>
              </li>
            </ul>
          )}
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
