// import { useNavigate } from "react-router-dom";

// const navigate = useNavigate;

export const isAuthenticated = () => {
  const userToken = localStorage.getItem("airdrops_token");
  let authenticated = userToken ? true : false;
  return authenticated;
};

export const handleLogout = (callback) => {
  localStorage.removeItem("airdrops_token");
  return callback()
};
