import React from "react";
import LoginRegisterNav from "../common/nav/login-register-nav";
import LoginForm from "../components/login";

const LoginPage = () => {
  return (
    <div className="main-wrapper">
      <LoginRegisterNav />
      <LoginForm />
    </div>
  );
};

export default LoginPage;
