/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import UserChangePassModal from "../../modals/UserChangePassModal";
import { useDispatch, useSelector } from "react-redux";
import { Country, State, City } from "country-state-city";
import { EditProfile } from "../../../manage_states/gen-states/auth/authServiceSync";
import SearchableDropDown from "../../../common/SearchableDropDown/SearchableDropDown";
import { CountryIcons } from "../../../common/CountryIcons";
import SearchableNameDropDown from "../../../common/SearchNameDropdown/SearchableNameDropDown";
import { GetAllCities } from "../../../manage_states/gen-states/country/countryServices_sync";
import { Skeleton } from "@mui/material";

const CustomerProfile = () => {
  //   const [value, onChange] = useState(new Date())
  const [userData, setUserData] = useState({});

  const [countryStateValue, setCountryStateValue] = useState({});

  const [statesValue, setStatesValue] = useState({});

  const [cityValue, setCityValue] = useState({});

  const [countryCode, setCountryCode] = useState("");
  const [countryName, setCountryName] = useState("");
  const [stateCode, setStateCode] = useState("");
  const dispatch = useDispatch();

  const countries = Country.getAllCountries();
  const states = State?.getStatesOfCountry(countryCode);

  const {
    user,
    loading,
    updatingProfile,
    profileUpdateSuccess,
    profileUpdateError,
    profileUpdateMessage,
  } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user) {
      setUserData(user);
    }
  }, [user]);

  console.log(userData, "user");

  const handleChange = (e) => {
    const { value, name } = e.target;
    setUserData({ ...userData, [name]: value });
    console.log(userData);
  };

  const {
    country_loading,
    all_countries,
    all_states,
    state_loading,
    all_cities,
    cityLoading,
  } = useSelector((state) => state.country);

  const handleUpdateProfile = (e) => {
    e.preventDefault();
    dispatch(
      EditProfile({
        ...userData,
        country: countryStateValue?.name,
        state: statesValue?.name,
        city: cityValue?.name,
      })
    );
  };

  const HandleClickSearch = (item) => {
    setCountryStateValue({ ...item });

    setCountryCode(item?.isoCode);

    // dispatch(GetAllStates({ country: item?.cca2 }));
  };

  const HandleClickSearchState = (item) => {
    setStatesValue({ ...item });

    dispatch(
      GetAllCities({
        country: countryStateValue?.isoCode,
        state: item?.isoCode,
      })
    );
  };

  const HandleClickSearchCities = (item) => {
    setCityValue({ ...item });
  };

  return (
    <>
      <div className="col-lg-9">
        <div className="widget-title">
          <h4>Account Settings</h4>
        </div>
        <h6 className="user-title">Profile Picture</h6>
        <div className="pro-picture">
          <div className="pro-img">
            <img src="/assets/img/profiles/avatar-02.jpg" alt="user" />
          </div>
          <div className="pro-info">
            <div className="d-flex">
              <div className="img-upload">
                <i className="feather-upload-cloud me-1" />
                Upload
                <input type="file" />
              </div>
              <a href="#" className="btn btn-remove">
                Remove
              </a>
              <a
                href="#"
                style={{ marginLeft: "1rem" }}
                data-bs-toggle="modal"
                data-bs-target="#change-password"
                className="btn btn-remove"
              >
                Change password
              </a>
            </div>
            <p>
              *image size should be at least 320px big,and less then 500kb.
              Allowed files .png and .jpg.
            </p>
          </div>
        </div>

        <h6 className="user-title">General Information</h6>
        <div className="general-info">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="col-form-label">
                  Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Your Name"
                  value={userData?.name}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="col-form-label">
                  Email <span className="text-danger">*</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter Email Address"
                  value={userData?.email}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="col-form-label">
                  Mobile Number <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Mobile Number"
                  name="phone_number"
                  value={userData?.phone_number}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="col-form-label">Country</label>
                <div style={{ width: "100%" }}>
                  <SearchableDropDown
                    iconsLead={true}
                    DetailsIcons={CountryIcons}
                    country={countryStateValue && countryStateValue}
                    setCountry={setCountryStateValue}
                    placeholder={"Please Search for a country"}
                    HandleSelect={HandleClickSearch}
                    apiList={countries}
                  />
                </div>
                {/* <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Your Country"
                  name="country"
                  value={userData?.country}
                  onChange={handleChange}
                /> */}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="col-form-label">State</label>
                {/* <input
                  type="email"
                  className="form-control"
                  placeholder="Enter Your State"
                  name="state"
                  value={userData?.state}
                  onChange={handleChange}
                /> */}
                <div style={{ width: "100%" }}>
                  {/* {state_loading ? (
                        <Skeleton
                          height={40}
                          width={'100%'}
                          style={{ margin: 0 }}
                        />
                      ) : ( */}
                  <SearchableNameDropDown
                    country={statesValue && statesValue}
                    setCountry={setStatesValue}
                    placeholder={"Please Search for a state"}
                    HandleSelect={HandleClickSearchState}
                    apiList={states}
                  />
                  {/* )} */}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="col-form-label">City</label>
                <div style={{ width: "100%" }}>
                  {cityLoading ? (
                    <Skeleton
                      height={40}
                      width={"100%"}
                      style={{ margin: 0 }}
                    />
                  ) : (
                    <SearchableNameDropDown
                      country={cityValue && cityValue}
                      setCountry={setCityValue}
                      placeholder={"Please Search for a city"}
                      HandleSelect={HandleClickSearchCities}
                      apiList={all_cities}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label className="col-form-label">Address</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Your Address"
                  name="address"
                  value={userData?.address}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="acc-submit">
          <a href="#" className="btn btn-secondary">
            Cancel
          </a>
          <button onClick={handleUpdateProfile} className="btn btn-primary">
            {updatingProfile ? "updating..." : "Save Changes"}
          </button>
        </div>
      </div>
      <UserChangePassModal />
    </>
  );
};

export default CustomerProfile;
