const faqData = [
    {
      category: "General Questions",
      items: [
        {
          question: "What is Airdrops’ PUNCH?",
          answer:
            "Airdrops’ PUNCH is a platform revolutionizing the automotive industry through innovative technology and exceptional service. We provide tools and services to streamline vehicle maintenance, repairs, and other automotive needs for both customers and technicians.",
        },
        {
          question: "Who can use Airdrops’ PUNCH?",
          answer:
            "Airdrops’ PUNCH is designed for individual vehicle owners, fleet managers, and automotive service professionals looking to simplify their vehicle management and service processes.",
        },
      ],
    },
    {
      category: "Booking Services",
      items: [
        {
          question: "How do I book a service?",
          answer:
            "To book a service:\n\n1. Sign in to your account.\n2. Navigate to the 'Book a Service' page.\n3. Select the service category, date, and time.\n4. Confirm your booking by following the prompts.",
        },
        {
          question: "Can I reschedule or cancel my booking?",
          answer:
            "Yes, you can reschedule or cancel your booking by going to 'My Bookings' in your account dashboard. Changes must be made at least 24 hours before the scheduled appointment.",
        },
        {
          question: "Will I receive a confirmation for my booking?",
          answer:
            "Yes, once your booking is confirmed, you'll receive a notification via email and SMS with the appointment details.",
        },
      ],
    },
    {
      category: "Payments",
      items: [
        {
          question: "How can I pay for a service?",
          answer:
            "You can pay securely online using:\n\n- Credit/Debit cards.\n- Digital wallets.\n- Bank transfers.",
        },
        {
          question: "Is my payment information safe?",
          answer:
            "Absolutely! We use advanced encryption and secure payment gateways to protect your data.",
        },
        {
          question: "Can I get a refund if I cancel a service?",
          answer:
            "Yes, refunds are processed according to our Refund Policy, which allows fair and timely refunds for eligible cancellations. Check the refund eligibility in your booking details.",
        },
      ],
    },
    {
      category: "Vehicle Management",
      items: [
        {
          question: "How can I add my vehicle details?",
          answer:
            "To add vehicle details:\n\n1. Log in to your account.\n2. Go to 'My Vehicles' and click 'Add Vehicle.'\n3. Fill in the required fields, including Make, Model, Year, VIN, and License Plate.\n4. Save your vehicle information.",
        },
        {
          question: "Can I manage multiple vehicles?",
          answer:
            "Yes, our platform allows you to manage multiple vehicles under a single account. Simply add each vehicle to your profile.",
        },
        {
          question: "How do I view my vehicle's service history?",
          answer:
            "Go to 'My Vehicles' and click on the specific vehicle. You'll see a detailed record of all past and upcoming services.",
        },
      ],
    },
    {
      category: "Subscriptions",
      items: [
        {
          question: "What subscription plans do you offer?",
          answer:
            "We offer flexible subscription plans, including:\n\n- Monthly: Perfect for occasional users.\n- Annual: Ideal for frequent users with added benefits like discounts and priority service.",
        },
        {
          question: "How can I subscribe to a plan?",
          answer:
            "Log in to your account, go to 'Subscriptions,' choose a plan, and follow the checkout process.",
        },
        {
          question: "Can I cancel or change my subscription?",
          answer:
            "Yes, you can manage your subscription through your account settings. Changes will take effect at the end of your current billing cycle.",
        },
      ],
    },
    {
      category: "Account Management",
      items: [
        {
          question: "How do I sign up?",
          answer:
            "Click on the 'Sign Up' button on our homepage. Fill in your details, create a password, and verify your email address to complete registration.",
        },
        {
          question: "How do I log in to my account?",
          answer:
            "Click 'Login' on the homepage and enter your registered email and password.",
        },
        {
          question: "I forgot my password. What should I do?",
          answer:
            "Click 'Forgot Password' on the login page, enter your registered email, and follow the instructions to reset your password.",
        },
      ],
    },
    {
      category: "Technician-Specific Questions",
      items: [
        {
          question: "How do technicians join Airdrops’ PUNCH?",
          answer:
            "Technicians can sign up through the 'Join as a Technician' link on our homepage. Complete the registration form, provide required documentation, and wait for approval.",
        },
        {
          question: "What services can technicians offer?",
          answer:
            "Technicians can provide a range of services, including diagnostics, repairs, regular maintenance, and specialized services based on their expertise.",
        },
        {
          question: "How do technicians receive payments?",
          answer:
            "Payments are directly transferred to the technician's registered account after service completion and customer confirmation.",
        },
      ],
    },
    {
      category: "Technical and Support",
      items: [
        {
          question: "What should I do if I face issues while booking or paying?",
          answer:
            "You can contact our customer support team via:\n\n- Email: [Insert Email Address]\n- Phone: [Insert Phone Number]\n- Live Chat: Available on our website.",
        },
        {
          question: "How do I report an issue with a service?",
          answer:
            "Go to 'Support' in your account dashboard, submit a ticket, and our team will assist you promptly.",
        },
        {
          question: "Is there a mobile app for Airdrops’ PUNCH?",
          answer:
            "Yes! You can download our app from the App Store or Google Play to manage bookings, payments, and vehicle details on the go.",
        },
      ],
    },
    {
      category: "Policies and Guidelines",
      items: [
        {
          question: "Where can I find your refund and cancellation policies?",
          answer:
            "Our policies are available on the 'Policies' section of the website footer. You can also access them during booking.",
        },
        {
          question: "Are my personal and vehicle details secure?",
          answer:
            "Yes, we are fully compliant with GDPR and other global data protection regulations to ensure your information is safe.",
        },
        {
          question: "How often are your policies updated?",
          answer:
            "Our policies are reviewed and updated regularly. You’ll be notified via email about any significant changes.",
        },
      ],
    },
    {
      category: "Other Questions",
      items: [
        {
          question: "Can I provide feedback or suggest improvements?",
          answer:
            "Absolutely! We value your feedback. Contact us at [Insert Feedback Email] or use the 'Feedback' option in your account.",
        },
        {
          question: "Is there a loyalty program for frequent users?",
          answer:
            "Yes, our loyalty program rewards customers with points for every completed service, which can be redeemed for discounts or special offers.",
        },
        {
          question: "Still have questions?",
          answer:
            "If you didn’t find your answer here, please contact our support team at [Insert Contact Details]. We're here to help!",
        },
      ],
    },
  ];
  
  export default faqData;
  