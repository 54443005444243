import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import CountryServices from './countryServices';

export const GetAllCountry = createAsyncThunk(
  'country/getAllCountry',
  async (credentials, thunkAPI) => {
    try {
      const delivered = await CountryServices.getAllCountry();

      return delivered;
    } catch (e) {
      toast(e.message);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const GetAllStates = createAsyncThunk(
  'country/getAllStates',
  async (credentials, thunkAPI) => {
    try {
      const delivered = await CountryServices.getAllStates(credentials);

      return delivered;
    } catch (e) {
      toast(e.message);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const GetAllCities = createAsyncThunk(
  'country/getAllCities',
  async (credentials, thunkAPI) => {
    try {
      const delivered = await CountryServices.getAllCities(credentials);

      return delivered;
    } catch (e) {
      toast(e.message);
      return thunkAPI.rejectWithValue(e);
    }
  }
);
