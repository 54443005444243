import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import NavFooterWrapper from "./pages/nav_footer_wrapper";
import Hompage from "./pages/home";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ServicesPage from "./pages/services";
import LoginPage from "./pages/login";
import SignupPage from "./pages/signup";
import UserSignupPage from "./pages/user-signup";
import ProviderSignupPage from "./pages/provider-signup";
import ProviderDashboardWrapper from "./pages/dashboard/prodvider_dashboard_wrapper";
import SwipeableTemporaryDrawer from "./components/dashboard/sidebars/drawer";
import ProviderIndex from "./pages/dashboard/provider";
import ProviderVerification from "./components/dashboard/provider/profile-verification";
import CustomerDashboardWrapper from "./pages/dashboard/customer_dashboard_wrapper";
import CustomerIndexPage from "./pages/dashboard/customer";
import CustomerBookListPage from "./pages/dashboard/customer/booking";
import PhoneOtpPage from "./pages/phone-otp";
import EmailOtpPage from "./pages/email-otp";
import ServiceList from "./pages/dashboard/provider/ServiceList";
import CreateService from "./pages/dashboard/provider/CreateService";
import ProviderPrivateRoute from "./RouteHelpers/ProviderPrivateRoute";
import CustomerVerification from "./components/dashboard/customers/profileVerification";
import CustomerChangePassword from "./components/dashboard/customers/changePassword";
import CustomerProfile from "./components/dashboard/customers/customerProfile";
import BookService from "./components/dashboard/customers/book-service";
import BookPayment from "./components/dashboard/customers/book-payment";
import BookingDone from "./components/dashboard/customers/booking-done";
import FavoriteListPage from "./pages/dashboard/customer/favorites";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "./context/context";
import ServiceDetailsPage from "./components/services/serviceDetailsPage";
import CustomerChat from "./components/dashboard/customers/CustomerChat";
import ProfileSetting from "./pages/dashboard/provider/ProfileSetting";
import CustomerServiceChat from "./components/dashboard/customers/CustomerServiceChat";
import CustomerServicesList from "./components/dashboard/customers/services-list";
import VehicleCheckListComp from "./components/dashboard/customers/vehicle-check-listComp";
import ChatScreen from "./components/dashboard/provider/ChatScreen";
import CustomerPrivateRoute from "./RouteHelpers/CustomerPrivateRoute";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Earnings from "./components/dashboard/provider/Earnings";
import VehicleInfo from "./components/dashboard/customers/VehicleInfo";
import Biddings from "./components/dashboard/provider/Biddings";
import VehicleInspection from "./components/dashboard/customers/Vehicle-Inspection";
import CustomerWallet from "./components/dashboard/customers/CustomerWallet";
import Payout from "./components/dashboard/provider/Payout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VehicleInspectionForm from "./components/dashboard/customers/VehicleInspectionForm";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndCondition";
import Pricing from "./pages/Pricing";
import PageInProgressComp from "./common/in-progress-component";
import FAQ from "./pages/Faq";
import TechnicianVehicleVerificationForm from "./components/dashboard/provider/TechnicianInspectionForm";
import Loader from "./components/Loader/Loader";
import { getServices } from "./manage_states/gen-states/services/servicesSync";
import { useDispatch, useSelector } from "react-redux";
import CustomerSubscriptionSuccess from "./components/dashboard/customers/CustomerSubscriptionSuccess";
import VendorSubscriptionSuccess from "./components/dashboard/provider/VendorSubscriptionSuccess";
import CardIndex from "./components/card/CardIndex";
import ForgotPassword from "./pages/ForgotPassword";
import ForgotPassEmailOtp from "./pages/ForgotPassEmailOtp";
import ForgotNewPassChange from "./pages/ForgotNewPassChange";

// assets/img/avatar-profile-photo.png

function App() {
  const { socket } = useContext(AppContext);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [currentSubCategories, setCurrentSubCategories] = useState([]);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category.name);
    setCurrentSubCategories(category.sub_category);
  };

  const {
    serviceLimit,
    serviceSkip,
    services,
    loadingServices,
    completeCategory,
    category_loader,
  } = useSelector((state) => state.services);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getServices({ page: serviceSkip, limit: serviceLimit })).finally(
      () => setIsLoading(false)
    );
  }, [dispatch]);

  // useEffect(() => {
  //   dispatch(getServices({ page: serviceSkip, limit: serviceLimit }))
  //   dispatch(GetAllCategory())
  // }, [])

  useEffect(() => {
    console.log("native work");
    socket.emit("connected");
  }, []);

  socket.off("connected-user").on("connected-user", (room) => {
    console.log(room, "visions");
  });

  // Track route changes and show loader
  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => setIsLoading(false), 1000); // Simulate loading time
    return () => clearTimeout(timer);
  }, [location]);
  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <div className="main-wrapper">
          <Routes>
            <Route path="/" element={<NavFooterWrapper />}>
              <Route path="/" element={<Hompage />} />
              <Route path="/services" element={<ServicesPage />} />
              <Route
                path="/service-details/:serviceTitle"
                element={<ServiceDetailsPage />}
              />
              <Route path="/book-service/:id" element={<BookService />} />
              <Route path="/book-payment/:id" element={<BookPayment />} />
              <Route path="/payment-done/:id" element={<BookingDone />} />
              {/* <Route
                path="/user-subcription-success/:id/:subscription/:duration"
                element={<CustomerSubscriptionSuccess />}
              />
              <Route
                path="/vendor-subcription-success/:id/:subscription/:duration"
                element={<VendorSubscriptionSuccess />}
              /> */}
               <Route
                path="/user-subcription-success"
                element={<CustomerSubscriptionSuccess />}
              />
              <Route
                path="/vendor-subcription-success"
                element={<VendorSubscriptionSuccess />}
              />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-condition" element={<TermsAndConditions />} />
              <Route path="/pricing/:id" element={<Pricing />} />
              <Route
                path="/customer/customer-chat"
                element={<CustomerChat />}
              />
              <Route
                path="/customer/customer-service-chat"
                element={<CustomerServiceChat />}
              />

              <Route path="/customer" element={<CustomerDashboardWrapper />}>
                <Route path="/customer" element={<CustomerIndexPage />} />
                <Route
                  path="/customer/booking"
                  element={<CustomerBookListPage />}
                />
                <Route
                  path="/customer/services-list"
                  element={<CustomerServicesList />}
                />
                <Route
                  path="/customer/vehicle-checklist"
                  element={<VehicleCheckListComp />}
                />
                <Route
                  path="/customer/vehicle-checklist"
                  element={<VehicleCheckListComp />}
                />
                <Route
                  path="/customer/vehicle-inspection"
                  element={<VehicleInspectionForm />}
                />
                {/* <Route
              path="/customer/vehicle-inspection"
              element={<VehicleInspection />}
            /> */}
                <Route
                  path="/customer/favorites"
                  element={<FavoriteListPage />}
                />
                <Route
                  path="/customer/verification"
                  element={<CustomerVerification />}
                />
                <Route path="/customer/car-info" element={<VehicleInfo />} />
                <Route path="/customer/wallet" element={<CustomerWallet />} />
                <Route
                  path="/customer/changepassword"
                  element={<CustomerChangePassword />}
                />
                <Route path="/customer/profile" element={<CustomerProfile />} />
              </Route>

              <Route
                path="/create-service"
                element={
                  <ProviderPrivateRoute>
                    <CreateService />
                  </ProviderPrivateRoute>
                }
              />
            </Route>
            <Route path="/card" element={<CardIndex />} />
            <Route
              path="/tow-trucks"
              element={
                <PageInProgressComp
                  title={"Tow Truck Services: On the Way"}
                  subTitle={
                    "Reliable assistance is just around the corner. Check back for updates!"
                  }
                  image={"/assets/img/tow-truck-3.gif"}
                />
              }
            />
            <Route
              path="/technicians"
              element={
                <PageInProgressComp
                  title={"Technicians Hub: Coming Soon!"}
                  subTitle={
                    "Streamlined tools and support for expert problem solvers. Stay tuned for the launch!"
                  }
                  image={"/assets/img/car-dealer-3.gif"}
                />
              }
            />
            <Route
              path="/dealers"
              element={
                <PageInProgressComp
                  title={"Dealer Network: Under Construction"}
                  subTitle={
                    "Connecting you with trusted dealerships. We're building something amazing!"
                  }
                  image={"/assets/img/dealer-sales.webp"}
                />
              }
            />
            <Route path="/provider" element={<ProviderDashboardWrapper />}>
              <Route path="/provider" element={<ProviderIndex />} />
              <Route
                path="/provider/verification"
                element={<ProviderVerification />}
              />
              <Route path="/provider/services" element={<ServiceList />} />
              <Route path="/provider/booking" element={<Biddings />} />
              <Route
                path="/provider/profile-settings"
                element={<ProfileSetting />}
              />
              <Route
                path="/provider/vehicle-inspection"
                element={<TechnicianVehicleVerificationForm />}
              />
              <Route path="/provider/chat" element={<ChatScreen />} />
              <Route path="/provider/earnings" element={<Earnings />} />
              <Route path="/provider/payout" element={<Payout />} />
            </Route>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/sign-up" element={<SignupPage />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/user-signup" element={<UserSignupPage />} />
            <Route path="/provider-signup" element={<ProviderSignupPage />} />
            <Route path="/phone-otp" element={<PhoneOtpPage />} />
            <Route path="/email-otp" element={<EmailOtpPage />} />
            <Route path="/forgot-email-otp" element={<ForgotPassEmailOtp />} />
            <Route path="/forgot-new-password" element={<ForgotNewPassChange />} />
          </Routes>
          <ToastContainer />
        </div>
      )}
    </>
  );
}

export default App;
