import React from "react";
import PhoneOtp from "../components/otp/forgot-email";
import LoginRegisterNav from "../common/nav/login-register-nav";

const PhoneOtpPage = () => {
  return (
    <div className="main-wrapper">
      <LoginRegisterNav />
      <PhoneOtp />
    </div>
  );
};

export default PhoneOtpPage;
