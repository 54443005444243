/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { GetSingleUser } from "../../../manage_states/gen-states/auth/authServiceSync";

const CustomerSideBar = ({ extraClass }) => {
  const [expanded, setExpanded] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { userBookings, isGettingUserBookings, isGettingUserBookingsFailure } =
  //   useSelector(serviceSelector);
  const { user, loading } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(GetSingleUser());
    // dispatch(getUserBookings("0/10"));
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("airdrops_token");
    localStorage.removeItem("airdrops_user_type");
    localStorage.removeItem("airdrop_user_email");
    navigate("/login");
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // console.log(user, "loing ing");

  return (
    <div className={`col-lg-3 theiaStickySidebar ${extraClass}`}>
      <div className="settings-widget">
        <div className="settings-header">
          <div className="settings-img">
            <img
              src={
                user?.picture
                  ? user?.picture
                  : `/assets/img/avatar-profile-photo.png`
              }
              alt="user"
            />
          </div>
          <h6>{user?.name}</h6>
          <p>{user?.email}</p>
        </div>
        <div style={{padding:"10px", display:"flex", flexDirection:"column", alignItems:"center"}}>
          <div > <h6 style={{fontSize:"15px"}}>Current Package: <span style={{color:"#6a686a", fontWeight:"500"}} >Free Tier</span></h6> </div>
         
          <a href="/pricing/user" className="btn btn-primary ">Upgrade Package</a>
        </div>
        <div className="settings-menu">
          <ul>
            <li>
              <NavLink to="/customer" activeClassName="active">
                <i className="feather-grid"></i> <span>Dashboard</span>
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="active" to="/customer/booking">
                <i className="feather-smartphone"></i> <span>Bookings</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                activeClassName="active"
                to="/customer/services-list"
              >
                <i className="feather-smartphone"></i> <span>Services</span>
              </NavLink>
            </li>
            <li>
              <NavLink exact activeClassName="active" to="/customer/car-info">
                <i class="fa-solid fa-circle-info"></i>{" "}
                <span>Vehicle Info</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                activeClassName="active"
                to="/customer/vehicle-inspection"
              >
                <i class="fa-solid fa-car"></i>
                <span>Vehicle Inspection</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                activeClassName="active"
                to="/customer/vehicle-checklist"
              >
                <i className="fa-solid fa-circle-check"></i>
                <span>Vehicle Checklist</span>
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/customer/wallet">
                <i className="feather-credit-card"></i> <span>Wallet</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="active"
                to="/customer/customer-service-chat"
              >
                <i className="feather-message-circle"></i>{" "}
                <span>Service Chat</span>
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="active" to="/customer/customer-chat">
                <i className="feather-message-circle"></i> <span>Chat</span>
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="active" to="/customer/profile">
              <i class="fa-solid fa-user"></i> Account
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="active" to="/customer/verification">
              <i class="fa-solid fa-user-check"></i>  Profile Verification
              </NavLink>
            </li>
            {/* <li>
              <a to='customer-profile.html'>
                <i className='feather-settings'></i> <span>Settings</span>
              </a>
            </li> */}

            {/* <ul className="mb-4">
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ width: "80%", flexShrink: 0 }}>
                    <i className="feather-settings"></i> Settings
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="ul">
                    <li>
                      <NavLink activeClassName="active" to="/customer/profile">
                        Account
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        activeClassName="active"
                        to="/customer/changepassword"
                      >
                        Security
                      </NavLink>
                    </li>
                    <li>
                      <a to="#">Notification</a>
                    </li>

                    <li>
                      <NavLink
                        activeClassName="active"
                        to="payment-settings.html"
                      >
                        Payment Settings
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="provider-connected-apps.html">
                        Connected Apps
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        activeClassName="active"
                        to="/customer/verification"
                      >
                        Profile Verification
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#del-account"
                      >
                        Delete Account
                      </NavLink>
                    </li>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </ul> */}
            <li>
              <NavLink
                activeClassName="active"
                to="/login"
                onClick={handleLogout}
              >
                <i className="feather-log-out"></i> <span>Logout</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CustomerSideBar;
