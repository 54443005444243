import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../common/nav';
import Footer from '../common/footer';
import { useDispatch } from 'react-redux';
import { GetSingleUser } from '../manage_states/gen-states/auth/authServiceSync';
const NavFooterWrapper = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetSingleUser());
    // dispatch(getUserBookings("0/10"));
  }, []);

  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
};

export default NavFooterWrapper;
