import React from "react";
import CreateServiceComp from "../../../components/dashboard/provider/services/CreateService";

const CreateService = () => {
  return (
    <div>
      <CreateServiceComp />
    </div>
  );
};

export default CreateService;
