import React from 'react'
import CustomersFavorites from '../../../components/dashboard/customers/favorites'

const FavoriteListPage = () => {
  return (
    <div>
      <CustomersFavorites />
    </div>
  )
}

export default FavoriteListPage
