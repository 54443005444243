import React from "react";

const TermsAndConditions = () => {
  return (
    <>
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title footer-a">Terms and Conditions</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a className="footer-a" href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active footer-a" aria-current="page">
                    Terms and Conditions
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="terms-content">
                <h3>Introduction</h3>
                <p>
                  Welcome to Airdrops’ PUNCH. These Terms and Conditions govern
                  your use of our website, services, and any associated
                  applications. By accessing or using our platform, you agree to
                  these Terms in full. If you do not agree, please refrain from
                  using our services.
                </p>

                <h3>Eligibility</h3>
                <p>
                  You must be at least 18 years old or the age of majority in
                  your jurisdiction to use our services. By using our platform,
                  you represent that you meet this requirement.
                </p>

                <h3>Scope of Services</h3>
                <ul>
                  <li>
                    <strong>Automotive Services:</strong> We provide innovative
                    automotive solutions, including scheduling, estimation, and
                    customer support.
                  </li>
                  <li>
                    <strong>Business Solutions:</strong> For B2B clients, we
                    offer tailored services with specific terms outlined in
                    individual agreements.
                  </li>
                  <li>
                    <strong>Platform Usage:</strong> Access to tools,
                    resources, and features to enhance user experience.
                  </li>
                </ul>

                <h3>Account Responsibilities</h3>
                <ul>
                  <li>
                    <strong>Account Security:</strong> You are responsible for
                    maintaining the confidentiality of your account and password.
                  </li>
                  <li>
                    <strong>Accurate Information:</strong> You must provide
                    accurate and up-to-date information when creating an
                    account.
                  </li>
                  <li>
                    <strong>Prohibited Activities:</strong> You agree not to
                    engage in unlawful or unauthorized activities, including
                    reverse-engineering, hacking, or exploiting vulnerabilities.
                  </li>
                </ul>

                <h3>Payment Terms</h3>
                <ul>
                  <li>
                    <strong>Fees:</strong> All fees for services will be
                    disclosed upfront. Payments must be made in accordance with
                    agreed terms.
                  </li>
                  <li>
                    <strong>Refunds:</strong> Refunds are subject to our Refund
                    Policy, as outlined in our Privacy Policy.
                  </li>
                  <li>
                    <strong>Taxes:</strong> Customers are responsible for any
                    applicable taxes on services rendered.
                  </li>
                </ul>

                <h3>Intellectual Property</h3>
                <p>
                  All content on our platform, including logos, designs, and
                  text, is owned by Airdrops’ PUNCH or its licensors. Unauthorized
                  use, reproduction, or distribution is prohibited.
                </p>

                <h3>Data Protection</h3>
                <p>
                  Your data is handled in accordance with our Privacy Policy. By
                  using our services, you consent to the collection, storage,
                  and processing of your information.
                </p>

                <h3>Disclaimer of Warranties</h3>
                <p>
                  Services are provided “as is” without warranties of any kind,
                  express or implied, including fitness for a particular
                  purpose. We do not guarantee uninterrupted or error-free
                  service.
                </p>

                <h3>Limitation of Liability</h3>
                <p>
                  To the fullest extent permitted by law, Airdrops’ PUNCH shall
                  not be liable for any indirect, incidental, or consequential
                  damages arising from the use or inability to use our services.
                </p>

                <h3>Indemnification</h3>
                <p>
                  You agree to indemnify and hold Airdrops’ PUNCH harmless from
                  any claims, damages, or losses arising from your breach of
                  these Terms.
                </p>

                <h3>Termination</h3>
                <p>
                  We reserve the right to suspend or terminate your access to
                  our services for violation of these Terms or any applicable
                  laws.
                </p>

                <h3>Governing Law</h3>
                <p>
                  These Terms are governed by the laws of [Insert Jurisdiction].
                  Any disputes will be resolved in the courts of [Insert
                  Jurisdiction].
                </p>

                <h3>Amendments</h3>
                <p>
                  Airdrops’ PUNCH reserves the right to modify these Terms and
                  Conditions at any time. Updates will be posted here, and
                  continued use of our services implies acceptance of the
                  revised terms.
                </p>

                <h3>Effective Date</h3>
                <p>[Insert Date]</p>

                <h3>Contact Information</h3>
                <p>
                  For inquiries, concerns, or feedback, please contact us:
                </p>
                <ul>
                  <li>Email: [Insert Email Address]</li>
                  <li>Phone: [Insert Phone Number]</li>
                  <li>Address: [Insert Business Address]</li>
                </ul>

                <h3>Entire Agreement</h3>
                <p>
                  These Terms and Conditions, along with our Privacy Policy,
                  constitute the entire agreement between you and Airdrops’
                  PUNCH regarding your use of our services.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
