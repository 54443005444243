import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserMakePayment } from "../../../manage_states/gen-states/payment/paymentServiceSync";

const BookPayment = () => {
  const dispatch = useDispatch();
  const paymentValue = {
    amount: "200",
    currency: "NGN",
    redirect_url: "https://flutterwave.com/ng",
    customer: {
      email: "user@example.com",
      phone_number: "08101112121",
      name: "Example User",
    },
    customizations: {
      title: "Pied Piper Payments",
      logo: "http://www.piedpiper.com/app/themes/joystick-v27/images/logo.png",
    },
    id: "payer id",
    profile_pic: "payer profile pic",
    services: "service title he is paying for",
    serviceId: "service id",
    services_pic: "service pics",
    booked_serviceId: "booked service id",
  };
  const {
    makingPayment,
    makePaymentSuccessful,
    makePaymentError,
    makePaymentMessage,
  } = useSelector((state) => state.payment);

  const handleMakePayment = (e) => {
    e.preventDefault();
    dispatch(UserMakePayment(paymentValue));
  };
  return (
    <div class="content book-content">
      <div class="container">
        <div class="row">
          {/* <!-- Booking --> */}
          <div class="col-lg-10 mx-auto">
            {/* <!-- Booking Step --> */}
            <ul class="step-register row">
              {/* <li class='activate col-md-4'>
                <div class='multi-step-icon'>
                  <span>
                    <img src='/assets/img/icons/calendar-icon.svg' alt='img' />
                  </span>
                </div>
                <div class='multi-step-info'>
                  <h6>Appointment</h6>
                  <p>Choose time & date for the service</p>
                </div>
              </li> */}
              <li class="active col-md-4">
                <div class="multi-step-icon">
                  <span>
                    <img src="/assets/img/icons/wallet-icon.svg" alt="img" />
                  </span>
                </div>
                <div class="multi-step-info">
                  <h6>Payment</h6>
                  <p>Select Payment Gateway</p>
                </div>
              </li>
              <li class="col-md-4">
                <div class="multi-step-icon">
                  <span>
                    <img src="/assets/img/icons/book-done.svg" alt="img" />
                  </span>
                </div>
                <div class="multi-step-info">
                  <h6>Done </h6>
                  <p>Completion of Booking</p>
                </div>
              </li>
            </ul>
            {/* <!-- /Booking Step --> */}

            {/* <!-- Booking Payment --> */}
            <div class="row">
              <div class="col-lg-6 mx-auto ">
                <h5 class="pay-title">Booking Payment Summary</h5>
                <div class="summary-box">
                  <div class="booking-info">
                    <div class="service-book">
                      <div class="service-book-img">
                        <img src="/assets/img/booking.jpg" alt="img" />
                      </div>
                      <div class="serv-profile">
                        <span class="badge">Car Wash</span>
                        <h2>Car Repair Services</h2>
                        <ul>
                          <li class="serv-pro">
                            <img
                              src="/assets/img/profiles/avatar-01.jpg"
                              alt="img"
                            />
                          </li>
                          <li class="serv-review">
                            <i class="fa-solid fa-star"></i> <span>4.9 </span>
                            (255 reviews)
                          </li>
                          <li class="service-map">
                            <i class="feather-map-pin"></i> Alabama, USA
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="booking-summary">
                    <ul class="booking-date">
                      <li>
                        Date <span>07/09/2023</span>
                      </li>
                      <li>
                        Time <span>12.30 Pm - 01. 00 PM</span>
                      </li>
                      <li>
                        Service Provider <span>Thomas Herzberg</span>
                      </li>
                    </ul>
                    <ul class="booking-date">
                      <li>
                        Subtotal <span>$150.00</span>
                      </li>
                      <li>
                        Coupoun Discount <span>$5.00</span>
                      </li>
                      <li>
                        Services Charges <span>$3.00</span>
                      </li>
                    </ul>
                    <div class="booking-total">
                      <ul class="booking-total-list">
                        <li>
                          <span>Total</span>
                          <span class="total-cost">$148.00</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="booking-pay">
                  <a href="/booking-done" class="btn btn-primary btn-pay w-100">
                    Proceed to Pay $148
                  </a>
                </div>
              </div>
            </div>
            {/* <!-- /Booking Payment --> */}
          </div>
          {/* <!-- /Booking --> */}
        </div>
      </div>
    </div>
  );
};

export default BookPayment;
