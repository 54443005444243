import React from "react";

const VehicleCheckListComp = () => {
  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        {/* <!-- Profile settings --> */}
        <div className="row">
          <div className="col-md-12">
            {/* <!-- Account Settings --> */}
            <div className="widget-title">
              <h2>Vehicle Checklist</h2>
            </div>
            <h6 className="user-title">Exterior Inspection:</h6>
            <div className="general-info">
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="col-form-label">
                      Headlights and taillights
                      <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeHolder="Enter your name"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="col-form-label">
                      Turn signals <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeHolder="Enter your user name"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="col-form-label">
                      Brake lights <span class="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      placeHolder="Enter Email Address"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="col-form-label">
                      Tires and tire pressure <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeHolder="Enter Mobile Number"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="col-form-label">
                      Windshield and mirrors <span class="text-danger">*</span>
                    </label>
                    <select className="select form-control">
                      <option>Select Gender</option>
                      <option>Male</option>
                      <option>Female</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="col-form-label">
                      License plate <span class="text-danger">*</span>
                    </label>
                    <select className="select form-control">
                      <option>Select Gender</option>
                      <option>Male</option>
                      <option>Female</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <h6 className="user-title">Interior Inspection:</h6>
            <div className="general-info">
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="col-form-label">
                      Seatbelts
                      <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeHolder="Enter your name"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="col-form-label">
                      Horn
                      <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeHolder="Enter your user name"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="col-form-label">
                      Dashboard lights <span class="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      placeHolder="Enter Email Address"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="col-form-label">
                      Steering wheel <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeHolder="Enter Mobile Number"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="col-form-label">
                      Brakes
                      <span class="text-danger">*</span>
                    </label>
                    <select className="select form-control">
                      <option>Select Gender</option>
                      <option>Male</option>
                      <option>Female</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="col-form-label">
                      Clutch (if applicable) <span class="text-danger">*</span>
                    </label>
                    <select className="select form-control">
                      <option>Select Gender</option>
                      <option>Male</option>
                      <option>Female</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="col-form-label">
                      Accelerator <span class="text-danger">*</span>
                    </label>
                    <select className="select form-control">
                      <option>Select Gender</option>
                      <option>Male</option>
                      <option>Female</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="col-form-label">
                      Rearview mirrors
                      <span class="text-danger">*</span>
                    </label>
                    <select className="select form-control">
                      <option>Select Gender</option>
                      <option>Male</option>
                      <option>Female</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="acc-submit">
              <a href="#" className="btn btn-secondary">
                Cancel
              </a>
              <a href="#" className="btn btn-primary">
                Save Changes
              </a>
            </div>
          </div>
          {/* <!-- /Account Settings --> */}
        </div>
        {/* <!-- /profile-settings --> */}
      </div>
    </div>
  );
};

export default VehicleCheckListComp;
