import React from "react";

const Earnings = () => {
  return (
    <div className="content container-fluid">
      <div className="page-header">
        <div className="row">
          <div className="col-md-4">
            <div className="provider-subtitle">
              <h6>Earnings</h6>
            </div>
          </div>
          <div className="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap">
            <div className="review-sort me-2">
              <p>Sort</p>
              <select className="select">
                <option>A -&gt; Z</option>
                <option>Z -&gt; A</option>
                <option>Most Helpful</option>
              </select>
            </div>
            <div className="grid-listview me-2">
              <ul>
                <li>
                  <a href="javascript:void(0);">
                    <i className="feather-filter" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="provide-table manage-table">
            <div className="table-responsive">
              <table
                className="table custom-table datatable mb-0"
                id="data-table"
              >
                <thead className="thead-light">
                  <tr>
                    <th>#</th>
                    <th>Service</th>
                    <th>Earned Amount</th>
                    <th>Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>
                      <h2 className="table-avatar table-sml">
                        <a href="#" className="avatar avatar-m me-2">
                          <img
                            className="avatar-img rounded"
                            src="/assets/img/services/service-06.jpg"
                            alt="User Image"
                          />
                        </a>
                        <a href="#">Computer Repair</a>
                      </h2>
                    </td>
                    <td>$38.25</td>
                    <td>07 Oct 2023 11:22:51</td>
                    <td>
                      <div className="table-actions">
                        <a className="action-set" href="javascript:void(0);">
                          <i className="feather-edit" />
                        </a>
                        <a className="action-set confirm-text" href="#">
                          <i className="feather-trash-2" />
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>
                      <h2 className="table-avatar table-sml">
                        <a href="#" className="avatar avatar-m me-2">
                          <img
                            className="avatar-img rounded"
                            src="/assets/img/services/service-04.jpg"
                            alt="User Image"
                          />
                        </a>
                        <a href="#">Car Repair Services</a>
                      </h2>
                    </td>
                    <td>$20</td>
                    <td>07 Oct 2023 11:22:51</td>
                    <td>
                      <div className="table-actions">
                        <a className="action-set" href="javascript:void(0);">
                          <i className="feather-edit" />
                        </a>
                        <a className="action-set confirm-text" href="#">
                          <i className="feather-trash-2" />
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>
                      <h2 className="table-avatar table-sml">
                        <a href="#" className="avatar avatar-m me-2">
                          <img
                            className="avatar-img rounded"
                            src="/assets/img/services/service-08.jpg"
                            alt="User Image"
                          />
                        </a>
                        <a href="#">Steam Car Wash</a>
                      </h2>
                    </td>
                    <td>$10</td>
                    <td>07 Oct 2023 11:22:51</td>
                    <td>
                      <div className="table-actions">
                        <a className="action-set" href="javascript:void(0);">
                          <i className="feather-edit" />
                        </a>
                        <a className="action-set confirm-text" href="#">
                          <i className="feather-trash-2" />
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>
                      <h2 className="table-avatar table-sml">
                        <a href="#" className="avatar avatar-m me-2">
                          <img
                            className="avatar-img rounded"
                            src="/assets/img/services/service-09.jpg"
                            alt="User Image"
                          />
                        </a>
                        <a href="#">House Cleaning </a>
                      </h2>
                    </td>
                    <td>$90</td>
                    <td>07 Oct 2023 11:22:51</td>
                    <td>
                      <div className="table-actions">
                        <a className="action-set" href="javascript:void(0);">
                          <i className="feather-edit" />
                        </a>
                        <a className="action-set confirm-text" href="#">
                          <i className="feather-trash-2" />
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>
                      <h2 className="table-avatar table-sml">
                        <a href="#" className="avatar avatar-m me-2">
                          <img
                            className="avatar-img rounded"
                            src="/assets/img/services/service-02.jpg"
                            alt="User Image"
                          />
                        </a>
                        <a href="#">Building Construction</a>
                      </h2>
                    </td>
                    <td>$55</td>
                    <td>07 Oct 2023 11:22:51</td>
                    <td>
                      <div className="table-actions">
                        <a className="action-set" href="javascript:void(0);">
                          <i className="feather-edit" />
                        </a>
                        <a className="action-set confirm-text" href="#">
                          <i className="feather-trash-2" />
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>
                      <h2 className="table-avatar table-sml">
                        <a href="#" className="avatar avatar-m me-2">
                          <img
                            className="avatar-img rounded"
                            src="/assets/img/services/service-07.jpg"
                            alt="User Image"
                          />
                        </a>
                        <a href="#">Interior Designing</a>
                      </h2>
                    </td>
                    <td>$20</td>
                    <td>07 Oct 2023 11:22:51</td>
                    <td>
                      <div className="table-actions">
                        <a className="action-set" href="javascript:void(0);">
                          <i className="feather-edit" />
                        </a>
                        <a className="action-set confirm-text" href="#">
                          <i className="feather-trash-2" />
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>
                      <h2 className="table-avatar table-sml">
                        <a href="#" className="avatar avatar-m me-2">
                          <img
                            className="avatar-img rounded"
                            src="/assets/img/services/service-05.jpg"
                            alt="User Image"
                          />
                        </a>
                        <a href="#">Commercial Painting </a>
                      </h2>
                    </td>
                    <td>$400</td>
                    <td>07 Oct 2023 11:22:51</td>
                    <td>
                      <div className="table-actions">
                        <a className="action-set" href="javascript:void(0);">
                          <i className="feather-edit" />
                        </a>
                        <a className="action-set confirm-text" href="#">
                          <i className="feather-trash-2" />
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>
                      <h2 className="table-avatar table-sml">
                        <a href="#" className="avatar avatar-m me-2">
                          <img
                            className="avatar-img rounded"
                            src="/assets/img/services/service-11.jpg"
                            alt="User Image"
                          />
                        </a>
                        <a href="#">Plumbing Services</a>
                      </h2>
                    </td>
                    <td>$70</td>
                    <td>07 Oct 2023 11:22:51</td>
                    <td>
                      <div className="table-actions">
                        <a className="action-set" href="javascript:void(0);">
                          <i className="feather-edit" />
                        </a>
                        <a
                          className="action-set confirm-text"
                          href="javascript:void(0);"
                        >
                          <i className="feather-trash-2" />
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div id="tablelength" />
            </div>
            <div className="col-md-9">
              <div className="table-ingopage">
                <div id="tableinfo" />
                <div id="tablepagination" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Earnings;
