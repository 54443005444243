import { useQuery } from "@tanstack/react-query";
import { Fragment } from "react";
import { Navigate } from "react-router-dom";
import { isAuthenticated } from "../hooks";

const ProviderPrivateRoute = ({ children }) => {
  const authenticatedUser = useQuery({
    queryKey: ["authenticated"],
    queryFn: isAuthenticated,
  });
  const authToken = localStorage.getItem("airdrops_token");
  console.log(authToken);

  console.log(authenticatedUser?.data, "authenticated");
  return <Fragment>{authToken ? children : <Navigate to="/login" />}</Fragment>;
};

export default ProviderPrivateRoute;
