import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { vinDecoder } from "../../../manage_states/gen-states/services/servicesSync";

const VehicleInfo = () => {
  const [vinValue, setVinValue] = useState("");
  const dispatch = useDispatch();
  const { vinData, gettingVin, getVinSuccess, getVinError } = useSelector(
    (state) => state.services
  );

  const handleGetVin = () => {
    dispatch(vinDecoder(vinValue));
  };

  console.log(vinData);
  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        {/* <!-- Profile settings --> */}
        <div className="row">
          <div className="col-md-12">
            {/* <!-- Account Settings --> */}
            <div className="widget-title">
              <h2>Vehicle Details</h2>
            </div>
            <h6 className="user-title">Search for your vehicle details:</h6>
            <div className="general-info mb-2">
              <div style={{ alignItems: "center" }} className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="col-form-label">
                      Select Details Type <span class="text-danger">*</span>
                    </label>
                    <select className="select form-control">
                      <option>Select One Info</option>
                      <option>Vehicle Details with VIN</option>
                      <option>Car Auction Details</option>
                      <option>Car Market Value</option>
                      <option>Vehicle Sales History</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="col-form-label">
                      Vin Number
                      <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeHolder="Enter your vin number"
                      onChange={(e) => setVinValue(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  {/* <div className="acc-submit"> */}
                  <button onClick={handleGetVin} className="btn btn-primary">
                    {gettingVin ? "searching..." : "Search"}
                  </button>
                  {/* </div> */}
                </div>
              </div>
            </div>
            <br />
            <h6 className="user-title mt-2">Basic Info</h6>
            <div className="general-info">
              <div className="row">
                <h6 className="col-4">Make:</h6>
                <p className="col-6">{vinData?.basic?.make}</p>
              </div>
              <div className="row">
                <h6 className="col-4">Model:</h6>
                <p className="col-6">{vinData?.basic?.model}</p>
              </div>
              <div className="row">
                <h6 className="col-4">Year:</h6>
                <p className="col-6">{vinData?.basic?.year}</p>
              </div>
              <div className="row">
                <h6 className="col-4">Body Type:</h6>
                <p className="col-6">{vinData?.basic?.body_type}</p>
              </div>
              <div className="row">
                <h6 className="col-4">Vehicle Type:</h6>
                <p className="col-6">{vinData?.basic?.vehicle_type}</p>
              </div>
            </div>
            <br />
            <h5 className="user-title m-2">Engine Info</h5>
            <div className="general-info">
              <div className="row">
                <h6 className="col-4">Engine size:</h6>
                <p className="col-6">{vinData?.engine?.engine_size}</p>
              </div>
              <div className="row">
                <h6 className="col-4">Engine Description:</h6>
                <p className="col-6">{vinData?.engine?.engine_description}</p>
              </div>
              <div className="row">
                <h6 className="col-4">Engine Capacity:</h6>
                <p className="col-6">{vinData?.engine?.engine_capacity}</p>
              </div>
            </div>
            <br />
            <h5 className="user-title m-2">Manufacturer Info</h5>
            <div className="general-info">
              <div className="row">
                <h6 className="col-4">Manufacturer:</h6>
                <p className="col-6">{vinData?.manufacturer?.manufacturer}</p>
              </div>
              <div className="row">
                <h6 className="col-4">Region:</h6>
                <p className="col-6">{vinData?.manufacturer?.city}</p>
              </div>
              <div className="row">
                <h6 className="col-4">Country:</h6>
                <p className="col-6">{vinData?.manufacturer?.country}</p>
              </div>
              <div className="row">
                <h6 className="col-4">Plant City:</h6>
                <p className="col-6">{vinData?.manufacturer?.plant_city}</p>
              </div>
            </div>
            <br />
            <h5 className="user-title m-2">Transmission Info</h5>
            <div className="general-info">
              <div className="row">
                <h6 className="col-4">Transmission:</h6>
                <p className="col-6">
                  {vinData?.transmission?.transmission_style}
                </p>
              </div>
            </div>
          </div>
          {/* <!-- /Account Settings --> */}
        </div>
        {/* <!-- /profile-settings --> */}
      </div>
    </div>
  );
};

export default VehicleInfo;
