import React from "react";
import CreateServiceHeader from "./CreateServiceHeader";
import CreateServiceProcess from "./CreateServiceProcess";

const CreateServiceComp = () => {
  return (
    <div>
      <CreateServiceHeader />
      <CreateServiceProcess />
    </div>
  );
};

export default CreateServiceComp;
