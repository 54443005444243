/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { AppContext } from "../../../context/context";

const SingleBookList = ({
  service_title,
  booking_date,
  amount,
  location,
  provider,
  status,
  data,
  CreateRoom,
  btnDisabled,
  handlePay,
  currency,
}) => {
  return (
    <div className="booking-list">
      <div className="booking-widget">
        <div className="booking-img">
          <a href="booking-details.html">
            <img
              src={
                data?.gallery[0]?.pic_url ||
                "/assets/img/services/service-08.jpg"
              }
              alt="User service"
            />
          </a>
          <div className="fav-item">
            <a href="#" className="fav-icon">
              <i className="feather-heart" />
            </a>
          </div>
        </div>
        <div className="booking-det-info">
          <h3>
            <a
              style={{ textTransform: "uppercase" }}
              href="booking-details.html"
            >
              {service_title}
            </a>
            <span
              className={
                status === "pending"
                  ? "badge badge-danger"
                  : status === "inprogress"
                  ? "badge badge-info"
                  : "badge badge-success"
              }
            >
              {status}
            </span>
          </h3>
          <ul className="booking-details">
            <li>
              <span className="book-item">Booking Date</span> : {booking_date}
            </li>
            <li>
              <span className="book-item">Amount</span> : {currency} {amount}
              {/* <span className="badge-grey badge-new">Paypal</span> */}
            </li>
            <li>
              <span className="book-item">Location</span> : {location}
            </li>
            <li onClick={() => CreateRoom(data)}>
              <span className="book-item">Service Advicer</span> :
              <div className="user-book">
                <div className="avatar avatar-xs">
                  <img
                    className="avatar-img rounded-circle"
                    alt="User avatar"
                    src={
                      data?.profile_pic ||
                      "/assets/img/avatar-profile-photo.png"
                    }
                  />
                </div>
                {"Admin"}
              </div>
              <p>
                <a href="#">{data?.email}</a>
              </p>
              <p>{data?.phone_number}</p>
            </li>
          </ul>
        </div>
      </div>
      <div className="booking-action action-popup-btn">
        <a
          href="#"
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#reschedule"
        >
          <i className="fa-regular fa-calendar-days" />
          Reschedule
        </a>
        <button
          className="btn btn-primary"
          style={{marginTop:  "1rem"}}
          disabled={btnDisabled}
          onClick={handlePay}
        >
          Make Payment
        </button>
      </div>
    </div>
  );
};

export default SingleBookList;
