/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

const CustomerPhoneVerification = () => {
  return (
    <>
      <div className='linked-item'>
        <div className='row'>
          <div className='col-md-8'>
            <div className='linked-acc'>
              <span className='link-icon'>
                <img src='/assets/img/icons/phone-call.svg' alt='Icons' />
              </span>
              <div className='linked-info'>
                <h6>Phone Number Verification</h6>
                <p>Lorem ipsum dolor sit amet, consectetur </p>
              </div>
            </div>
          </div>
          <div className='col-md-4 text-md-end'>
            <div className='security-verifi d-flex align-items-center'>
              <a href='#' className='link-close'>
                <i className='fa-solid fa-circle-xmark' />
              </a>
              <a
                href='#'
                className='btn btn-primary btn-connect'
                data-bs-toggle='modal'
                data-bs-target='#change-phone'
              >
                Change
              </a>
              <a href='#' className='btn-acc'>
                Remove
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Change Phone  */}
      <div className='modal fade custom-modal verify-modal' id='change-phone'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content doctor-profile'>
            <div className='modal-header verfication-modal-head'>
              <h5 className='modal-title'>Verify Your Phone Number</h5>
              <p>You will receive a 4 digit code to verify next</p>
            </div>
            <div className='modal-body'>
              <form action='https://html.truelysell.com/template3/provider-security-settings.html'>
                <div className='wallet-add'>
                  <div className='form-group'>
                    <input
                      className='form-control'
                      name='phone'
                      type='text'
                      placeholder='Enter Phone Number'
                    />
                  </div>
                  <div className='modal-submit'>
                    <a
                      href='#'
                      className='btn btn-primary w-100'
                      data-bs-toggle='modal'
                      data-bs-target='#otp'
                    >
                      Change Number
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Change Phone  */}
      {/* OTP  */}
      <div className='modal fade custom-modal verify-modal' id='otp'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content doctor-profile'>
            <div className='modal-header verfication-modal-head'>
              <h5 className='modal-title'>Enter OTP</h5>
              <p>Code is sent to +1 23456789</p>
            </div>
            <div className='modal-body'>
              <form
                action='https://html.truelysell.com/template3/provider-security-settings.html'
                className='digit-group'
              >
                <div className='wallet-add'>
                  <div className='otp-box'>
                    <div className='forms-block text-center'>
                      <input
                        type='text'
                        id='digit-1'
                        name='digit-1'
                        data-next='digit-2'
                        maxLength={1}
                        defaultValue={1}
                      />
                      <input
                        type='text'
                        id='digit-2'
                        name='digit-2'
                        data-next='digit-3'
                        data-previous='digit-1'
                        maxLength={1}
                        defaultValue={2}
                      />
                      <input
                        type='text'
                        id='digit-3'
                        name='digit-3'
                        data-next='digit-4'
                        data-previous='digit-2'
                        maxLength={1}
                        defaultValue={3}
                      />
                      <input
                        type='text'
                        id='digit-4'
                        name='digit-4'
                        data-next='digit-5'
                        data-previous='digit-3'
                        maxLength={1}
                        defaultValue={4}
                      />
                    </div>
                  </div>
                  <div className='otp-timer text-center'>
                    <h6>00:29</h6>
                  </div>
                  <div className='resend-code-otp text-center'>
                    <p>
                      Don’t <span> receive </span> code?{'{'}' '{'}'}
                      <a href='#'> Resend Code </a>
                    </p>
                  </div>
                  <div className='modal-submit'>
                    <a
                      href='#'
                      className='btn btn-primary w-100'
                      data-bs-toggle='modal'
                      data-bs-target='#success'
                    >
                      Verify
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* OTP  */}
      {/* Success phone */}
      <div className='modal fade success-modal verify-modal' id='success'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-body success-body text-center'>
              <span>
                <i className='fa-regular fa-circle-check' />
              </span>
              <h3>Success</h3>
              <p className='success-modal-body'>
                Your phone number has been successfully verified
              </p>
            </div>
            <div className='popup-btn text-center'>
              <a
                href='#'
                className='btn btn-primary verify-phone'
                onclick='verifiePhone()'
              >
                Go to Dashboard
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Success  */}
    </>
  )
}

export default CustomerPhoneVerification
