/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

const DownloadApp = () => {
  return (
    <section class='app-section' style={{backgroundColor:"#3E4265"}} >
      <div class='container'>
        <div class='app-sec'>
          <div class='row'>
            <div class='col-lg-6 col-12'>
              <div class='col-md-12'>
                <div class='heading aos' data-aos='fade-up'>
                  <h2>Download Our App</h2>
                  <p>
                    Get your vehicle punched today by one of auto-experts near
                    you and discover a new world of automotive assurance
                  </p>
                  <h6>Scan the QR code to get the app now</h6>
                  <div class='scan-img'>
                    <img
                      src='assets/img/scan-img.png'
                      class='img-fluid'
                      alt='img'
                    />
                  </div>
                </div>
              </div>
              <div class='downlaod-btn aos' data-aos='fade-up'>
                <a href='#'>
                  <img src='assets/img/googleplay.svg' alt='img' />
                </a>
                <a href='#'>
                  <img src='assets/img/appstore.svg' alt='img' />
                </a>
              </div>
            </div>
            <div class='col-lg-6 col-12'>
              <div class='appimg-set aos' data-aos='fade-up'>
                <img
                  src='assets/img/Group 8747 1.png'
                  class='img-fluid'
                  alt='img'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DownloadApp
