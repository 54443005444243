import React from "react";
import LoginRegisterNav from "../common/nav/login-register-nav";
import ChooseSignup from "../components/register/choose-signup";
const SignupPage = () => {
  return (
    <div className="main-wrapper">
      <LoginRegisterNav />
      <ChooseSignup />
    </div>
  );
};

export default SignupPage;
