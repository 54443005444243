import { toast } from "react-toastify";
import APIs from "../../Api";
// import APIs from "../Api";

const Login = async (credentials) => {
  try {
    const response = await APIs.post(`/login`, credentials);

    if (response?.data?.message?.toLowerCase() === "success") {
      toast("Login Successfully");

      APIs.defaults.headers[
        "Authorization"
      ] = `Bearer ${response?.data?.token}`;

      toast.success("Login successful!");

      toast("Login Successfully");

      APIs.defaults.headers[
        "Authorization"
      ] = `Bearer ${response?.data?.token}`;

      localStorage.setItem("airdrops_token", response?.data?.token);

      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

const CreateUser = async (payload) => {
  try {
    const response = await APIs.post("/register", payload);

    if (response?.data?.message === "success") {
      toast("Registered Successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getSingleUser = async (payload) => {
  try {
    const response = await APIs.get(`/get_user`);

    // console.log(response, 'get user details hot hot');
    if (response?.data?.message === "success") {
      // toast("successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const editProfile = async (payload) => {
  try {
    const response = await APIs.patch(`/edit_user`, payload);

    if (response?.data?.message === "success") {
      toast("successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const AuthServices = {
  Login,
  getSingleUser,
  CreateUser,
  editProfile,
};

export default AuthServices;
