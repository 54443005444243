/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {
  return (
    <footer className='footer' style={{backgroundColor:"#3E4265"}} >
      {/* <!-- Footer Top --> */}
      <div className='footer-top aos' data-aos='fade-up'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-md-6'>
              {/* <!-- Footer Widget --> */}
              <div className='footer-widget'>
                <div className='footer-logo'>
                  <a href='/'>
                    <img
                      src='/assets/img/cropped-Untitled-1-1 3.png'
                      alt='logo'
                    />
                  </a>
                </div>
                <div className='footer-content'>
                  {/* <p>
                    {' '}
                    Empower Your Lorem ipsum dolor sit consectetur adipiscing
                    elit, sed do eiusmod tempor commodo consequat.{' '}
                  </p> */}
                </div>
                <div className='footer-selects'>
                  <h2 className='footer-title footer-header'>Language & Currency</h2>
                  <div className='row'>
                    <div className='col-lg-12 d-flex'>
                      <div className='footer-select'>
                        <img src='/assets/img/icons/global.svg' alt='img' />
                        <select className='select form-control'>
                          <option>English</option>
                          <option>France</option>
                          <option>Spanish</option>
                        </select>
                      </div>
                      <div className='footer-select'>
                        <img
                          src='/assets/img/icons/dropdown.svg'
                          className='footer-dropdown'
                          alt='img'
                        />
                        <select className='select form-control'>
                          <option>US Dollars</option>
                          <option>INR</option>
                          <option>Kuwait</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- /Footer Widget --> */}
            </div>
            <div className='col-lg-2 col-md-6'>
              {/* <!-- Footer Widget --> */}
              <div className='footer-widget footer-menu'>
                <h2 className='footer-title footer-header'>Quick Links</h2>
                <ul>
                  <li>
                    <a className='footer-a' href='/about-us'>About Us</a>
                  </li>
                  {/* <li>
                    <a href='blog-grid.html'>Blogs</a>
                  </li> */}
                  <li>
                    <a className='footer-a' href='/contact-us'>Contact Us</a>
                  </li>
                  <li>
                    <a className='footer-a' href='/faq'>Frequently asked</a>
                  </li>
                  <li>
                    <a className='footer-a' href='/provider-signup'>Become a Partiner</a>
                  </li>
                  <li>
                    <a className='footer-a' href='/user-signup'>Join us</a>
                  </li>
                </ul>
              </div>
              {/* <!-- /Footer Widget --> */}
            </div>
            <div className='col-lg-3 col-md-6'>
              {/* <!-- Footer Widget --> */}
              <div className='footer-widget footer-contact'>
                <h2 className='footer-title footer-header'>Contact Us</h2>
                <div className='footer-contact-info'>
                  <div className='footer-address'>
                    <p className='footer-a'>
                      <span>
                        <i className='feather-map-pin'></i>
                      </span>
                      8500 Macleod Trail, Calgary, AB T2H 2N1
                    </p>
                  </div>
                  {/* <p>
                    <span>
                      <i className='feather-phone'></i>
                    </span>{' '}
                    321 546 8764
                  </p> */}
                  <p className='mb-0'>
                    <span>
                      <i className='feather-mail'></i>
                    </span>
                    <a
                      href='https://html.truelysell.com/cdn-cgi/l/email-protection'
                      className='footer-a'
                      data-cfemail='384c4a4d5d54414b5d5454785d40595548545d165b5755'
                    >
                      support@airdropspunch.com
                    </a>
                  </p>
                </div>
              </div>
              {/* <!-- /Footer Widget --> */}
            </div>
            <div className='col-lg-3 col-md-6'>
              {/* <!-- Footer Widget --> */}
              <div className='footer-widget'>
                <h2 className='footer-title footer-header'>Follow Us</h2>
                <div className='social-icon'>
                  <ul>
                    <li>
                      <a href='#'>
                        <i className='fa-brands fa-facebook'></i>{' '}
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaXTwitter />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <i className='fa-brands fa-instagram'></i>
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <i className='fa-brands fa-linkedin'></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <h2 className='footer-subtitle footer-a'>Newsletter Signup</h2>
                <div className='subscribe-form'>
                  <input
                    type='email'
                    className='form-control'
                    placeholder='Enter Email Address'
                  />
                  <button type='submit' className='btn footer-btn'>
                    <i className='feather-send'></i>
                  </button>
                </div>
              </div>
              {/* <!-- /Footer Widget --> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /Footer Top --> */}

      {/* <!-- Footer Bottom --> */}
      <div className='footer-bottom'>
        <div className='container'>
          {/* <!-- Copyright --> */}
          <div className='copyright'>
            <div className='row align-items-center'>
              <div className='col-md-4'>
                <div className='copyright-text'>
                  <p className='mb-0'>
                    Copyright &copy; {new Date().getFullYear()} Airdrops' PUNCH.
                    All Rights Reserved.
                  </p>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='payment-image'>
                  <ul>
                    <li>
                      <a href=''>
                        <img src='/assets/img/payment/visa.png' alt='img' />
                      </a>
                    </li>
                    <li>
                      <a href=''>
                        <img
                          src='/assets/img/payment/mastercard.png'
                          alt='img'
                        />
                      </a>
                    </li>
                    <li>
                      <a href=''>
                        <img src='/assets/img/payment/stripe.png' alt='img' />
                      </a>
                    </li>
                    <li>
                      <a href=''>
                        <img src='/assets/img/payment/discover.png' alt='img' />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-md-4'>
                {/* <!-- Copyright Menu --> */}
                <div className='copyright-menu'>
                  <ul className='policy-menu'>
                    <li>
                      <a className='footer-a' href='/privacy-policy'>Privacy Policy</a>
                    </li>
                    <li>
                      <a className='footer-a' href='/terms-condition'>Terms & Conditions</a>
                    </li>
                  </ul>
                </div>
                {/* <!-- /Copyright Menu --> */}
              </div>
            </div>
          </div>
          {/* <!-- /Copyright --> */}
        </div>
      </div>
      {/* <!-- /Footer Bottom --> */}
    </footer>
  )
}

export default Footer
