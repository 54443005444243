import axios from "axios";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { Api_Url } from "../../api_calls/url";
import { redirect, useNavigate } from "react-router-dom";

const EmailOtp = () => {
  const [otp, setOtp] = useState("");
  const [verifyingAccount, setVerifyingAccount] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Error Occured");
  const email = localStorage.getItem("airdrop_user_email");
  const displayEmail = email?.split("@")[0].slice(-3);
  var domain = email?.split("@")[1];
  console.log(email, displayEmail);
  console.log(otp);
  const navigate = useNavigate();
  useEffect(() => {
    if (!email) {
      navigate("/sign-up");
    }
  }, []);

  const verifyOtp = async (e) => {
    e.preventDefault();
    try {
      const requiredValues = {
        email: email,
        otp: otp,
      };
      setVerifyingAccount(true);
      const verify = await axios.post(
        `${Api_Url}/api/v1/activate_otp`,
        requiredValues
      );
      console.log(verify.status, verify.data);
      if (verify.status === 200) {
        setVerifyingAccount(false);
        setShowModal(true);
        setErrorState(false);
        localStorage.removeItem("airdrop_user_email")
        // localStorage.setItem("airdrop_user_email", formValues.email);
      }
    } catch (err) {
      console.log("error", err.response.data);
      setVerifyingAccount(false);
      setShowModal(true);
      setErrorState(true);
      setErrorMessage(err.response.data.message);
    }
  };
  return (
    <>
      {!email}
      <div class="content">
        <div class="container">
          <div class="row">
            {/* <!-- Phone Otp Form --> */}
            <div class="col-md-6 col-lg-6 mx-auto">
              {!showModal && (
                <div class="login-wrap otp-wrap">
                  <div class="otp-img">
                    <img src="assets/img/icons/email-otp.svg" alt="image" />
                  </div>
                  <div class="login-header">
                    <h3>Email OTP Verification</h3>
                    <p>
                      OTP sent to your Email Address ending{" "}
                      <span>******{displayEmail + "@" + domain}</span>
                    </p>
                  </div>

                  {/* <form action="https://html.truelysell.com/template3/login-phone.html"> */}
                  <div class="form-group">
                    <div class="d-flex passcode-wrap digit-group passcode-verified">
                      <OtpInput
                        className="d-flex passcode-wrap digit-group passcode-verified"
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        inputStyle={{ width: "60%" }}
                        // renderSeparator={<span>-</span>}
                        renderInput={(props) => <input {...props} />}
                      />
                    </div>
                  </div>
                  <div class="text-center">
                    <div class="time-expiry">
                      <i class="feather-clock me-1"></i>00:25 secs
                    </div>
                  </div>
                  <p class="no-acc">
                    Didn't get the OTP? <a href="#">Resend OTP</a>
                  </p>
                  <a
                    class="btn btn-primary w-100 login-btn mb-0"
                    type="submit"
                    href="#"
                    onClick={verifyOtp}
                  >
                    {verifyingAccount ? "verifying..." : "Verify & Proceed"}
                  </a>
                  {/* </form> */}
                </div>
              )}
              {showModal && (
                <div class="login-wrap">
                  <div class="modal-content">
                    <div class="modal-body danger-body text-center">
                      {errorState ? (
                        <img src="assets/img/icons/sad.gif" alt="image" />
                      ) : (
                        <span style={{ fontSize: "80px" }}>
                          <i class="fa-regular fa-circle-check text-green"></i>
                        </span>
                      )}

                      <h3 class={errorState ? "text-danger" : ""}>
                        {errorState
                          ? "Error Occured !!!"
                          : "Verification Success"}
                      </h3>
                      <p class="success-modal-body">
                        {errorState
                          ? errorMessage
                          : "Your email has been verified successfully."}
                      </p>
                    </div>
                    {!errorState && (
                      <div class="popup-btn text-center">
                        <a
                          href="/login"
                          class="btn btn-primary verify-document"
                        >
                          Proceed to login
                        </a>
                      </div>
                    )}
                    {errorState && (
                      <div class="popup-btn text-center">
                        <a
                          href="#"
                          onClick={() => setShowModal(false)}
                          class="btn btn-primary verify-document"
                        >
                          Try again
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            {/* <!-- /Phone Otp Form --> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailOtp;
