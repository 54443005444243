import React from "react";
import LoginRegisterNav from "../common/nav/login-register-nav";
import ChangeForgotPassword from "../components/register/change-password";

const ForgotNewPassChange = () => {
  return (
    <div className="main-wrapper">
      <LoginRegisterNav />
      <ChangeForgotPassword />
    </div>
  );
};

export default ForgotNewPassChange;
