/* eslint-disable jsx-a11y/anchor-is-valid */

import CustomerDocumentVerification from './documentVerification'
// import FaceVerification from './faceVerification'
import CustomerPhoneVerification from './phoneVerification'

const CustomerVerification = () => {
  return (
    <>
      <div className='row'>
        {/* Security Settings */}
        <div className='col-md-12'>
          <div className='widget-title'>
            <h4>Profile Verification</h4>
          </div>

          {/* <FaceVerification /> */}
          <CustomerPhoneVerification />
          <div className='linked-item'>
            <div className='row'>
              <div className='col-md-8'>
                <div className='linked-acc'>
                  <span className='link-icon'>
                    <img
                      src='/assets/img/icons/mail-envelope.svg'
                      alt='Icons'
                    />
                  </span>
                  <div className='linked-info'>
                    <h6>Email Verification</h6>
                    <p>Lorem ipsum dolor sit amet, consectetur </p>
                  </div>
                </div>
              </div>
              <div className='col-md-4 text-md-end'>
                <div className='security-verifi d-flex align-items-center'>
                  <a href='#' className='link-check'>
                    <i className='fa-solid fa-circle-check' />
                  </a>
                  <a
                    href='#'
                    className='btn btn-primary btn-connect'
                    data-bs-toggle='modal'
                    data-bs-target='#change-email'
                  >
                    Change
                  </a>
                  <a href='#' className='btn-acc'>
                    Remove
                  </a>
                </div>
              </div>
            </div>
          </div>

          <CustomerDocumentVerification />

          {/* Change Email */}
          <div className='modal fade custom-modal' id='change-email'>
            <div className='modal-dialog modal-dialog-centered'>
              <div className='modal-content doctor-profile'>
                <div className='modal-header border-bottom-0 justify-content-between'>
                  <h5 className='modal-title'>Change Email</h5>
                  <button
                    type='button'
                    className='close-btn'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                  >
                    <i className='feather-x' />
                  </button>
                </div>
                <div className='modal-body pt-0'>
                  <form action='#'>
                    <div className='wallet-add'>
                      <div className='form-group'>
                        <label className='col-form-label'>
                          Current Email Address
                        </label>
                        <input
                          type='email'
                          className='form-control'
                          placeholder='Enter Email Address'
                        />
                      </div>
                      <div className='form-group'>
                        <label className='col-form-label'>
                          New Email Address
                          <span className='text-danger'>*</span>
                        </label>
                        <input
                          type='email'
                          className='form-control'
                          placeholder='Enter Email Address'
                        />
                        <p className='brief-bio mb-0 mt-2'>
                          <i className='feather-alert-circle' /> New Email
                          Address Only Updated Once You Verified
                        </p>
                      </div>
                      <div className='form-group'>
                        <label className='col-form-label'>
                          Confirm New Password
                        </label>
                        <div className='pass-group'>
                          <input
                            type='password'
                            className='form-control pass-input'
                            placeholder='*************'
                          />
                          <span className='toggle-password feather-eye-off' />
                        </div>
                      </div>
                      <div className='modal-submit text-end'>
                        <a
                          href='#'
                          className='btn btn-secondary me-2'
                          data-bs-dismiss='modal'
                        >
                          Cancel
                        </a>
                        <button className='btn btn-primary'>
                          Change Email
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* /Change Email */}
        </div>
        {/* /Security Settings */}
      </div>
    </>
  )
}

export default CustomerVerification
