import { createAsyncThunk } from "@reduxjs/toolkit";
import PaymentServices from "./paymentService";
import { toast } from "react-toastify";

export const loginUser = createAsyncThunk(
  "payment/loginUser",
  async (credentials, thunkAPI) => {
    try {
      const delivered = await PaymentServices.Login(credentials);
      return delivered;
    } catch (e) {
      toast(e.message);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const UserMakePayment = createAsyncThunk(
  "payment/UserMakePayment",
  async (credentials, thunkAPI) => {
    try {
      const delivered = await PaymentServices.UserMakePayment(credentials);
      console.log(delivered, "delivered")
      window.open(delivered?.data?.data?.link, "_blank")
      return delivered;
    } catch (e) {
      toast(e.message);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const ConfirmUserPayment = createAsyncThunk(
  "payment/ConfirmUserPayment",
  async (credentials, thunkAPI) => {
    try {
      const delivered = await PaymentServices.ConfirmUserPayment(credentials);
      console.log(delivered, "delivered")
      // window.open(delivered?.data?.data?.link, "_blank")
      return delivered;
    } catch (e) {
      toast(e.message);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const VendorRequestPayout = createAsyncThunk(
  "payment/VendorRequestPayout",
  async (credentials, thunkAPI) => {
    try {
      const delivered = await PaymentServices.VendorRequestPayout(credentials);
      return delivered;
    } catch (e) {
      toast(e.message);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const UserRequestRefund =createAsyncThunk(
  "payment/UserRequestRefund",
  async (credentials, thunkAPI) => {
    try {
      const delivered = await PaymentServices.UserRequestRefund(credentials);
      return delivered;
    } catch (e) {
      toast(e.message);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const GetUserRefundRequests = createAsyncThunk(
  "payment/GetUserRequestRefund",
  async (credentials, thunkAPI) => {
    try {
      const delivered = await PaymentServices.GetUserRefundRequests(credentials);
      return delivered;
    } catch (e) {
      toast(e.message);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const GetVendorPayoutRequests = createAsyncThunk(
  "payment/GetVendorPayoutRequests",
  async (credentials, thunkAPI) => {
    try {
      const delivered = await PaymentServices.GetVendorPayoutRequests(credentials);
      return delivered;
    } catch (e) {
      toast(e.message);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const GetSingleUser = createAsyncThunk(
  "payment/getSingleUser",
  async (thunkAPI) => {
    try {
      const delivered = await PaymentServices.getSingleUser();
      console.log(delivered, "delivered successfully");
      return delivered;
    } catch (e) {
      toast(e.message);
      return thunkAPI.rejectWithValue(e);
    }
  }
);
