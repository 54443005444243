import React from 'react'
import OwlCarousel from 'react-owl-carousel'

const Testimonies = () => {
  const options = {
    // nav: true,
    dots: true,
    margin: 20,
    loop: true,
    autoPlay: true,
    autoplayTimeout: 4000,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      768: {
        items: 1,
      },
      992: {
        items: 1,
      },
      1200: {
        items: 1,
      },
    },
  }
  return (
    // <section class="client-section">
    //   <div class="container">
    //     <div class="row">
    //       <div class="col-md-12 text-center">
    //         <div class="section-heading aos" data-aos="fade-up">
    //           <h2>What our client says</h2>
    //           <p>Lorem ipsum dolor sit amet, consectetur elit</p>
    //         </div>
    //       </div>
    //     </div>
    //     <div class="row">
    //       <div class="col-md-12">
    //         <OwlCarousel
    //           class="owl-carousel testimonial-slider"
    //           autoplay={true}
    //           loop
    //           margin={10}
    //           {...options}
    //         >
    //           <div class="client-widget aos" data-aos="fade-up">
    //             <div class="client-img">
    //               <a href="#">
    //                 <img
    //                   class="img-fluid"
    //                   alt="Image"
    //                   src="assets/img/profiles/avatar-01.jpg"
    //                 />
    //               </a>
    //             </div>
    //             <div class="client-content">
    //               <div class="rating">
    //                 <i class="fas fa-star filled"></i>
    //                 <i class="fas fa-star filled"></i>
    //                 <i class="fas fa-star filled"></i>
    //                 <i class="fas fa-star filled"></i>
    //                 <i class="fas fa-star filled"></i>
    //               </div>
    //               <p>
    //                 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
    //                 do eiusmod tempor incididunt ut labore et dolore magna
    //                 aliqua. Ut enim ad minim veniam, quis nostrud exercitation
    //                 ullamco laboris nisi{" "}
    //               </p>
    //               <h5>Sophie Moore</h5>
    //               <h6>Director</h6>
    //             </div>
    //           </div>
    //           <div class="client-widget aos" data-aos="fade-up">
    //             <div class="client-img">
    //               <a href="#">
    //                 <img
    //                   class="img-fluid"
    //                   alt="Image"
    //                   src="assets/img/profiles/avatar-02.jpg"
    //                 />
    //               </a>
    //             </div>
    //             <div class="client-content">
    //               <div class="rating">
    //                 <i class="fas fa-star filled"></i>
    //                 <i class="fas fa-star filled"></i>
    //                 <i class="fas fa-star filled"></i>
    //                 <i class="fas fa-star filled"></i>
    //                 <i class="fas fa-star filled"></i>
    //               </div>
    //               <p>
    //                 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
    //                 do eiusmod tempor incididunt ut labore et dolore magna
    //                 aliqua. Ut enim ad minim veniam, quis nostrud exercitation
    //                 ullamco laboris nisi{" "}
    //               </p>
    //               <h5>Mike Hussy</h5>
    //               <h6>Lead</h6>
    //             </div>
    //           </div>
    //           <div class="client-widget aos" data-aos="fade-up">
    //             <div class="client-img">
    //               <a href="#">
    //                 <img
    //                   class="img-fluid"
    //                   alt="Image"
    //                   src="assets/img/profiles/avatar-03.jpg"
    //                 />
    //               </a>
    //             </div>
    //             <div class="client-content">
    //               <div class="rating">
    //                 <i class="fas fa-star filled"></i>
    //                 <i class="fas fa-star filled"></i>
    //                 <i class="fas fa-star filled"></i>
    //                 <i class="fas fa-star filled"></i>
    //                 <i class="fas fa-star filled"></i>
    //               </div>
    //               <p>
    //                 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
    //                 do eiusmod tempor incididunt ut labore et dolore magna
    //                 aliqua. Ut enim ad minim veniam, quis nostrud exercitation
    //                 ullamco laboris nisi{" "}
    //               </p>
    //               <h5>John Doe</h5>
    //               <h6>CEO</h6>
    //             </div>
    //           </div>
    //         </OwlCarousel>
    //       </div>
    //     </div>
    //   </div>
    // </section>

    <section className='customer-review-section'>
      <div className='customer-side-main-all'>
        <div className='customer-side-left-img'>
          <img src='assets/img/customer-review-after.png' alt='imag' />
        </div>
        <div className='customer-side-right-img'>
          <img src='assets/img/customer-review-before.png' alt='imag' />
        </div>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12 text-center'>
            <div
              className='section-heading section-heading-nine aos'
              data-aos='fade-up'
            >
              <p>OUR LATEST ARTICLES</p>
              <h2>Customer Reviews</h2>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <OwlCarousel
              className='owl-carousel customer-review-slider'
              autoplay={true}
              loop
              margin={10}
              {...options}
            >
              <div className='customer-reviews-all aos' data-aos='fade-up'>
                <div className='customer-reviews-main'>
                  <img src='assets/img/blog/blog-14.jpg' alt='imag' />
                  <div className='customer-quote'>
                    <img src='assets/img/icons/customer-quote.svg' alt='imag' />
                  </div>
                </div>
                <h4>Vincent Cooper</h4>
                <span>Canada</span>
                <div className='rating'>
                  <i className='fas fa-star filled' />
                  <i className='fas fa-star filled' />
                  <i className='fas fa-star filled' />
                  <i className='fas fa-star filled' />
                  <i className='fa-solid fa-star-half-stroke filled' />
                </div>
                <p>
                  I've been using Airdrops' PUNCH for my car maintenance, and I
                  must say the service is exceptional. The technicians are
                  skilled, professional, and always go the extra mile to ensure
                  my vehicle is in perfect condition. Their attention to detail
                  and use of modern technology gives me peace of mind every time
                  I'm on the road. It's not just a service; it's a partnership.
                  Highly recommended!
                </p>
              </div>
              <div className='customer-reviews-all'>
                <div className='customer-reviews-main'>
                  <img src='assets/img/blog/blog-14.jpg' alt='imag' />
                  <div className='customer-quote'>
                    <img src='assets/img/icons/customer-quote.svg' alt='imag' />
                  </div>
                </div>
                <h4>Emmanuel Valentine</h4>
                <span>Alaska, USA</span>
                <div className='rating'>
                  <i className='fas fa-star filled' />
                  <i className='fas fa-star filled' />
                  <i className='fas fa-star filled' />
                  <i className='fas fa-star filled' />
                  <i className='fa-solid fa-star-half-stroke filled' />
                </div>
                <p>
                  Airdrops' PUNCH has truly revolutionized the way I maintain my
                  vehicle. As a commercial driver, I need my car to be in top
                  shape at all times, and this platform never disappoints. The
                  verified technicians are efficient and trustworthy, and their
                  innovative approach keeps me coming back. It's comforting to
                  know that my safety is their top priority. Five stars all the
                  way!.
                </p>
              </div>
              <div className='customer-reviews-all'>
                <div className='customer-reviews-main'>
                  <img src='assets/img/blog/blog-14.jpg' alt='imag' />
                  <div className='customer-quote'>
                    <img src='assets/img/icons/customer-quote.svg' alt='imag' />
                  </div>
                </div>
                <h4>Anita Vincent</h4>
                <span> USA</span>
                <div className='rating'>
                  <i className='fas fa-star filled' />
                  <i className='fas fa-star filled' />
                  <i className='fas fa-star filled' />
                  <i className='fas fa-star filled' />
                  <i className='fa-solid fa-star-half-stroke filled' />
                </div>
                <p>
                  Maintaining my car used to be a hassle until I discovered
                  Airdrops' PUNCH. Their network of skilled technicians ensures
                  that every aspect of my vehicle is checked and serviced
                  meticulously. I appreciate their dedication to safety and
                  reliability, which means I can focus on driving without
                  worrying about unexpected breakdowns. This is the future of
                  vehicle services!
                </p>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Testimonies
