/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import React, { useState } from 'react'
import { Api_Url } from '../../api_calls/url'

const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false)
  const [loggingIn, setLoggingIn] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [errorState, setErrorState] = useState(false)
  const [errorMessage, setErrorMessage] = useState('Error Occured')
  const [navigationState, setNavigationState] = useState('#')
  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }))
    console.log('user data', formValues)
  }
  const toogleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleLogin = async (e) => {
    e.preventDefault()
    try {
      setLoggingIn(true)
      const register = await axios.post(`${Api_Url}/api/v1/login`, formValues)
      console.log(register, 'login data')
      if (register.status === 200) {
        setLoggingIn(false)
        setShowModal(true)
        setErrorState(false)
        localStorage.setItem('airdrops_token', register?.data?.token)
        const userType =
          register?.data?.data?.client_type === true ? 'client' : 'provider'
        localStorage.setItem('airdrops_user_type', userType)
        register?.data?.data?.client_type === true
          ? setNavigationState('/customer')
          : setNavigationState('/provider')
      }
    } catch (err) {
      console.log('error', err?.response?.data)
      setLoggingIn(false)
      setShowModal(true)
      setErrorState(true)
      setErrorMessage(err?.response?.data?.message)
    }
  }
  return (
    <div class='content'>
      <div class='container'>
        <div class='row'>
          <div class='col-md-6 col-lg-6 mx-auto'>
            {!showModal && (
              <div class='login-wrap'>
                <div class='login-header'>
                  <h3>Login</h3>
                  <p>Please enter your details</p>
                </div>

                {/* <!-- Login Form --> */}
                <div>
                  <div class='log-form'>
                    <div class='form-group'>
                      <label class='col-form-label'>Email</label>
                      <input
                        type='text'
                        class='form-control'
                        placeholder='johndoe@example.com'
                        name='email'
                        value={formValues.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div class='form-group'>
                      <div class='row'>
                        <div class='col'>
                          <label class='col-form-label'>Password</label>
                        </div>
                        <div class='col-auto'>
                          <a class='forgot-link' href='/forgot-password'>
                            Forgot password?
                          </a>
                        </div>
                      </div>
                      <div class='pass-group'>
                        <input
                          type={showPassword === true ? 'text' : 'password'}
                          class='form-control pass-input'
                          placeholder='*************'
                          name='password'
                          value={formValues.password}
                          onChange={handleChange}
                        />
                        <span
                          class={
                            showPassword === true
                              ? 'toggle-password feather-eye'
                              : 'toggle-password feather-eye-off'
                          }
                          onClick={toogleShowPassword}
                        ></span>
                      </div>
                    </div>
                    <div class='row'>
                      <div class='col-6'>
                        <label class='custom_check'>
                          <input
                            type='checkbox'
                            name='rememberme'
                            class='rememberme'
                          />
                          <span class='checkmark'></span>Remember Me
                        </label>
                      </div>
                    </div>
                  </div>
                  <button
                    class='btn btn-primary w-100 login-btn'
                    onClick={handleLogin}
                  >
                    {loggingIn ? 'validating...' : 'Login'}
                  </button>
                  <div class='login-or'>
                    <span class='or-line'></span>
                    <span class='span-or'>OR</span>
                  </div>

                  <p class='no-acc'>
                    Don't have an account ? <a href='/sign-up'>Register</a>
                  </p>
                </div>
                {/* <!-- /Login Form --> */}
              </div>
            )}
            {showModal && (
              <div class='login-wrap'>
                <div class='modal-content'>
                  <div class='modal-body danger-body text-center'>
                    {errorState ? (
                      <img src='assets/img/icons/sad.gif' alt='imag' />
                    ) : (
                      <span style={{ fontSize: '80px' }}>
                        <i class='fa-regular fa-circle-check text-green'></i>
                      </span>
                    )}

                    <h3 class={errorState ? 'text-danger' : ''}>
                      {errorState ? 'Error Occured !!!' : 'Login Success'}
                    </h3>
                    <p class='success-modal-body'>
                      {errorState
                        ? errorMessage
                        : "Welcome back!  we're here to keep your car in great shape, so you can hit the road worry-free."}
                    </p>
                  </div>
                  {!errorState && (
                    <div class='popup-btn text-center'>
                      <a
                        href={navigationState}
                        class='btn btn-primary verify-document'
                      >
                        Proceed to Dashboard
                      </a>
                    </div>
                  )}
                  {errorState && (
                    <div class='popup-btn text-center'>
                      <a
                        href='#'
                        onClick={() => setShowModal(false)}
                        class='btn btn-primary verify-document'
                      >
                        Try again
                      </a>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginForm
