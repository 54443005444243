import React, { useState } from "react";
import { useSelector } from "react-redux";

const ProfileSettingComp = () => {
  const [editable, setEditable] = useState(false);

  const { user } = useSelector((state) => state.auth);

  console.log(user, "user");
  return (
    <div className="">
      <div className="content container-fluid">
        {/* <!-- Profile settings --> */}
        <div className="row">
          <div className="col-md-12">
            {/* <!-- Account Settings --> */}
            <div className="widget-title">
              <h4>Account Settings</h4>
            </div>
            <h6 className="user-title">Profile Picture</h6>
            <div className="pro-picture">
              <div className="pro-img">
                <img src="/assets/img/profiles/avatar-02.jpg" alt="user" />
              </div>
              <div className="pro-info">
                <div className="d-flex">
                  <div className="img-upload">
                    <i className="feather-upload-cloud me-1"></i>Upload
                    <input type="file" />
                  </div>
                  <a href="#" className="btn btn-remove">
                    Remove
                  </a>
                </div>
                <p>
                  *image size should be at least 320px big,and less then 500kb.
                  Allowed files .png and .jpg.
                </p>
              </div>
            </div>
            <h6 className="user-title">General Information</h6>
            <button onClick={() => setEditable(!editable)} className="btn img-upload">
              {editable ? "Cancel" : "Edit Profile"}
            </button>
            <br />
            <br />
            <div className="general-info">
              <div className="row">
                <div className={editable ? "col-md-6" : "col-md-4"}>
                  <div className="form-group">
                    <label className="col-form-label">
                      Name <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={editable ? "form-control" : "form-control details-show"}
                      placeHolder="Enter your name"
                      value={user?.name}
                    />
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <div className="form-group">
                    <label className="col-form-label">User Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeHolder="Enter your user name"
                    />
                  </div>
                </div> */}
                <div className={editable ? "col-md-6" : "col-md-4"}>
                  <div className="form-group">
                    <label className="col-form-label">
                      Email <span class="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className={editable ? "form-control" : "form-control details-show"}
                      placeHolder="Enter Email Address"
                      value={user?.email}
                    />
                  </div>
                </div>
                <div className={editable ? "col-md-6" : "col-md-4"}>
                  <div className="form-group">
                    <label className="col-form-label">
                      Mobile Number <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={editable ? "form-control" : "form-control details-show"}
                      placeHolder="Enter Mobile Number"
                      value={user?.phone_number}
                    />
                  </div>
                </div>
                <div className={editable ? "col-md-6" : "col-md-4"}>
                  <div className="form-group">
                    <label className="col-form-label">Gender</label>
                    <select className={editable ? "select form-control" : "select form-control details-show"}>
                      <option>Select Gender</option>
                      <option>Male</option>
                      <option>Female</option>
                    </select>
                  </div>
                </div>
                <div className={editable ? "col-md-6" : "col-md-4"}>
                  <div className="form-group">
                    <label className="col-form-label">Date of birth</label>
                    <div className="form-icon">
                      <input
                        type="text"
                        className={editable ? "datetimepicker form-control" : " form-control details-show"}
                        placeHolder="DD/MM/YYYY"
                      />
                      <span className="cus-icon">
                        <i class="feather-calendar"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className={editable ? "col-md-6" : "col-md-4"}>
                  <div className="form-group">
                    <label class="col-htmlForm-label d-block">
                      Your Bio{" "}
                      {/* <span class="brief-bio float-end">
                        Brief description for your profile. URLs are
                        hyperlinked.
                      </span> */}
                    </label>
                    <textarea
                      className={editable ? " form-control" : " form-control details-show"}
                      placeHolder="Add a Short Bio....."
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <h6 className="user-title">Address </h6>
            <div className="row">
              <div className={editable ? "col-md-12" : "col-md-4"}>
                <div>
                  <label className="col-form-label">Address</label>
                  <input
                    type="text"
                    className={editable ? "select form-control" : "select form-control details-show"}
                    placeHolder="Enter your address"
                  />
                </div>
              </div>
              <div className={editable ? "col-md-6" : "col-md-4"}>
                <div className="form-group">
                  <label className="col-form-label">Country</label>
                  <input
                    type="text"
                    className={editable ? "select form-control" : "select form-control details-show"}
                    placeHolder="Enter your Country"
                  />
                </div>
              </div>
              <div className={editable ? "col-md-6" : "col-md-4"}>
                <div className="form-group">
                  <label className="col-form-label">State</label>
                  <input
                    type="email"
                    className={editable ? "select form-control" : "select form-control details-show"}
                    placeHolder="Enter your state"
                  />
                </div>
              </div>
              <div className={editable ? "col-md-6" : "col-md-4"}>
                <div className="form-group">
                  <label className="col-form-label">City</label>
                  <input
                    type="text"
                    className={editable ? "select form-control" : "select form-control details-show"}
                    placeHolder="Enter your city"
                  />
                </div>
              </div>
              <div className={editable ? "col-md-6" : "col-md-4"}>
                <div className="form-group">
                  <label className="col-form-label">Postal Code</label>
                  <input
                    type="text"
                    className={editable ? "select form-control" : "select form-control details-show"}
                    placeHolder="Enter your postal code"
                  />
                </div>
              </div>
              <div className={editable ? "col-md-6" : "col-md-4"}>
                <div className="form-group">
                  <label className="col-form-label">Currency Code</label>
                  <select className={editable ? "select form-control" : "select form-control details-show"}>
                    <option>Choose Currency</option>
                    <option>Eur</option>
                    <option>Aud</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="col-form-label">Language</label>
                  <input
                    className={editable ? "select form-control" : "select form-control details-show"}
                    type="text"
                    data-role="tagsinput"
                    name="specialist"
                    value="English,French"
                    id="specialist"
                  />
                </div>
              </div>
            </div>
            <div className="acc-submit">
              <a href="#" className="btn btn-secondary">
                Cancel
              </a>
              <a href="#" className="btn btn-primary">
                Save Changes
              </a>
            </div>
          </div>
          {/* <!-- /Account Settings --> */}
        </div>
        {/* <!-- /profile-settings --> */}
      </div>
    </div>
  );
};

export default ProfileSettingComp;
