// src/features/auth/authSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
  GetAllCities,
  GetAllCountry,
  GetAllStates,
} from './countryServices_sync';

const initialState = {
  country_loading: false,
  state_loading: false,
  cityLoading: false,
  all_countries: [],
  all_states: [],
  all_cities: [],
  country: {},
  state: {},
  city: {},
  error: '',
  message: '',
};

const countrySlice = createSlice({
  name: 'country',
  initialState,
  reducers: {
    reset_country: (state) => {
      state.country_loading = false;
      state.state_loading = false;
      state.cityLoading = false;
      state.all_countries = [];
      state.all_states = [];
      state.all_cities = [];
      state.country = {};
      state.state = {};
      state.city = {};
      state.error = '';
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllCountry.pending, (state) => {
        state.country_loading = true;
      })
      .addCase(GetAllCountry.fulfilled, (state, { payload }) => {
        state.country_loading = false;
        state.all_countries = payload.data;
        state.message = payload?.message;
      })
      .addCase(GetAllCountry.rejected, (state, { payload }) => {
        state.country_loading = false;
        state.message = payload?.message;
      });

    builder
      .addCase(GetAllStates.pending, (state) => {
        state.state_loading = true;
      })
      .addCase(GetAllStates.fulfilled, (state, { payload }) => {
        state.state_loading = false;
        state.all_states = payload.data;
        state.message = payload?.message;
      })
      .addCase(GetAllStates.rejected, (state, { payload }) => {
        state.state_loading = false;
        state.message = payload?.message;
      });

    builder
      .addCase(GetAllCities.pending, (state) => {
        state.cityLoading = true;
      })
      .addCase(GetAllCities.fulfilled, (state, { payload }) => {
        state.cityLoading = false;
        state.all_cities = payload.data;
        state.message = payload?.message;
      })
      .addCase(GetAllCities.rejected, (state, { payload }) => {
        state.cityLoading = false;
        state.message = payload?.message;
      });
  },
});

export const { reset_country } = countrySlice.actions;
export default countrySlice.reducer;
