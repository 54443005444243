import React from "react";

const DocumentVerification = () => {
  return (
    <>
      <div class="linked-item">
        <div class="row">
          <div class="col-md-8">
            <div class="linked-acc">
              <span class="link-icon">
                <img src="/assets/img/icons/document-pdf.svg" alt="Icons" />
              </span>
              <div class="linked-info">
                <h6>
                  <a href="javascript:void(0);">Document Verification</a>
                </h6>
                <p>Not Yet Verified </p>
              </div>
            </div>
          </div>
          <div class="col-md-4 text-md-end">
            <div class="verfification-modal-block">
              <a
                href="javascript:void(0);"
                class="link-close"
                data-bs-toggle="tooltip"
                data-bs-placement="left"
                data-bs-title="Not Verified"
              >
                <i class="fa-solid fa-circle-xmark"></i>
              </a>
              <a
                href="javascript:void(0);"
                class="btn btn-primary btn-connect"
                data-bs-toggle="modal"
                data-bs-target="#change-document"
              >
                Change
              </a>
              <a href="javascript:void(0);" class="btn-acc">
                Remove
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade custom-modal verify-modal" id="change-document">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content doctor-profile">
            <div class="modal-header verfication-modal-head">
              <h5 class="modal-title">Verify Your Identity</h5>
              <p>Upload document</p>
            </div>
            <div class="modal-body">
              <form action="https://html.truelysell.com/template3/provider-security-settings.html">
                <div class="wallet-add">
                  <div class="form-group">
                    <label>Document Name</label>
                    <input class="form-control" name="phone" type="text" />
                  </div>
                  <div class="file-uploaded-mail">
                    <img
                      src="/assets/img/icons/upload-icon-02.svg"
                      class="img-fluid"
                      alt="Upload"
                    />
                    <h4>
                      Drag & drop files or <span> Browse </span>
                    </h4>
                    <p>Supported formats: PDF</p>
                    <input type="file" id="file-input" class="image-upload" />
                  </div>
                  <div class="document-upload-file">
                    <p>
                      {" "}
                      <img
                        src="/assets/img/icons/file-pdf.svg"
                        class="img-fluid"
                        alt="Pdf"
                      />{" "}
                      licence.pdf{" "}
                    </p>
                    <span>
                      <a href="#">
                        <img
                          src="/assets/img/icons/trash-alt.svg"
                          class="img-fluid"
                          alt="Trash"
                        />
                      </a>
                    </span>
                  </div>
                  <div class="document-update-success">
                    <p>
                      <i class="fa-regular fa-circle-check"></i> Document
                      uploaded successfully{" "}
                    </p>
                  </div>
                  <div class="modal-submit">
                    <a
                      href="#"
                      class="btn btn-primary w-100"
                      data-bs-toggle="modal"
                      data-bs-target="#success-document"
                    >
                      Continue
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Success mail --> */}
      <div class="modal fade success-modal verify-modal" id="success-document">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body success-body text-center">
              <span>
                <i class="fa-regular fa-circle-check"></i>
              </span>
              <h3>Success</h3>
              <p class="success-modal-body">
                Document is sent for approval for the admin once approved you
                will notified.
              </p>
            </div>
            <div class="popup-btn text-center">
              <a href="/provider" class="btn btn-primary verify-document">
                Go to Dashboard
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Success  --> */}
    </>
  );
};

export default DocumentVerification;
