import React, { useEffect, useState } from "react";
import CustomerSideBar from "../../components/dashboard/sidebars/customer-sidebar";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const CustomerDashboardWrapper = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  // Close sidebar when the route changes
  useEffect(() => {
    setIsSidebarVisible(false);
  }, [location.pathname]);
  return (
    <>
      <div className="content">
        <div className="container">
          <div className="row">
          {/* <div className={`sidebar-container ${isSidebarVisible ? "visible" : ""}`}> */}
            <CustomerSideBar extraClass={`sidebar-container ${isSidebarVisible ? "visible" : ""}`} />
          {/* </div> */}
            {/* <CustomerSideBar /> */}
            <div className="col-lg-9">
              <div className="col-md-12 text-md-end">
                <button
                  style={{ marginRight: "20px" }}
                  onClick={toggleSidebar}
                  className="btn btn-primary btn-wallet mr-5 d-lg-none"
                >
                  <i class="fa-solid fa-arrow-left"></i>
                </button>
              </div>
              <Outlet />
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        /* Hide sidebar on smaller screens */
        .sidebar-container {
          display: none;
        }
        .sidebar-container.visible {
          display: block;
        }
        @media (min-width: 992px) {
          /* Show sidebar on large screens by default */
          .sidebar-container {
            display: block;
          }
        }
      `}</style>
    </>
  );
};

export default CustomerDashboardWrapper;
