/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { ConfirmUserPayment } from "../../../manage_states/gen-states/payment/paymentServiceSync";

const BookingDone = () => {
  const location = useLocation();
  const currentUrl = `${window.location.origin}${location.pathname}${location.search}`;
  const [basePath, queryString] = currentUrl.split("?");
  const beforeQuestionMark = basePath.split("/").pop();

  // Parse the query string to get "status"
  const params = new URLSearchParams(location.search);
  const status = params.get("status");

  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    confirmingPayment,
    confirmPaymentSuccessful,
    confirmPaymentError,
    confirmPaymentMessage,
  } = useSelector((state) => state.payment);

  console.log(id, "id");
console.log("Value before '?':", beforeQuestionMark);
  useEffect(() => {
    if(id){
      dispatch(ConfirmUserPayment(beforeQuestionMark))
    }
  }, [id])
  return (
    <div className="content book-content">
      <div className="container">
        {confirmingPayment ? (<div><h1>loading...</h1></div>) : <div className="row">
          {/* Booking */}
          <div className="col-lg-10 mx-auto">
            {/* Booking Step */}
            <ul className="step-register row">
              {/* <li className="activate col-md-4">
                <div className="multi-step-icon">
                  <span>
                    <img src="/assets/img/icons/wallet-icon.svg" alt="img" />
                  </span>
                </div>
                <div className="multi-step-info">
                  <h6>Payment</h6>
                  <p>Select Payment Gateway</p>
                </div>
              </li> */}
              <li className="active col-md-12">
                <div className="multi-step-icon">
                  <span>
                    <img src="/assets/img/icons/book-done.svg" alt="img" />
                  </span>
                </div>
                <div className="multi-step-info">
                  <h6>Payment Completed</h6>
                  {/* <p></p> */}
                </div>
              </li>
            </ul>
            {/* /Booking Step */}
            {/* Booking Done */}
            <div className="row align-items-center">
              <div className="col-md-7">
                <div className="BookingDone">
                  <h6>Successfully Completed Payment</h6>
                  <p>Your Booking Payment has been Successfully Competed</p>
                  <div className="book-submit">
                    {/* <a href="/" className="btn btn-primary">
                      <i className="feather-arrow-left-circle" /> Go to Home
                    </a> */}
                    {/* <a href="#" className="btn btn-secondary">
                      <i className="fa-solid fa-calendar-days me-2" />
                      Add to Calender
                    </a> */}
                    <a href="/customer/booking" className="btn btn-secondary">
                      Booking History
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="BookingDone">
                  <img
                    src="/assets/img/booking-done.png"
                    className="img-fluid"
                    alt="imag"
                  />
                </div>
              </div>
            </div>
            {/* /Booking Done */}
          </div>
          {/* /Booking */}
        </div>}
        
      </div>
    </div>
  );
};

export default BookingDone;
