import React, { useEffect } from 'react';
import '../styles/common-styles.css';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GetSingleUser } from '../../../manage_states/gen-states/auth/authServiceSync';
const ProviderSidebar = () => {
  const [expanded, setExpanded] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetSingleUser());
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('airdrops_token');
    localStorage.removeItem('airdrops_user_type');
    navigate('/login');
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { user } = useSelector((state) => state.auth);

  console.log(user?.name, 'season of work in work boy');

  console.log(user, "vendor user")

  return (
    <div class="sidebar" id="sidebar">
      <div class="sidebar-inner slimscroll">
        <div id="sidebar-menu" class="sidebar-menu">
          <ul>
            <li class="active">
              <NavLink exact
                activeClassName="active" to="/provider">
                <i class="feather-grid"></i> <span>Dashboard</span>
              </NavLink>
            </li>
            <li>
              <NavLink exact
                activeClassName="active" to="/provider/services">
                <i class="feather-briefcase"></i> <span>Available Bids</span>
              </NavLink>
            </li>
            <li>
              <NavLink exact
                activeClassName="active" to="/provider/booking">
                <i class="feather-calendar"></i> <span>Biddings </span>
              </NavLink>
            </li>
            <li>
            <NavLink
                exact
                activeClassName="active"
                to="/provider/vehicle-inspection"
              >
                <i class="fa-solid fa-car"></i>
                <span>Vehicle Inspection</span>
              </NavLink>
            </li>
            <li>
              <NavLink exact
                activeClassName="active" to="/provider/payout">
                <i class="feather-credit-card"></i> <span>Payout</span>
              </NavLink>
            </li>

            {/* <li>
              <NavLink exact
                activeClassName="active" to="/provider/earnings">
                <i class="feather-dollar-sign"></i> <span>Earnings</span>
              </NavLink>
            </li> */}
            <li>
              <NavLink exact
                activeClassName="active" to="/provider/chat">
                <i class="feather-message-circle"></i> <span>Chat</span>
              </NavLink>
            </li>
            <li>
              <NavLink exact
                activeClassName="active" to="/provider/profile-settings">
                {' '}
                <i class="feather-settings"></i> <span>Account Settings</span>{' '}
              </NavLink>
            </li>
            <li>
              <NavLink exact
                activeClassName="active" to="/provider/verification">
                {' '}
                <i class="fa-solid fa-list-check"></i>
                <span>Profile Verification</span>{' '}
              </NavLink>
            </li>

            <li>
              <NavLink onClick={handleLogout} exact
                activeClassName="active" to="#">
                <i class="feather-log-out"></i> <span>Logout</span>
              </NavLink>
            </li>
          </ul>
          <div class="menu-bottom">
            <div class="menu-user">
              <div class="menu-user-img avatar-online">
                <img
                  src={
                    user?.profile_pic
                      ? user?.profile_pic
                      : `/assets/img/avatar-profile-photo.png`
                  }
                  alt="user"
                />
              </div>
              <div class="menu-user-info">
                <h6>{user?.name}</h6>
                <p>
                  <NavLink
                    exact
                    activeClassName="active"
                    to="#"
                    class="__cf_email__"
                    // data-cfemail="7e141116100d13170a163e1b061f130e121b501d1113"
                  >
                    [email&#160;protected]
                  </NavLink>
                </p>
              </div>
            </div>
            <NavLink exact activeClassName="active" to="#" class="select-set">
              <i class="feather-settings"></i>
            </NavLink>
            <div class="dropdown-menu user-drop" id="dropboxes">
              <div class="menu-user">
                <div class="menu-user-img avatar-online">
                  <img src="assets/img/profiles/avatar-02.jpg" alt="user" />
                </div>
                <div class="menu-user-info">
                  <h6>John Smith</h6>
                  <p>Active</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderSidebar;
