import React from "react";
import UserSignup from "../components/register/user-sign-up";
import LoginRegisterNav from "../common/nav/login-register-nav";

const UserSignupPage = () => {
  return (
    <div className="main-wrapper">
      <LoginRegisterNav />
      <UserSignup />
    </div>
  );
};

export default UserSignupPage;
