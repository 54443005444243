import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  GetUserRefundRequests,
  UserRequestRefund,
} from "../../../manage_states/gen-states/payment/paymentServiceSync";
import { formatAmount, formatDate } from "../../../utils/formatters";

const CustomerWallet = () => {
  const [refundDetails, setRefundDetails] = useState({
    reason: "",
    country: "",
    currency: "",
    bankName: "",
    bankAccountNumber: "",
    amount: 0,
  });
  const [activeTab, setActiveTab] = useState("transactions");
  const [currentStatus, setCurrentStatus] = useState("all");
  const dispatch = useDispatch();
  const {
    requestingRefund,
    refundSuccessful,
    refundError,
    refundMessage,
    gettingRefunds,
    refunds,
    getRefundsError,
    getRefundsSuccessful,
    refundsPage,
    refundsLimit,
  } = useSelector((state) => state.payment);

  const handleRefundChange = (e) => {
    const { name, value } = e.target;

    if (name === "bankAccountNumber") {
      // Only allow numbers and limit to 10 digits
      const numericValue = value.replace(/\D/g, "").slice(0, 10);
      setRefundDetails((prevDetails) => ({
        ...prevDetails,
        bankAccountNumber: numericValue,
      }));
    } else if (name === "amount") {
      // Only allow numbers for the amount
      const numericValue = value.replace(/\D/g, "");
      setRefundDetails((prevDetails) => ({
        ...prevDetails,
        amount: numericValue === "" ? 0 : Number(numericValue),
      }));
    } else {
      setRefundDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }
  };

  const validateForm = () => {
    const { reason, country, currency, bankName, bankAccountNumber, amount } =
      refundDetails;

    // Ensure all fields are filled
    if (!reason || !country || !currency || !bankName || !bankAccountNumber) {
      toast("All fields must be filled.");
      return false;
    }

    // Validate bankAccountNumber as a 10-digit number
    const isAccountNumberValid = /^[0-9]{10}$/.test(bankAccountNumber);
    if (!isAccountNumberValid) {
      toast("Bank account number must be a 10-digit number.");
      return false;
    }

    // Validate amount as a valid number greater than zero
    const isAmountValid =
      typeof amount === "number" && !isNaN(amount) && amount > 0;
    if (!isAmountValid) {
      toast("Amount must be a valid number greater than zero.");
      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("Form is valid:", refundDetails);
    if (validateForm()) {
      // Proceed with form submission or other actions
      console.log("Form is valid:", refundDetails);
      dispatch(UserRequestRefund(refundDetails));
    }
  };

  console.log(refunds, "Refunds gotten");

  useEffect(() => {
    const payload = {
      status: currentStatus,
      page: refundsPage,
      limit: refundsLimit,
    };
    dispatch(GetUserRefundRequests(payload));
  }, []);

  return (
    <>
      {/* <div className="col-lg-9"> */}
      <div className="row">
        <div className="col-md-6">
          <div className="widget-title">
            <h4>Wallet</h4>
          </div>
        </div>
        <div className="col-md-6 text-md-end">
          <a
            style={{ marginRight: "20px" }}
            href="#"
            className="btn btn-primary btn-wallet mr-5 "
            data-bs-toggle="modal"
            data-bs-target="#refund-wallet"
          >
            <i className="feather-plus" /> Request Refund
          </a>
          <a
            href="#"
            className="btn btn-primary btn-wallet"
            data-bs-toggle="modal"
            data-bs-target="#add-wallet"
          >
            <i className="feather-plus" /> Add Wallet
          </a>
        </div>
      </div>
      {/* Dashboard Widget */}
      <div className="row row-cols-lg-3 row-cols-xl-5 justify-content-center">
        <div className="col-md-6 d-flex">
          <div className="dash-widget dash-wallet flex-fill">
            <div className="dash-img">
              <span className="dash-icon">
                <img src="/assets/img/icons/cus-wallet.svg" alt="image" />
              </span>
            </div>
            <div className="dash-info">
              <div className="dash-order">
                <h6>Balance</h6>
                <h5>$351.4</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 d-flex">
          <div className="dash-widget dash-wallet flex-fill">
            <div className="dash-img">
              <span className="dash-icon">
                <img src="/assets/img/icons/cus-withdraw.svg" alt="image" />
              </span>
            </div>
            <div className="dash-info">
              <div className="dash-order">
                <h6>Total Credit</h6>
                <h5>$590.4</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 d-flex">
          <div className="dash-widget dash-wallet flex-fill">
            <div className="dash-img">
              <span className="dash-icon">
                <img src="/assets/img/icons/cus-credit-card.svg" alt="image" />
              </span>
            </div>
            <div className="dash-info">
              <div className="dash-order">
                <h6>Total Debit</h6>
                <h5>$228.04</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 d-flex">
          <div className="dash-widget dash-wallet flex-fill">
            <div className="dash-img">
              <span className="dash-icon">
                <img src="/assets/img/icons/cus-money.svg" alt="image" />
              </span>
            </div>
            <div className="dash-info">
              <div className="dash-order">
                <h6>Savings</h6>
                <h5>$200.00</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 d-flex">
          <div className="dash-widget dash-wallet flex-fill">
            <div className="dash-img">
              <span className="dash-icon bg-light-danger">
                <img src="/assets/img/icons/cus-file.svg" alt="image" />
              </span>
            </div>
            <div className="dash-info">
              <div className="dash-order">
                <h6>Taxes</h6>
                <h5>$20.04</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Dashboard Widget */}
      {/* Wallet Transactions */}
      <div className="row">
        <div className="col-md-12">
          <button
            style={{ marginRight: "20px" }}
            onClick={() => setActiveTab("refunds")}
            className={
              activeTab === "refunds"
                ? "btn btn-primary btn-wallet mr-5 "
                : "btn btn-secondary btn-wallet"
            }
          >
            Refund Requests
          </button>
          <button
            onClick={() => setActiveTab("transactions")}
            className={
              activeTab === "transactions"
                ? "btn btn-primary btn-wallet mr-5 "
                : "btn btn-secondary btn-wallet"
            }
          >
            Wallet Transactions
          </button>
        </div>
      </div>
      {activeTab === "transactions" && (
        <>
          <h6 className="user-title">Wallet Transactions</h6>
          <div className="table-responsive">
            <table className="table mb-0 custom-table">
              <thead className="thead-light">
                <tr>
                  <th>#</th>
                  <th>Account Number</th>
                  <th>Bank Name</th>
                  <th>Amount</th>
                  <th>Date</th>
                  <th>Currency</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {refunds?.docs?.map((item, key) => (
                  <tr>
                    <td>{key + 1}</td>
                    <td>{item?.bankAccountNumber}</td>
                    <td>{item?.bankName}</td>
                    <td className="text-light-success">
                      {formatAmount(item?.amount)}
                    </td>
                    <td className="text-body">{formatDate(item?.date)}</td>
                    <td className="text-body">{item?.currency}</td>
                    <td>
                      <span
                        className={
                          item?.status === "pending"
                            ? "badge-warning"
                            : item?.status === "cancelled"
                            ? "badge-danger"
                            : "badge-success"
                        }
                      >
                        {item?.status}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}

      {activeTab === "refunds" && (
        <>
          <h6 className="user-title">Refunds Requests</h6>
          <div className="table-responsive">
            <table className="table mb-0 custom-table">
              <thead className="thead-light">
                <tr>
                  <th>#</th>
                  <th>Account Number</th>
                  <th>Bank Name</th>
                  <th>Amount</th>
                  <th>Date</th>
                  <th>Currency</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {refunds?.docs?.map((item, key) => (
                  <tr>
                    <td>{key + 1}</td>
                    <td>{item?.bankAccountNumber}</td>
                    <td>{item?.bankName}</td>
                    <td className="text-light-success">
                      {formatAmount(item?.amount)}
                    </td>
                    <td className="text-body">{formatDate(item?.date)}</td>
                    <td className="text-body">{item?.currency}</td>
                    <td>
                      <span
                        className={
                          item?.status === "pending"
                            ? "badge-warning"
                            : item?.status === "cancelled"
                            ? "badge-danger"
                            : "badge-success"
                        }
                      >
                        {item?.status}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}

      {/* /Wallet Transactions */}
      {/* </div> */}
      {/* Modal Add Wallet */}
      <div className="modal fade custom-modal" id="add-wallet">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 justify-content-between">
              <h5 className="modal-title">Add Wallet</h5>
              <button
                type="button"
                className="close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="feather-x" />
              </button>
            </div>
            <div className="modal-body pt-0">
              <form action="#">
                <div className="wallet-add">
                  <div className="form-group">
                    <label className="col-form-label pt-0">Amount</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Amount"
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="bank-selection">
                        <input
                          type="radio"
                          defaultValue="attach_link"
                          id="rolelink"
                          name="attachment"
                          defaultChecked
                        />
                        <label htmlFor="rolelink">
                          <img src="/assets/img/paypal.png" alt="Paypal" />
                          <span className="role-check">
                            <i className="fa-solid fa-circle-check" />
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="bank-selection">
                        <input
                          type="radio"
                          defaultValue="attach_link"
                          id="rolelink1"
                          name="attachment"
                        />
                        <label htmlFor="rolelink1">
                          <img src="/assets/img/stripe.png" alt="Stripe" />
                          <span className="role-check">
                            <i className="fa-solid fa-circle-check" />
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="bank-selection">
                        <input
                          type="radio"
                          defaultValue="attach_link"
                          id="rolelink2"
                          name="attachment"
                        />
                        <label htmlFor="rolelink2">
                          <img src="/assets/img/bank.png" alt="image" />
                          <span className="role-check">
                            <i className="fa-solid fa-circle-check" />
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="modal-submit text-end">
                    <a
                      href="#"
                      className="btn btn-secondary me-2"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </a>
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* refund */}
      <div className="modal fade custom-modal" id="refund-wallet">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 justify-content-between">
              <h5 className="modal-title">Request Refund</h5>
              <button
                type="button"
                className="close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="feather-x" />
              </button>
            </div>
            <div className="modal-body pt-0">
              <>
                <div className="wallet-add">
                  <div className="form-group">
                    <label className="col-form-label pt-0">Amount</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Amount"
                      name="amount"
                      value={refundDetails?.amount}
                      onChange={handleRefundChange}
                    />
                  </div>
                  <div className="form-group">
                    <label className="col-form-label pt-0">Country</label>
                    <select
                      className="form-control"
                      name="country"
                      value={refundDetails?.country}
                      onChange={handleRefundChange}
                      id=""
                    >
                      <option value="NGN">Nigeria</option>
                      <option value="USD">United State</option>
                      <option value="USD">Canada</option>
                      <option value="EUR">Euros</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label pt-0">Currency</label>
                    <select
                      className="form-control"
                      name="currency"
                      value={refundDetails?.currency}
                      onChange={handleRefundChange}
                    >
                      <option value="NGN">Naira</option>
                      <option value="USD">Dollars</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label pt-0">
                      Account Number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter account number"
                      name="bankAccountNumber"
                      value={refundDetails?.bankAccountNumber}
                      onChange={handleRefundChange}
                      maxLength={10}
                    />
                  </div>
                  <div className="form-group">
                    <label className="col-form-label pt-0">Bank Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your bank name"
                      name="bankName"
                      value={refundDetails?.bankName}
                      onChange={handleRefundChange}
                    />
                  </div>

                  <div className="form-group">
                    <label className="col-form-label pt-0">Description</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter a description"
                      name="reason"
                      value={refundDetails?.reason}
                      onChange={handleRefundChange}
                    />
                  </div>

                  <div className="modal-submit text-end">
                    <a
                      href="#"
                      className="btn btn-secondary me-2"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </a>
                    <button
                      onClick={handleSubmit}
                      type="submit"
                      className="btn btn-primary"
                    >
                      {requestingRefund ? "sending request" : "Submit"}
                    </button>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerWallet;
