import React from "react";
import LoginRegisterNav from "../common/nav/login-register-nav";
import ForgotEmail from "../components/otp/forgot-email";

const ForgotPassEmailOtp = () => {
  return (
    <div className="main-wrapper">
      <LoginRegisterNav />
      <ForgotEmail />
    </div>
  );
};

export default ForgotPassEmailOtp;
