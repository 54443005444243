/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { NavLink } from 'react-router-dom'

const LoginRegisterNav = () => {
  return (
    <header className='header'>
      <div className='container'>
        <nav className='navbar navbar-expand-lg header-nav'>
          <div className='navbar-header'>
            <NavLink to='/' className='navbar-brand logo'>
              <img
                src='assets/img/cropped-Untitled-1-1 3.png'
                className='img-fluid'
                alt='Logo'
              />
            </NavLink>
          </div>
          <ul className='nav header-navbar-rht'>
            <li className='nav-item'>
              <a className='link' href='faq.html'>
                FAQ
              </a>
            </li>
            <li className='nav-item'>
              <a className='link' href='/login'>
                Login
              </a>
            </li>
            <li className='nav-item dropdown flag-nav'>
              <a
                className='nav-link dropdown-toggle'
                data-bs-toggle='dropdown'
                href='#'
                role='button'
                aria-expanded='false'
              >
                <img src='assets/img/flag.png' alt='Flag' /> En
              </a>
              <div className='dropdown-menu dropdown-menu-right'>
                <a href='#' className='dropdown-item'>
                  <img src='assets/img/flags/us.png' alt='Flag' height={16} />
                  En
                </a>
                <a href='#' className='dropdown-item'>
                  <img src='assets/img/flags/fr.png' alt='Flag' height={16} />
                  French
                </a>
                <a href='#' className='dropdown-item'>
                  <img src='assets/img/flags/de.png' alt='Flag' height={16} />
                  German
                </a>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
}

export default LoginRegisterNav
