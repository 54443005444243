import React from 'react';
import { getUserProfile } from '../../../api_calls/profile/service';
import { useQuery } from '@tanstack/react-query';
import { NavLink, useNavigate } from 'react-router-dom';

const ProviderDashboardHeader = () => {
  const result = useQuery({ queryKey: ['profile'], queryFn: getUserProfile });
  const navigate = useNavigate();
  console.log(result, result.data, 'result returned, user profile');
  const handleLogout = () => {
    localStorage.removeItem('airdrops_token');
    localStorage.removeItem('airdrops_user_type');
    localStorage.removeItem('airdrop_user_email');
    navigate('/login');
  };
  // if (result.isLoading) return <div>Fetching profile...</div>;
  // if (result.error) return <div>An error occurred: {result.error.message}</div>;
  return (
    <div className="header">
      <div className="header-left">
        <div className="sidebar-logo">
          <a href="/">
            <img
              src="/assets/img/cropped-Untitled-1-1 3.png"
              className="img-fluid logo"
              alt="Logo"
            />
          </a>
          <a href="/">
            <img
              src="/assets/img/logo-small.png"
              className="img-fluid logo-small"
              alt="Logo"
            />
          </a>
        </div>
        <div className="siderbar-toggle">
          <label class="switch" id="toggle_btn">
            <input type="checkbox" />
            <span className="slider round"></span>
          </label>
        </div>
      </div>
      <a class="mobile_btns" id="mobile_btns" href="javascript:void(0);">
        <i className="fas fa-align-left"></i>
      </a>
      <div className="header-split">
        <div className="page-headers">
          <div className="search-bar">
            <span>
              <i className="feather-search"></i>
            </span>
            <input type="text" placeholder="Search" className="form-control" />
          </div>
        </div>
        <ul className="nav user-menu noti-pop-detail">
          {/* <!-- Notifications --> */}
          <li className="nav-item">
            <a href="#" className="viewsite">
              <i className="feather-globe me-2"></i>View Site
            </a>
          </li>
          <li className="nav-item dropdown has-arrow dropdown-heads flag-nav">
            <a
              className="nav-link"
              data-bs-toggle="dropdown"
              href="#"
              role="button"
            >
              <img src="/assets/img/flags/us1.png" alt="Flag" height="20" />
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <a href="javascript:void(0);" className="dropdown-item">
                <img
                  src="/assets/img/flags/us.png"
                  className="me-2"
                  alt="Flag"
                  height="16"
                />{' '}
                English
              </a>
              <a href="javascript:void(0);" className="dropdown-item">
                <img
                  src="/assets/img/flags/fr.png"
                  className="me-2"
                  alt="Flag"
                  height="16"
                />{' '}
                French
              </a>
              <a href="javascript:void(0);" className="dropdown-item">
                <img
                  src="/assets/img/flags/es.png"
                  className="me-2"
                  alt="Flag"
                  height="16"
                />{' '}
                Spanish
              </a>
              <a href="javascript:void(0);" className="dropdown-item">
                <img
                  src="/assets/img/flags/de.png"
                  className="me-2"
                  alt="Flag"
                  height="16"
                />{' '}
                German
              </a>
            </div>
          </li>
          {/* <!-- Notifications --> */}
          <li className="nav-item  has-arrow dropdown-heads dropdown logged-item noti-nav noti-wrapper">
            <a
              href="#"
              className="dropdown-toggled nav-link notify-link"
              data-bs-toggle="dropdown"
            >
              <span className="noti-message">1</span>
              <img src="/assets/img/icons/bell-icon.svg" alt="Bell" />
            </a>
            <div className="dropdown-menu notify-blk notifications">
              <div className="topnav-dropdown-header">
                <div>
                  <p className="notification-title">
                    Notifications <span> 3 </span>
                  </p>
                </div>
                <a href="javascript:void(0)" className="clear-noti">
                  <i className="fa-regular fa-circle-check"></i> Mark all as
                  read{' '}
                </a>
              </div>
              <div className="noti-content">
                <ul className="notification-list">
                  <li className="notification-message">
                    <a href="notification.html">
                      <div className="media noti-img d-flex">
                        <span className="avatar avatar-sm flex-shrink-0">
                          <img
                            className="avatar-img rounded-circle img-fluid"
                            alt="User Image"
                            src="/assets/img/notifications/avatar-01.jpg"
                          />
                        </span>
                        <div className="media-body flex-grow-1">
                          <p className="noti-details">
                            Lex Murphy left 6 comments on Isla Nublar SOC2
                            compliance report
                          </p>
                          <p className="noti-time">
                            <span className="notification-time">1m ago</span>
                          </p>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="notification-message">
                    <div className="media noti-img d-flex">
                      <a href="notification.html">
                        <span className="avatar avatar-sm flex-shrink-0">
                          <img
                            className="avatar-img rounded-circle img-fluid"
                            alt="User Image"
                            src="/assets/img/notifications/avatar-02.jpg"
                          />
                        </span>
                      </a>
                      <div className="media-body flex-grow-1">
                        <a href="notification.html">
                          <p className="noti-details">
                            Ray Arnold requested access to UNIX directory tree
                            hierarchy
                          </p>
                        </a>
                        <p className="noti-time">
                          <span className="notification-time">1 min</span>
                        </p>
                        <div className="notify-btns">
                          <button className="btn btn-secondary">Decline</button>
                          <button className="btn btn-primary">Accept</button>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="notification-message">
                    <a href="notification.html">
                      <div className="media noti-img d-flex">
                        <span className="avatar avatar-sm flex-shrink-0">
                          <img
                            className="avatar-img rounded-circle img-fluid"
                            alt="User Image"
                            src="/assets/img/notifications/avatar-03.jpg"
                          />
                        </span>
                        <div className="media-body flex-grow-1">
                          <p className="noti-details">
                            Dennis Nedry commented on Isla Nublar SOC2
                            compliance report
                          </p>
                          <p className="noti-time">
                            <span className="notification-time">1m ago</span>
                          </p>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="notification-message">
                    <a href="notification.html">
                      <div className="media noti-img d-flex">
                        <span className="avatar avatar-sm flex-shrink-0">
                          <img
                            className="avatar-img rounded-circle img-fluid"
                            alt="User Image"
                            src="/assets/img/notifications/avatar-04.jpg"
                          />
                        </span>
                        <div className="media-body flex-grow-1">
                          <p className="noti-details">
                            John Hammond created Isla Nublar SOC2 compliance
                            report{' '}
                          </p>
                          <p className="noti-time">
                            <span className="notification-time">1m ago</span>
                          </p>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="topnav-dropdown-footer">
                <a href="#">
                  View All Notifications{' '}
                  <img src="/assets/img/icons/arrow-right-01.svg" alt="Arrow" />
                </a>
              </div>
            </div>
          </li>
          {/* <!-- /Notifications --> */}
          <li className="nav-item  has-arrow dropdown-heads ">
            <a href="#" className="win-maximize">
              <i className="feather-maximize"></i>
            </a>
          </li>

          {/* <!-- User Menu --> */}
          <li className="nav-item dropdown has-arrow account-item">
            <a
              href="#"
              className="dropdown-toggle nav-link"
              data-bs-toggle="dropdown"
            >
              <div className="user-infos">
                <span className="user-img">
                  <img
                    src={
                      result?.data?.profile_pic
                        ? result?.data?.profile_pic
                        : `/assets/img/avatar-profile-photo.png`
                    }
                    className="rounded-circle"
                    alt="User Image"
                  />
                </span>
                <div className="">
                  <h6 style={{ margin: '0' }}>{result?.data?.name}</h6>
                  <h5
                    style={{
                      color: 'gray',
                      margin: '0',
                      padding: '0',
                      // background: "orange",
                    }}
                  >
                    {result?.data?.user_type}
                  </h5>
                </div>
              </div>
            </a>
            <div className="dropdown-menu dropdown-menu-end emp">
              <NavLink to={'/provider/profile-settings'}>
                <a class="dropdown-item" href="javascript:void(0);">
                  <i className="feather-user me-2"></i> Profile
                </a>
              </NavLink>
              <a
                onClick={handleLogout}
                class="dropdown-item"
                href="javascript:void(0);"
              >
                <i className="feather-log-out me-2"></i> Logout
              </a>
            </div>
          </li>
          {/* <!-- /User Menu --> */}
        </ul>
      </div>
    </div>
  );
};

export default ProviderDashboardHeader;
