import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  GetSingleUser,
  loginUser,
  UserMakePayment,
  VendorRequestPayout,
  UserRequestRefund,
  GetVendorPayoutRequests,
  GetUserRefundRequests,
  ConfirmUserPayment,
} from "./paymentServiceSync";

const initialState = {
  loading: false,
  user: null,
  token: localStorage.getItem("token") ? localStorage.getItem("token") : null,
  error: null,

  makingPayment: false,
  makePaymentSuccessful: false,
  makePaymentError: false,
  makePaymentMessage: "",

  // confirm payment
  confirmingPayment: false,
  confirmPaymentSuccessful: false,
  confirmPaymentError: false,
  confirmPaymentMessage: "",

  // request refund
  requestingRefund: false,
  refundSuccessful: false,
  refundError: false,
  refundMessage: "",

  // get refund
  gettingRefunds: false,
  refunds: [],
  getRefundsError: false,
  getRefundsSuccessful: false,
  refundsPage: 1,
  refundsLimit: 10,

  // request payout
  gettingRequestPayout: false,
  gettingRequestPayoutSuccessful: false,
  gettingRequestPayoutError: false,
  gettingRequestPayoutMessage: "",
  payouts: [],
  requestPayoutPage: 1,
  requestPayoutLimit: 10,
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
      localStorage.removeItem("token");
      toast.info("Logged out successfully");
    },

    updateReFundPage: (state, { payload }) => {
      state.refundsPage = payload;
    },

    updateRefundLimit: (state, { payload }) => {
      state.refundsLimit = payload;
    },

    updateRequestPayoutPage: (state, { payload }) => {
      state.requestPayoutPage = payload;
    },

    updateRequestPayoutLimit: (state, { payload }) => {
      state.requestPayoutLimit = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.user = payload.user;
        state.token = payload.token;
        localStorage.setItem("token", payload.token);
      })
      .addCase(loginUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(UserMakePayment.pending, (state) => {
        state.makingPayment = true;
      })
      .addCase(UserMakePayment.fulfilled, (state, { payload }) => {
        state.makingPayment = false;
        state.makePaymentSuccessful = true;
        state.makePaymentMessage = "transaction successful";
      })
      .addCase(UserMakePayment.rejected, (state, { payload }) => {
        state.makingPayment = false;
        state.makePaymentError = true;
        state.makePaymentMessage = "";
      })
      .addCase(ConfirmUserPayment.pending, (state) => {
        state.confirmingPayment = true;
      })
      .addCase(ConfirmUserPayment.fulfilled, (state, { payload }) => {
        state.confirmingPayment = false;
        state.confirmPaymentSuccessful = true;
        state.confirmPaymentMessage = "transaction successful";
      })
      .addCase(ConfirmUserPayment.rejected, (state, { payload }) => {
        state.confirmingPayment = false;
        state.confirmPaymentError = true;
        state.confirmPaymentMessage = "";
      })
      .addCase(VendorRequestPayout.pending, (state) => {
        state.requestingPayout = true;
      })
      .addCase(VendorRequestPayout.fulfilled, (state, { payload }) => {
        state.requestingPayout = false;
        state.payoutSuccessful = true;
        state.payouts = payload;
        state.payoutMessage = "Payout request successful";
      })
      .addCase(VendorRequestPayout.rejected, (state, { payload }) => {
        state.requestingPayout = false;
        state.payoutError = true;
        state.payoutMessage = "Payout request failed";
      })
      .addCase(UserRequestRefund.pending, (state) => {
        state.requestingRefund = true;
      })
      .addCase(UserRequestRefund.fulfilled, (state, { payload }) => {
        state.requestingRefund = false;
        state.refundSuccessful = true;
        state.refundMessage = "fund request successful";
      })
      .addCase(UserRequestRefund.rejected, (state, { payload }) => {
        state.requestingRefund = false;
        state.refundError = true;
        state.refundMessage = "Error in fund request";
      })
      .addCase(GetUserRefundRequests.pending, (state) => {
        state.gettingRefunds = true;
      })
      .addCase(GetUserRefundRequests.fulfilled, (state, { payload }) => {
        state.gettingRefunds = false;
        state.refunds = payload?.data;
        state.getRefundsSuccessful = true;
      })
      .addCase(GetUserRefundRequests.rejected, (state, { payload }) => {
        state.gettingRefunds = false;
        state.getRefundsError = true;
      })
      .addCase(GetVendorPayoutRequests.pending, (state) => {
        state.gettingRequestPayout = true;
      })
      .addCase(GetVendorPayoutRequests.fulfilled, (state, { payload }) => {
        state.gettingRequestPayout = false;
        state.gettingRequestPayoutSuccessful = true;
        state.payouts = payload?.data;
        state.gettingRequestPayoutMessage = "payouts returned successfully";
      })
      .addCase(GetVendorPayoutRequests.rejected, (state, { payload }) => {
        state.gettingRequestPayout = false;
        state.gettingRequestPayoutError = true;
        state.gettingRequestPayoutMessage = "";
      })
      .addCase(GetSingleUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetSingleUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.user = payload?.data;
      })
      .addCase(GetSingleUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const { logout } = paymentSlice.actions;
export default paymentSlice.reducer;
