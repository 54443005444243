import axios from 'axios';
import { Api_Url } from '../url';

export const getUserProfile = async () => {
  const userToken = localStorage.getItem('airdrops_token');
  try {
    const response = await axios.get(`${Api_Url}/api/v1/get_user`, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });

    return response?.data?.data;
  } catch (err) {
    return 'Error getting user profile';
  }
};
