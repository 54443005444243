import React, { useRef, useState } from "react";
import FaceVerification from "./face-verification";
import Webcam from "react-webcam";
import BusinessVerification from "./business-verification";
import PhoneVerification from "./phone-verificatio";
import DocumentVerification from "./document-verification";
const ProviderVerification = () => {
  return (
    <>
      <div class="row">
        {/* <!-- Security Settings --> */}
        <div class="col-md-12">
          <div class="widget-title">
            <h4>Profile Verification</h4>
          </div>
          <FaceVerification />
          <PhoneVerification />
          <div class="linked-item">
            <div class="row">
              <div class="col-md-8">
                <div class="linked-acc">
                  <span class="link-icon">
                    <img
                      src="/assets/img/icons/mail-envelope.svg"
                      alt="Icons"
                    />
                  </span>
                  <div class="linked-info">
                    <h6>
                      <a href="javascript:void(0);">Email Verification</a>
                    </h6>
                    <p> Verified </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 text-md-end">
                <div class="verfification-modal-block">
                  <a
                    href="javascript:void(0);"
                    class="link-close"
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                    data-bs-title="Verified"
                  >
                    <i class="fa-solid fa-circle-check"></i>
                  </a>
                  <a
                    href="javascript:void(0);"
                    class="btn btn-primary btn-connect"
                    data-bs-toggle="modal"
                    data-bs-target="#change-mail"
                  >
                    Change
                  </a>
                  <a href="javascript:void(0);" class="btn-acc">
                    Remove
                  </a>
                </div>
              </div>
            </div>
          </div>
          <DocumentVerification />
          <BusinessVerification />
        </div>
        {/* <!-- /Security Settings --> */}
      </div>
    </>
  );
};

export default ProviderVerification;
