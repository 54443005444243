/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

const AboutUs = () => {
  return (
    <>
      {/* Breadcrumb */}
      <div className='breadcrumb-bar'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 col-12'>
              <h2 className='breadcrumb-title text-white'>About Us</h2>
              <nav aria-label='breadcrumb' className='page-breadcrumb'>
                <ol className='breadcrumb'>
                  <li className='breadcrumb-item'>
                    <a href='index.html'>Home</a>
                  </li>
                  <li
                    className='breadcrumb-item active text-black'
                    aria-current='page'
                  >
                    About Us
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* /Breadcrumb */}

      <div className='content p-0'>
        {/* About */}
        <div className='about-sec'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-6'>
                <div className='about-img'>
                  <div className='about-exp'>
                    <span>Welcome to the Future &nbsp; &nbsp; </span>
                  </div>
                  <div className='abt-img'>
                    <img
                      src='assets/img/about-1.jpg'
                      className='img-fluid'
                      alt='img'
                    />
                  </div>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='about-content'>
                  <h6>ABOUT OUR COMPANY</h6>
                  <h2>Best Solution For Vehicle Services</h2>
                  <p>
                    We are reinventing the wheel at Airdrops’ PUNCH, we’re just
                    building an automotive service infrastructure; as we
                    contribute to the future of transportation support. We’re
                    building a community for a new era where vehicle care is
                    smarter, faster, and fully integrated with tomorrow’s
                    technology. Imagine a world where your vehicle’s health is
                    monitored in real-time, where AI-driven diagnostics assist
                    experts to predict issues before they happen, and where
                    service is a few taps away, anytime and anywhere, safely
                    accessible to every driver and service provider. Our
                    platform combines frontier innovation, cutting-edge
                    automation, AI, and a network of skilled technicians to
                    bring you a seamless, reliable service experience. As we
                    look to the future, Airdrops’ Punch is developing solutions
                    to support the next generation of Electric Vehicle EV,
                    autonomous eVTOL, and beyond.
                  </p>
                  <p>
                    We’re committed to creating a world where vehicle
                    maintenance isn’t just an afterthought, but a proactive,
                    effortless part of everyday life. Join us on this journey as
                    we redefine automotive care and make the future of
                    transportation accessible to everyone. With Airdrops’ PUNCH,
                    the road ahead is limitless.
                  </p>
                  {/* <div className='row'>
                    <div className='col-md-6'>
                      <ul>
                        <li>At vero et accusamus iusto dignissimos</li>
                        <li>At vero et accusamus iusto dignissimos</li>
                      </ul>
                    </div>
                    <div className='col-md-6'>
                      <ul>
                        <li>Nam libero tempore, cum soluta nobis</li>
                        <li>Nam libero tempore, cum soluta nobis</li>
                      </ul>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /About */}
        {/* Work Section */}
        <section className='work-section work-bg'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12 text-center'>
                <div className='section-heading'>
                  <h2>How It Works</h2>
                  <p>Explore the platforms to find your needs</p>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-4'>
                <div className='work-box'>
                  <div className='work-icon'>
                    <span>
                      <img src='assets/img/icons/work-icon.svg' alt='img' />
                    </span>
                  </div>
                  <h5>Choose What To Do</h5>
                  <p>
                    First, decide what you need. Whether you’re looking for a
                    specific service or offering one, Airdrops’ PUNCH lets you
                    explore a wide range of options.
                  </p>
                  <h4>01</h4>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='work-box'>
                  <div className='work-icon'>
                    <span>
                      <img src='assets/img/icons/find-icon.svg' alt='img' />
                    </span>
                  </div>
                  <h5>Find What You Want</h5>
                  <p>
                    Use the platform to search for exactly what you need. Filter
                    through categories, compare options, and find the perfect
                    match for your requirements.
                  </p>
                  <h4>02</h4>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='work-box'>
                  <div className='work-icon'>
                    <span>
                      <img src='assets/img/icons/place-icon.svg' alt='img' />
                    </span>
                  </div>
                  <h5>Amazing Places</h5>
                  <p>
                    Discover incredible service providers or customers, ready to
                    meet your needs or collaborate with you. Seamless,
                    efficient, and all in one place.
                  </p>
                  <h4>03</h4>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /Work Section */}
        {/* Choose Us Section */}
        <div className='chooseus-sec'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='choose-content'>
                  <h2>Why Choose Airdrops?</h2>
                  <p>We are at Your Service when you need us</p>
                  <div className='support-card'>
                    <h4 className='support-title'>
                      <a
                        className='collapsed'
                        data-bs-toggle='collapse'
                        href='#aboutone'
                        aria-expanded='false'
                      >
                        24/7 Supports
                      </a>
                    </h4>
                    <div id='aboutone' className='card-collapse collapse'>
                      <p>
                        24/7 Support: Airdrops' PUNCH is here whenever you need
                        us. With round-the-clock support, you can rely on our
                        team to assist you anytime, anywhere. Seamless Service
                        Matching: From home maintenance to travel needs,
                        Airdrops' PUNCH connects you to the best services
                        quickly and efficiently, eliminating the hassle of
                        endless searches. Flexibility for Both Customers and
                        Providers: Airdrops' PUNCH works for everyone, offering
                        flexibility for customers looking for specific services
                        and for providers seeking to expand their reach.
                        Comprehensive Platform with Local Connections: Access a
                        diverse range of services in your area, all through one
                        platform that partners with trusted providers globally
                        and locally. Future-Focused and Reliable: Airdrops'
                        PUNCH is built for long-term reliability, integrating
                        innovations in transportation, automation, and customer
                        experience to ensure our services keep evolving to meet
                        your needs.
                      </p>
                    </div>
                  </div>
                  <div className='support-card'>
                    <h4 className='support-title'>
                      <a
                        className='collapsed'
                        data-bs-toggle='collapse'
                        href='#abouttwo'
                        aria-expanded='false'
                      >
                        Client’s reviews
                      </a>
                    </h4>
                    <div id='abouttwo' className='card-collapse collapse'>
                      <p>
                        Many clients rave about our 24/7 support, noting how our
                        team’s dedication has made a real difference in their
                        experience, no matter the time zone. “Effortless Service
                        Matching”: Customers appreciate the streamlined,
                        user-friendly interface that allows them to quickly find
                        and connect with the best service providers, tailored to
                        their needs. “A Platform That Supports Growth”: Service
                        providers love the flexibility Airdrops' PUNCH offers,
                        helping them reach new clients and grow their business
                        through an accessible and dynamic platform. “One-Stop
                        Solution for Everything”: Many clients highlight how
                        Airdrops' PUNCH simplifies their lives, bringing diverse
                        services under one roof and connecting them with local
                        experts they can trust. “Future-Focused, Reliable”: From
                        transportation to maintenance and beyond, clients trust
                        Airdrops' PUNCH as a reliable and innovative platform
                        that adapts to their evolving needs.
                      </p>
                    </div>
                  </div>
                  <div className='support-card'>
                    <h4 className='support-title'>
                      <a
                        className='collapsed'
                        data-bs-toggle='collapse'
                        href='#about3'
                        aria-expanded='false'
                      >
                        Professional Team
                      </a>
                    </h4>
                    <div id='about3' className='card-collapse collapse'>
                      <p>
                        Airdrops' PUNCH is powered by a dedicated team of
                        industry experts and seasoned professionals who are
                        committed to providing top-notch service and support.
                        Experienced and Knowledgeable: Our team brings extensive
                        expertise across various sectors, including technology,
                        transportation, customer service, and logistics,
                        ensuring you receive the highest quality of support and
                        service. Client-Centered Approach: Every member of our
                        team is focused on understanding your needs and
                        exceeding expectations, from initial inquiries to
                        post-service follow-up. Innovative Solutions: Our
                        professional team continuously innovates, bringing
                        forward new ideas and improvements to make Airdrops'
                        PUNCH a leader in service convenience and quality.
                        Reliable and Available: With 24/7 support, our team is
                        always available to assist you, making sure that your
                        experience with Airdrops' PUNCH is seamless and
                        efficient every time. Building Trust: With a reputation
                        for reliability and professionalism, Airdrops' PUNCH
                        team members work to create an environment where clients
                        feel confident and supported.
                      </p>
                    </div>
                  </div>
                  <div className='support-card'>
                    <h4 className='support-title'>
                      <a
                        className='collapsed'
                        data-bs-toggle='collapse'
                        href='#about4'
                        aria-expanded='false'
                      >
                        Best Services
                      </a>
                    </h4>
                    <div id='about4' className='card-collapse collapse'>
                      <p>
                        At Airdrops' PUNCH, excellence isn’t just a goal—it’s a
                        standard. Our platform is committed to delivering the
                        best services across multiple industries, all tailored
                        to meet your needs. Quality Assurance: We handpick
                        trusted service providers and conduct rigorous quality
                        checks to ensure you receive top-tier service every
                        time. Wide Range of Services: From home maintenance and
                        vehicle servicing to travel arrangements, Airdrops'
                        PUNCH offers a comprehensive suite of services, designed
                        to make life easier for both customers and providers.
                        Customizable Options: With options for every budget and
                        requirement, we make it easy for you to find services
                        that fit your unique needs, whether it’s a one-time task
                        or an ongoing service. Reliable and Timely: Our
                        providers are dedicated to punctuality and reliability,
                        ensuring that your experience is as smooth and
                        dependable as possible. Consistent Customer
                        Satisfaction: We prioritize client feedback and
                        continuously improve our services based on what matters
                        most to you, maintaining a high standard of
                        satisfaction.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='chooseus-img'>
                  <img
                    src='assets/img/about-3.jpg'
                    className='img-fluid'
                    alt='img'
                  />
                </div>
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className='col-xl-3 col-lg-4 col-sm-6'>
                <div className='choose-icon'>
                  <img
                    src='assets/img/icons/choose-icon.svg'
                    className='img-fluid'
                    alt='img'
                  />
                  <div className='choose-info'>
                    <h5>2583+</h5>
                    <p>Satisfied Clients</p>
                  </div>
                </div>
              </div>
              <div className='col-xl-3 col-lg-4 col-sm-6'>
                <div className='choose-icon'>
                  <img
                    src='assets/img/icons/choose-icon-01.svg'
                    className='img-fluid'
                    alt='img'
                  />
                  <div className='choose-info'>
                    <h5>2383+</h5>
                    <p>Expert Team</p>
                  </div>
                </div>
              </div>
              <div className='col-xl-3 col-lg-4 col-sm-6'>
                <div className='choose-icon'>
                  <img
                    src='assets/img/icons/choose-icon.png'
                    className='img-fluid'
                    alt='img'
                  />
                  <div className='choose-info'>
                    <h5>2129+</h5>
                    <p>Project Completed</p>
                  </div>
                </div>
              </div>
              <div className='col-xl-3 col-lg-4 col-sm-6'>
                <div className='choose-icon border-0'>
                  <img
                    src='assets/img/icons/choose-icon-03.svg'
                    className='img-fluid'
                    alt='img'
                  />
                  <div className='choose-info'>
                    <h5>30+</h5>
                    <p>Years of experience</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Choose Us Section */}
        {/* Providers Section */}
        <section className='providers-section abt-provider'>
          <div className='container'>
            <div className='section-heading'>
              <div className='row'>
                <div className='col-md-6'>
                  <h2>Top Providers</h2>
                  <p>Meet Our Experts</p>
                </div>
                <div className='col-md-6 text-md-end'>
                  <a href='#' className='btn btn-primary btn-view'>
                    View All
                    <i className='feather-arrow-right-circle' />
                  </a>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-3 col-sm-6'>
                <div className='providerset'>
                  <div className='providerset-img'>
                    <a href='#'>
                      <img src='/assets/img/providers/WhatsApp Image 1403-10-01 at 02.34.08 (2).jpeg' alt='img' />
                    </a>
                  </div>
                  <div className='providerset-content'>
                    <div className='providerset-price'>
                      <div className='providerset-name'>
                        <h4>
                          <a href='#'>John Smith</a>
                          <i
                            className='fa fa-check-circle'
                            aria-hidden='true'
                          />
                        </h4>
                       
                      </div>
                      <div className='providerset-prices'>
                        <h6>
                        <span>Vehicle Electrician</span>
                          {/* $20.00<span>/hr</span> */}
                        </h6>
                      </div>
                    </div>
                    <div className='provider-rating'>
                      <div className='rating'>
                        <i className='fas fa-star filled' />
                        <i className='fas fa-star filled' />
                        <i className='fas fa-star filled' />
                        <i className='fas fa-star filled' />
                        <i className='fa-solid fa-star-half-stroke filled' />
                        <span>(320)</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-sm-6'>
                <div className='providerset'>
                  <div className='providerset-img'>
                    <a href='#'>
                      <img src='/assets/img/providers/WhatsApp Image 1403-10-01 at 02.34.09 (3).jpeg' alt='img' />
                    </a>
                  </div>
                  <div className='providerset-content'>
                    <div className='providerset-price'>
                      <div className='providerset-name'>
                        <h4>
                          <a href='#'>Michael</a>
                          <i
                            className='fa fa-check-circle'
                            aria-hidden='true'
                          />
                        </h4>
                       
                      </div>
                      <div className='providerset-prices'>
                        <h6>
                          {/* $50.00<span>/hr</span> */}
                          <span>Break Pad </span>
                        </h6>
                      </div>
                    </div>
                    <div className='provider-rating'>
                      <div className='rating'>
                        <i className='fas fa-star filled' />
                        <i className='fas fa-star filled' />
                        <i className='fas fa-star filled' />
                        <i className='fas fa-star filled' />
                        <i className='fa-solid fa-star-half-stroke filled' />
                        <span>(228)</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-sm-6'>
                <div className='providerset'>
                  <div className='providerset-img'>
                    <a href='#'>
                      {/* public/assets/img/providers/WhatsApp Image 1403-10-01 at 02.34.08 (1).jpeg */}
                      <img src='/assets/img/providers/WhatsApp Image 1403-10-01 at 02.34.08 (1).jpeg' alt='img' />
                    </a>
                  </div>
                  <div className='providerset-content'>
                    <div className='providerset-price'>
                      <div className='providerset-name'>
                        <h4>
                          <a href='#'>Antoinette</a>
                          <i
                            className='fa fa-check-circle'
                            aria-hidden='true'
                          />
                        </h4>
                        
                      </div>
                      <div className='providerset-prices'>
                        <h6>
                          {/* $25.00<span>/hr</span> */}
                          <span>Engine Oil</span>
                        </h6>
                      </div>
                    </div>
                    <div className='provider-rating'>
                      <div className='rating'>
                        <i className='fas fa-star filled' />
                        <i className='fas fa-star filled' />
                        <i className='fas fa-star filled' />
                        <i className='fas fa-star filled' />
                        <i className='fa-solid fa-star-half-stroke filled' />
                        <span>(130)</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-sm-6'>
                <div className='providerset'>
                  <div className='providerset-img'>
                    <a href='#'>
                      <img src='/assets/img/providers/WhatsApp Image 1403-10-01 at 02.34.09 (4).jpeg' alt='img' />
                    </a>
                  </div>
                  <div className='providerset-content'>
                    <div className='providerset-price'>
                      <div className='providerset-name'>
                        <h4>
                          <a href='#'>Thompson</a>
                          <i
                            className='fa fa-check-circle'
                            aria-hidden='true'
                          />
                        </h4>
                        
                      </div>
                      <div className='providerset-prices'>
                        <h6>
                        <span>Maintainance</span>
                          {/* $25.00<span>/hr</span> */}
                        </h6>
                      </div>
                    </div>
                    <div className='provider-rating'>
                      <div className='rating'>
                        <i className='fas fa-star filled' />
                        <i className='fas fa-star filled' />
                        <i className='fas fa-star filled' />
                        <i className='fas fa-star filled' />
                        <i className='fa-solid fa-star-half-stroke filled' />
                        <span>(95)</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /Providers Section */}
        {/* Client Section */}
        <section className='client-section'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12 text-center'>
                <div className='section-heading'>
                  <h2>What our client says</h2>
                  <p>It has been a good experience on this plaform</p>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <div className='owl-carousel testimonial-slider'>
                  <div className='client-widget'>
                    <div className='client-img'>
                      <a href='#'>
                        <img
                          className='img-fluid'
                          alt='Imag'
                          src='assets/img/profiles/avatar-01.jpg'
                        />
                      </a>
                    </div>
                    <div className='client-content'>
                      <div className='rating'>
                        <i className='fas fa-star filled' />
                        <i className='fas fa-star filled' />
                        <i className='fas fa-star filled' />
                        <i className='fas fa-star filled' />
                        <i className='fas fa-star filled' />
                      </div>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi{' '}
                      </p>
                      <h5>Mike Hussy</h5>
                      <h6>Director</h6>
                    </div>
                  </div>
                  <div className='client-widget'>
                    <div className='client-img'>
                      <a href='#'>
                        <img
                          className='img-fluid'
                          alt='Imag'
                          src='assets/img/profiles/avatar-02.jpg'
                        />
                      </a>
                    </div>
                    <div className='client-content'>
                      <div className='rating'>
                        <i className='fas fa-star filled' />
                        <i className='fas fa-star filled' />
                        <i className='fas fa-star filled' />
                        <i className='fas fa-star filled' />
                        <i className='fas fa-star filled' />
                      </div>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi{' '}
                      </p>
                      <h5>Mike Hussy</h5>
                      <h6>Director</h6>
                    </div>
                  </div>
                  <div className='client-widget'>
                    <div className='client-img'>
                      <a href='#'>
                        <img
                          className='img-fluid'
                          alt='Imag'
                          src='assets/img/profiles/avatar-03.jpg'
                        />
                      </a>
                    </div>
                    <div className='client-content'>
                      <div className='rating'>
                        <i className='fas fa-star filled' />
                        <i className='fas fa-star filled' />
                        <i className='fas fa-star filled' />
                        <i className='fas fa-star filled' />
                        <i className='fas fa-star filled' />
                      </div>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi{' '}
                      </p>
                      <h5>Mike Hussy</h5>
                      <h6>Director</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /Client Section */}

        {/* Service Section */}
        <div className='service-offer'>
          <div className='container'>
            <div className='col-md-12'>
              <div className='offer-paths about-offer'>
                <div className='offer-path-content'>
                  <h3>Looking for the Best Auto Assurance & Services</h3>
                  <p>
                    The platform designed to connect people with top-quality
                    services across a range of automotive needs, from home
                    maintenance to travel arrangements, all through a
                    streamlined and easy-to-use experience. Powered by a team of
                    industry professionals, to ensures reliable service, high
                    standards of quality, and seamless connections with trusted
                    providers locally and globally. With a focus on innovation,
                    customization, restoration and a commitment to customer
                    satisfaction, Airdrops' PUNCH simplifies access to essential
                    services, making life easier and more efficient for all
                    users.
                  </p>
                  <a
                    href='service-grid.html'
                    className='btn btn-primary btn-views'
                  >
                    Get Started
                    <i className='feather-arrow-right-circle' />
                  </a>
                </div>
                <div className='offer-pathimg'>
                  <img src='assets/img/service-img.png' alt='img' />
                  {/* <img src='assets/img/provider/provider-14.jpg' alt='img' /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Service Section */}
      </div>
    </>
  )
}

export default AboutUs
