import React from "react";
import LoginRegisterNav from "../common/nav/login-register-nav";
import EmailOtp from "../components/otp/email";

const EmailOtpPage = () => {
  return (
    <div className="main-wrapper">
      <LoginRegisterNav />
      <EmailOtp />
    </div>
  );
};

export default EmailOtpPage;
