import React, { useState, useEffect } from "react";

const Timer = ({ targetDate, spanClass }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  return (
    <>
      <span className={spanClass}>{timeLeft.days || "0"} d: </span>
      <span className={spanClass}>{timeLeft.hours || "0"} h: </span>
      <span className={spanClass}>{timeLeft.minutes || "0"} m: </span>
      <span className={spanClass}>{timeLeft.seconds || "0"} s</span>
    </>
  );
};

export default Timer;
