/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel'
import { useNavigate } from 'react-router-dom'
import { getServices } from '../../manage_states/gen-states/services/servicesSync'
import { updateServicesSkip } from '../../manage_states/gen-states/services/slice'
import { useDispatch, useSelector } from 'react-redux'
import { AppContext } from '../../context/context'

const FeaturedServices = () => {
  const options = {
    nav: true,
    dots: true,
    margin: 20,
    loop: true,
    autoPlay: true,
    autoplayTimeout: 4000,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      768: {
        items: 2,
      },
      992: {
        items: 3,
      },
      1200: {
        items: 3,
      },
    },
  }

  const navigate = useNavigate()

  const handleBookAppointment = (params) => {
    const isAuthenticated = !!localStorage.getItem('airdrops_token')

    if (isAuthenticated) {
      navigate(`/book-service/${params}`) // If logged in, go to booking
    } else {
      navigate('/login') // If not logged in, go to login
    }
  }

  const [messageInput, setMessageInput] = useState('')
  const dispatch = useDispatch()
  const { socket } = useContext(AppContext)

  const { serviceLimit, serviceSkip, services, loadingServices } = useSelector(
    (state) => state.services
  )

  useEffect(() => {
    // socket.emit("connect-to-services");
    dispatch(getServices({ page: serviceSkip, limit: serviceLimit }))
  }, [])

  socket.off('services').on('services', (room) => {
    console.log(room, 'visions')
    // setServices(room);
  })

  const sendMessage = () => {
    // Send 'message' event to server
    socket.emit('message', messageInput)
    // Clear message input
    setMessageInput('')
  }

  function convertToKebabCase(str) {
    return str.replace(/\s+/g, '-').toLowerCase()
  }

  const nextPage = () => {
    if (!loadingServices) {
      if (services?.hasNextPage) {
        dispatch(updateServicesSkip(serviceSkip + 1))

        dispatch(
          getServices({
            page: serviceSkip + 1,
            limit: serviceLimit,
          })
        )
      }
    }
  }

  const prevPage = () => {
    if (!loadingServices) {
      if (services?.hasPrevPage) {
        dispatch(updateServicesSkip(serviceSkip - 1))

        dispatch(
          getServices({
            page: serviceSkip - 1,
            limit: serviceLimit,
          })
        )
      }
    }
  }

  return (
    <section className='feature-service-nine'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12 text-center'>
            <div
              className='section-heading section-heading-nine aos'
              data-aos='fade-up'
            >
              <p>TRUSTED &amp; QUALITY SERVICE</p>
              <h2>Featured Services</h2>
            </div>
          </div>
        </div>
        <div className='row'>
          {services?.docs?.map((service, i) => (
            <div className='col-lg-4 col-md-6 col-sm-6 col-12' key={service.id}>
              <div className='service-widget service-widget-nine'>
                <div className='service-img'>
                  {/* <a href='#'> */}
                    <img
                      className='img-fluid service-img-responsive'
                      alt='Service icon'
                      src={`${service?.gallery[0]?.pic_url}`}
                    />
                  {/* </a> */}
                
                </div>
                <div className='service-content service-content-nine'>
                  <span className='sub-title'>{service?.sub_category}</span>
                  <h3 className='title'>
                    <a
                      style={{ textTransform: 'uppercase' }}
                      href={`/service-details/${service?.service_title}`}
                    >
                      {service?.service_title}
                    </a>
                  </h3>
                  <div className='main-saloons-profile'>
                    <div className='saloon-profile-left'>
                      <div className='saloon-content'>
                        {/* <div className='saloon-content-top'>
                          <i className='feather-clock' />
                          <span>{service.time} </span>
                          <span> 07:00 AM - 11:00 PM </span>
                        </div> */}
                        <div className='saloon-content-btn'>
                          <span>
                            <i className='feather-map-pin' />
                          </span>
                          {/* <span>{service.location}</span> */}
                          <span> Main Boulevard, Lahore</span>
                        </div>
                      </div>
                    </div>
                    {/* <div className='saloon-right'>
                      <span>${service?.price}.00</span>
                    </div> */}
                  </div>
                  <div className='service-bottom-nine'>
                    <a
                      href={`/book-service/${service?.service_title}`}
                      className='btn btn-primary'
                      onClick={(e) => {
                        e.preventDefault() // Prevents the default anchor tag behavior
                        handleBookAppointment(service?.service_title) // Calls your custom function
                      }}
                    >
                      Book Appointment
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className='btn-sec btn-service-nine aos' data-aos='fade-up'>
          <a href='/services' className='btn btn-primary btn-view'>
            VIEW ALL SERVICES
          </a>
        </div>
      </div>
    </section>
  )
}

export default FeaturedServices
