/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate } from "react-router-dom";

const HeroTwo = () => {
  const navigate = useNavigate();

  const handleBookAppointment = () => {
    const isAuthenticated = !!localStorage.getItem("airdrops_token"); // Check if token exists

    if (isAuthenticated) {
      navigate("/services"); // If logged in, go to booking
    } else {
      navigate("/sign-up"); // If not logged in, go to login
    }
  };
  return (
    <section className="hero-section-nine">
      <video
        autoPlay
        loop
        muted
        playsInline
        poster="/assets/img/poster/poster.png"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: -1,
        }}
      >
        <source
          src="https://res.cloudinary.com/dwrmw2fsn/video/upload/v1735406649/Airdrops_Punch_Logo_online-video-cutter.com_1_gdbov7.mp4"
          type="video/mp4"
        />
        {/* Fallback message for browsers that do not support the video tag */}
        Your browser does not support the video tag.``
      </video>
      <div
        style={{ backgroundColor: "#2105059b", height: "100vh", width: "100%" }}
      >
        <div className="container">
          <div className="home-banner home-banner-nine">
            <div className="row align-items-center w-100">
              <div className="col-lg-4">
                <div className="banner-imgs banner-imgs-nine">
                  <div className="banner-1 shape-1">
                    <img
                      class="img-fluid"
                      alt="banner"
                      src="/assets/img/banner-img-01.jpg"
                      // src="/assets/img/banner-img-04.jpg"
                    />
                  </div>
                  {/* <div className="banner-2 shape-3">
                    <img
                      class="img-fluid"
                      alt="banner"
                      src="/assets/img/banner-img-03.jpg"
                    />
                  </div> */}
                  <div className="banners-3 shape-3">
                    <img
                      class="img-fluid"
                      alt="banner"
                      src="/assets/img/banner-img-02.jpg"
                    />
                  </div>
                  {/* <div className="banner-4 shape-2">
                    <img
                      class="img-responsive"
                      alt="banner"
                      src="/assets/img/banner-img-01.jpg"
                    />
                  </div> */}
                </div>
              </div>
              <div className="col-lg-8 col-md-10 mx-auto">
                <div
                  className="section-search section-search-nine aos"
                  data-aos="fade-up"
                >
                  <div className="arrow-ryt-all">
                    <h6>
                      Welcome to Airdrops'{" "}
                      <span className="text-black footer-a">PUNCH</span>
                    </h6>
                    <div className="arrow-ryt">
                      <img
                        src="/assets/img/icons/arrow-nine.svg"
                        alt="arroimag"
                      />
                    </div>
                  </div>
                  <h1 className="footer-a">Providing Professional Reliable Service</h1>
                  <p style={{color:"#ffff"}}>
                    Our professional service offers a complete solution that
                    gets your vehicle running smoothly and reliably!
                  </p>
                  <a
                    href="#"
                    className="btn btn-primary appoints-btn"
                    onClick={handleBookAppointment}
                  >
                    Start For Free
                  </a>
                  {/* <div className="banner-appointment-nine">
                    <img src="/assets/img/profiles/avatar-15.jpg" alt="User" />
                    <div className="banner-appointment-content">
                      <p  className="footer-a" >Got a question about our service?</p>
                      <h5 className="footer-a">
                        Email Us:{" "}
                        <a
                          href="mailto:info@airdropspunch.com"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="footer-a"
                        >
                          info@airdropspunch.com
                        </a>
                      </h5>
                    </div>
                  </div> */}
                  {/* <br /> */}
                  {/* <div class="downlaod-btn aos" data-aos="fade-up">
                    <a href="#">
                      <img src="assets/img/googleplay.svg" alt="img" />
                    </a>
                    <a href="#">
                      <img src="assets/img/appstore.svg" alt="img" />
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroTwo;
