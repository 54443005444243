/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getServices,
  getSingleService,
} from "../../manage_states/gen-states/services/servicesSync";

const ServiceDetailsPage = () => {
  const { serviceTitle } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function convertToKebabCase(str) {
    return str?.replace(/\s+/g, "-")?.toLowerCase();
  }

  const { singleService } = useSelector((state) => state.services);
  const { serviceLimit, serviceSkip, services, loadingServices } = useSelector(
    (state) => state.services
  );

  useEffect(() => {
    dispatch(getServices({ page: serviceSkip, limit: serviceLimit }));
  }, []);

  useEffect(() => {
    dispatch(getSingleService(serviceTitle));
  }, []);

  const options = {
    nav: true,
    dots: true,
    margin: 20,
    loop: true,
    autoPlay: true,
    autoplayTimeout: 4000,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      768: {
        items: 2,
      },
      992: {
        items: 2,
      },
      1200: {
        items: 2,
      },
    },
  };

  const handleBookAppointment = (params) => {
    const isAuthenticated = !!localStorage.getItem("airdrops_token"); // Check if token exists

    if (isAuthenticated) {
      navigate(`/book-service/${params}`); // If logged in, go to booking
    } else {
      navigate("/login"); // If not logged in, go to login
    }
  };

  console.log(singleService, "single service");
  return (
    <>
      <div className="content">
        <div className="container">
          <div className="row">
            {/* Service Profile */}
            <div className="col-md-8">
              <div className="serv-profile">
                <h2>{singleService?.service_title}</h2>
                <ul>
                  <li>
                    <span className="badge">{singleService?.category}</span>
                  </li>
                  <li className="service-map">
                    <i className="feather-map-pin" /> Alabama, USA
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="serv-action">
                {/* <ul>
                  <li>
                    <a href="#" data-bs-toggle="tooltip" title="Share">
                      <i className="feather-share-2" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="feather-printer" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="feather-download" />
                    </a>
                  </li>
                </ul> */}
                <ul>
                  {/* Dropdown for Social Media Sharing */}
                  <li className="dropdown">
                    <a
                      href="#"
                      className="dropdown-toggle a-child"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      title="Share"
                    >
                      <i className="feather-share-2" />
                    </a>
                    <div className="dropdown-menu">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "17px",
                          padding: "10px",
                        }}
                      >
                        {/* WhatsApp */}
                        <li>
                          <a
                            className=""
                            href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                              window.location.href
                            )}`}
                            style={{ display: "flex" }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span>WhatsApp</span>
                          </a>
                        </li>
                        {/* Facebook */}
                        <li>
                          <a
                            className=""
                            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                              window.location.href
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="feather-facebook" /> Facebook
                          </a>
                        </li>
                        {/* Instagram */}
                        <li>
                          <a
                            className=""
                            href="https://www.instagram.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="feather-instagram" /> Instagram
                          </a>
                        </li>
                        {/* TikTok */}
                        <li>
                          <a
                            className=""
                            href="https://www.tiktok.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="feather-tiktok" /> TikTok
                          </a>
                        </li>
                        {/* Copy Link */}
                        <li>
                          <a
                            className=""
                            href="#"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                window.location.href
                              );
                              alert("Link copied to clipboard!");
                            }}
                          >
                            <i className="feather-copy" /> Copy Link
                          </a>
                        </li>
                      </div>
                    </div>
                  </li>

                  {/* Print Page */}
                  <li>
                    <a
                      href="#"
                      onClick={() => window.print()}
                      data-bs-toggle="tooltip"
                      title="Print Page"
                      className="a-child"
                    >
                      <i className="feather-printer" />
                    </a>
                  </li>

                  {/* Download as PDF */}
                  <li>
                    <a
                      href="#"
                      onClick={() => {
                        const element = document.body; // Replace with specific element to capture
                        import("html2pdf.js").then((html2pdf) => {
                          html2pdf.default().from(element).save("page.pdf");
                        });
                      }}
                      data-bs-toggle="tooltip"
                      title="Download Page as PDF"
                      className="a-child"
                    >
                      <i className="feather-download" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-12">
              <div className="service-gal">
                <div className="row gx-2">
                  <div className="col-md-9">
                    <div
                      style={{ maxHeight: "500px", overflow: "hidden" }}
                      className="service-images big-gallery"
                    >
                      {singleService?.gallery?.length > 0 && (
                        <img
                          src={singleService?.gallery[0]?.pic_url}
                          className="img-fluid serv-img"
                          alt="img"
                        />
                      )}
                      {/* <a
                        href={singleService?.gallery[0]?.pic_url}
                        data-fancybox="gallery"
                        className="btn btn-show"
                      >
                        <i className="feather-image me-2" />
                        Show photo
                      </a> */}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="service-images small-gallery">
                      <a
                        href={
                          singleService?.gallery?.length > 0 &&
                          singleService?.gallery[0]?.pic_url
                        }
                        data-fancybox="gallery"
                      >
                        <img
                          src={
                            singleService?.gallery?.length > 0 &&
                            singleService?.gallery[0]?.pic_url
                          }
                          className="img-fluid"
                          alt="img"
                        />
                        <span className="circle-icon">
                          <i className="feather-plus" />
                        </span>
                      </a>
                    </div>
                    <div className="service-images small-gallery">
                      <a
                        href={
                          singleService?.gallery?.length > 0 &&
                          singleService?.gallery[0]?.pic_url
                        }
                        data-fancybox="gallery"
                      >
                        <img
                          src={
                            singleService?.gallery?.length > 0 &&
                            singleService?.gallery[1]?.pic_url
                          }
                          className="img-fluid"
                          alt="img"
                        />
                        <span className="circle-icon">
                          <i className="feather-plus" />
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Service Profile */}
          </div>
          <div className="row">
            {/* Service Details */}
            <div className="col-lg-8">
              <div className="service-wrap">
                <h5>Service Details</h5>
                <p>{singleService?.description}</p>
              </div>
              <div className="service-wrap provide-service">
                <h5>Speak to a Service Advicer</h5>
                <div className="row">
                  <div className="col-md-4">
                    <div className="provide-box">
                      {/* <img
                        src="assets/img/profiles/avatar-02.jpg"
                        className="img-fluid"
                        alt="img"
                      /> */}
                      <div className="provide-info">
                        <h6>{singleService.name}</h6>
                        {/* <div className="serv-review">
                          <i className="fa-solid fa-star" /> <span>4.9 </span>
                          (255 reviews)
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="provide-box">
                      <span>
                        <i className="feather-mail" />
                      </span>
                      <div className="provide-info">
                        <h6>Email</h6>
                        <p>
                          <a
                            href="#"
                            className="__cf_email__"
                            data-cfemail="9beff3f4f6fae8f3dbfee3faf6ebf7feb5f8f4f6"
                          >
                            {singleService?.email}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="provide-box">
                      <span>
                        <i className="feather-phone" />
                      </span>
                      <div className="provide-info">
                        <h6>Phone</h6>
                        <p>{singleService?.phone_number}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="service-wrap">
                <h5>Video</h5>
                <div id="background-video">
                  <a
                    className="play-btn"
                    data-fancybox
                    href="https://www.youtube.com/watch?v=Vdp6x7Bibtk"
                  >
                    <i className="fa-solid fa-play" />
                  </a>
                </div>
              </div>

              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="service-wrap">
                    <h5>Related Services</h5>
                  </div>
                </div>
                <div className="col-md-6 text-md-end">
                  <div className="owl-nav mynav" />
                </div>
              </div>
              <div>
                <OwlCarousel
                  className="owl-carousel related-slider "
                  autoplay={true}
                  loop
                  margin={10}
                  {...options}
                >
                  {services?.docs?.map((item) => (
                    <div className="service-widget mb-0">
                      <div style={{ width: "100%" }} className="service-cont">
                        <div
                          style={{
                            width: "100% !important",
                            maxHeight: "200px",
                          }}
                          className="service-cont-im"
                        >
                          <a href={`/service-details/${item?.service_title}`}>
                            <img
                              className="img-fluid serv-img"
                              alt="Service Imag"
                              src={`${item?.gallery[0]?.pic_url}`}
                            />
                          </a>
                        </div>

                        <div className="fav-item">
                          <a href="categories.html">
                            <span className="item-cat">{item?.category}</span>
                          </a>
                          {/* <a href="#" className="fav-icon">
                            <i className="feather-heart" />
                          </a> */}
                        </div>
                      </div>
                      <div className="service-content">
                        <h3 className="title">
                          <a href={`/service-details/${item?.service_title}`}>
                            {item?.service_title}
                          </a>
                        </h3>
                        <p>
                          <i className="feather-map-pin" />
                          Around the globe
                        </p>
                        <div className="serv-info">
                          {/* <h6>
                            $25.00<span className="old-price">$35.00</span>
                          </h6> */}
                          <button
                            // href={`/book-service/${singleService?.service_title}`}
                            className="btn btn-book"
                            onClick={() =>
                              handleBookAppointment(
                                `${singleService?.service_title}`
                              )
                            }
                          >
                            Book Now
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
              </div>
            </div>
            {/* /Service Details */}
            <div className="col-lg-4 theiaStickySidebar">
              {/* Service Availability */}
              <div className="card card-provide mb-0">
                <div className="card-body">
                  {/* <div className="provide-widget">
                    <div className="service-amount">
                      <h5>${singleService?.price}</h5>
                    </div>
                  </div> */}
                  <div className="package-widget">
                    <h5>What You’ll Gain with Us</h5>
                    <ul>
                      <li>Seamless booking for your convenience</li>
                      <li>Expert care from trusted professionals</li>
                      <li>Clear, upfront pricing with no surprises</li>
                      <li>Top-notch service satisfaction guaranteed</li>
                    </ul>
                  </div>

                  <button
                    // href={`/book-service/${singleService?.service_title}`}
                    className="btn btn-primary"
                    onClick={() =>
                      handleBookAppointment(`${singleService?.service_title}`)
                    }
                  >
                    Book Service
                  </button>
                </div>
              </div>
              {/* /Service Availability */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceDetailsPage;
