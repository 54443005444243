import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isAuthenticated } from "../../hooks";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { GetSingleUser } from "../../manage_states/gen-states/auth/authServiceSync";
import { UserSubscribe } from "../../manage_states/gen-states/subscription/subscriptionServiceSync";

const CardIndex = () => {
  const [validTill, setValidTill] = useState("");
  const [formData, setFormData] = useState({
    card_number: "",
    cvv: "",
    payer_name: "",
    payer_email: "",
    billing_name: "",
    billing_email: "",
    billing_address: "",
    billing_city: "",
    billing_state: "",
    billing_zip: "",
    billing_country: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authenticatedUser = useQuery({
    queryKey: ["authenticated"],
    queryFn: isAuthenticated,
  });
  const authToken = localStorage.getItem("airdrops_token");
  const location = useLocation();
  const { subscription, amount, currency, duration } = location.state || {};
  const { user, loading } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(GetSingleUser());
  }, []);

  const handleInputChange = (e) => {
    let value = e.target.value;

    // Remove any non-digit characters
    value = value.replace(/\D/g, "");

    // Add a "/" after the first two digits
    if (value.length > 2) {
      value = value.slice(0, 2) + "/" + value.slice(2);
    }

    // Limit input to MM/YY format
    if (value.length > 5) {
      value = value.slice(0, 5);
    }

    setValidTill(value);
  };

  const handleBlur = () => {
    const [month, year] = validTill.split("/").map(Number);
    if (validTill.length === 5 && (month < 1 || month > 12)) {
      alert("Please enter a valid month (01-12).");
      setValidTill("");
    }
  };

  useEffect(() => {
    if (!authToken) {
      navigate("/login");
    }
  }, []);

  const formatAmount = (amount, currency = "USD", locale = "en-US") => {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency,
    }).format(amount);
  };

  const handlePay = () => {
    const value = {
      amount,
      name: user?.name,
      interval: duration,
      currency,
      duration: "50",
      subscription,
      card_number: formData?.card_number,
      expiry_month: validTill.split("/")[0],
      expiry_year: `20${validTill.split("/")[1]}`,
      cvv: formData?.cvv,
      payer_name: formData?.payer_name,
      payer_email: formData?.payer_email,
      billing_name: formData?.billing_name,
      billing_email: formData?.billing_email,
      billing_address: formData?.billing_address,
      billing_city: formData?.billing_city,
      billing_state: formData?.billing_state,
      billing_zip: formData?.billing_zip,
      billing_country: formData?.billing_country,
      email: user?.email,
      tx_ref: "MC-3243erygg",
      redirect_url: "https://www.flutterwave.ng",
    };

    dispatch(UserSubscribe(value));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="main-wrapper">
      <div className="bg-img">
        <img src="/assets/img/bg/work-bg-03.png" alt="img" className="bgimg1" />
        <img src="/assets/img/bg/work-bg-03.png" alt="img" className="bgimg2" />
      </div>
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="error-wrap text-center">
                <div className="error-logo mb-0">
                  <a href="/">
                    <img
                      className="img-fluid"
                      src="/assets/img/cropped-Untitled-1-1 3.png"
                      alt="img"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="d-flex justify-content-center align-items-center"
            // style={{ height: "80vh", overflowY:"scroll", paddingTop: "80px" }}
          >
            <div
              className="card p-4 shadow-lg"
              style={{ maxWidth: "500px", width: "100%" }}
            >
              <div className="d-flex justify-content-between align-items-center mb-4 border-bottom pb-3">
                <h5 className="mb-0 fw-light fs-6">{user?.email}</h5>
                <h6 className="mb-0 text-muted">
                  {formatAmount(amount, currency)}
                </h6>
              </div>
              <div>
                <h5 className="text-center fw-light mb-3">Enter Payment Details</h5>
                {/* Payer Details */}
                <div className="mb-3">
                  <label className="form-label">Payer Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="John Doe"
                    name="payer_name"
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Payer Email</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="payer@example.com"
                    name="payer_email"
                    onChange={handleChange}
                  />
                </div>

                {/* Billing Details */}
                <h6 className="text-muted mt-4">Billing Information</h6>
                <div className="mb-3">
                  <label className="form-label">Billing Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="John Doe"
                    name="billing_name"
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Billing Email</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="billing@example.com"
                    name="billing_email"
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Address</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="123 Main St"
                    name="billing_address"
                    onChange={handleChange}
                  />
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label className="form-label">City</label>
                    <input
                      type="text"
                      className="form-control"
                      name="billing_city"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">State</label>
                    <input
                      type="text"
                      className="form-control"
                      name="billing_state"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label className="form-label">Zip Code</label>
                    <input
                      type="text"
                      className="form-control"
                      name="billing_zip"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">Country</label>
                    <input
                      type="text"
                      className="form-control"
                      name="billing_country"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                {/* Card Details */}
                <h6 className="text-muted mt-4">Card Details</h6>
                <div className="mb-3">
                  <label className="form-label">Card Number</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="0000 0000 0000 0000"
                    name="card_number"
                    onChange={handleChange}
                  />
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label className="form-label">Valid Till</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="MM/YY"
                      maxLength="5"
                      value={validTill}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">CVV</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="123"
                      maxLength="3"
                      name="cvv"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <button
                  type="button"
                  className="btn btn-primary w-100 mt-4"
                  onClick={handlePay}
                >
                  Pay Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardIndex;
