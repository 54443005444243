import React from "react";
import { Accordion } from "react-bootstrap";
import faqData from "../datas/faqData";

const FAQ = () => {
  return (
    <>
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title footer-a">Frequently Asked Questions</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a className="footer-a" href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active footer-a" aria-current="page">
                    FAQ
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Accordion defaultActiveKey="0">
                {faqData?.map((category, index) => (
                  <Accordion.Item eventKey={index.toString()} key={index}>
                    <Accordion.Header>{category.category}</Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        {category?.items?.map((q, i) => (
                          <li key={i}>
                            <strong>{q.question}</strong>
                            <p>{q.answer}</p>
                          </li>
                        ))}
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQ;
