import APIs from '../../Api';

const getAllCountry = async () => {
  try {
    const response = await APIs.get('/get_all_country');

    if (response?.data?.message === 'success') {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getAllStates = async (payload) => {
  try {
    const response = await APIs.get(`/get_states/${payload?.country}`);

    if (response?.data?.message === 'success') {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getAllCities = async (payload) => {
  try {
    const response = await APIs.get(
      `/get_cities/${payload?.country}/${payload?.state}/`
    );

    if (response?.data?.message === 'success') {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const CountryServices = {
  getAllCountry,
  getAllStates,
  getAllCities,
};

export default CountryServices;
