import React from "react";
import { FaXTwitter } from "react-icons/fa6";


const PageInProgressComp = ({title, subTitle, image}) => {
  return (
    <div className="main-wrapper">
      <div className="bg-img">
        <img src="/assets/img/bg/work-bg-03.png" alt="img" className="bgimg1" />
        <img src="/assets/img/bg/work-bg-03.png" alt="img" className="bgimg2" />
      </div>
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="error-wrap text-center">
                <div className="error-logo mb-0">
                  <a href="/">
                    <img
                      className="img-fluid"
                      src='/assets/img/cropped-Untitled-1-1 3.png'
                      alt="img"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="maintenance-sec pb-0">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="maintenance-wrap">
                  <h2>{title}</h2>
                  <p>
                   {subTitle}
                  </p>
                  <div className="maintenance-icon">
                    <h6>We'll Be Back Shortly</h6>
                    <div className="social-icon media-icon">
                      <ul>
                        <li>
                          <a href="#" target="_blank">
                            <i className="feather-instagram" />{" "}
                          </a>
                        </li>
                        <li>
                          <a href="#" target="_blank">
                          <FaXTwitter />
                          </a>
                        </li>
                        <li>
                          <a href="#" target="_blank">
                            <i className="feather-youtube" />
                          </a>
                        </li>
                        <li>
                          <a href="#" target="_blank">
                            <i className="feather-linkedin" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <a href="/" className="btn btn-primary">
                    <i className="feather-arrow-left-circle me-2" />
                    Back to Home
                  </a>
                </div>
              </div>
              <div className="col-lg-6">
                <img
                  src={image ? image : "/assets/img/maintenance.png"}
                  className="img-fluid"
                  alt="image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageInProgressComp;
