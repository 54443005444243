import React from "react";
import { Accordion } from "react-bootstrap";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title footer-a">Privacy Policy</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a className="footer-a" href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active footer-a" aria-current="page">
                    Privacy Policy
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Accordion defaultActiveKey="0">
                {/* Mission Statement */}
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Mission Statement</Accordion.Header>
                  <Accordion.Body>
                    Airdrops’ PUNCH is committed to revolutionizing the automotive industry through innovative technology, exceptional service, and unwavering integrity. We aim to lead by example in customer satisfaction, transparency, and data protection while ensuring compliance with global regulations.
                  </Accordion.Body>
                </Accordion.Item>

                {/* Core Values */}
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Core Values</Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li><strong>Customer Satisfaction:</strong> Deliver exceptional experiences for B2C and B2B clients.</li>
                      <li><strong>Quality Assurance:</strong> Ensure seamless, efficient transactions.</li>
                      <li><strong>Transparency:</strong> Foster open communication and trust.</li>
                      <li><strong>Innovation:</strong> Continuously improve and adapt technology.</li>
                      <li><strong>Accountability:</strong> Take responsibility for actions and outcomes.</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

                {/* Service Policies */}
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Service Policies</Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li><strong>Service Advisor:</strong> Dedicated professionals facilitate clear communication.</li>
                      <li><strong>Appointment Scheduling:</strong> Efficient, timely scheduling for customers.</li>
                      <li><strong>Quote and Estimation:</strong> Accurate, transparent pricing.</li>
                      <li><strong>Workmanship Guarantee:</strong> Warranty on services provided.</li>
                      <li><strong>Customer Support:</strong> Responsive, knowledgeable assistance.</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

                {/* Payment and Refund Policies */}
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Payment and Refund Policies</Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li><strong>Secure Payment Processing:</strong> All payments are processed using advanced encryption protocols to protect customer data.</li>
                      <li><strong>Refund and Cancellation:</strong> Fair and timely refunds are provided for cancellations in accordance with our policy.</li>
                      <li><strong>Payment Terms:</strong> Clear and mutually agreed-upon terms for all B2B transactions.</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

                {/* Data Protection and Security */}
                <Accordion.Item eventKey="4">
                  <Accordion.Header>Data Protection and Security</Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li><strong>GDPR and PIPEDA Compliance:</strong> We adhere to global data protection laws, including GDPR (EU) and PIPEDA (Canada).</li>
                      <li><strong>Encryption and Access Controls:</strong> All customer data is encrypted and stored securely, with access limited to authorized personnel only.</li>
                      <li><strong>Data Breach Notification:</strong> In the unlikely event of a data breach, affected users will be notified promptly, along with regulatory authorities.</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

                {/* Dispute Resolution */}
                <Accordion.Item eventKey="5">
                  <Accordion.Header>Dispute Resolution</Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li><strong>Internal Review Process:</strong> We encourage customers to reach out with concerns, which will be reviewed and resolved promptly by our dedicated team.</li>
                      <li><strong>External Mediation:</strong> Neutral third-party mediation is available for unresolved disputes.</li>
                      <li><strong>Regulatory Compliance:</strong> We fully cooperate with industry regulators and adhere to their directives.</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

                {/* Amendments and Updates */}
                <Accordion.Item eventKey="6">
                  <Accordion.Header>Amendments and Updates</Accordion.Header>
                  <Accordion.Body>
                    Airdrops’ PUNCH reserves the right to update this policy as necessary. All updates will be posted on this page, and we will notify users where required. Continued use of our services implies acceptance of the revised policy.
                  </Accordion.Body>
                </Accordion.Item>

                {/* Contact Information */}
                <Accordion.Item eventKey="7">
                  <Accordion.Header>Contact Information</Accordion.Header>
                  <Accordion.Body>
                    <p>For inquiries, concerns, or feedback, please contact us:</p>
                    <ul>
                      <li>Email: [Insert Email Address]</li>
                      <li>Phone: [Insert Phone Number]</li>
                      <li>Address: [Insert Business Address]</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

                {/* Legal Disclaimer */}
                <Accordion.Item eventKey="8">
                  <Accordion.Header>Legal Disclaimer</Accordion.Header>
                  <Accordion.Body>
                    This Privacy Policy is intended to comply with all applicable laws and regulations. Any conflict arising from the use of our services will be subject to the jurisdiction of [Insert Jurisdiction]. For detailed legal advice, customers are encouraged to consult independent counsel.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
