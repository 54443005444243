import React, { useState } from "react";

const TechnicianVehicleVerificationForm = () => {
  const [feedback, setFeedback] = useState("");

  // State for inspection results
  const [inspectionResults, setInspectionResults] = useState({
    tires: { treadDepth: "", pressure: "" },
    engine: { noise: "", oilLevel: "" },
    brakes: "",
    suspension: "",
    battery: "",
  });

  // Calculate feedback based on inspection results
  const calculateFeedback = () => {
    let issues = 0;

    // Tires
    if (inspectionResults.tires.treadDepth === "poor" || inspectionResults.tires.treadDepth === "urgent") issues++;
    if (inspectionResults.tires.pressure === "poor" || inspectionResults.tires.pressure === "urgent") issues++;
    // Engine
    if (inspectionResults.engine.noise === "poor" || inspectionResults.engine.noise === "urgent") issues++;
    if (inspectionResults.engine.oilLevel === "poor" || inspectionResults.engine.oilLevel === "urgent") issues++;
    // Brakes
    if (inspectionResults.brakes === "poor" || inspectionResults.brakes === "urgent") issues++;
    // Suspension
    if (inspectionResults.suspension === "poor" || inspectionResults.suspension === "urgent") issues++;
    // Battery
    if (inspectionResults.battery === "poor" || inspectionResults.battery === "urgent") issues++;

    // Feedback based on issues detected
    if (issues === 0) {
      setFeedback("The vehicle is in acceptable condition to proceed with servicing.");
    } else if (issues <= 3) {
      setFeedback("The vehicle has notable issues. Record findings and notify the customer.");
    } else {
      setFeedback("The vehicle requires immediate attention! Discuss repairs with the customer before proceeding.");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    calculateFeedback();
  };

  return (
    <div className="container mt-4">
      <h2>Technician Vehicle Verification Form</h2>
      <p>Verify the vehicle's condition before proceeding with repairs or servicing.</p>

      <form onSubmit={handleSubmit}>
        {/* Technician Information */}
        <h4>Technician Information</h4>
        <div className="row mb-3">
          <div className="col-md-4">
            <label className="form-label">Name</label>
            <input type="text" className="form-control" placeholder="Technician Name" />
          </div>
          <div className="col-md-4">
            <label className="form-label">ID Number</label>
            <input type="text" className="form-control" placeholder="Technician ID" />
          </div>
          <div className="col-md-4">
            <label className="form-label">Date and Time</label>
            <input type="datetime-local" className="form-control" />
          </div>
        </div>

        {/* Customer Complaint Details */}
        <h4>Customer Complaint Details</h4>
        <div className="form-group mb-3">
          <textarea className="form-control" rows="4" placeholder="Describe customer-reported issues"></textarea>
        </div>

        {/* Preliminary Diagnosis */}
        <h4>Preliminary Diagnosis</h4>
        <div className="form-group mb-3">
          <textarea className="form-control" rows="4" placeholder="Enter preliminary observations or suspected issues"></textarea>
        </div>

        {/* Vehicle Condition Check */}
        <h4>Vehicle Condition Check</h4>
        <h6>Tires</h6>
        <div className="row mb-3">
          <div className="col-md-4">
            <label className="form-label">Tread Depth</label>
            <select className="form-select" onChange={(e) => setInspectionResults({ ...inspectionResults, tires: { ...inspectionResults.tires, treadDepth: e.target.value } })}>
              <option value="">Select Condition</option>
              <option value="good">Good</option>
              <option value="fair">Fair</option>
              <option value="poor">Poor</option>
              <option value="urgent">Urgent</option>
            </select>
          </div>
          <div className="col-md-4">
            <label className="form-label">Pressure</label>
            <select className="form-select" onChange={(e) => setInspectionResults({ ...inspectionResults, tires: { ...inspectionResults.tires, pressure: e.target.value } })}>
              <option value="">Select Condition</option>
              <option value="good">Good</option>
              <option value="fair">Fair</option>
              <option value="poor">Poor</option>
              <option value="urgent">Urgent</option>
            </select>
          </div>
        </div>

        <h6>Engine</h6>
        <div className="row mb-3">
          <div className="col-md-4">
            <label className="form-label">Noise</label>
            <select className="form-select" onChange={(e) => setInspectionResults({ ...inspectionResults, engine: { ...inspectionResults.engine, noise: e.target.value } })}>
              <option value="">Select Condition</option>
              <option value="good">Good</option>
              <option value="fair">Fair</option>
              <option value="poor">Poor</option>
              <option value="urgent">Urgent</option>
            </select>
          </div>
          <div className="col-md-4">
            <label className="form-label">Oil Level</label>
            <select className="form-select" onChange={(e) => setInspectionResults({ ...inspectionResults, engine: { ...inspectionResults.engine, oilLevel: e.target.value } })}>
              <option value="">Select Condition</option>
              <option value="good">Good</option>
              <option value="fair">Fair</option>
              <option value="poor">Poor</option>
              <option value="urgent">Urgent</option>
            </select>
          </div>
        </div>

        <h6>Brakes</h6>
        <div className="col-md-4 mb-3">
          <select className="form-select" onChange={(e) => setInspectionResults({ ...inspectionResults, brakes: e.target.value })}>
            <option value="">Select Condition</option>
            <option value="good">Good</option>
            <option value="fair">Fair</option>
            <option value="poor">Poor</option>
            <option value="urgent">Urgent</option>
          </select>
        </div>

        {/* Comments/Notes */}
        <h4>Comments/Notes</h4>
        <div className="form-group mb-3">
          <textarea className="form-control" rows="4" placeholder="Additional notes or recommendations"></textarea>
        </div>

        <button type="submit" className="btn btn-primary">Submit Verification</button>
      </form>

      {/* Feedback Section */}
      {feedback && (
        <div className="mt-4">
          <h4>Feedback</h4>
          <p>{feedback}</p>
          <button className="btn btn-warning" onClick={() => alert('Consult the customer for approval.')}>Notify Customer</button>
        </div>
      )}
    </div>
  );
};

export default TechnicianVehicleVerificationForm;
