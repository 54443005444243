import { toast } from "react-toastify";
import APIs from "../../Api";
// import APIs from "../Api";

const UserSubscribe = async (payload) => {
  try {
    // const response = await APIs.post(
    //   `/subscription/user/`,
    //   payload
    // );
    const response = await APIs.post(
      `/create-checkout-session`,
      payload
    );
    

    console.log(response, "response");

    if (response?.status === 200) {
      toast("Payment Successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const ConfirmUserSubscription = async (payload) => {
  try {
    const response = await APIs.post(
      `/subscription/user/complete`, payload
    );

    console.log(response, "response");

    if (response?.status === 200) {
      toast("Payment Successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const VendorSubcribe = async (payload) => {
  try {
    const response = await APIs.post("/create-checkout-session-vendor", payload);

    if (response?.status === 200) {
      toast("Payment Successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const ConfirmVendorSubscription = async (paymentId) => {
  try {
    const response = await APIs.post(
      `/subscription/vendor/complete`
    );

    console.log(response, "response");

    if (response?.status === 200) {
      toast("Payment Successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const SubscriptionServices = {
  VendorSubcribe,
  UserSubscribe,
  ConfirmUserSubscription,
  ConfirmVendorSubscription,
};

export default SubscriptionServices;
