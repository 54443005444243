/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getServices } from "../../manage_states/gen-states/services/servicesSync";

const MostPopularServices = () => {
  const options = {
    nav: true,
    dots: true,
    margin: 20,
    loop: true,
    autoPlay: true,
    autoplayTimeout: 4000,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      768: {
        items: 2,
      },
      992: {
        items: 3,
      },
      1200: {
        items: 3,
      },
    },
  };
  const navigate = useNavigate();

  const handleBookAppointment = (params) => {
    const isAuthenticated = !!localStorage.getItem("airdrops_token");

    if (isAuthenticated) {
      navigate(`/book-service/${params}`); // If logged in, go to booking
    } else {
      navigate("/login"); // If not logged in, go to login
    }
  };

  const [messageInput, setMessageInput] = useState("");
  const dispatch = useDispatch();

  const { serviceLimit, serviceSkip, services, loadingServices } = useSelector(
    (state) => state.services
  );

  useEffect(() => {
    // socket.emit("connect-to-services");
    dispatch(getServices({ page: serviceSkip, limit: serviceLimit }));
  }, []);
  return (
    <section class="service-section">
      <div class="container">
        <div class="section-heading">
          <div class="row align-items-center">
            <div class="col-md-6 aos" data-aos="fade-up">
              <h2>Most Popular Services</h2>
              <p>Explore the greates our services. You won’t be disappointed</p>
            </div>
            <div class="col-md-6 text-md-end aos" data-aos="fade-up">
              <div class="owl-nav mynav1"></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <OwlCarousel
              class="owl-carousel popular-slider"
              autoplay={true}
              loop
              margin={10}
              {...options}
            >
              {services?.docs?.map((service, i) => (
                <div class="service-widget aos" data-aos="fade-up">
                  <div class="service-img">
                    <a href="#" onClick={handleBookAppointment}>
                      <img
                        class="img-fluid service-img-responsive"
                        alt="Service Imag"
                        src={`${service?.gallery[0]?.pic_url}`}
                      />
                    </a>
                    <div class="fav-item">
                      <a href="categories.html">
                        <span class="item-cat">{service?.sub_category}</span>
                      </a>
                      <a href="#" class="fav-icon">
                        <i class="feather-heart"></i>
                      </a>
                    </div>
                   
                  </div>
                  <div class="service-content">
                    <h3 class="title">
                      <a href="service-details.html">{service?.service_title}</a>
                    </h3>
                    <p>
                      <i class="feather-map-pin"></i>Any where around the globe
                      
                    </p>
                    <div class="serv-info">
                      {/* <h6>
                        $20.00<span class="old-price">$35.00</span>
                      </h6> */}
                      <a href="#" onClick={handleBookAppointment} class="btn btn-book">
                        Book Now
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
          <div class="btn-sec aos" data-aos="fade-up">
            <a href="/services" class="btn btn-primary btn-view">
              View All<i class="feather-arrow-right-circle"></i>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MostPopularServices;
