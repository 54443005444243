import React, { useEffect, useState } from "react";
import { getUserProfile } from "../../../api_calls/profile/service";
import { useQuery } from "@tanstack/react-query";
import OverviewCards from "./OverviewCards";
import { useDispatch, useSelector } from "react-redux";
// import {
//   getBiddings,
//   getSingleBidding,
//   placeBid,
//   providerRequestSelector,
// } from './api-requests/requests/request-slice';
import { GetSingleUser } from "../../../manage_states/gen-states/auth/authServiceSync";
import {
  getBiddings,
  getSingleBidding,
  placeBid,
} from "../../../manage_states/dashboard/provider/bidding/bidding-services-sync";
import Timer from "../Timer";
import { formatAmount, formatDate } from "../../../utils/formatters";
const Index = () => {
  // const { data, error, isLoading } = useQuery("postsData", getUserProfile);
  const [bidId, setBidId] = useState("");
  const [bidAmount, setBidAmount] = useState("");
  const dispatch = useDispatch();

  const {
    requests,
    singleBidding,
    placingBid,
    placeBidError,
    placeBidSuccess,
  } = useSelector((state) => state.bidding);
  const { user, loading } = useSelector((state) => state.auth);
  const result = useQuery({ queryKey: ["profile"], queryFn: getUserProfile });

  function convertToKebabCase(str) {
    return str.replace(/\s+/g, "-").toLowerCase();
  }

  const handleSetId = ({ title, id }) => {
    console.log(title, id, "place id");
    const service_title = convertToKebabCase(title);
    const required = `${service_title}-${id}`;
    console.log(required, "required");
    dispatch(getSingleBidding(`${required}`));
    setBidId(id);
  };

  const handlePlaceBid = () => {
    const requiredData = {
      id: bidId,
      data: {
        amount: Number(bidAmount),
      },
    };
    console.log(requiredData);
    dispatch(placeBid(requiredData));
  };

  const isDateExpired = (targetDate) => {
    const currentDate = new Date();
    const target = new Date(targetDate);
    return target < currentDate;
  };

  console.log(requests, "biddings", singleBidding, "single bidding");
  console.log(placeBidError, placeBidSuccess, "bid info");
  useEffect(() => {
    dispatch(GetSingleUser());
    dispatch(getBiddings("0/20"));
  }, []);
  // console.log(user, "user");
  if (loading) return <div>Fetching profile...</div>;
  if (result.error) return <div>An error occurred: {result.error.message}</div>;

  return (
    <>
      {/* <!-- Page Header --> */}
      <div class="page-header">
        <div class="row">
          <div class="col-md-12  dash-card">
            <div class="provider-subtitle mt-6">
              <h6>Welcome Back {user?.name}!</h6>
              <p>
                Your current balance is $0. complete your profile verification
                to start earning
              </p>
              <a href="/provider/verification" class="btn btn-primary">
                Verify Now !
              </a>
              <br />
              <br />
            </div>
          </div>
          {/* <div class="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap">
            <div class="review-sort me-2">
              <p>Sort</p>
              <select class="select">
                <option>A - Z</option>
                <option>Most Helpful</option>
              </select>
            </div>
            <div class="grid-listview">
              <ul>
                <li>
                  <a href="javascript:void(0);">
                    <i class="feather-filter"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div> */}
        </div>
      </div>
      {/* <!-- /Page Header --> */}

      <div class="row">
        <OverviewCards
          title="Earnings"
          value="$0"
          subTitle="Total Earnings"
          subValue="0"
          svgIcon="/assets/img/icons/pro-icon-04.svg"
        />
        <OverviewCards
          title="Balance"
          value="$0"
          subTitle="Provided service"
          subValue="0"
          svgIcon="/assets/img/icons/pro-icon-01.svg"
          hasDashTotal={false}
          hasButton={true}
        />
        <OverviewCards
          title="Biddings"
          value="0"
          subTitle="Total Bids"
          subValue="0"
          svgIcon="/assets/img/icons/pro-icon-03.svg"
        />
        <OverviewCards
          title="Completed Works"
          value="0"
          subTitle="Provided service"
          subValue="0"
          svgIcon="/assets/img/icons/pro-icon-02.svg"
        />
      </div>
      <div class="row">
        {/* <div class="col-md-6 d-flex flex-column">
          <h6 class="subhead-title">Current Plan</h6>
          <div class="card flex-fill">
            <div class="card-body">
              <div class="plan-info">
                <div class="plan-term">
                  <h5>Standard</h5>
                  <p>Our most popular plan for small teams.</p>
                  <h6>
                    <span>Renew Date:</span> Jan 22, 2023
                  </h6>
                </div>
                <div class="plan-price">
                  <h4>$291</h4>
                  <p>per user/Year</p>
                </div>
              </div>
              <div class="plan-btns">
                <a href="#" class="btn btn-danger-light">
                  Cancel Subscription
                </a>
                <a
                  href="provider-subscription.html"
                  class="btn btn-secondary-outline"
                >
                  Upgrade
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 d-flex flex-column">
          <h6 class="subhead-title">Payment</h6>
          <div class="card flex-fill">
            <div class="card-body pay-next">
              <div class="plan-info">
                <div class="plan-term">
                  <h5>Last Payment</h5>
                  <h6>January 22, 2023</h6>
                </div>
                <div class="plan-price">
                  <a href="#" class="btn btn-light-success">
                    Active
                  </a>
                </div>
              </div>
              <div class="plan-info">
                <div class="plan-term">
                  <h5>Next Payment</h5>
                  <h6>January 22, 2023</h6>
                </div>
                <div class="plan-price">
                  <a href="#" class="btn btn-primary">
                    Manage Payment
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div class="col-md-12">
          <h6 class="subhead-title">Available Bidding</h6>
          {/* <!-- Booking List --> */}
          {requests?.data?.docs?.map((item, key) => (
            <div key={key} class="booking-list">
              <div class="booking-widget">
                <div class="booking-img">
                  <a href="service-details.html">
                    <img src={item?.bid_pic[1]?.pic_url} alt="User Image" />
                  </a>
                  <div class="fav-item">
                    <a href="javascript:void(0)" class="fav-icon">
                      <i class="feather-heart"></i>
                    </a>
                  </div>
                </div>
                <div class="booking-det-info">
                  <h3>
                    <a href="service-details.html">{item?.service_title}</a>
                    <span class="badge badge-success">Completed</span>
                  </h3>
                  <ul class="booking-details">
                    <li>
                      <span class="book-item">Expiration time:</span>
                      <span>
                        <Timer
                          spanClass="text btn btn-danger-light"
                          targetDate={item?.bid_end_date_time}
                        />
                      </span>
                    </li>
                    <li>
                      <span class="book-item">Booking Date</span> :{" "}
                      {formatDate(item?.booking_date)}
                    </li>
                    <li>
                      <span class="book-item">Amount Range</span> : from ${" "}
                      {formatAmount(item?.amount_range_Start)} to ${" "}
                      {formatAmount(item?.amount_range_end)}
                      {/* <span class="badge-grey">COD</span> */}
                    </li>
                    <li>
                      <span class="book-item">Location</span> :{" "}
                      {item?.booker_address}, {item?.booker_city},{" "}
                      {item?.booker_state},{item?.booker_country}
                    </li>
                    <li>
                      <span class="book-item">Creator</span> :
                      <div class="user-book">
                        <div class="avatar avatar-xs">
                          <img
                            class="avatar-img rounded-circle"
                            alt="User Image"
                            src={item?.bid_creator_profile_pic}
                          />
                        </div>
                        {item?.bid_creator_name}
                      </div>
                      <p>
                        <a
                          href="https://html.truelysell.com/cdn-cgi/l/email-protection"
                          class="__cf_email__"
                          data-cfemail="98f1f6fef7d8f2f7f0f6ebf5f1ecf0b6fbf7f5"
                        >
                          [email&#160;protected]
                        </a>
                      </p>
                      {/* <p>+1 607-276-5393</p> */}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="booking-action">
                <button
                  href="#"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target={"#add-review"}
                  onClick={() =>
                    handleSetId({
                      title: `${item?.service_title}`,
                      id: `${item._id}`,
                    })
                  }
                  disabled={
                    isDateExpired(item?.bid_end_date_time) ? true : false
                  }
                >
                  {/* <button onClick={handleSetId(`${item._id}`)}> */}
                  {isDateExpired(item?.bid_end_date_time)
                    ? "Bid Expired"
                    : "Place a Bid"}

                  {/* </button> */}
                </button>
              </div>
            </div>
          ))}

          {/* <!-- /Booking List --> */}

          <div class="view-history">
            <a href="provider-booking" class="btn btn-primary">
              View Available Biddings
            </a>
          </div>

          <div className="modal fade custom-modal" id="add-review">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content doctor-profile">
                <div className="modal-header border-bottom-0 justify-content-between">
                  <h5 className="modal-title">Place a bid</h5>
                  <button
                    type="button"
                    className="close-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="feather-x" />
                  </button>
                </div>
                <div className="modal-body pt-0">
                  <div>
                    <div className="write-review">
                      <div className="review-add">
                        <div className="rev-img">
                          <img
                            // src="assets/img/services/service-19.jpg"
                            src={singleBidding?.data?.bid_pic[1]?.pic_url}
                            alt="image"
                          />
                        </div>
                        <div className="rev-info">
                          <h6>{singleBidding?.data?.service_title}</h6>
                          <p>
                            {" "}
                            {singleBidding?.data?.booker_state},
                            {singleBidding?.data?.booker_country}
                          </p>
                        </div>
                      </div>
                      <div className="form-group form-info">
                        <ul class="booking-details">
                          <li>
                            <span class="book-item">Booking Date</span> :{" "}
                            {formatDate(singleBidding?.data?.booking_date)}
                          </li>
                          <li>
                            <span class="book-item">Amount</span> : from ${" "}
                            {formatAmount(
                              singleBidding?.data?.amount_range_Start
                            )}{" "}
                            to ${" "}
                            {formatAmount(
                              singleBidding?.data?.amount_range_end
                            )}
                            <span class="badge-grey">COD</span>
                          </li>
                          <li>
                            <span class="book-item">Location</span> :{" "}
                            {singleBidding?.data?.booker_address},{" "}
                            {singleBidding?.data?.booker_city},{" "}
                            {singleBidding?.data?.booker_state},
                            {singleBidding?.data?.booker_country}
                          </li>
                        </ul>
                      </div>
                      <div className="form-group form-info">
                        <label className="col-form-label">Bid amount</label>
                        <input
                          className="form-control"
                          placeholder="input amount"
                          defaultValue={""}
                          onChange={(e) => setBidAmount(e.target.value)}
                        />
                      </div>
                      <div className="modal-submit text-end">
                        <button
                          onClick={handlePlaceBid}
                          type="button"
                          className="btn btn-primary"
                        >
                          {placingBid ? "bidding.." : "Add Bid"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
