/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { serviceSelector } from "../../../manage_states/gen-states/services/slice";
import { getUserBookings } from "../../../manage_states/gen-states/services/servicesSync";
import { GetSingleUser } from "../../../manage_states/gen-states/auth/authServiceSync";

const Index = () => {
  const dispatch = useDispatch();
  const { userBookings, isGettingUserBookings, isGettingUserBookingsFailure } =
    useSelector(serviceSelector);
  const { user, loading } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(GetSingleUser());
    dispatch(getUserBookings("all/0/10"));
  }, []);
  console.log(userBookings, "userBookings");
  return (
    <>
      <div className="widget-title">
        <h4>Dashboard</h4>
      </div>
      {/* { dashboard widgets  */}
      {/* <!-- Page Header --> */}
      <div class="page-header">
        <div class="row">
          <div class="col-md-12  dash-car">
            <div class="provider-subtitle mt-6">
              <h6>Hello {user?.name} 👋</h6>
              <p>
                Your current subcription is free. Upgrade to enjoy better features
              </p>
              <a href="/pricing/user" class="btn btn-primary">
                Upgrade Now !
              </a>
              <br />
              <br />
            </div>
          </div>
          
        </div>
      </div>
      {/* <!-- /Page Header --> */}
      <div className="row">
        <div className="col-md-6 col-lg-4">
          <div className="dash-widget">
            <div className="dash-img">
              <span className="dash-icon bg-yellow">
                <img src="/assets/img/icons/dash-icon-01.svg" alt="dash icon" />
              </span>
            </div>
            <div className="dash-info">
              <div className="dash-order">
                <h6>Total Booking</h6>
                <h5>0{userBookings?.data?.docs?.length}</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4">
          <div className="dash-widget">
            <div className="dash-img">
              <span className="dash-icon bg-blue">
                <img
                  src="/assets/img/icons/wallet-icon-01.svg"
                  alt="wallet icon"
                />
              </span>
            </div>
            <div className="dash-info">
              <div className="dash-order">
                <h6>Total Spend</h6>
                <h5>$0</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4">
          <div className="dash-widget">
            <div className="dash-img">
              <span className="dash-icon bg-blue">
                <img src="/assets/img/icons/wallet-add.svg" alt="wallet" />
              </span>
            </div>
            <div className="dash-info">
              <div className="dash-order">
                <h6>Refund Requests</h6>
                <h5>0</h5>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-md-6 col-lg-3">
          <div className="dash-widget">
            <div className="dash-img">
              <span className="dash-icon bg-light-danger">
                <img src="/assets/img/icons/wallet-amt.svg" alt="amount icon" />
              </span>
            </div>
            <div className="dash-info">
              <div className="dash-order">
                <h6>Total Savings</h6>
                <h5>$354</h5>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      {/* dashboard widgets end */}

      {/* recent bookings and recent transactions  */}
      <div className="row">
        {/* recent bookings */}
        <div className="col-lg-8 d-flex flex-column">
          <h6 className="user-title">Recent Booking</h6>
          <div className="table-responsive recent-booking flex-fill">
            <table className="table mb-0">
              <tbody>
                {userBookings?.data?.docs.map((item, key) => (
                  <tr key={key}>
                    <td>
                      <h2 className="table-avatar">
                        <a href="#" className="avatar avatar-m me-2">
                          <img
                            className="avatar-img rounded"
                            src="/assets/img/services/service-06.jpg"
                            alt="User Img"
                          />
                        </a>
                        <a style={{ textTransform: "uppercase" }} href="#">
                          {item?.service_title}
                          <span>
                            <i className="feather-calendar" />{" "}
                            {item?.booking_date}
                          </span>
                        </a>
                      </h2>
                    </td>
                    <td>
                      <h2 className="table-avatar table-user">
                        <a href="#" className="avatar avatar-m me-2">
                          <img
                            className="avatar-img"
                            src="/assets/img/profiles/avatar-02.jpg"
                            alt="User Img"
                          />
                        </a>
                        <a href="#">
                          John Smith
                          <span>
                            <span
                              className="__cf_email__"
                              data-cfemail="33595c5b5d73564b525e435f561d505c5e"
                            >
                              [email&nbsp;protected]
                            </span>
                          </span>
                        </a>
                      </h2>
                    </td>
                    <td className="text-end">
                      <a href="#" className="btn btn-light-danger">
                        Cancel
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* recent booking end  */}

        {/* Recent Transaction */}

        <div className="col-lg-4 d-flex flex-column">
          <h6 className="user-title">Recent Transaction</h6>
          <div className="table-responsive transaction-table flex-fill">
            <table className="table mb-0">
              <tbody>
                <tr>
                  <td>
                    <div className="table-book d-flex align-items-center">
                      <span className="book-img">
                        <img
                          src="/assets/img/icons/trans-icon-01.svg"
                          alt="Icon"
                        />
                      </span>
                      <div>
                        <h6>Service Booking</h6>
                        <p>
                          <i className="feather-calendar" />
                          22 Sep 2023
                          <span>
                            <i className="feather-clock" /> 10:12 AM
                          </span>
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className="text-end">
                    <h5 className="trans-amt">$280.00</h5>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* /Recent Transaction */}
      </div>
      {/* recent bookings and recent transactions end */}
    </>
  );
};

export default Index;
