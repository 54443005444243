import React from 'react'

const ChooseSignup = () => {
  return (
    <div class='content'>
      <div class='container'>
        <div class='row'>
          {/* <!-- Choose Signup --> */}
          <div class=''>
            <div class='login-wrap'>
              <div class='login-header'>
                <h3 style={{textAlign:"center"}}>Get Started</h3>
              </div>
              <div class='row'>
                {/* <!-- Provider Signup --> */}
                <div class='col-md-6'>
                  <div class='choose-signup '>
                    <h6>Become a Partner</h6>
                    <div class='choose-img'>
                      <img src='/assets/img/car-technician-img.jpg' alt='imag' />
                    </div>
                    <a href='/provider-signup' class='btn btn-secondary w-100'>
                      Sign Up<i class='feather-arrow-right-circle ms-1'></i>
                    </a>
                  </div>
                </div>
                {/* <!-- /Provider Signup --> */}

                {/* <!-- User Signup --> */}
                <div class='col-md-6'>
                  <div class='choose-signup  mb-0'>
                    <h6>Signup as Customer</h6>
                    <div class='choose-img'>
                      <img src='/assets/img/user-car-img-4.jpg' alt='imag' />
                    </div>
                    <a href='/user-signup' class='btn btn-secondary w-100'>
                      Sign Up<i class='feather-arrow-right-circle ms-1'></i>
                    </a>
                  </div>
                </div>
                {/* <!-- /User Signup --> */}
              </div>
            </div>
          </div>
          {/* <!-- /Choose Signup --> */}
        </div>
      </div>
    </div>
  )
}

export default ChooseSignup
