/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

const CustomerDocumentVerification = () => {
  return (
    <>
      <div className='linked-item'>
        <div className='row'>
          <div className='col-md-8'>
            <div className='linked-acc'>
              <span className='link-icon'>
                <img src='/assets/img/icons/document-pdf.svg' alt='Icons' />
              </span>
              <div className='linked-info'>
                <h6>Document Verification</h6>
                <p>Not Yet Verified </p>
              </div>
            </div>
          </div>
          <div className='col-md-4 text-md-end'>
            <div className='security-verifi d-flex align-items-center'>
              <a
                href='#'
                className='link-close'
                data-bs-toggle='tooltip'
                data-bs-placement='left'
                data-bs-title='Not Verified'
              >
                <i className='fa-solid fa-circle-xmark' />
              </a>
              <a
                href='#'
                className='btn btn-primary btn-connect'
                data-bs-toggle='modal'
                data-bs-target='#change-document'
              >
                Change
              </a>
              <a href='#' className='btn-acc'>
                Remove
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        className='modal fade custom-modal verify-modal'
        id='change-document'
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content doctor-profile'>
            <div className='modal-header verfication-modal-head'>
              <h5 className='modal-title'>Verify Your Identity</h5>
              <p>Upload document</p>
            </div>
            <div className='modal-body'>
              <form action='https://html.truelysell.com/template3/provider-security-settings.html'>
                <div className='wallet-add'>
                  <div className='form-group'>
                    <label>Document Name</label>
                    <input className='form-control' name='phone' type='text' />
                  </div>
                  <div className='file-uploaded-mail'>
                    <img
                      src='/assets/img/icons/upload-icon-02.svg'
                      className='img-fluid'
                      alt='Upload'
                    />
                    <h4>
                      Drag &amp; drop files or <span> Browse </span>
                    </h4>
                    <p>Supported formats: PDF</p>
                    <input
                      type='file'
                      id='file-input'
                      className='image-upload'
                    />
                  </div>
                  <div className='document-upload-file'>
                    <p>
                      <img
                        src='/assets/img/icons/file-pdf.svg'
                        className='img-fluid'
                        alt='Pdf'
                      />
                      licence.pdf
                    </p>
                    <span>
                      <a href='#'>
                        <img
                          src='/assets/img/icons/trash-alt.svg'
                          className='img-fluid'
                          alt='Trash'
                        />
                      </a>
                    </span>
                  </div>
                  <div className='document-update-success'>
                    <p>
                      <i className='fa-regular fa-circle-check' /> Document
                      uploaded successfully
                    </p>
                  </div>
                  <div className='modal-submit'>
                    <a
                      href='#'
                      className='btn btn-primary w-100'
                      data-bs-toggle='modal'
                      data-bs-target='#success-document'
                    >
                      Continue
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Success mail */}
      <div
        className='modal fade success-modal verify-modal'
        id='success-document'
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-body success-body text-center'>
              <span>
                <i className='fa-regular fa-circle-check' />
              </span>
              <h3>Success</h3>
              <p className='success-modal-body'>
                Document is sent for approval for the admin once approved you
                will notified.
              </p>
            </div>
            <div className='popup-btn text-center'>
              <a href='/provider' className='btn btn-primary verify-document'>
                Go to Dashboard
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Success  */}
    </>
  )
}

export default CustomerDocumentVerification
