import React, { useState } from "react";
import { serviceInitialState } from "./schemas";
import axios from "axios";
import { Api_Url } from "../../../../api_calls/url";

const CreateServiceProcess = () => {
  const initialScheduleState = {
    all_days: [{ from: "", to: "", slots: "" }],
    monday: [{ from: "", to: "", slots: "" }],
    tuesday: [{ from: "", to: "", slots: "" }],
    wednesday: [{ from: "", to: "", slots: "" }],
    thursday: [{ from: "", to: "", slots: "" }],
    friday: [{ from: "", to: "", slots: "" }],
    saturday: [{ from: "", to: "", slots: "" }],
    sunday: [{ from: "", to: "", slots: "" }],
  };

  const [schedule, setSchedule] = useState(initialScheduleState);
  const [formData, setFormData] = useState(serviceInitialState);
  const [currentStep, setCurrentStep] = useState(1);
  const [day, setDay] = useState("all_days");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleArrayChange = (e, index, fieldName, arrayName) => {
    const { value } = e.target;
    setFormData((prevState) => {
      const updatedArray = [...prevState[arrayName]];
      updatedArray[index][fieldName] = value;
      return { ...prevState, [arrayName]: updatedArray };
    });
  };

  const addArrayItem = (arrayName, newItem) => {
    setFormData((prevState) => {
      const updatedArray = [...prevState[arrayName], newItem];
      return { ...prevState, [arrayName]: updatedArray };
    });
  };

  const addAvailability = (arrayName, newItem) => {
    setSchedule({
      ...schedule,
      [arrayName]: [...schedule[arrayName], newItem],
    });
  };
  const removeArrayItem = (index, arrayName) => {
    setFormData((prevState) => {
      const updatedArray = prevState[arrayName].filter((_, i) => i !== index);
      return { ...prevState, [arrayName]: updatedArray };
    });
  };

  const nextStep = () => {
    const next = currentStep + 1;
    console.log(next);
    setCurrentStep(next);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Submit form data to the endpoint
    console.log(formData);
  };

  console.log(formData, "form data");
  const handleAvailability = (day, index, field, value) => {
    const updatedDay = schedule[day].map((slot, i) =>
      i === index ? { ...slot, [field]: value } : slot
    );
    setSchedule({ ...schedule, [day]: updatedDay });
  };

  // const handleImageChange = async (e) => {
  //   const file = e.target.files[0];
  //   if (!file) return;

  //   const formData = new FormData();
  //   formData.append("file", file);
  //   formData.append("upload_preset", "your_upload_preset"); // Replace with your Cloudinary upload preset

  //   try {
  //     const response = await axios.post(
  //       "https://api.cloudinary.com/v1_1/your_cloud_name/image/upload",
  //       formData
  //     );
  //     const newImage = {
  //       pic_url: response.data.secure_url,
  //       pic_id: response.data.public_id,
  //     };
  //     setServiceDetails({
  //       ...serviceDetails,
  //       gallery: [...serviceDetails.gallery, newImage],
  //     });
  //   } catch (error) {
  //     console.error("Error uploading image:", error);
  //   }
  // };

  const convertToBase64 = (file, callback) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      callback(e.target.result);
    };

    reader.readAsDataURL(file);
  };

  const handleFileInputChange = (event) => {
    event.preventDefault();
    try {
      // setUploadingDocument(true);
      const file = event.target.files[0];
      console.log(file, "from file");
      // setUpdatingPics(true);
      if (file) {
        convertToBase64(file, async (base64String) => {
          const pics = { file: base64String };
          // setIsLoading(true);
          console.log(pics, "base 64");
          try {
            const response = await axios.post(
              `${Api_Url}/api/v1/upload_through_cloudinary`,
              pics
            );
            console.log(response, "file document upload complete");
            if (response.status === 200) {
              console.log(response.data.data, "response data");
              // setTrade_Certificate_id(response.data.data.public_id);
              // setTrade_Certificate(response.data.data.url);
              // setUploadingDocument(false);
              const newImage = {
                pic_url: response.data.data.url,
                pic_id: response.data.data.public_id,
              };
              setFormData({
                ...formData,
                gallery: [...formData?.gallery, newImage],
              });
            }
          } catch (err) {
            console.log(err, "from file upload error");
            // setUploadingDocument(false);
          }
        });
      }
    } catch (err) {
      console.log(err, "error occurred");
    }
  };

  const handleImageDelete = (pic_id) => {
    setFormData({
      ...formData,
      gallery: formData.gallery.filter((image) => image.pic_id !== pic_id),
    });
  };
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mx-auto">
              {/* Service List */}
              <div className="service-wizard">
                <ul id="progressbar">
                  <li className="active">
                    <div className="multi-step-icon span-info">
                      <span>
                        <i className="fa-regular fa-circle-check" />
                      </span>
                    </div>
                    <div className="multi-step-info">
                      <h6>Information</h6>
                    </div>
                  </li>
                  <li className={currentStep === 2 && "active"}>
                    <div className="multi-step-icon">
                      <span>
                        <i className="fa-regular fa-clock" />
                      </span>
                    </div>
                    <div className="multi-step-info">
                      <h6>Availability</h6>
                    </div>
                  </li>
                  <li className={currentStep === 3 && "active"}>
                    <div className="multi-step-icon">
                      <span>
                        <i className="fa-regular fa-map" />
                      </span>
                    </div>
                    <div className="multi-step-info">
                      <h6>Location</h6>
                    </div>
                  </li>
                  <li className={currentStep === 4 && "active"}>
                    <div className="multi-step-icon">
                      <span>
                        <i className="feather-image" />
                      </span>
                    </div>
                    <div className="multi-step-info">
                      <h6>Gallery</h6>
                    </div>
                  </li>
                  <li className={currentStep === 5 && "active"}>
                    <div className="multi-step-icon">
                      <span>
                        <i className="fa-solid fa-chart-bar" />
                      </span>
                    </div>
                    <div className="multi-step-info">
                      <h6>Seo</h6>
                    </div>
                  </li>
                </ul>
              </div>
              {/* /Service List */}
              <div className="service-inform-fieldset">
                {/* Service Information */}
                {currentStep === 1 && (
                  <fieldset id="first-field">
                    <div className="card add-service">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="sub-title">
                            <h6>Service Information</h6>
                          </div>
                          <div className="form-group">
                            <label className="col-form-label">
                              Service Title
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Service Name"
                              name="service_title"
                              value={formData.service_title}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">Category</label>
                            <select
                              name="category"
                              value={formData.category}
                              onChange={handleInputChange}
                              className="select form-control"
                            >
                              <option>Select Category</option>
                              <option>Car wash</option>
                              <option>House Cleaning</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">
                              Sub Category
                            </label>
                            <select
                              name="sub_category"
                              value={formData.sub_category}
                              onChange={handleInputChange}
                              className="select form-control"
                            >
                              <option>Select Sub Category</option>
                              <option>House Cleaning</option>
                              <option>Car wash</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label d-block">
                              Price
                              <span className="brief-bio float-end">
                                Set 0 for free
                              </span>
                            </label>
                            <input
                              onChange={handleInputChange}
                              value={formData.price}
                              name="price"
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label d-block">
                              Duration
                            </label>
                            <div className="form-duration">
                              <input
                                name="duration"
                                onChange={handleInputChange}
                                value={formData.duration}
                                type="text"
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group mb-0">
                            <label className="col-form-label">
                              Description
                            </label>
                            <textarea
                              className="form-control ck-editor"
                              defaultValue={""}
                              value={formData.description}
                              name="description"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card add-service">
                      <div className="add-service-toggle">
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="sub-title">
                              <h6>Additional Service</h6>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="status-toggle sml-status float-sm-end">
                              <input
                                type="checkbox"
                                id="status_1"
                                className="check"
                                defaultChecked
                              />
                              <label htmlFor="status_1" className="checktoggle">
                                checkbox
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="addservice-info">
                        {formData.additional_services.map((service, index) => (
                          <div key={index} className="row service-cont">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="col-form-label">
                                  Additional Service
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Service Item"
                                  name="service_item"
                                  value={service.service_item}
                                  onChange={(e) =>
                                    handleArrayChange(
                                      e,
                                      index,
                                      "service_item",
                                      "additional_services"
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="col-form-label">Price</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Price"
                                  name="Price"
                                  value={service.price}
                                  onChange={(e) =>
                                    handleArrayChange(
                                      e,
                                      index,
                                      "price",
                                      "additional_services"
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="col-form-label">
                                  Duration
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Service Duration"
                                  name="Duration"
                                  value={service.duration}
                                  onChange={(e) =>
                                    handleArrayChange(
                                      e,
                                      index,
                                      "duration",
                                      "additional_services"
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <a
                        href="#"
                        className="add-text add-extra "
                        onClick={() =>
                          addArrayItem("additional_services", {
                            service_item: "",
                            price: "",
                            duration: "",
                          })
                        }
                      >
                        <i className="feather-plus-circle" /> Add Additional
                        Service
                      </a>
                    </div>
                    <div className="card add-service">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="sub-title">
                            <h6>Video</h6>
                          </div>
                          <div className="form-group mb-0">
                            <label className="col-form-label">Video Link</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="https://www.youtube.com/shorts/Lf-Z7H8bZ8o"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="field-bottom-btns">
                          <div className="field-btns">
                            <button
                              onClick={nextStep}
                              className="btn btn-primary next_btn"
                              type="button"
                            >
                              Next <i className="fa-solid fa-arrow-right" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                )}

                {/* /Service Information */}
                {/* Availability */}
                {currentStep === 2 && (
                  <>
                    <div className="card add-service">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="sub-title">
                            <h6>Availability</h6>
                          </div>
                        </div>
                        <div className="col-md-12">
                          {/* Timeslot */}
                          <div className="timeslot-sec availablt-time-slots">
                            {/* Schedule Nav */}
                            <label className="col-form-label">
                              Configure Time Slots
                            </label>
                            <div className="schedule-nav">
                              <ul className="nav">
                                {[
                                  // { id: "all_days", name: "All Days", value:"all_days" },
                                  {
                                    id: "#all_days",
                                    name: "All Days",
                                    value: "all_days",
                                  },
                                  {
                                    id: "#slot_monday",
                                    name: " Monday",
                                    value: "monday",
                                  },
                                  {
                                    id: "#slot_tuesday",
                                    name: "Tuesday",
                                    value: "tuesday",
                                  },
                                  {
                                    id: "#slot_wednesday",
                                    name: "Wednesday",
                                    value: "wednesday",
                                  },
                                  {
                                    id: "#slot_thursday",
                                    name: "Thursday",
                                    value: "thursday",
                                  },
                                  {
                                    id: "#slot_friday",
                                    name: "Friday",
                                    value: "friday",
                                  },
                                  {
                                    id: "#slot_saturday",
                                    name: "Saturday",
                                    value: "saturday",
                                  },
                                  {
                                    id: "#slot_sunday",
                                    name: "Sunday",
                                    value: "sunday",
                                  },
                                ].map((day) => (
                                  <li className="nav-item">
                                    <a
                                      className="nav-link"
                                      data-bs-toggle="tab"
                                      href={day.id}
                                      onClick={() => setDay(day.value)}
                                    >
                                      {day.name}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </div>
                            {/* /Schedule Nav */}
                            <div className="tab-content pt-0">
                              {schedule[day].map((slot, index) => (
                                <div className="tab-pane active" id={day}>
                                  <div className="hours-info">
                                    <h4 className="nameof-day">{day}</h4>
                                    <div className="row hours-cont">
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <label className="col-form-label">
                                            From
                                          </label>
                                          <div className="form-icon">
                                            <input
                                              type="text"
                                              className="form-control timepicker"
                                              placeholder="Select Time"
                                              name="from"
                                              value={slot.from}
                                              onChange={(e) =>
                                                handleAvailability(
                                                  day,
                                                  index,
                                                  "from",
                                                  e.target.value
                                                )
                                              }
                                            />
                                            <span className="cus-icon">
                                              <i className="feather-clock" />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <label className="col-form-label">
                                            To
                                          </label>
                                          <div className="form-icon">
                                            <input
                                              type="text"
                                              className="form-control timepicker"
                                              placeholder="Select Time"
                                              name="to"
                                              value={slot.to}
                                              onChange={(e) =>
                                                handleAvailability(
                                                  day,
                                                  index,
                                                  "to",
                                                  e.target.value
                                                )
                                              }
                                            />
                                            <span className="cus-icon">
                                              <i className="feather-clock" />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <label className="col-form-label">
                                            Slots
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Slot"
                                            name="slots"
                                            value={slot.slots}
                                            onChange={(e) =>
                                              handleAvailability(
                                                day,
                                                index,
                                                "slots",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <a
                                    href="#"
                                    className="add-text add-hours"
                                    onClick={() =>
                                      addAvailability(day, {
                                        from: "",
                                        to: "",
                                        slots: "",
                                      })
                                    }
                                  >
                                    <i className="feather-plus-circle" /> Add
                                    Slot
                                  </a>
                                </div>
                              ))}
                              {/* <div className="tab-pane active" id="all_days">
                                <div className="hours-info">
                                  <h4 className="nameof-day">All Days</h4>
                                  <div className="row hours-cont">
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <label className="col-form-label">
                                          From
                                        </label>
                                        <div className="form-icon">
                                          <input
                                            type="text"
                                            className="form-control timepicker"
                                            placeholder="Select Time"
                                          />
                                          <span className="cus-icon">
                                            <i className="feather-clock" />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <label className="col-form-label">
                                          To
                                        </label>
                                        <div className="form-icon">
                                          <input
                                            type="text"
                                            className="form-control timepicker"
                                            placeholder="Select Time"
                                          />
                                          <span className="cus-icon">
                                            <i className="feather-clock" />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <label className="col-form-label">
                                          Slots
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Slot"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <a href="#" className="add-text add-hours">
                                  <i className="feather-plus-circle" /> Add Slot
                                </a>
                              </div>
                              <div className="tab-pane fade" id="slot_monday">
                                <div className="hours-info">
                                  <h4 className="nameof-day">Monday</h4>
                                  <div className="row hours-cont">
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <label className="col-form-label">
                                          From
                                        </label>
                                        <div className="form-icon">
                                          <input
                                            type="text"
                                            className="form-control timepicker"
                                            placeholder="Select Time"
                                          />
                                          <span className="cus-icon">
                                            <i className="feather-clock" />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <label className="col-form-label">
                                          To
                                        </label>
                                        <div className="form-icon">
                                          <input
                                            type="text"
                                            className="form-control timepicker"
                                            placeholder="Select Time"
                                          />
                                          <span className="cus-icon">
                                            <i className="feather-clock" />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <label className="col-form-label">
                                          Slots
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Slot"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <a href="#" className="add-text add-hours">
                                  <i className="feather-plus-circle" /> Add More
                                </a>
                              </div>
                              <div className="tab-pane fade" id="slot_tuesday">
                                <div className="hours-info">
                                  <h4 className="nameof-day">Tuesday</h4>
                                  <div className="row hours-cont">
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <label className="col-form-label">
                                          From
                                        </label>
                                        <div className="form-icon">
                                          <input
                                            type="text"
                                            className="form-control timepicker"
                                            placeholder="Select Time"
                                          />
                                          <span className="cus-icon">
                                            <i className="feather-clock" />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <label className="col-form-label">
                                          To
                                        </label>
                                        <div className="form-icon">
                                          <input
                                            type="text"
                                            className="form-control timepicker"
                                            placeholder="Select Time"
                                          />
                                          <span className="cus-icon">
                                            <i className="feather-clock" />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <label className="col-form-label">
                                          Slots
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Slot"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <a href="#" className="add-text add-hours">
                                  <i className="feather-plus-circle" /> Add More
                                </a>
                              </div>
                              <div
                                className="tab-pane fade"
                                id="slot_wednesday"
                              >
                                <div className="hours-info">
                                  <h4 className="nameof-day">Wednesday</h4>
                                  <div className="row hours-cont">
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <label className="col-form-label">
                                          From
                                        </label>
                                        <div className="form-icon">
                                          <input
                                            type="text"
                                            className="form-control timepicker"
                                            placeholder="Select Time"
                                          />
                                          <span className="cus-icon">
                                            <i className="feather-clock" />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <label className="col-form-label">
                                          To
                                        </label>
                                        <div className="form-icon">
                                          <input
                                            type="text"
                                            className="form-control timepicker"
                                            placeholder="Select Time"
                                          />
                                          <span className="cus-icon">
                                            <i className="feather-clock" />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <label className="col-form-label">
                                          Slots
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Slot"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <a href="#" className="add-text add-hours">
                                  <i className="feather-plus-circle" /> Add More
                                </a>
                              </div>
                              <div className="tab-pane fade" id="slot_thursday">
                                <div className="hours-info">
                                  <h4 className="nameof-day">Thursday</h4>
                                  <div className="row hours-cont">
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <label className="col-form-label">
                                          From
                                        </label>
                                        <div className="form-icon">
                                          <input
                                            type="text"
                                            className="form-control timepicker"
                                            placeholder="Select Time"
                                          />
                                          <span className="cus-icon">
                                            <i className="feather-clock" />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <label className="col-form-label">
                                          To
                                        </label>
                                        <div className="form-icon">
                                          <input
                                            type="text"
                                            className="form-control timepicker"
                                            placeholder="Select Time"
                                          />
                                          <span className="cus-icon">
                                            <i className="feather-clock" />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <label className="col-form-label">
                                          Slots
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Slot"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <a href="#" className="add-text add-hours">
                                  <i className="feather-plus-circle" /> Add More
                                </a>
                              </div>
                              <div className="tab-pane fade" id="slot_friday">
                                <div className="hours-info">
                                  <h4 className="nameof-day">Friday</h4>
                                  <div className="row hours-cont">
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <label className="col-form-label">
                                          From
                                        </label>
                                        <div className="form-icon">
                                          <input
                                            type="text"
                                            className="form-control timepicker"
                                            placeholder="Select Time"
                                          />
                                          <span className="cus-icon">
                                            <i className="feather-clock" />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <label className="col-form-label">
                                          To
                                        </label>
                                        <div className="form-icon">
                                          <input
                                            type="text"
                                            className="form-control timepicker"
                                            placeholder="Select Time"
                                          />
                                          <span className="cus-icon">
                                            <i className="feather-clock" />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <label className="col-form-label">
                                          Slots
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Slot"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <a href="#" className="add-text add-hours">
                                  <i className="feather-plus-circle" /> Add More
                                </a>
                              </div>
                              <div className="tab-pane fade" id="slot_saturday">
                                <div className="hours-info">
                                  <h4 className="nameof-day">Saturday</h4>
                                  <div className="row hours-cont">
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <label className="col-form-label">
                                          From
                                        </label>
                                        <div className="form-icon">
                                          <input
                                            type="text"
                                            className="form-control timepicker"
                                            placeholder="Select Time"
                                          />
                                          <span className="cus-icon">
                                            <i className="feather-clock" />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <label className="col-form-label">
                                          To
                                        </label>
                                        <div className="form-icon">
                                          <input
                                            type="text"
                                            className="form-control timepicker"
                                            placeholder="Select Time"
                                          />
                                          <span className="cus-icon">
                                            <i className="feather-clock" />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <label className="col-form-label">
                                          Slots
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Slot"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <a href="#" className="add-text add-hours">
                                  <i className="feather-plus-circle" /> Add More
                                </a>
                              </div>
                              <div className="tab-pane fade" id="slot_sunday">
                                <div className="hours-info">
                                  <h4 className="nameof-day">Sunday</h4>
                                  <div className="row hours-cont">
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <label className="col-form-label">
                                          From
                                        </label>
                                        <div className="form-icon">
                                          <input
                                            type="text"
                                            className="form-control timepicker"
                                            placeholder="Select Time"
                                          />
                                          <span className="cus-icon">
                                            <i className="feather-clock" />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <label className="col-form-label">
                                          To
                                        </label>
                                        <div className="form-icon">
                                          <input
                                            type="text"
                                            className="form-control timepicker"
                                            placeholder="Select Time"
                                          />
                                          <span className="cus-icon">
                                            <i className="feather-clock" />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group ">
                                        <label className="col-form-label">
                                          Slots
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Slot"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <a href="#" className="add-text add-hours">
                                  <i className="feather-plus-circle" /> Add More
                                </a>
                              </div> */}
                            </div>
                          </div>
                          {/* Timeslot */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="field-bottom-btns">
                          <div className="field-btns">
                            <button
                              className="btn btn-primary prev_btn"
                              type="button"
                              onClick={prevStep}
                            >
                              <i className="fa-solid fa-arrow-left" />
                              Prev
                            </button>
                          </div>
                          <div className="field-btns">
                            <button
                              onClick={nextStep}
                              className="btn btn-primary next_btn"
                              type="button"
                            >
                              Next <i className="fa-solid fa-arrow-right" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {/* /Availability */}
                {/* Location */}
                {currentStep === 3 && (
                  <>
                    <div className="card add-service">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="sub-title">
                            <h6>Location</h6>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="col-form-label">Address</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Your Address"
                              name="address"
                              value={formData.address}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">Country</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Country"
                              name="country"
                              value={formData.country}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">City</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Your City"
                              name="city"
                              value={formData.city}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">State</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Your State"
                              name="state"
                              value={formData.state}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">Pincode</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Your Pincode"
                              name="pincode"
                              value={formData.pincode}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="col-form-label">
                              Google Maps Place ID
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Maps Place ID"
                              name="googleMapPlaceId"
                              value={formData.googleMapPlaceId}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">Latitude</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Latitude Number"
                              name="latitude"
                              value={formData.latitude}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">Longitude</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Longitude Number"
                              name="longitude"
                              value={formData.longitude}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="form-group mb-0">
                          <div className="map-grid">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6509170.989457427!2d-123.80081967108484!3d37.192957227641294!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb9fe5f285e3d%3A0x8b5109a227086f55!2sCalifornia%2C%20USA!5e0!3m2!1sen!2sin!4v1669181581381!5m2!1sen!2sin"
                              allowFullScreen
                              aria-hidden="false"
                              tabIndex={0}
                              className="contact-map"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="field-bottom-btns">
                          <div className="field-btns">
                            <button
                              className="btn btn-primary prev_btn"
                              type="button"
                              onClick={prevStep}
                            >
                              <i className="fa-solid fa-arrow-left" />
                              Prev
                            </button>
                          </div>
                          <div className="field-btns">
                            <button
                              onClick={nextStep}
                              className="btn btn-primary next_btn"
                              type="button"
                            >
                              Next <i className="fa-solid fa-arrow-right" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {/* /Location */}
                {/* Gallery */}
                {currentStep === 4 && (
                  <>
                    <div className="card add-service">
                      <div className="sub-title">
                        <h6>Gallery</h6>
                      </div>
                      <div className="file-upload">
                        <img
                          src="assets/img/icons/upload-icon.svg"
                          alt="image"
                        />
                        <h6>
                          Drag &amp; drop files or <span>Browse</span>
                        </h6>
                        <p>Supported formates: JPEG, PNG</p>
                        <input
                          type="file"
                          onChange={handleFileInputChange}
                          accept="video/image"
                        />
                      </div>
                      <div className="file-preview">
                        <label>Select Default Image</label>
                        <ul className="gallery-selected-img">
                          {formData?.gallery.map((image, key) => (
                            <li>
                              <div className="img-preview">
                                <img
                                  src="assets/img/services/service-01.jpg"
                                  alt="Service Image"
                                />
                                <a
                                  href="javascript:void(0);"
                                  className="remove-gallery"
                                >
                                  <i className="feather-trash-2" />
                                </a>
                              </div>
                              <label className="custom_check">
                                <input
                                  type="radio"
                                  name="gallery"
                                  defaultChecked
                                />
                                <span className="checkmark" />
                              </label>
                            </li>
                          ))}

                          {/* <li>
                            <div className="img-preview">
                              <img
                                src="assets/img/services/service-02.jpg"
                                alt="Service"
                              />
                              <a
                                href="javascript:void(0);"
                                className="remove-gallery"
                              >
                                <i className="feather-trash-2" />
                              </a>
                            </div>
                            <label className="custom_check">
                              <input type="radio" name="gallery" />
                              <span className="checkmark" />
                            </label>
                          </li>
                          <li>
                            <div className="img-preview">
                              <img
                                src="assets/img/services/service-03.jpg"
                                alt="Service Image"
                              />
                              <a
                                href="javascript:void(0);"
                                className="remove-gallery"
                              >
                                <i className="feather-trash-2" />
                              </a>
                            </div>
                            <label className="custom_check">
                              <input type="radio" name="gallery" />
                              <span className="checkmark" />
                            </label>
                          </li>
                          <li>
                            <div className="img-preview">
                              <img
                                src="assets/img/services/service-04.jpg"
                                className="img-fluid thumbnail"
                                alt="image"
                              />
                              <a
                                href="javascript:void(0);"
                                className="remove-gallery"
                              >
                                <i className="feather-trash-2" />
                              </a>
                            </div>
                            <label className="custom_check">
                              <input type="radio" name="gallery" />
                              <span className="checkmark" />
                            </label>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                    <div className="field-bottom-btns">
                      <div className="field-btns">
                        <button
                          className="btn btn-primary prev_btn"
                          type="button"
                          onClick={prevStep}
                        >
                          <i className="fa-solid fa-arrow-left" />
                          Prev
                        </button>
                      </div>
                      <div className="field-btns">
                        <button
                          className="btn btn-primary next_btn"
                          type="button"
                          onClick={nextStep}
                        >
                          Next <i className="fa-solid fa-arrow-right" />
                        </button>
                      </div>
                    </div>
                  </>
                )}

                {/* /Gallery */}
                {/* Seo */}
                {currentStep === 5 && (
                  <>
                    <div className="card add-service">
                      <div className="sub-title">
                        <h6>SEO</h6>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="col-form-label">Meta Title</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="col-form-label">
                              Meta Keywords
                            </label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group mb-0">
                            <label className="col-form-label">
                              Meta Description
                            </label>
                            <textarea
                              className="form-control"
                              rows={5}
                              defaultValue={""}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="field-bottom-btns">
                      <div className="field-btns">
                        <button
                          className="btn btn-primary prev_btn"
                          type="button"
                          onClick={prevStep}
                        >
                          <i className="fa-solid fa-arrow-left" />
                          Prev
                        </button>
                      </div>
                      <div className="field-btns">
                        <a
                          href="#"
                          className="btn btn-primary done_btn"
                          data-bs-toggle="modal"
                          data-bs-target="#successmodal"
                        >
                          Next
                          <i className="fa-solid fa-arrow-right" />
                        </a>
                      </div>
                    </div>
                  </>
                )}

                {/* /Seo */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateServiceProcess;
