import React from 'react'

const PhoneVerification = () => {
  return (
    <>
      <div class='linked-item'>
        <div class='row'>
          <div class='col-md-8'>
            <div class='linked-acc' id='phone-veri'>
              <span class='link-icon'>
                <img src='/assets/img/icons/phone-call.svg' alt='Icons' />
              </span>
              <div class='linked-info'>
                <h6>
                  <a href='javascript:void(0);'>Phone Number Verification</a>
                </h6>
                <p>Not Yet Verified </p>
              </div>
            </div>
          </div>
          <div class='col-md-4 text-md-end'>
            <div class='verfification-modal-block'>
              <a
                href='javascript:void(0);'
                class='link-close'
                data-bs-toggle='tooltip'
                data-bs-placement='left'
                data-bs-title='Not Verified'
              >
                <i class='fa-solid fa-circle-xmark'></i>
              </a>
              <a
                href='javascript:void(0);'
                class='btn btn-primary btn-connect'
                data-bs-toggle='modal'
                data-bs-target='#change-phone'
              >
                Change
              </a>
              <a href='javascript:void(0);' class='btn-acc'>
                Remove
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Change Phone  --> */}
      <div class='modal fade custom-modal verify-modal' id='change-phone'>
        <div class='modal-dialog modal-dialog-centered'>
          <div class='modal-content doctor-profile'>
            <div class='modal-header verfication-modal-head'>
              <h5 class='modal-title'>Verify Your Phone Number</h5>
              <p>You will receive a 4 digit code to verify next</p>
            </div>
            <div class='modal-body'>
              <form action='https://html.truelysell.com/template3/provider-security-settings.html'>
                <div class='wallet-add'>
                  <div class='form-group'>
                    <input
                      class='form-control'
                      name='phone'
                      type='text'
                      placeholder='Enter Phone Number'
                    />
                  </div>
                  <div class='modal-submit'>
                    <a
                      href='#'
                      class='btn btn-primary w-100'
                      data-bs-toggle='modal'
                      data-bs-target='#otp'
                    >
                      Change Number
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /Change Phone  --> */}
      {/* <!-- OTP  --> */}
      <div class='modal fade custom-modal verify-modal' id='otp'>
        <div class='modal-dialog modal-dialog-centered'>
          <div class='modal-content doctor-profile'>
            <div class='modal-header verfication-modal-head'>
              <h5 class='modal-title'>Enter OTP</h5>
              <p>Code is sent to +1 23456789</p>
            </div>
            <div class='modal-body'>
              <form
                action='https://html.truelysell.com/template3/provider-security-settings.html'
                class='digit-group'
              >
                <div class='wallet-add'>
                  <div class='otp-box'>
                    <div class='forms-block text-center'>
                      <input
                        type='text'
                        id='digit-1'
                        name='digit-1'
                        data-next='digit-2'
                        maxlength='1'
                        value='1'
                      />
                      <input
                        type='text'
                        id='digit-2'
                        name='digit-2'
                        data-next='digit-3'
                        data-previous='digit-1'
                        maxlength='1'
                        value='2'
                      />
                      <input
                        type='text'
                        id='digit-3'
                        name='digit-3'
                        data-next='digit-4'
                        data-previous='digit-2'
                        maxlength='1'
                        value='3'
                      />
                      <input
                        type='text'
                        id='digit-4'
                        name='digit-4'
                        data-next='digit-5'
                        data-previous='digit-3'
                        maxlength='1'
                        value='4'
                      />
                    </div>
                  </div>
                  <div class='otp-timer text-center'>
                    <h6>00:29</h6>
                  </div>
                  <div class='resend-code-otp text-center'>
                    <p>
                      Don’t <span> receive </span> code?{' '}
                      <a href='#'> Resend Code </a>
                    </p>
                  </div>
                  <div class='modal-submit'>
                    <a
                      href='#'
                      class='btn btn-primary w-100'
                      data-bs-toggle='modal'
                      data-bs-target='#success'
                    >
                      Verify
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- OTP  --> */}

      {/* <!-- Success phone --> */}
      <div class='modal fade success-modal verify-modal' id='success'>
        <div class='modal-dialog modal-dialog-centered'>
          <div class='modal-content'>
            <div class='modal-body success-body text-center'>
              <span>
                <i class='fa-regular fa-circle-check'></i>
              </span>
              <h3>Success</h3>
              <p class='success-modal-body'>
                Your phone number has been successfully verified
              </p>
            </div>
            <div class='popup-btn text-center'>
              <a
                href='#'
                class='btn btn-primary verify-phone'
                onclick='verifiePhone()'
              >
                Go to Dashboard
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Success  --> */}
    </>
  )
}

export default PhoneVerification
