export const plans = {
  user: {
    NGN: {
      monthly: [
        {
          subscription: "Free Trial",
          description: "Standard Plan Trial",
          price: "0",
          value: 0,
          duration: "14 days",
          popular: false,
          features: [
            "Access to basic services (e.g., booking technicians, diagnostics)",
            "Standard booking priority",
            "Limited discounts on services (e.g., 5%)",
            "Service reminders (e.g., oil changes, tire rotations)",
            "Option to upgrade to a paid plan at any time",
          ],
        },
        {
          subscription: "Standard",
          description: "Affordable User Plan",
          price: "3000",
          value: 3000,
          duration: "month",
          popular: false,
          features: [
            "Priority booking for technicians",
            "10% discounts on all services",
            "Free diagnostic service once a year",
            "Notifications for service reminders and exclusive offers",
          ],
        },
        {
          subscription: "Pro",
          description: "Mid-range User Plan",
          price: "5000",
          value: 5000,
          duration: "month",
          popular: true,
          features: [
            "All Standard Plan features",
            "Free pickup/drop-off for up to 2 services annually",
            "Roadside assistance (e.g., jumpstarts, towing, tire changes)",
            "Access to top-rated technicians",
            "Enhanced discounts (e.g., 15%)",
          ],
        },
        {
          subscription: "Enterprise",
          description: "Ideal for Businesses or Families",
          price: "15000",
          value: 15000,
          duration: "month",
          popular: false,
          features: [
            "Covers up to 5 cars or family members",
            "All Premium Plan features",
            "Dedicated service manager",
            "Discounts on bulk service bookings",
            "Free annual detailing for all vehicles",
          ],
        },
        {
          subscription: "Lifetime",
          description: "One-time Fee Plan",
          price: "200000",
          value: 200000,
          duration: "lifetime",
          popular: false,
          features: [
            "All Enterprise Plan features for life",
            "Unlimited free checkups",
            "Exclusive lifetime discounts (e.g., 20%)",
            "VIP booking concierge",
          ],
        },
      ],
      yearly: [
        {
          subscription: "Free Trial",
          description: "Standard Plan Trial",
          price: "0",
          value: 0,
          duration: "14 days",
          popular: false,
          features: [
            "Access to basic services (e.g., booking technicians, diagnostics)",
            "Standard booking priority",
            "Limited discounts on services (e.g., 5%)",
            "Service reminders (e.g., oil changes, tire rotations)",
            "Option to upgrade to a paid plan at any time",
          ],
        },
        {
          subscription: "Standard",
          description: "Affordable User Plan",
          price: "2800",
          value: 2800 * 12,
          duration: "year",
          popular: false,
          features: [
            "Priority booking for technicians",
            "10% discounts on all services",
            "Free diagnostic service once a year",
            "Notifications for service reminders and exclusive offers",
          ],
        },
        {
          subscription: "Pro",
          description: "Mid-range User Plan",
          price: "4600",
          value: 4600 * 12,
          duration: "year",
          popular: true,
          features: [
            "All Standard Plan features",
            "Free pickup/drop-off for up to 2 services annually",
            "Roadside assistance (e.g., jumpstarts, towing, tire changes)",
            "Access to top-rated technicians",
            "Enhanced discounts (e.g., 15%)",
          ],
        },
        {
          subscription: "Enterprise",
          description: "Ideal for Businesses or Families",
          price: "19000",
          value: 19000 * 12,
          duration: "year",
          popular: false,
          features: [
            "Covers up to 5 cars or family members",
            "All Premium Plan features",
            "Dedicated service manager",
            "Discounts on bulk service bookings",
            "Free annual detailing for all vehicles",
          ],
        },
        {
          subscription: "Lifetime",
          description: "One-time Fee Plan",
          price: "200000",
          value: 200000,
          duration: "lifetime",
          popular: false,
          features: [
            "All Enterprise Plan features for life",
            "Unlimited free checkups",
            "Exclusive lifetime discounts (e.g., 20%)",
            "VIP booking concierge",
          ],
        },
      ],
    },
    USD: {
      monthly: [
        {
          subscription: "Free Trial",
          description: "Standard Plan Trial",
          price: "0",
          value: 0,
          duration: "14 days",
          popular: false,
          features: [
            "Access to basic services (e.g., booking technicians, diagnostics)",
            "Standard booking priority",
            "Limited discounts on services (e.g., 5%)",
            "Service reminders (e.g., oil changes, tire rotations)",
            "Option to upgrade to a paid plan at any time",
          ],
        },
        {
          subscription: "Standard",
          description: "Affordable User Plan",
          price: "15",
          value: 15,
          duration: "month",
          popular: false,
          features: [
            "Priority booking for technicians",
            "10% discounts on all services",
            "Free diagnostic service once a year",
            "Notifications for service reminders and exclusive offers",
          ],
        },
        {
          subscription: "Pro",
          description: "Mid-range User Plan",
          price: "25",
          value: 25,
          duration: "month",
          popular: true,
          features: [
            "All Standard Plan features",
            "Free pickup/drop-off for up to 2 services annually",
            "Roadside assistance (e.g., jumpstarts, towing, tire changes)",
            "Access to top-rated technicians",
            "Enhanced discounts (e.g., 15%)",
          ],
        },
        {
          subscription: "Enterprise",
          description: "Ideal for Businesses or Families",
          price: "75",
          value: 75,
          duration: "month",
          popular: false,
          features: [
            "Covers up to 5 cars or family members",
            "All Premium Plan features",
            "Dedicated service manager",
            "Discounts on bulk service bookings",
            "Free annual detailing for all vehicles",
          ],
        },
        {
          subscription: "Lifetime",
          description: "One-time Fee Plan",
          price: "3000",
          value: 3000,
          duration: "lifetime",
          popular: false,
          features: [
            "All Enterprise Plan features for life",
            "Unlimited free checkups",
            "Exclusive lifetime discounts (e.g., 20%)",
            "VIP booking concierge",
          ],
        },
      ],
      yearly: [
        {
          subscription: "Free Trial",
          description: "Standard Plan Trial",
          price: "0",
          value: 0,
          duration: "14 days",
          popular: false,
          features: [
            "Access to basic services (e.g., booking technicians, diagnostics)",
            "Standard booking priority",
            "Limited discounts on services (e.g., 5%)",
            "Service reminders (e.g., oil changes, tire rotations)",
            "Option to upgrade to a paid plan at any time",
          ],
        },
        {
          subscription: "Standard",
          description: "Affordable User Plan",
          price: "13.5",
          value: 13.5 * 12,
          duration: "year",
          popular: false,
          features: [
            "Priority booking for technicians",
            "10% discounts on all services",
            "Free diagnostic service once a year",
            "Notifications for service reminders and exclusive offers",
          ],
        },
        {
          subscription: "Pro",
          description: "Mid-range User Plan",
          price: "23",
          value: 23 * 12,
          duration: "year",
          popular: true,
          features: [
            "All Standard Plan features",
            "Free pickup/drop-off for up to 2 services annually",
            "Roadside assistance (e.g., jumpstarts, towing, tire changes)",
            "Access to top-rated technicians",
            "Enhanced discounts (e.g., 15%)",
          ],
        },
        {
          subscription: "Enterprise",
          description: "Ideal for Businesses or Families",
          price: "90",
          value: 90 * 12,
          duration: "year",
          popular: false,
          features: [
            "Covers up to 5 cars or family members",
            "All Premium Plan features",
            "Dedicated service manager",
            "Discounts on bulk service bookings",
            "Free annual detailing for all vehicles",
          ],
        },
        {
          subscription: "Lifetime",
          description: "One-time Fee Plan",
          price: "3000",
          value: 3000,
          duration: "lifetime",
          popular: false,
          features: [
            "All Enterprise Plan features for life",
            "Unlimited free checkups",
            "Exclusive lifetime discounts (e.g., 20%)",
            "VIP booking concierge",
          ],
        },
      ],
    },
  },

  technician: {
    USD: {
      monthly: [
        {
          subscription: "Free Trial",
          description: "Standard Plan Trial",
          price: "0",
          value: 0,
          duration: "14 days",
          popular: false,
          features: [
            "Access to basic job listings",
            "Profile visibility in standard search results",
            "Standard transaction fees",
          ],
        },
        {
          subscription: "Standard",
          description: "Affordable Technician Plan",
          price: "15",
          value: 15,
          duration: "month",
          popular: false,
          features: [
            "Access to more job listings",
            "Improved profile visibility",
            "Reduced transaction fees",
            "Basic analytics (e.g., job acceptance rates)",
          ],
        },
        {
          subscription: "Business",
          description: "Mid-range Technician Plan",
          price: "20",
          value: 20,
          duration: "month",
          popular: true,
          features: [
            "All Standard Plan features",
            "Highlighted profile in search results",
            "Priority access to high-value jobs",
            "Advanced analytics (e.g., performance trends)",
            "Customer reviews management",
          ],
        },
        {
          subscription: "Business Pro",
          description: "Suitable for Teams or Businesses",
          price: "30",
          value: 30,
          duration: "month",
          popular: false,
          features: [
            "All Premium Plan features",
            "Dedicated account manager",
            "Team management tools for multiple technicians",
            "Marketing support (e.g., featured profiles)",
            "Lowest transaction fees",
          ],
        },
        {
          subscription: "Enterprise",
          description: "Looking for more? Contact us for a quote.",
          price: "400000",
          duration: "lifetime",
          popular: false,
          features: [
            "All Enterprise Plan features for life",
            "Unlimited access to platform tools",
            "Exclusive access to high-value clients",
            "Custom branding support (e.g., logos on profiles)",
          ],
        },
        {
          subscription: "Lifetime",
          description: "One-time Fee Plan",
          price: "3500",
          value: 3500,
          duration: "lifetime",
          popular: false,
          features: [
            "All Enterprise Plan features for life",
            "Unlimited free checkups",
            "Exclusive lifetime discounts (e.g., 20%)",
            "VIP booking concierge",
          ],
        },
      ],
      yearly:  [
        {
          subscription: "Free Trial",
          description: "Standard Plan Trial",
          price: "0",
          value: 0 * 12,
          duration: "14 days",
          popular: false,
          features: [
            "Access to basic job listings",
            "Profile visibility in standard search results",
            "Standard transaction fees",
          ],
        },
        {
          subscription: "Standard",
          description: "Affordable Technician Plan",
          price: "13.5",
          value: 13.5 * 12,
          duration: "month",
          popular: false,
          features: [
            "Access to more job listings",
            "Improved profile visibility",
            "Reduced transaction fees",
            "Basic analytics (e.g., job acceptance rates)",
          ],
        },
        {
          subscription: "Business",
          description: "Mid-range Technician Plan",
          price: "18",
          value: 18 * 12,
          duration: "month",
          popular: true,
          features: [
            "All Standard Plan features",
            "Highlighted profile in search results",
            "Priority access to high-value jobs",
            "Advanced analytics (e.g., performance trends)",
            "Customer reviews management",
          ],
        },
        {
          subscription: "Business Pro",
          description: "Suitable for Teams or Businesses",
          price: "27",
          value: 27 * 12,
          duration: "month",
          popular: false,
          features: [
            "All Premium Plan features",
            "Dedicated account manager",
            "Team management tools for multiple technicians",
            "Marketing support (e.g., featured profiles)",
            "Lowest transaction fees",
          ],
        },
        {
          subscription: "Enterprise",
          description: "Looking for more? Contact us for a quote.",
          price: "400000",
          duration: "lifetime",
          popular: false,
          features: [
            "All Enterprise Plan features for life",
            "Unlimited access to platform tools",
            "Exclusive access to high-value clients",
            "Custom branding support (e.g., logos on profiles)",
          ],
        },
        {
          subscription: "Lifetime",
          description: "One-time Fee Plan",
          price: "3500",
          value: 3500,
          duration: "lifetime",
          popular: false,
          features: [
            "All Enterprise Plan features for life",
            "Unlimited free checkups",
            "Exclusive lifetime discounts (e.g., 20%)",
            "VIP booking concierge",
          ],
        },
      ],
    },
    NGN: {
      monthly: [
        {
          subscription: "Free Trial",
          description: "Standard Plan Trial",
          price: "0",
          duration: "14 days",
          popular: false,
          features: [
            "Access to basic job listings",
            "Profile visibility in standard search results",
            "Standard transaction fees",
          ],
        },
        {
          subscription: "Standard",
          description: "Affordable Technician Plan",
          price: "3000",
          value: 3000,
          duration: "month",
          popular: false,
          features: [
            "Access to more job listings",
            "Improved profile visibility",
            "Reduced transaction fees",
            "Basic analytics (e.g., job acceptance rates)",
          ],
        },
        {
          subscription: "Business",
          description: "Mid-range Technician Plan",
          price: "5000",
          value: 5000,
          duration: "month",
          popular: true,
          features: [
            "All Standard Plan features",
            "Highlighted profile in search results",
            "Priority access to high-value jobs",
            "Advanced analytics (e.g., performance trends)",
            "Customer reviews management",
          ],
        },
        {
          subscription: "Business Pro",
          description: "Suitable for Teams or Businesses",
          price: "15000",
          value: 15000,
          duration: "month",
          popular: false,
          features: [
            "All Premium Plan features",
            "Dedicated account manager",
            "Team management tools for multiple technicians",
            "Marketing support (e.g., featured profiles)",
            "Lowest transaction fees",
          ],
        },
        {
          subscription: "Enterprise",
          description: "Looking for more? Contact us for a quote.",
          price: "200000",
          duration: "lifetime",
          popular: false,
          features: [
            "All Enterprise Plan features for life",
            "Unlimited access to platform tools",
            "Exclusive access to high-value clients",
            "Custom branding support (e.g., logos on profiles)",
          ],
        },
        {
          subscription: "Lifetime",
          description: "One-time Fee Plan",
          price: "201000",
          value: 201000,
          duration: "lifetime",
          popular: false,
          features: [
            "All Enterprise Plan features for life",
            "Unlimited free checkups",
            "Exclusive lifetime discounts (e.g., 20%)",
            "VIP booking concierge",
          ],
        },
      ],
      yearly:  [
        {
          subscription: "Free Trial",
          description: "Standard Plan Trial",
          price: "0",
          duration: "14 days",
          popular: false,
          features: [
            "Access to basic job listings",
            "Profile visibility in standard search results",
            "Standard transaction fees",
          ],
        },
        {
          subscription: "Standard",
          description: "Affordable Technician Plan",
          price: "2800",
          value: 2800 * 12,
          duration: "month",
          popular: false,
          features: [
            "Access to more job listings",
            "Improved profile visibility",
            "Reduced transaction fees",
            "Basic analytics (e.g., job acceptance rates)",
          ],
        },
        {
          subscription: "Business",
          description: "Mid-range Technician Plan",
          price: "4600",
          value: 4600 * 12,
          duration: "month",
          popular: true,
          features: [
            "All Standard Plan features",
            "Highlighted profile in search results",
            "Priority access to high-value jobs",
            "Advanced analytics (e.g., performance trends)",
            "Customer reviews management",
          ],
        },
        {
          subscription: "Business Pro",
          description: "Suitable for Teams or Businesses",
          price: "14500",
          value: 14500 * 12,
          duration: "month",
          popular: false,
          features: [
            "All Premium Plan features",
            "Dedicated account manager",
            "Team management tools for multiple technicians",
            "Marketing support (e.g., featured profiles)",
            "Lowest transaction fees",
          ],
        },
        {
          subscription: "Enterprise",
          description: "Looking for more? Contact us for a quote.",
          price: "200000",
          duration: "lifetime",
          popular: false,
          features: [
            "All Enterprise Plan features for life",
            "Unlimited access to platform tools",
            "Exclusive access to high-value clients",
            "Custom branding support (e.g., logos on profiles)",
          ],
        },
        {
          subscription: "Lifetime",
          description: "One-time Fee Plan",
          price: "201000",
          value: 201000,
          duration: "lifetime",
          popular: false,
          features: [
            "All Enterprise Plan features for life",
            "Unlimited free checkups",
            "Exclusive lifetime discounts (e.g., 20%)",
            "VIP booking concierge",
          ],
        },
      ],
    },
  },
};
