import React from 'react'

const CustomersFavorites = () => {
  return (
    <>
      {/* Start Sort  */}
      <div class='row align-items-center'>
        <div class='col-sm-6'>
          <div class='widget-title'>
            <h4>Favorites</h4>
          </div>
        </div>

        <div class='col-sm-6 d-flex align-items-center justify-content-end'>
          <div class='review-sort me-2'>
            <p>Sort</p>
            <select class='select'>
              <option>A - Z</option>
              <option>Most Helpful</option>
            </select>
          </div>
          <div class='grid-listview'>
            <ul>
              <li>
                <a href='javascript:void(0);'>
                  <i class='feather-filter'></i>
                </a>
              </li>
              <li>
                <a href='customer-favourite.html' class='active'>
                  <i class='feather-grid'></i>
                </a>
              </li>
              <li>
                <a href='customer-booking.html'>
                  <i class='feather-list'></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* End Sort */}
    </>
  )
}

export default CustomersFavorites
