import React from 'react'

const ActivityChoose = () => {
  return (
    <div>
      <section className='journey-nine-section'>
        <div className='container'>
          <div className='row justify-content-between'>
            <div className='col-lg-4'>
              <div
                className='section-heading section-heading-nine journey-heading-nine aos'
                data-aos='fade-up'
              >
                <p>Activity</p>
                <h2>It’s Our journey</h2>
              </div>
            </div>
            <div className='col-md-2 col-sm-6 d-flex justify-content-center align-items-center'>
              <div className='journey-client-all aos' data-aos='fade-up'>
                <div className='journey-client-main'>
                  <div className='journey-client-counter'>
                    <h2 className='counter'>644</h2>
                    <span>+</span>
                  </div>
                  <h5>Active Clients</h5>
                </div>
              </div>
            </div>
            <div className='col-md-2 col-sm-6 d-flex justify-content-center align-items-center'>
              <div className='journey-client-all aos' data-aos='fade-up'>
                <div className='journey-client-main'>
                  <div className='journey-client-counter'>
                    <h2 className='counter'>290</h2>
                    <span>+</span>
                  </div>
                  <h5>Expert Mechanics</h5>
                </div>
              </div>
            </div>
            <div className='col-md-2 col-sm-6 d-flex justify-content-center align-items-center'>
              <div className='journey-client-all aos' data-aos='fade-up'>
                <div className='journey-client-main'>
                  <div className='journey-client-counter'>
                    <h2 className='counter'>100</h2>
                    <span>+</span>
                  </div>
                  <h5>Total Rewards</h5>
                </div>
              </div>
            </div>
            <div className='col-md-2 col-sm-6 d-flex justify-content-center align-items-center'>
              <div className='journey-client-all aos' data-aos='fade-up'>
                <div className='journey-client-main'>
                  <div className='journey-client-counter'>
                    <h2 className='counter'>24</h2>
                    <span>+</span>
                  </div>
                  <h5>Years Reputation</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Why Choose Us */}
      <section className='choose-nine-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 text-center'>
              <div
                className='section-heading section-heading-nine aos'
                data-aos='fade-up'
              >
                <p>TRUSTED &amp; QUALITY SERVICE</p>
                <h2>Why Choose Airdrops?</h2>
              </div>
            </div>
          </div>
          <div className='row justify-content-center aos' data-aos='fade-up'>
            <div className='col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12'>
              <div className='reasonable-all'>
                <img src='assets/img/icons/choose-icon-4.svg' alt='imag' />
                <h5>Reasonable Price</h5>
                <p>Affordable solutions tailored to meet your budget needs.</p>
              </div>
            </div>
            <div className='col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12'>
              <div className='reasonable-all expert-all'>
                <img src='assets/img/icons/choose-icon-3.svg' alt='imag' />
                <h5>Expert Technicians</h5>
                <p>Skilled hands ensuring top performance for every ride</p>
              </div>
            </div>
            <div className='col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12'>
              <div className='reasonable-all delivery-all'>
                <img src='assets/img/icons/choose-icon-2.svg' alt='imag' />
                <h5>fast feature</h5>
                <p>
                  Experience unmatched speed and reliability on every journey.
                </p>
              </div>
            </div>
            <div className='col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12'>
              <div className='reasonable-all workshop-all'>
                <img src='assets/img/icons/choose-icon-1.svg' alt='imag' />

                <h5>Awarded Workshop</h5>
                <p>Recognized for excellence in transformation</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Why Choose Us? */}
    </div>
  )
}

export default ActivityChoose
