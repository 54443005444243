import React from "react";
import PricePlans from "../components/home/pricing";
import { useParams } from "react-router-dom";

const Pricing = () => {
  const {id} = useParams();

  console.log(id)
  return (
    <div>
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title footer-a">Pricing</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a className="footer-a" href="/">
                      Home
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item active footer-a"
                    aria-current="page"
                  >
                    Subscription Package
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div></div>
      <PricePlans planType={id} />
    </div>
  );
};

export default Pricing;
