import React from 'react'
import LoginRegisterNav from '../common/nav/login-register-nav'
import ForgotPasswordComp from '../components/register/forgot-password'

const ForgotPassword = () => {
  return (
    <div className="main-wrapper">
      <LoginRegisterNav />
      <ForgotPasswordComp />
    </div>
  )
}

export default ForgotPassword