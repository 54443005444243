import React, { useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import axios from "axios";
import { MultiSelect } from "react-multi-select-component";
import PhoneInput from "react-phone-number-input";
import {
  isStrongPassword,
  isValidEmail,
} from "../validations/registration-validation";
import { Api_Url } from "../../api_calls/url";
import { useDispatch, useSelector } from "react-redux";
import { GetAllCategory } from "../../manage_states/category/category_services_syn";
import { automotiveSpecialization } from "../../datas/signupData";

const ProviderSignup = () => {
  const [completedStepOne, setCompletedStepOne] = useState(false);
  const [completedStepTwo, setCompletedStepTwo] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [selectedVehicleType, setSelectedVehicleType] = useState([])
  const [showPassword, setShowPassword] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Error Occured");
  const [carBrands, setCarBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [creatingAccount, setCreatingAccount] = useState(false);
  const [msg, setMsg] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [value, setValue] = useState();
  const [formValues, setFormValues] = useState({
    email: "",
    name: "",
    password: "",
    user_type: "provider",
    brand_transmission: "",
    engineer: "",
  });

  const dispatch = useDispatch();

  const { completeCategory, category_loader, categoryDeleteLoader } =
    useSelector((state) => state.category);

  useEffect(() => {
    dispatch(GetAllCategory());

    return () => {};
  }, []);

  console.log("complete category", completeCategory);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "email") {
      const validateEmail = !isValidEmail(value)
        ? setMsg("Please enter a valid email")
        : value;
    } else {
      setMsg("");
    }
    if (name === "password") {
      const passwordValidationResult = isStrongPassword(value);
      passwordValidationResult !== true
        ? setMsg("password must consist of uppercase and number")
        : setMsg("");
    }
    console.log("user data", formValues);
  };

  const getCars = async () => {
    try {
      const response = await axios.get(
        "https://public.opendatasoft.com/api/explore/v2.1/catalog/datasets/all-vehicles-model/records?limit=100"
      );
      console.log(response.data, "created cars");
      const valueFormat = response?.data?.results?.map((item) => {
        const data = { value: item.make, label: item.make };
        return data;
      });
      console.log(valueFormat, "value format");
      setCarBrands(response.data);
      setCarBrands(valueFormat);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCars();
    const valueFormat = completeCategory.map((item) => {
      const data = {
        value: item.name,
        label: item.name,
        subCat: item.sub_category,
      };
      return data;
    });
    setCategories(valueFormat);
  }, [completeCategory]);

  const vehicleType = [
    {
      value: "⁠Passenger vehicles (Cars, SUVs, vans, and buses.)",
      label: "⁠Passenger vehicles (Cars, SUVs, vans, and buses.)",
    },
    {
      value: "Commercial vehicles (Trucks, lorries, and other vehicles used for business or transportation of goods.)",
      label:  "Commercial vehicles (Trucks, lorries, and other vehicles used for business or transportation of goods.)",
    },
    {
      value: "Two-wheelers",
      label:  "Two-wheelers (Motorcycles, scooters, and bicycles.)",
    },
    {
      value: "⁠Electric vehicles",
      label:  "⁠Electric vehicles (Cars, buses, and other vehicles powered by electricity.)",
    },
    {
      value: "Heavy vehicles",
      label:  "Heavy vehicles (Construction equipment, agricultural machinery, and other large vehicles.)",
    },
    {
      value: "Recreational vehicles",
      label:  "Recreational vehicles: (RVs, campers, and other vehicles used for leisure activities.)",
    },
    {
      value: "⁠Military vehicles",
      label:  "⁠Military vehicles: (Tanks, armored cars, and other vehicles used for military purposes.)",
    },
    {
      value: "Specialized vehicles",
      label:  "Specialized vehicles: Ambulances, fire trucks, and other vehicles designed for specific tasks.",
    },
  ];

  // useEffect(() => {
  //   setSubCategory([...subCategory, selected.subCat])
  // },[selected])

  const toogleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const continueToStepTwo = () => {
    if (
      formValues.name.length >= 5 &&
      formValues.email.length >= 5 &&
      formValues.password.length >= 8 &&
      value.length >= 9
    ) {
      setCompletedStepOne(true);
    } else {
      alert("All fields are required");
    }
  };

  const continueToStepThree = async (e) => {
    e.preventDefault();
    try {
      setCreatingAccount(true);
      const brands = selected.map((item) => item.value);
      const registrationValues = {
        ...formValues,
        brand: brands,
        phone_number: value,
      };
      console.log(registrationValues);
      const register = await axios.post(
        `${Api_Url}/api/v1/registration`,
        registrationValues
      );
      console.log(register?.status, register?.data);
      if (register.status === 201) {
        setCreatingAccount(false);
        setCompletedStepTwo(true);
        setErrorState(false);
        setShowModal(true);
        localStorage.setItem("airdrop_user_email", formValues?.email);
      }
    } catch (err) {
      console.log("error", err);
      setCreatingAccount(false);
      setCompletedStepTwo(true);
      setErrorState(true);
      setShowModal(true);

      setErrorMessage(err?.response?.data?.message);
    }
  };

  const backToStepOne = (e) => {
    e.preventDefault();
    setCompletedStepTwo(false);
    setCompletedStepOne(false);
  };

  const finishRegistration = () => {
    try {
      const registrationValues = { ...formValues, brand: [...selected] };
      // const registration =
    } catch (err) {
      console.log(err);
    }
  };

  console.log(selected, "selected");
  return (
    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-lg-6 mx-auto">
            <div class="login-wrap">
              <div class="login-header">
                <h3>Provider Signup</h3>
              </div>
              <div
                className="step"
                style={{
                  // background: "red",
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "3rem 0",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "45%",
                  }}
                >
                  <span
                    style={{
                      padding: "3px 10px",
                      border: "2px solid red",
                      borderRadius: "50%",
                      borderStyle: "solid",
                      cursor: "pointer",
                    }}
                    onClick={
                      completedStepOne === true && completedStepTwo === false
                        ? backToStepOne
                        : () => {
                            alert("registration completed");
                          }
                    }
                  >
                    1
                  </span>
                  <div
                    style={{
                      background: `${
                        completedStepOne === true ? "red" : "gray"
                      }`,
                      height: "2px",
                      width: "100%",
                    }}
                  ></div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "45%",
                  }}
                >
                  <span
                    style={{
                      padding: "3px 10px",
                      border: `${
                        completedStepTwo === true
                          ? "2px solid red"
                          : "2px solid gray"
                      }`,
                      borderRadius: "50%",
                      borderStyle: "dotted",
                    }}
                  >
                    2
                  </span>
                  <div
                    style={{
                      background: `${
                        completedStepTwo === true ? "red" : "gray"
                      }`,
                      height: "2px",
                      width: "100%",
                    }}
                  ></div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "10%",
                  }}
                >
                  <span
                    style={{
                      padding: "3px 10px",
                      border: `${
                        completedStepTwo === true
                          ? "2px solid red"
                          : "2px solid gray"
                      }`,
                      borderRadius: "50%",
                      borderStyle: "dotted",
                    }}
                  >
                    3
                  </span>
                  {/* <div
                    style={{ background: "red", height: "2px", width: "100%" }}
                  ></div> */}
                </div>
              </div>

              {/* <!-- Signup Form --> */}
              <form>
                {completedStepOne === false && (
                  <>
                    <div class="form-group">
                      <label class="col-form-label">Name</label>
                      <input
                        onChange={handleChange}
                        type="text"
                        name="name"
                        value={formValues.name}
                        class="form-control"
                        placeholder="Enter your name"
                      />
                    </div>
                    <div class="form-group">
                      <label class="col-form-label">Email</label>
                      <input
                        type="email"
                        class="form-control"
                        value={formValues.email}
                        placeholder="johndoe@example.com"
                        onChange={handleChange}
                        name="email"
                      />
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <label class="col-form-label">Phone Number</label>
                        <div class="form-group">
                          <PhoneInput
                            placeholder="Enter phone number"
                            value={value}
                            class="form-control form-control-lg group_formcontrol"
                            onChange={setValue}
                          />
                          {/* <input
                            type="text"
                            class="form-control form-control-lg group_formcontrol"
                            id="phone"
                            name="phone_number"
                            value={formValues.phone_number}
                            onChange={handleChange}
                            placeholder="(256) 789-6253"
                          /> */}
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-form-label d-block">
                        Password
                        <span class="brief-bio float-end">
                          Must be 8 Characters at Least
                        </span>
                      </label>
                      <div class="pass-group">
                        <input
                          type={showPassword === true ? "text" : "password"}
                          class="form-control pass-input"
                          placeholder="*************"
                          onChange={handleChange}
                          name="password"
                          value={formValues.password}
                        />
                        <span
                          class={
                            showPassword === true
                              ? "toggle-password feather-eye"
                              : "toggle-password feather-eye-off"
                          }
                          onClick={toogleShowPassword}
                        ></span>
                      </div>
                    </div>
                    <button
                      onClick={continueToStepTwo}
                      class="btn btn-primary w-100 login-btn"
                    >
                      Next
                    </button>
                  </>
                )}
                {completedStepOne === true && completedStepTwo === false && (
                  <>
                   <div class="form-group">
                      <label class="col-form-label">
                      What's your Specialization?
                      </label>
                      <select
                        class="form-control"
                        name="brand_transmission"
                        onChange={handleChange}
                        aria-label="Default select example"
                      >
                        <option selected>
                          what kind of cars do you work on
                        </option>
                        {automotiveSpecialization?.map((item) => (<option value={item.name}>{item.name}</option>))}
                        {/* <option value="manual">manual</option>
                        <option value="automatic">automatic</option>
                        <option value="both">both</option> */}
                      </select>
                    </div>
                    <div class="form-group">
                      <label class="col-form-label">Select Vehicle Type</label>
                      <MultiSelect
                        options={vehicleType}
                        value={selectedVehicleType}
                        onChange={setSelectedVehicleType}
                        labelledBy="Choose the vehicle type you can work on"
                      />
                    </div>
                    <div class="form-group">
                      <label class="col-form-label">Select Vehicle Brand</label>
                      <MultiSelect
                        options={carBrands}
                        value={selectedBrand}
                        onChange={setSelectedBrand}
                        labelledBy="Choose the vehicle type you can work on"
                      />
                    </div>
                    {/* <div class="form-group">
                      <label class="col-form-label">Select Category</label>
                      <MultiSelect
                        options={categories}
                        value={selected}
                        onChange={setSelected}
                        labelledBy="Select"
                      />
                    </div> */}
                   
                    {/* <div class="form-group">
                      <label class="col-form-label">
                        Transmission Selection
                      </label>
                      <select
                        class="form-control"
                        name="brand_transmission"
                        onChange={handleChange}
                        aria-label="Default select example"
                      >
                        <option selected>
                          what kind of cars do you work on
                        </option>
                        <option value="manual">manual</option>
                        <option value="automatic">automatic</option>
                        <option value="both">both</option>
                      </select>
                    </div> */}

                    {/* <div class="form-group">
                      <label class="col-form-label">
                        Skills and Specializations
                      </label>
                      <select
                        class="form-control"
                        name="engineer"
                        onChange={handleChange}
                        aria-label="Default select example"
                      >
                        <option selected>
                          e.g, engine repair, electrical systems, diagnostics
                        </option>
                        <option value="manual">Car Body</option>
                        <option value="automatic">Engine</option>
                        <option value="both">Electricals</option>
                        <option value="others">others</option>
                      </select>
                    </div> */}
                    <button
                      onClick={continueToStepThree}
                      class="btn btn-primary w-100 login-btn"
                    >
                      {creatingAccount === true ? "creating" : "Finish Signup"}
                    </button>
                  </>
                )}
                {showModal && (
                  <>
                    {!errorState && (
                      <>
                        <div class="modal-content">
                          <div class="modal-body success-body text-center">
                            <span style={{ fontSize: "80px", color: "green" }}>
                              <i class="fa-regular fa-circle-check"></i>
                            </span>
                            <h3>Your Account Creation was Successful</h3>
                            <p class="success-modal-body">
                              A one time password has been sent to your email,
                              please check your mail to confirm.
                            </p>
                          </div>
                          <div class="popup-btn text-center">
                            <a
                              href="/email-otp"
                              class="btn btn-primary verify-document"
                            >
                              Proceed to verify OTP
                            </a>
                          </div>
                        </div>
                      </>
                    )}
                    {errorState && (
                      <>
                        <div class="modal-body danger-body text-center">
                          {errorState ? (
                            <img src="assets/img/icons/sad.gif" alt="image" />
                          ) : (
                            <span style={{ fontSize: "80px" }}>
                              <i class="fa-regular fa-circle-check text-green"></i>
                            </span>
                          )}

                          <h3 class={errorState ? "text-danger" : ""}>
                            {errorState ? "Error Occured !!!" : "Login Success"}
                          </h3>
                          <p class="success-modal-body">
                            {errorState
                              ? errorMessage
                              : "Welcome back!  we're here to keep your car in great shape, so you can hit the road worry-free."}
                          </p>
                          <a
                            href="/login"
                            class="btn btn-primary verify-document"
                          >
                            Proceed to Login
                          </a>
                        </div>
                      </>
                    )}
                  </>
                )}

                {/* <button type="submit" class="btn btn-primary w-100 login-btn">
                  Signup
                </button> */}
                <div class="login-or">
                  <span class="or-line"></span>
                  <span class="span-or">Or, log in with your email</span>
                </div>
                <p class="no-acc">
                  Already have an account ? <a href="/login"> Sign In</a>
                </p>
              </form>
              {/* <!-- /Signup Form --> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderSignup;
