import React, { useState } from "react";
import APIs from "../../manage_states/Api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ForgotPasswordComp = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [formValues, setFormValues] = useState({
    password: "",
    confirm: "",
  });
  const navigate = useNavigate();
  const [email, setEmail] = useState("")
  const [creating, setCreating] = useState()
  const [error, setError] = useState('');

  const validateEmail = (email) => {
    // Regular expression for validating email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Validate email on change
    if (!validateEmail(value)) {
      setError('Please enter a valid email address.');
    } else {
      setError('');
    }
  };

  const toogleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  

   const handleSend = async (e) => {
      e.preventDefault();
      if (!validateEmail(email)) {
        setError('Invalid email address.');
        return;
      }
      try {
        setCreating(true);
        const requiredValues = {
          ...formValues,
        };
        console.log(requiredValues, "required values");
        const register = await APIs.post(
          `/request_forgot_pass_otp`,
          {email:email}
        );
        console.log(register.status, register.data);
        if (register.status === 200) {
          localStorage.setItem("airdrop_user_forgot_email", email);
          navigate("/forgot-email-otp");
        }
      } catch (err) {
        console.log("error", err);
        setCreating(false)
        toast(err?.message)
      }
    };
  return (
    <div className="content">
      <div className="container">
        <div className="row">
          {/* Password Recovery */}
          <div className="col-md-6 col-lg-6 mx-auto">
            <div className="login-wrap">
              <div className="login-header">
                <h3>Password Recovery</h3>
                <p>
                  Enter your email and we will send you a link to reset your
                  password.
                </p>
              </div>
              <>
                <div className="log-form">
                  <div className="form-group">
                    <label className="col-form-label">Email</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="johndoe@example.com"
                      onChange={handleChange}
                    />
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                  </div>
                </div>
                <button
                  className="btn btn-primary w-100 login-btn"
                  onClick={handleSend}
                >
                 {creating ? "sending" : "Submit"}
                </button>
                <p className="no-acc mt-0">
                  Remember Password ? <a href="/login">Login</a>
                </p>
              </>
            </div>
          </div>
          {/* /Password Recovery */}
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordComp;
