import { MenuItem, MenuList } from '@mui/material';
import React, { forwardRef, useState } from 'react';
import { BsCheckLg } from 'react-icons/bs';

const SearchableDropDownItem = forwardRef(
  (
    {
      open,
      closeMenu,
      List,
      HandleSelect,
      handleClose,
      country,
      query,
      searchDetails,
    },
    ref
  ) => {
    return (
      <>
        {/* <div style={{width: "100%"}}> */}
        {open && (
          <div ref={ref}>
            <div id="basic-menu" className="dropdownItem" onClick={closeMenu}>
              {query !== '' && query !== null
                ? searchDetails?.map((item, index) => (
                    <>
                      {item?.name !== 'Worldwide' && (
                        <div
                          className="ItemAlign"
                          key={index}
                          onClick={() => {
                            HandleSelect(item);
                          }}
                        >
                          <div className="textContainer">
                            <p className={'text'}>{item?.name}</p>
                          </div>
                          {item.name === country.name && (
                            <div className="dropDownIconBody">
                              <BsCheckLg className="dropDownIcon" />
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  ))
                : List?.length !== 0
                ? List?.map((item, index) => {
                    return (
                      <>
                        {item?.name !== 'Worldwide' && (
                          <div
                            className="ItemAlign"
                            key={index}
                            onClick={() => {
                              HandleSelect(item);
                            }}
                          >
                            <div className="textContainer">
                              <p className={'text'}>{item?.name}</p>
                            </div>
                            {item.name === country?.name && (
                              <div className="dropDownIconBody">
                                <BsCheckLg className="dropDownIcon" />
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    );
                  })
                : List?.length === 0 && (
                    <div
                      className="ItemAlign"
                      onClick={() => {
                        handleClose('BTC');
                      }}
                    >
                      <p className="text">No Option available</p>
                    </div>
                  )}
            </div>
          </div>
        )}
        {/* </div> */}
      </>
    );
  }
);

export default SearchableDropDownItem;
