// src/features/auth/authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { GetSingleUser, loginUser, EditProfile } from "./authServiceSync";

const initialState = {
  loading: false,
  user: null,
  token: localStorage.getItem("token") ? localStorage.getItem("token") : null,
  error: null,

  // updating profile
  updatingProfile: false,
  profileUpdateSuccess: false,
  profileUpdateError: false,
  profileUpdateMessage: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
      localStorage.removeItem("token");
      toast.info("Logged out successfully");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.user = payload.user;
        state.token = payload.token;
        localStorage.setItem("token", payload.token);
      })
      .addCase(loginUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });

    builder
      .addCase(GetSingleUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetSingleUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.user = payload?.data;
      })
      .addCase(GetSingleUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });

    builder
      .addCase(EditProfile.pending, (state) => {
        state.updatingProfile = true;
      })
      .addCase(EditProfile.fulfilled, (state, { payload }) => {
        state.updatingProfile = false;
        state.profileUpdateSuccess = true;
        state.profileUpdateMessage = "updated profile";
      })
      .addCase(EditProfile.rejected, (state, { payload }) => {
        state.updatingProfile = false;
        state.profileUpdateError = true;
        state.profileUpdateMessage = "updated";
      });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
