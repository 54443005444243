import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import SubscriptionServices from "./subscriptionService";



export const UserSubscribe = createAsyncThunk(
  "subscription/UserSubscribe",
  async (credentials, thunkAPI) => {
    try {
      const delivered = await SubscriptionServices.UserSubscribe(credentials);
      console.log(delivered, "delivered")
      window.open(delivered?.data?.url, "_blank")
      return delivered;
    } catch (e) {
      toast(e.message);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const ConfirmUserSubscription = createAsyncThunk(
  "subscription/ConfirmUserSubscription",
  async (credentials, thunkAPI) => {
    try {
      const delivered = await SubscriptionServices.ConfirmUserSubscription(credentials);
      console.log(delivered, "delivered")
      // window.open(delivered?.data?.data?.link, "_blank")
      return delivered;
    } catch (e) {
      toast(e.message);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const VendorSubscribe = createAsyncThunk(
  "subscription/VendorSubscribe",
  async (credentials, thunkAPI) => {
    try {
      const delivered = await SubscriptionServices.VendorSubcribe(credentials);
      console.log(delivered?.data, "delivered")
      window.open(delivered?.data?.url, "_blank")
      return delivered;
    } catch (e) {
      toast(e.message);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const ConfirmVendorSubscription = createAsyncThunk(
  "subscription/ConfirmVendorSubscription",
  async (credentials, thunkAPI) => {
    try {
      const delivered = await SubscriptionServices.ConfirmVendorSubscription();
      console.log(delivered, "delivered")
      // window.open(delivered?.data?.data?.link, "_blank")
      return delivered;
    } catch (e) {
      toast(e.message);
      return thunkAPI.rejectWithValue(e);
    }
  }
);


