export function isStrongPassword(password) {
  // Check if the password has at least one uppercase letter
  const uppercaseRegex = /[A-Z]/;
  if (!uppercaseRegex.test(password)) {
    return false;
  }

  // Check if the password has at least one number
  const numberRegex = /[0-9]/;
  if (!numberRegex.test(password)) {
    return false;
  }

  // Check if the password has a length of at least 8 characters
  if (password.length < 8) {
    return false;
  }

  // If all checks pass, the password is considered strong
  return true;
}

export const isValidEmail = (email) => {
  // Regular expression for email validation
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailRegex.test(email);
};

// export default isStrongPassword;
