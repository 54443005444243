import React from "react";

const UserChangePassModal = () => {
  return (
    <div className="modal fade custom-modal" id="change-password">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content doctor-profile">
          <div className="modal-header border-bottom-0 justify-content-between">
            <h5 className="modal-title">Change Password</h5>
            <button
              type="button"
              className="close-btn"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="feather-x" />
            </button>
          </div>
          <div className="modal-body pt-0">
            <form action="https://html.truelysell.com/template3/success.html">
              <div className="form-group">
                <label className="col-form-label">Current Password</label>
                <div className="pass-group">
                  <input
                    type="password"
                    className="form-control pass-input"
                    placeholder="*************"
                  />
                  <span className="toggle-password feather-eye-off" />
                </div>
              </div>
              <div className="form-group">
                <label className="col-form-label">New Password</label>
                <div className="pass-group" id="passwordInput">
                  <input
                    type="password"
                    className="form-control pass-input"
                    placeholder="*************"
                  />
                  <span className="toggle-password feather-eye-off" />
                </div>
                <div className="password-strength" id="passwordStrength">
                  <span id="poor" />
                  <span id="weak" />
                  <span id="strong" />
                  <span id="heavy" />
                </div>
                <div id="passwordInfo">
                  Use 8 or more characters with a mix of letters, numbers &amp;
                  symbols.
                </div>
              </div>
              <div className="form-group">
                <label className="col-form-label">Confirm New Password</label>
                <div className="pass-group">
                  <input
                    type="password"
                    className="form-control pass-input"
                    placeholder="*************"
                  />
                  <span className="toggle-password feather-eye-off" />
                </div>
              </div>
              <div className="acc-submit">
                <a href="#" className="btn btn-secondary">
                  Cancel
                </a>
                <button className="btn btn-primary" type="submit">
                  Update Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserChangePassModal;
