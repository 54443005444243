export const automotiveSpecialization = [
  {
    id: 1,
    name: "General Automotive Repair Technicians",
    subtitle: "Comprehensive maintenance and repair for all vehicle types.",
  },
  {
    id: 2,
    name: "Diesel Mechanics",
    subtitle: "Specialists in heavy-duty diesel engine systems.",
  },
  {
    id: 3,
    name: "Transmission Specialists",
    subtitle: "Experts in manual and automatic transmission repair.",
  },
  {
    id: 4,
    name: "Engine Repair Technicians",
    subtitle: "Professionals in engine diagnostics and rebuilding.",
  },
  {
    id: 5,
    name: "Automotive Electrical System Technicians",
    subtitle: "Masters of vehicle electrical and control systems.",
  },
  {
    id: 6,
    name: "Brake System Technicians",
    subtitle: "Ensuring reliable and safe braking systems.",
  },
  {
    id: 7,
    name: "Suspension and Steering Technicians",
    subtitle: "Optimizing vehicle handling and ride comfort.",
  },
  {
    id: 8,
    name: "Tire and Wheel Specialists",
    subtitle: "Ensuring optimal tire performance and alignment.",
  },
  {
    id: 9,
    name: "Exhaust and Emissions Technicians",
    subtitle: "Reducing emissions for cleaner, greener vehicles.",
  },
  {
    id: 10,
    name: "Performance and Motorsports Technicians",
    subtitle: "Enhancing high-performance and motorsports vehicles.",
  },
  {
    id: 11,
    name: "Agricultural and Heavy Equipment Mechanics",
    subtitle: "Maintaining machinery for agriculture and construction.",
  },
  {
    id: 12,
    name: "Marine and Powersports Technicians",
    subtitle: "Servicing recreational and marine vehicles.",
  },
  {
    id: 13,
    name: "Fleet Maintenance Technicians",
    subtitle: "Keeping fleet vehicles in top condition.",
  },
  {
    id: 14,
    name: "Classic and Vintage Car Restoration Technicians",
    subtitle: "Preserving the charm of timeless vehicles.",
  },
  {
    id: 15,
    name: "Hybrid Vehicle Technicians",
    subtitle: "Bridging combustion engines and electric propulsion.",
  },
  {
    id: 16,
    name: "Commercial Truck and Trailer Technicians",
    subtitle: "Supporting commercial transportation reliability.",
  },
  {
    id: 17,
    name: "Paint and Body Repair Technicians",
    subtitle: "Restoring vehicles after collisions or wear.",
  },
  {
    id: 18,
    name: "Air Conditioning and Climate Control Technicians",
    subtitle: "Ensuring comfort through advanced HVAC systems.",
  },
  {
    id: 19,
    name: "Advanced Driver Assistance Systems (ADAS) Technicians",
    subtitle: "Maintaining cutting-edge driver safety technologies.",
  },
  {
    id: 20,
    name: "Customer Service and Diagnostic Technicians",
    subtitle: "Balancing technical expertise with client support.",
  },
  {
    id: 21,
    name: "Electric Vehicle (EV) Specialists",
    subtitle: "Experts in electric and hybrid vehicle technologies.",
  },
  {
    id: 22,
    name: "Connected Vehicle Technology Experts",
    subtitle: "Pioneers in vehicle connectivity and cybersecurity.",
  },
  {
    id: 23,
    name: "Alternative Fuel Systems Technicians",
    subtitle: "Driving sustainability with alternative fuel expertise.",
  },
  {
    id: 24,
    name: "Autonomous Vehicle Diagnostic Specialists",
    subtitle: "Diagnosing and maintaining self-driving vehicle systems.",
  },
  {
    id: 25,
    name: "Augmented Reality (AR) Repair Technicians",
    subtitle: "Innovating with AR for automotive diagnostics.",
  },
  {
    id: 26,
    name: "Sustainable Manufacturing and Repair Technicians",
    subtitle: "Promoting eco-friendly automotive practices.",
  },
  {
    id: 27,
    name: "Advanced Materials and Composite Repair Experts",
    subtitle: "Specialists in modern lightweight vehicle materials.",
  },
  {
    id: 28,
    name: "AI and Predictive Maintenance Technicians",
    subtitle: "Leveraging AI for vehicle health monitoring.",
  },
  {
    id: 29,
    name: "Cybersecurity and Vehicle Network Protection Specialists",
    subtitle: "Protecting vehicles from digital threats.",
  },
  {
    id: 30,
    name: "Robotics and Automation Integration Technicians",
    subtitle: "Integrating advanced robotics in automotive workflows.",
  },
  {
    id: 31,
    name: "Renewable Energy Vehicle Infrastructure Technicians",
    subtitle: "Supporting renewable energy-based automotive systems.",
  },
];
