import React from "react";
import ProfileSettingComp from "../../../components/dashboard/provider/profile-setting";

const ProfileSetting = () => {
  return (
    <>
      <ProfileSettingComp />
    </>
  );
};

export default ProfileSetting;
