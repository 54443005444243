const requiredValues = {
  service_title: "car ac repairs",
  category: "car",
  sub_category: "benz",
  price: "5000",
  duration: "per 1h",
  description: "this what i do",
  video_uri: "https://www.youtube.com/watch?v=FUfWAkwFKCs&t=1618s",
  additional_services: [
    {
      serice_item: "season",
      price: "20",
      duration: "1h",
    },
    {
      serice_item: "season",
      price: "20",
      duration: "1h",
    },
  ],
  availability: {
    all_days: [
      {
        from: "2:30",
        to: "4:40",
        slots: "3",
      },
      {
        from: "2:30",
        to: "4:40",
        slots: "3",
      },
    ],
    monday: [
      {
        from: "2:30",
        to: "4:40",
        slots: "3",
      },
      {
        from: "2:30",
        to: "4:40",
        slots: "3",
      },
    ],
    friday: [
      {
        from: "2:30",
        to: "4:40",
        slots: "3",
      },
      {
        from: "2:30",
        to: "4:40",
        slots: "3",
      },
    ],
  },
  address: "lubge",
  country: "Nigeria",
  city: "Abuja",
  state: "fct",
  pincode: "900102",
  googleMapPlaceId: "1122633737",
  longitude: "hdhd626262626",
  latitude: "hdd626266sjs",
  meta_title: "car repairs",
  meta_keywords: "car",
  meta_description: "welcome to oure care service",
  gallery: [
    {
      pic_url:
        "https://res.cloudinary.com/dyful7cgo/image/upload/v1705678103/airdrop_punch/zs4ccz67svdvkctauu9q.png",
      pic_id: "airdrop_punch/zs4ccz67svdvkctauu9q",
    },
    {
      pic_url:
        "https://res.cloudinary.com/dyful7cgo/image/upload/v1705678103/airdrop_punch/zs4ccz67svdvkctauu9q.png",
      pic_id: "airdrop_punch/zs4ccz67svdvkctauu9q",
    },
  ],
};

export const serviceInitialState = {
  service_title: "",
  category: "car",
  sub_category: "benz",
  price: "",
  duration: "",
  description: "",
  video_uri: "",
  additional_services: [{ service_item: "", price: "", duration: "" }],
  availability: {
    all_days: [{ from: "", to: "", slots: "" }],
    monday: [{ from: "", to: "", slots: "" }],
    tuesday: [{ from: "", to: "", slots: "" }],
    wednesday: [{ from: "", to: "", slots: "" }],
    thursday: [{ from: "", to: "", slots: "" }],
    friday: [{ from: "", to: "", slots: "" }],
    saturday: [{ from: "", to: "", slots: "" }],
    sunday: [{ from: "", to: "", slots: "" }],
  },
  address: "",
  country: "Nigeria",
  city: "",
  state: "",
  pincode: "",
  googleMapPlaceId: "",
  longitude: "",
  latitude: "",
  meta_title: "",
  meta_keywords: "",
  meta_description: "",
  gallery: [{ pic_url: "", pic_id: "" }],
};
